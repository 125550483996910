import React, { useState } from 'react';
import { useFormContext } from 'react-hook-form';

import { CollectiomFormType } from 'types';

import { SelectFile } from 'components/forms';
import { CollectionAssignmentType, FileType } from 'cogamika-back/types';

interface Props {
  setIsShow: React.Dispatch<React.SetStateAction<boolean>>;
  collectionType: CollectionAssignmentType;
}

export const SelectCollectionImage: React.FC<Props> = ({
  setIsShow,
  collectionType,
}) => {
  const { setValue } = useFormContext<CollectiomFormType>();
  const [selectedFile, setSelectedFile] = useState(() => new Set<string>());
  const [fileName, setFileName] = useState<string>('');

  const handleFileSelect = (
    id: string,
    type?: FileType,
    originalName?: string
  ) => {
    if (selectedFile.has(id)) {
      setSelectedFile(new Set());
      setFileName('');
    } else {
      setSelectedFile(new Set([id]));
      setFileName(originalName || 'image');
    }
  };

  const save = () => {
    setValue('collection.type', collectionType);
    setValue('collection.imageId', Array.from(selectedFile)[0]);
    setValue('collection.name', fileName);
  };

  return (
    <SelectFile
      handleFileSelect={handleFileSelect}
      selectedFile={selectedFile}
      setIsShow={setIsShow}
      onSave={save}
    />
  );
};
