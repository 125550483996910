import React, { useEffect } from 'react';

import { useAppDispatch } from 'hooks';
import { hideNotification } from 'slices';
import { Alert } from 'components/common';
import { SingleNotification } from 'types';

import { StyledNotification } from './styles';

interface Props {
  notification: SingleNotification;
}

export const Notification: React.FC<Props> = ({ notification }) => {
  const dispatch = useAppDispatch();

  useEffect(() => {
    const timeout = setTimeout(
      () => dispatch(hideNotification(notification.id)),
      5000
    );

    return () => clearTimeout(timeout);
  }, [dispatch, notification.id]);

  const onClick = () => dispatch(hideNotification(notification.id));

  return (
    <StyledNotification onClick={onClick} role="button" tabIndex={0}>
      <Alert type={notification.type} text={notification.text} />
    </StyledNotification>
  );
};
