import { useContext, useState } from 'react';
import { useApi } from './useApi';
import { prepareApiParamsObj } from 'utils';
import {
  BasePaginationRes,
  CategoryReq,
  CategoryRes,
  StatusRes,
} from 'cogamika-back/types';
import { BasePaginationContext } from 'context';
import { useAppDispatch } from './redux';
import { successNotification } from 'slices';

interface UseCategory {
  getCategory: () => Promise<void>;
  addCategory: (data: CategoryReq) => Promise<void>;
  editCategory: (data: CategoryReq, id: string) => Promise<void>;
  removeCategory: (id: string) => Promise<void>;
  categoryData: CategoryRes[];
}

export const useCategory = (): UseCategory => {
  const { setPagesPossibility, pagination } = useContext(BasePaginationContext);
  const [categoryData, setCategoryData] = useState<CategoryRes[]>([]);
  const { get, post, remove, patch } = useApi();
  const dispatch = useAppDispatch();

  const getCategory = async () => {
    const result = await get<BasePaginationRes<CategoryRes>>(
      'Category/paginated',
      prepareApiParamsObj({ ...pagination })
    );

    if (result) {
      const { items, hasNextPage, hasPreviousPage } = result;
      setCategoryData(items);
      setPagesPossibility({
        hasNextPage,
        hasPreviousPage,
      });
    }
  };

  const addCategory = async (data: CategoryReq) => {
    const result = await post<CategoryRes, CategoryReq>('category', data);

    if (result) {
      dispatch(successNotification('notifications.successfullyAddedCategory'));
      getCategory();
    }
  };

  const editCategory = async (data: CategoryReq, id: string) => {
    const result = await patch<CategoryRes, CategoryReq>(
      `category/${id}`,
      data
    );

    if (result) {
      dispatch(successNotification('notifications.successfullyEditedCategory'));
      getCategory();
    }
  };

  const removeCategory = async (id: string) => {
    const result = await remove<StatusRes>(`category/${id}`);

    if (result) {
      dispatch(
        successNotification('notifications.successfullyDeletedCategory')
      );
      getCategory();
    }
  };

  return {
    categoryData,
    getCategory,
    addCategory,
    editCategory,
    removeCategory,
  };
};
