import {
  AccessibleCollectionItem,
  CollectionSettingsRes,
} from 'cogamika-back/types';
import { GameResult } from 'types';
import { Game } from 'games';
import { shuffle } from 'utils';

export class Collection extends Game {
  public settings: CollectionSettingsRes;
  public accessibleImages: AccessibleCollectionItem[];

  constructor(
    level: number,
    gameId: string,
    domainId: string,
    settings: CollectionSettingsRes
  ) {
    super(level, gameId, domainId);
    this.settings = settings;
    this.accessibleImages = [];

    this.initGame();
  }

  private initGame() {
    this.accessibleImages = shuffle(this.settings.accessibleImages);
  }

  public get firstCollection() {
    return this.settings.firstCollection;
  }

  public get secondCollection() {
    return this.settings.secondCollection;
  }

  public get accessibleImagesAmount() {
    return this.settings.accessibleImagesAmount;
  }

  public getImage(index: number) {
    return this.accessibleImages[index];
  }

  public checkIsNextImage(index: number) {
    return this.settings.accessibleImagesAmount >= index + 1;
  }

  public endCollectionGame(resultGame?: GameResult): void {
    if (resultGame === GameResult.Lose) {
      super.endGame(false);
      return;
    }

    const result = this.result;
    const isWin = result.incorrectAnswers === 0;

    super.endGame(isWin);
  }
}
