import styled from 'styled-components';

export const StyledSelect = styled.select(
  ({ theme: { radius, colors, fontSizes } }) => `
  width: 100%;
  padding: 14px 30px 14px 0px;
  border-radius: ${radius.md};
  background-color: ${colors.light};
  font-size: ${fontSizes.base};
  color: ${colors.secondaryGray};
  font-weight: 600;
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  background-image: url('/assets/images/icons/dropdown.svg');
  background-repeat: no-repeat;
  background-position: right center;
  background-color: transparent;
  border: none;
`
);

export const Option = styled.option`
  text-align: center;
`;
