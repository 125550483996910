import { Button, Flex, FlexAlignCenter, Heading } from 'components/common';
import { FC, useEffect, useState } from 'react';
import { Form, Select } from 'components/forms';
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import { DomainBaseRes } from 'cogamika-back/types';
import { useApi } from 'hooks';
import { CsvFileDownloadSchema } from 'types';
import { csvDomain } from 'config';
import { BoxContainer } from './styles';

export const CsvFiles: FC = () => {
  const { get, download } = useApi();
  const [domains, setDomains] = useState<DomainBaseRes[]>([]);

  const methods = useForm<CsvFileDownloadSchema>({
    mode: 'onSubmit',
    resolver: yupResolver(csvDomain),
  });

  const getDomains = async () => {
    const domainsRes = await get<DomainBaseRes[]>('/domain');
    if (domainsRes) {
      setDomains(domainsRes);
    }
  };

  const handleOnDownloadFile = async (domainId: string) => {
    const asName = domains.find(({ id }) => id === domainId)?.name || 'file';
    const blob = await download(`file/csv/${domainId}`);

    if (blob) {
      const extension = 'csv';
      const link = document.createElement('a');
      link.href = URL.createObjectURL(blob);
      link.setAttribute('download', `${asName}.${extension}`);
      document.body.appendChild(link);
      link.click();
    }
  };

  const submit = (data: CsvFileDownloadSchema) => {
    const { domainId } = data;
    handleOnDownloadFile(domainId);
  };

  useEffect(() => {
    getDomains();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <Flex gap="xl" columnDirection fullWidth>
      <FlexAlignCenter justifyContent="space-between" gap="lg" fullWidth>
        <Heading size="md" text="views.settings.csvFiles.heading" />
      </FlexAlignCenter>
      <BoxContainer>
        <Form submitHandler={submit} methods={methods} fullWidth>
          <Flex columnDirection gap="lg" fullWidth alignItems="center">
            <Select
              label="labels.domain"
              name="domainId"
              options={domains.map((option) => ({
                label: option.name,
                value: option.id,
              }))}
              noTranslate
            />
            <Button
              text="button.download"
              variant="secondary"
              type="submit"
              uppercase
            />
          </Flex>
        </Form>
      </BoxContainer>
    </Flex>
  );
};
