import React, { useContext, useState } from 'react';

import { MosaicImageType } from 'cogamika-back/types';
import { GameFormSteps, MosaicGameFormSteps } from 'types';
import { GameFormContext } from 'context';
import { Levels } from 'components/forms';

import { MosaicGameForm } from './MosaicGameForm';

export const Mosaic: React.FC = () => {
  const { setStep, gameSettings } = useContext(GameFormContext);

  const [stage, setStage] = useState<MosaicGameFormSteps>(
    MosaicGameFormSteps.Levels
  );
  const [level, setLevel] = useState<number>();
  const levelsSettings = gameSettings?.mosaicLevelSettings;

  const selectLevel = (level: number) => {
    setLevel(level);
    setStage(MosaicGameFormSteps.MosaicFormCards);
  };

  const levelsGoBack = () => {
    setStep(GameFormSteps.Instruction);
  };

  const levelsSubmit = () => {
    setStep(GameFormSteps.PublishGame);
  };

  const getStage = (type: MosaicGameFormSteps) => {
    switch (type) {
      case MosaicGameFormSteps.Levels:
        return (
          <Levels
            levelsSettings={levelsSettings?.levels}
            selectLevel={selectLevel}
            goBack={levelsGoBack}
            submit={levelsSubmit}
          />
        );
      case MosaicGameFormSteps.MosaicFormCards:
        return (
          <MosaicGameForm
            level={level || 1}
            setStage={setStage}
            type={MosaicImageType.Card}
            key={MosaicImageType.Card}
          />
        );
      case MosaicGameFormSteps.MosaicFormDistractors:
        return (
          <MosaicGameForm
            level={level || 1}
            setStage={setStage}
            type={MosaicImageType.Distractor}
            key={MosaicImageType.Distractor}
          />
        );
      default:
        return <></>;
    }
  };

  return getStage(stage);
};
