import { Form } from 'components/forms';
import styled from 'styled-components';

export const StyledForm = styled(Form)(
  () => `
  width: 100%;
`
);

export const BoxContainer = styled('div')(
  ({ theme: { colors } }) => `
  display: flex;
  align-items: center;
  flex-direction: column;
  padding: 40px 54px;
  gap: 40px;
  width: 100%;
  background-color: ${colors.secondaryBackground};
  box-shadow: ${colors.boxShadow};
`
);
