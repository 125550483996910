import React from 'react';

import { hideModal } from 'slices';
import { useAppDispatch } from 'hooks';
import { Icon, Heading, Text, Button, Flex } from 'components/common';

import { MainContainer } from './styles';

export const RegisterConfirmation: React.FC = () => {
  const dispatch = useAppDispatch();

  const onHandleCloseModal = () => {
    dispatch(hideModal());
  };

  return (
    <MainContainer>
      <Icon name="mail" />
      <Flex columnDirection gap="sm">
        <Heading text="modals.registerConfirmation.heading" size="lg" />
        <Text text="modals.registerConfirmation.description" size="sm" />
      </Flex>
      <Button
        text="button.close"
        variant="primary"
        uppercase
        onClick={onHandleCloseModal}
        dataCy="register-confirmation-close"
      />
    </MainContainer>
  );
};
