import { FC } from 'react';
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import { Button, Flex, FlexAlignCenter, Heading } from 'components/common';
import { Form, TextInput } from 'components/forms';
import { addAdminSchema } from 'config';
import { useApi, useAppDispatch } from 'hooks';
import { RegisterManually, UserRes } from 'cogamika-back/types';
import { AddAdmin as AddAdminSchema } from 'types';
import { clearErrorNotification, successNotification } from 'slices';
import { BoxContainer } from './styles';

export const AddAdmin: FC = () => {
  const { post } = useApi();
  const dispatch = useAppDispatch();

  const methodsEditProfile = useForm<AddAdminSchema>({
    mode: 'onSubmit',
    resolver: yupResolver(addAdminSchema),
  });

  const { setValue } = methodsEditProfile;

  const submitEditData = async (data: AddAdminSchema) => {
    dispatch(clearErrorNotification());
    const { lastName, firstName, email } = data;
    const preparedData: RegisterManually = {
      lastName,
      firstName,
      email,
    };

    const res = await post<UserRes, RegisterManually>(
      '/user/register-admin-manually',
      preparedData
    );

    if (res) {
      setValue('email', '');
      setValue('firstName', '');
      setValue('lastName', '');
      dispatch(successNotification('notifications.successfullyAddedAdmin'));
    }
  };

  return (
    <Flex gap="xl" columnDirection fullWidth>
      <Form
        submitHandler={submitEditData}
        methods={methodsEditProfile}
        fullWidth
      >
        <Flex gap="xl" columnDirection fullWidth>
          <FlexAlignCenter justifyContent="space-between" gap="lg" fullWidth>
            <Heading size="md" text="views.settings.addAdmin.heading" />
            <Button
              text="button.add"
              variant="outlined"
              type="submit"
              uppercase
            />
          </FlexAlignCenter>
          <BoxContainer>
            <Flex gap="lg" columnDirection fullWidth>
              <TextInput name="email" placeholder="email" fullWidth required />
              <TextInput
                name="firstName"
                placeholder="firstName"
                fullWidth
                required
              />
              <TextInput
                name="lastName"
                placeholder="lastName"
                fullWidth
                required
              />
            </Flex>
          </BoxContainer>
        </Flex>
      </Form>
    </Flex>
  );
};
