import { useForm } from 'react-hook-form';
import React, { useContext, useEffect } from 'react';
import { yupResolver } from '@hookform/resolvers/yup';

import { gameSelectLanguage } from 'config';
import { Language } from 'cogamika-back/types';
import { GameFormSteps, GameSelectLanguageType } from 'types';
import { GameFormContext } from 'context';
import { getSelectOptionsFromEnum } from 'utils';
import { Form, Select } from 'components/forms';
import { Button, Flex, Heading } from 'components/common';

import { FormContainer, StyledDetailsForm } from './styles';

export const SelectLanguage: React.FC = () => {
  const { setDetailsForm, detailsForm, setStep } = useContext(GameFormContext);

  const methods = useForm<GameSelectLanguageType>({
    mode: 'onSubmit',
    resolver: yupResolver(gameSelectLanguage),
  });

  const { isSubmitting, isValid } = methods.formState;
  const isButtonDisabled = isSubmitting || !isValid;

  const submit = (data: GameSelectLanguageType) => {
    setStep(GameFormSteps.GameName);
    setDetailsForm((prev) => {
      return {
        ...prev,
        lang: data.lang,
      };
    });
  };

  useEffect(() => {
    methods.reset({
      lang: detailsForm?.lang,
    });
  }, [methods, detailsForm]);

  return (
    <StyledDetailsForm>
      <Heading text="views.newGame.selectLanguage" size="xl" />
      <FormContainer>
        <Form submitHandler={submit} methods={methods}>
          <Flex columnDirection gap="lg">
            <Select
              label="labels.language"
              name="lang"
              options={getSelectOptionsFromEnum(Language, true)}
              noTranslate
            />
            <Button
              text="button.next"
              variant="secondary"
              fullWidth
              type="submit"
              uppercase
              disabled={isButtonDisabled}
            />
          </Flex>
        </Form>
      </FormContainer>
    </StyledDetailsForm>
  );
};
