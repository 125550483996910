import Sidebar from 'react-sidebar';
import React, { useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';

import { ColorType } from 'types';
import { Button, Flex, GameNavigation } from 'components/common';
import { clearInstruction, clearSessionGames } from 'slices';
import { AppConfig, adminRoutes, routes, superAdminRoutes } from 'config';
import { useAppDispatch, useAppSelector } from 'hooks';
import { CloseGame, SidebarInstruction } from 'components/games';
import { colors } from 'config/theme/basicStyleValues/colors';
import { UserRole } from 'cogamika-back/types';
import { replaceParams } from 'utils';
import { __ } from 'utils/i18n';

import {
  StyledGameLayout,
  StyledLevelContainer,
  StyledMainContainer,
} from './styles';

interface Props {
  children: React.ReactNode;
  bgColor?: ColorType;
}

export const GameTestLayout: React.FC<Props> = ({
  children,
  bgColor = 'primaryBackground',
}) => {
  const { level, gameId } = useParams();
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const { user } = useAppSelector((state) => state.user);
  const { instruction } = useAppSelector((state) => state.game);
  const [isLeavingGame, setIsLeavingGame] = useState<boolean>(false);
  const [isInstructionOpen, setIsInstructionOpen] = useState<boolean>(false);

  const onShowPopUp = () => setIsLeavingGame(true);
  const onClosePopUp = () => setIsLeavingGame(false);

  const onOpenInstruction = () => setIsInstructionOpen(true);
  const onCloseInstruction = () => setIsInstructionOpen(false);

  const onLeaveGame = () => {
    dispatch(clearSessionGames());
    navigate(routes.root);
  };

  const changeLevel = (level: number) => {
    const url =
      user?.role === UserRole.SuperAdmin
        ? superAdminRoutes.gameTest
        : adminRoutes.gameTest;
    dispatch(clearInstruction());
    dispatch(clearSessionGames());
    navigate(replaceParams(url, { gameId, level }));
  };

  const editGame = () => {
    const url =
      user?.role === UserRole.SuperAdmin
        ? superAdminRoutes.editGame
        : adminRoutes.editGame;
    dispatch(clearInstruction());
    dispatch(clearSessionGames());
    navigate(replaceParams(url, { id: gameId }));
  };

  const renderLevelButtons = () =>
    AppConfig.GAME_LEVELS.map((item) => (
      <Button
        fullWidth
        key={item}
        text={__('views.gameTest.level', { level: item })}
        noTranslate
        variant="outlined"
        onClick={() => changeLevel(item)}
      />
    ));

  return (
    <Sidebar
      sidebar={
        instruction && (
          <SidebarInstruction
            instruction={instruction}
            onCloseInstruction={onCloseInstruction}
          />
        )
      }
      open={isInstructionOpen}
      styles={{ sidebar: { background: colors.light } }}
      onSetOpen={onCloseInstruction}
    >
      <StyledGameLayout bgColor={bgColor}>
        <GameNavigation
          onClose={onShowPopUp}
          onOpenInstruction={onOpenInstruction}
        />
        <Flex alignItems="center">
          <StyledMainContainer>{children}</StyledMainContainer>
          <StyledLevelContainer>
            <Button
              text="button.editGame"
              variant="secondary"
              onClick={editGame}
            />
            {renderLevelButtons()}
          </StyledLevelContainer>
        </Flex>
        {isLeavingGame && (
          <CloseGame onClose={onClosePopUp} onLeave={onLeaveGame} />
        )}
      </StyledGameLayout>
    </Sidebar>
  );
};
