import { createSlice, PayloadAction } from '@reduxjs/toolkit';

import { GameState, Instruction } from 'types';

const initialState: GameState = {
  isStarted: false,
  isInstructionOpen: false,
};

export const gameSlice = createSlice({
  name: 'game',
  initialState,
  reducers: {
    startGame: (state) => {
      state.isStarted = true;
    },
    stopGame: (state) => {
      state.isStarted = false;
    },
    openInstruction: (state) => {
      state.isInstructionOpen = true;
    },
    hideInstruction: (state) => {
      state.isInstructionOpen = false;
    },
    setInstruction: (state, action: PayloadAction<Instruction>) => {
      state.instruction = action.payload;
    },
    clearInstruction: (state) => {
      state.instruction = undefined;
    },
  },
});

export const {
  startGame,
  openInstruction,
  hideInstruction,
  setInstruction,
  clearInstruction,
} = gameSlice.actions;
