import React from 'react';

import { ColorType, FontSizeType, FontWeightType } from 'types';
import { TranslateText } from 'components/common';

import { BasicHeading } from './styles';

interface Props {
  text: string;
  size: FontSizeType;
  color?: ColorType;
  noTranslate?: boolean;
  translationArgs?: Record<string, string | number>;
  className?: string;
  weight?: FontWeightType;
  uppercase?: boolean;
  as?: 'h1' | 'h2' | 'h3' | 'h4' | 'h5';
}

export const Heading: React.FC<Props> = ({
  text,
  size,
  color = 'primary',
  className,
  translationArgs,
  noTranslate,
  weight,
  uppercase,
  as,
}) => {
  return (
    <BasicHeading
      size={size}
      color={color}
      className={className}
      weight={weight}
      as={as}
      uppercase={uppercase}
    >
      <TranslateText
        text={text}
        translationArgs={translationArgs}
        noTranslate={noTranslate}
      />
    </BasicHeading>
  );
};
