export const getLetterByIndex = (index: number) => {
  switch (index) {
    case 0:
      return String.fromCharCode(65 + index);
    case 1:
      return String.fromCharCode(65 + index);
    case 2:
      return String.fromCharCode(65 + index);
    case 3:
      return String.fromCharCode(65 + index);
    default:
      return '';
  }
};
