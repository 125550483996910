import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { UserRes } from 'cogamika-back/types';
import { axiosInstance } from 'config';
import { ApiPayload } from 'hooks/useApi';
import { UserState } from 'types';

const initialState: UserState = {
  user: null,
};

export const getUserDetails = createAsyncThunk('getUserData', async () =>
  axiosInstance
    .get<ApiPayload<UserRes>>('auth/me')
    .then((res) => res.data.payload)
);

export const logout = createAsyncThunk('logout', async () =>
  axiosInstance.post('auth/logout').then((res) => res.data.payload)
);

export const userSlice = createSlice({
  name: 'modal',
  initialState,
  reducers: {
    setUser: (state, action) => {
      state.user = action.payload;
    },
    clearUserData: (state) => {
      state.user = null;
    },
  },
  extraReducers(builder) {
    builder.addCase(getUserDetails.fulfilled, (state, action) => {
      state.user = action.payload ?? null;
    });
    builder.addCase(logout.fulfilled, (state) => {
      state.user = null;
    });
  },
});

export const { setUser, clearUserData } = userSlice.actions;
