import { Dispatch, FC, SetStateAction } from 'react';
import { Flex, Text, FlexCenter, IconButton } from 'components/common';
import { useFaq } from 'hooks';
import { FaqTopicBox } from '../styles';

interface FaqTopicContentProps {
  setTopicEditing: Dispatch<SetStateAction<boolean>>;
  topicId: string;
  topic: string;
}

export const FaqTopicContent: FC<FaqTopicContentProps> = ({
  setTopicEditing,
  topic,
  topicId,
}) => {
  const { deleteTopic } = useFaq();

  return (
    <FaqTopicBox>
      <Flex
        alignItems="center"
        justifyContent="space-between"
        gap="md"
        fullWidth
      >
        <Text text={topic} noTranslate weight="bold" />
        <FlexCenter gap="sm">
          <IconButton
            name="delete"
            onClick={() => deleteTopic(topicId)}
            type="button"
          />
          <IconButton
            name="edit"
            onClick={() => setTopicEditing(true)}
            type="button"
          />
        </FlexCenter>
      </Flex>
    </FaqTopicBox>
  );
};
