import React, { useEffect, useState } from 'react';

import { DomainBaseRes } from 'cogamika-back/types';
import { useApi } from 'hooks';
import { Button } from 'components/common';

import { DomainsFilterContainer } from './styles';

interface Props {
  setDomainId: (id: string) => void;
  selectedDomainId?: string;
}

export const DomainsFilter: React.FC<Props> = ({
  setDomainId,
  selectedDomainId,
}) => {
  const { get } = useApi();
  const [domains, setDomains] = useState<DomainBaseRes[]>([]);

  const getDomains = async () => {
    const domainsRes = await get<DomainBaseRes[]>('/domain');
    if (domainsRes) {
      setDomains(domainsRes);
    }
  };

  const selectDomainId = (id: string) => {
    setDomainId(id);
  };

  useEffect(() => {
    getDomains();

    // eslint-disable-next-line
  }, []);

  return (
    <DomainsFilterContainer>
      {domains.map((domain) => (
        <Button
          key={domain.id}
          text={domain.name}
          variant={
            selectedDomainId === domain.id
              ? 'domains-filter-selected'
              : 'domains-filter'
          }
          noTranslate
          onClick={() => selectDomainId(domain.id)}
        />
      ))}
    </DomainsFilterContainer>
  );
};
