import styled from 'styled-components';

import { MoviePressedResult, Theme } from 'types';
import { Button } from 'components/common';

export const Container = styled.div`
  height: 100%;
  position: relative;
`;

export const StyledVideo = styled.video`
  height: 100%;
  width: 100%;
`;

export const ButtonContainer = styled.div`
  position: absolute;
  bottom: 72px;
  left: 50%;
  transform: translateX(-50%);
`;

interface ReactButtonProps {
  result: MoviePressedResult;
}

const getVariant = (result: MoviePressedResult, theme: Theme) => {
  switch (result) {
    case MoviePressedResult.Sucess:
      return `
        color: ${theme.colors.light};
        background-color: ${theme.colors.success};
        &:disabled {   
          color: ${theme.colors.light};
          background-color: ${theme.colors.success};
        }
        &:hover {
          color: ${theme.colors.light};
          background-color: ${theme.colors.success};
        }
      `;
    case MoviePressedResult.Failure:
      return `
        color: ${theme.colors.light};
        background-color: ${theme.colors.error};
        &:disabled {   
          color: ${theme.colors.light};
          background-color: ${theme.colors.error};
        }
        &:hover {
          color: ${theme.colors.light};
          background-color: ${theme.colors.error};
        }
      `;
    case MoviePressedResult.InProgress:
      return `
        &:hover:not([disabled]) {
          background-color: ${theme.colors.light};
          color: ${theme.colors.secondary};
        }
      `;
  }
};

export const ReactButton = styled(Button)<ReactButtonProps>(
  ({ theme: { spaces, fontSizes, colors }, theme, result }) => `
  padding: ${spaces['4xl']} 80px;
  font-size: ${fontSizes.lg};
  background-color: ${colors.light};
  color: ${colors.primary};
  display: flex;
  height: auto;
  ${getVariant(result, theme)}; 
  
`
);
