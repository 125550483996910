import React, { useEffect, useState } from 'react';

import { Memory } from 'games';
import { MemoryCardType } from 'types';
import { Spinner } from 'components/common';

import MemoryCard from '../MemoryCard';
import { MemoryCardContainer } from './styles';

interface Props {
  memoryCards: MemoryCardType[];
  level: number;
  memory: Memory;
  cardClick: (cardId: string) => void;
  setMemoryCards: React.Dispatch<React.SetStateAction<MemoryCardType[]>>;
}

const MemoryCards: React.FC<Props> = ({
  memoryCards,
  cardClick,
  level,
  setMemoryCards,
  memory,
}) => {
  const [loadedImages, setLoadedImages] = useState(0);
  const [isAllImagesLoaded, setIsAllImagesLoaded] = useState(false);

  const handleImageLoad = () => {
    setLoadedImages((prevLoadedImages) => prevLoadedImages + 1);
  };

  useEffect(() => {
    if (loadedImages === memoryCards.length) {
      memory.startMemoryGame(setMemoryCards);
      setIsAllImagesLoaded(true);
    }

    // eslint-disable-next-line
  }, [loadedImages]);

  return (
    <MemoryCardContainer level={level}>
      {!isAllImagesLoaded && <Spinner />}
      {memoryCards.map((memoryCard) => (
        <MemoryCard
          key={memoryCard.id}
          card={memoryCard}
          handleClick={() => cardClick(memoryCard.id)}
          level={level}
          isAllImagesLoaded={isAllImagesLoaded}
          handleImageLoad={handleImageLoad}
        />
      ))}
    </MemoryCardContainer>
  );
};

export default MemoryCards;
