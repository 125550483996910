import React from 'react';
import { FormProvider, SubmitHandler, UseFormReturn } from 'react-hook-form';
import { StyledForm } from './styled';

interface Props<T extends object> {
  methods: UseFormReturn<T>;
  submitHandler: SubmitHandler<T>;
  children: React.ReactNode;
  className?: string;
  fullWidth?: boolean;
}

export const Form = <T extends object>({
  methods,
  children,
  submitHandler,
  className,
  fullWidth,
}: Props<T>) => {
  return (
    <StyledForm
      onSubmit={methods.handleSubmit(submitHandler)}
      className={className}
      fullWidth={fullWidth}
    >
      <FormProvider {...methods}>{children}</FormProvider>
    </StyledForm>
  );
};
