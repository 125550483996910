import React from 'react';

import { adminRoutes } from 'config';

const AdminDashboard = React.lazy(() => import('views/Admin/Dashboard'));
const AdminNewGame = React.lazy(() => import('views/Admin/NewGame'));
const AdminFiles = React.lazy(() => import('views/Admin/Files'));
const AdminSettings = React.lazy(() => import('views/Admin/Settings'));
const AdminEditGame = React.lazy(() => import('views/Admin/EditGame'));
const AdminTestGame = React.lazy(() => import('views/Admin/GameTest'));

export const AdminRoutes = [
  {
    path: adminRoutes.dashboard,
    element: <AdminDashboard />,
  },
  {
    path: adminRoutes.gameTest,
    element: <AdminTestGame />,
  },
  {
    path: adminRoutes.settings,
    element: <AdminSettings />,
  },
  {
    path: adminRoutes.newGame,
    element: <AdminNewGame />,
  },
  {
    path: adminRoutes.editGame,
    element: <AdminEditGame />,
  },
  {
    path: adminRoutes.files,
    element: <AdminFiles />,
  },
];
