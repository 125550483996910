import { FC } from 'react';
import { ColorType, IconName } from 'types';
import { Container } from './styles';
import { Icon } from '../Icon';
import { Text } from '../Text';

interface MarkedInfoBoxProps {
  text: string;
  icon: IconName;
  color: ColorType;
  translationArgs?: Record<string, string | number>;
  fullWidth?: boolean;
}

export const MarkedInfoBox: FC<MarkedInfoBoxProps> = ({
  text,
  icon,
  color,
  translationArgs,
  fullWidth = false,
}) => {
  return (
    <Container color={color} fullWidth={fullWidth}>
      <Icon name={icon} />
      <Text text={text} color={color} translationArgs={translationArgs} />
    </Container>
  );
};
