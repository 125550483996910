import { FaqRes } from 'cogamika-back/types';
import { createContext, Dispatch, SetStateAction } from 'react';

type FaqContextType = {
  faqData: FaqRes;
  setFaqData: Dispatch<SetStateAction<FaqRes>>;
};

export const FaqContext = createContext<FaqContextType>({
  faqData: [],
  setFaqData: () => {},
});
