import { FC, useEffect } from 'react';
import { clearInstruction, setInstruction } from 'slices';
import { GameMode } from 'types';
import { useAppDispatch, useAppSelector, useGameController } from 'hooks';
import { GameLayout } from 'components/common';
import { Game } from 'components/games';
import { useParams } from 'react-router-dom';

const UserSingleGame: FC = () => {
  const { gameId } = useParams();
  const { settings, getGame, inProgress } = useGameController();
  const dispatch = useAppDispatch();
  const { instruction } = useAppSelector((state) => state.game);

  useEffect(() => {
    if (!settings && gameId) {
      getGame(gameId);
    }

    if (settings) {
      dispatch(
        setInstruction({
          instruction: settings.instruction,
          instructionFileId: settings.instructionFileId,
        })
      );
    }

    return () => {
      dispatch(clearInstruction());
    };
    // eslint-disable-next-line
  }, [settings, gameId]);

  if (!settings || inProgress || !instruction) return null;

  return (
    <GameLayout bgColor="light">
      <Game gameSettings={settings} gameMode={GameMode.OneGame} />
    </GameLayout>
  );
};

export default UserSingleGame;
