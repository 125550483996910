import { AppConfig } from 'config';
import ReactPixel from 'react-facebook-pixel';
import ReactGA from 'react-ga4';

interface UseAnalytics {
  sendRegisterEvent: (props: SignUpEventProps) => void;
  sendLoginEvent: (props: LoginEventProps) => void;
  sendPaymentEvent: (props: PaymentEventProps) => void;
  sendChooseDemoGameEvent: (props: ChooseDemoGameProps) => void;
}

interface PaymentItem {
  item_name: string;
}

interface PaymentEventProps {
  currency: string;
  value: number;
  coupon: string;
  transaction_id: string;
  items: PaymentItem[];
}

interface LoginEventProps {
  method: string;
}

interface SignUpEventProps {
  method: string;
}

interface ChooseDemoGameProps {
  id: string;
}

// interface CustomEventProps {
//   action: string;
//   category: string;
//   label?: string;
//   value?: number;
//   nonInteraction?: boolean;
//   transport?: 'beacon' | 'xhr' | 'image';
// }

ReactGA.initialize(AppConfig.GA_MEASUREMENT_ID);
ReactPixel.init(AppConfig.META_PIXEL_ID);

export const useAnalytics = (): UseAnalytics => {
  const sendRegisterEvent = (props: SignUpEventProps) => {
    ReactGA.event('sign_up', { ...props });
    ReactPixel.trackCustom('sign_up', { ...props });
  };

  const sendLoginEvent = (props: LoginEventProps) => {
    ReactGA.event('login', { ...props });
    ReactPixel.trackCustom('login', { ...props });
  };

  const sendPaymentEvent = (props: PaymentEventProps) => {
    ReactGA.event('add_to_cart', {
      currency: props.currency,
      value: props.value,
      items: props.items,
    });
    ReactPixel.track('AddToCart', {
      contents: [
        {
          id: props.items[0].item_name,
          quantity: 1,
        },
      ],
      currency: props.currency,
      value: props.value,
    });
    ReactGA.event('purchase', { ...props });
    ReactPixel.track('Purchase', { ...props });
  };

  const sendChooseDemoGameEvent = (props: ChooseDemoGameProps) => {
    ReactGA.event({
      action: 'choose_demo_game',
      category: 'game',
      label: props.id,
    });
    ReactPixel.trackCustom('choose_demo_game', { label: props.id });
  };

  return {
    sendLoginEvent,
    sendRegisterEvent,
    sendPaymentEvent,
    sendChooseDemoGameEvent,
  };
};
