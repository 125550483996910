import styled from 'styled-components';

interface Props {
  isSelected: boolean;
}

export const Container = styled.div<Props>(
  ({ theme: { colors, radius }, isSelected }) => `
  background-color: ${colors.lightGray};
  border-radius: ${radius.md};
  padding: 20px 24px;
  margin-right: 18px;
  display: flex;
  justify-content: space-between;
  filter: ${isSelected ? ' brightness(0.5)' : 'none'};
  align-items: center;
`
);
