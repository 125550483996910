import { FC } from 'react';
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import { Button, Flex, FlexAlignCenter, Heading } from 'components/common';
import { Form, TextInput } from 'components/forms';
import { addSubdomainSchema } from 'config';
import { useAppDispatch } from 'hooks';
import { SubdomainReq } from 'cogamika-back/types';
import { AddSubdomainSchema } from 'types';
import { clearErrorNotification } from 'slices';
import { BoxContainer } from './styles';

interface AddSubdomainProps {
  submit: (data: SubdomainReq) => void;
}

export const AddSubdomain: FC<AddSubdomainProps> = ({ submit }) => {
  const dispatch = useAppDispatch();

  const methodsAddSubdomain = useForm<AddSubdomainSchema>({
    mode: 'onSubmit',
    resolver: yupResolver(addSubdomainSchema),
  });

  const { setValue } = methodsAddSubdomain;

  const submitAddSubdomain = (data: AddSubdomainSchema) => {
    dispatch(clearErrorNotification());
    const { name } = data;
    const preparedData: SubdomainReq = {
      name,
    };
    submit(preparedData);
    setValue('name', '');
  };

  return (
    <Flex gap="xl" columnDirection fullWidth>
      <Form
        submitHandler={submitAddSubdomain}
        methods={methodsAddSubdomain}
        fullWidth
      >
        <Flex gap="xl" columnDirection fullWidth>
          <FlexAlignCenter justifyContent="space-between" gap="lg" fullWidth>
            <Heading
              size="md"
              text="views.superAdmin.settings.headers.subdomains.add"
            />
            <Button
              text="button.add"
              variant="outlined"
              type="submit"
              uppercase
            />
          </FlexAlignCenter>
          <BoxContainer>
            <Flex gap="lg" columnDirection fullWidth>
              <TextInput name="name" placeholder="name" fullWidth required />
            </Flex>
          </BoxContainer>
        </Flex>
      </Form>
    </Flex>
  );
};
