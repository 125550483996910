import styled from 'styled-components';

export const FilesSelectorWrapper = styled.div`
  width: 100%;
`;

export const SelectedFiles = styled.div(
  ({ theme: { spaces } }) => `
  display: flex;
  flex-wrap: wrap;
  width: 100%;
  overflow-y: scroll;
  gap: ${spaces.sm};
`
);

export const Wrapper = styled.div`
  width: 100%;
  height: 573px;
  display: grid;
  grid-template-columns: 4fr 2fr;
  gap: 150px;
`;
