import styled, { css } from 'styled-components';
import { Theme } from 'types';

type Variant = 'success' | 'failure';

interface Props {
  variant?: Variant;
}

const getVaraint = (variant: Variant, theme: Theme) => {
  switch (variant) {
    case 'success':
      return `
          background-color: ${theme.colors.secondarySuccess};
          color: ${theme.colors.success};        
        `;
    case 'failure':
      return `
        background-color: ${theme.colors.warningBackground};
        color: ${theme.colors.error};
      `;
  }
};

export const ResultContainer = styled.div<Props>(
  ({ theme, theme: { spaces, radius }, variant }) => `
  height: 108px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: ${spaces.lg} ${spaces['2xl']};
  border-radius: ${radius.md};
  ${variant && getVaraint(variant, theme)};
`
);

const commonStyles = css`
  margin-top: 20px;
  height: 280px;
  width: 280px;
`;

export const StyledVideo = styled.video`
  ${commonStyles};
`;

export const StyledAudio = styled.audio`
  ${commonStyles};
`;
