import styled from 'styled-components';

export const Container = styled.div(
  ({ theme: { spaces } }) => `
  padding: 40px 68px;
  max-width: 600px;
  display: flex;
  flex-direction: column;
  gap: ${spaces['6xl']};
`
);

export const HeaderContainer = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
`;

export const StyledVideo = styled.video(
  ({ theme }) => `
  height: 100%;
  width: 100%;
`
);
