import { BoxSizes, Sizes } from 'types';

export const sizes: Sizes = {
  xs: '15px',
  sm: '30px',
  md: '60px',
  lg: '120px',
  xl: '240px',
};

export const boxSizes: BoxSizes = {
  confirmModalBox: '580px',
};
