import React from 'react';
import { UseFieldArrayRemove } from 'react-hook-form';

import { Flex, IconButton, Text } from 'components/common';

import { Container } from './styles';
import { MovieTimeRange } from 'types';
import { TimeRangeInput } from '../TimeRangeInput';

interface Props {
  fieldIndex: number;
  number: number;
  field: MovieTimeRange;
  remove: UseFieldArrayRemove;
}

export const TimeRange: React.FC<Props> = ({ fieldIndex, number, remove }) => {
  const removeField = () => {
    remove(fieldIndex);
  };

  return (
    <Container>
      <Flex justifyContent="space-between" alignItems="center">
        <Text
          text="views.newGame.movie.rangeNumber"
          translationArgs={{ number }}
          size="md"
          weight="semibold"
        />
        <IconButton onClick={removeField} name="delete" type="button" />
      </Flex>
      <Flex gap="md">
        <TimeRangeInput
          label="labels.start"
          fieldIndex={fieldIndex}
          firstInputName="startTimeMinutes"
          secondInputName="startTimeSeconds"
        />
        <TimeRangeInput
          label="labels.end"
          fieldIndex={fieldIndex}
          firstInputName="endTimeMinutes"
          secondInputName="endTimeSeconds"
        />
      </Flex>
    </Container>
  );
};
