import styled from 'styled-components';

export const PublishContainer = styled.div(
  ({ theme: { spaces } }) => `
  width: 500px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  gap: ${spaces.md}; 
`
);
