import React, { useRef } from 'react';
import { createPortal } from 'react-dom';

import { hideModal } from 'slices';
import { useAppDispatch, useAppSelector } from 'hooks';

import { Overlay, Content } from './styles';
import ModalSelector from './ModalSelector';

export const Modal: React.FC = () => {
  const ref = useRef<null | HTMLDivElement>(null);
  const dispatch = useAppDispatch();
  const { type, isOpen } = useAppSelector(({ modal }) => modal);

  if (!isOpen) {
    return null;
  }

  return createPortal(
    <Overlay onClick={() => dispatch(hideModal())} role="button" tabIndex={0}>
      <Content
        ref={ref}
        id="modal-content"
        onClick={(e) => e.stopPropagation()}
        role="button"
        tabIndex={0}
      >
        <ModalSelector content={type} />
      </Content>
    </Overlay>,
    document.body!
  );
};
