import React, { ButtonHTMLAttributes } from 'react';

import { FontSizeType, IconName } from 'types';
import { Icon, TranslateText } from 'components/common';

import { StyledLinkButton } from './styles';

interface Props extends ButtonHTMLAttributes<HTMLButtonElement> {
  text: string;
  noTranslate?: boolean;
  uppercase?: boolean;
  underline?: boolean;
  iconName?: IconName;
  size?: FontSizeType;
  dataCy?: string;
}

export const LinkButton: React.FC<Props> = ({
  text,
  noTranslate,
  uppercase,
  underline,
  onClick,
  iconName,
  dataCy,
  type,
  size,
}) => {
  if (iconName) {
    return (
      <StyledLinkButton
        size={size}
        onClick={onClick}
        uppercase={uppercase}
        underline={underline}
        type={type}
      >
        <Icon name={iconName} />
        <TranslateText text={text} noTranslate={noTranslate} />
      </StyledLinkButton>
    );
  }

  return (
    <StyledLinkButton
      size={size}
      onClick={onClick}
      uppercase={uppercase}
      underline={underline}
      data-cy={dataCy}
      type={type}
    >
      <TranslateText text={text} noTranslate={noTranslate} />
    </StyledLinkButton>
  );
};
