import React, { useState } from 'react';

import { apiUrl } from 'config/url';
import { FileRes, FileType } from 'cogamika-back/types';
import { ModalType } from 'types';
import { useModal } from 'hooks';
import { IconButton } from 'components/common/Button';

import {
  StyledImage,
  StyledVideo,
  StyledAudio,
  FileContainer,
  ButtonContainer,
} from './styles';

interface Props {
  file: FileRes;
}

export const SingleFile: React.FC<Props> = ({
  file: { originalName, type, id },
}) => {
  const { showModal } = useModal();
  const [isHovered, setIsHovered] = useState(false);
  const openModal = () => {
    showModal({
      type: ModalType.DeleteFile,
      data: {
        fileId: id,
      },
    });
  };

  const handleMouseEnter = () => {
    setIsHovered(true);
  };

  const handleMouseLeave = () => {
    setIsHovered(false);
  };

  const getFile = (type: FileType) => {
    switch (type) {
      case FileType.Image:
        return (
          <StyledImage
            src={`${apiUrl}file/${id}`}
            alt={originalName}
            effect="opacity"
          />
        );
      case FileType.Video:
        return (
          <StyledVideo
            controls
            src={`${apiUrl}file/${id}`}
            width="280"
            disablePictureInPicture
          ></StyledVideo>
        );
      case FileType.Audio:
        return <StyledAudio controls src={`${apiUrl}file/${id}`}></StyledAudio>;
      default:
        return <></>;
    }
  };

  return (
    <FileContainer
      onMouseEnter={handleMouseEnter}
      onMouseLeave={handleMouseLeave}
    >
      {getFile(type)}
      {isHovered && (
        <ButtonContainer>
          <IconButton name="remove" onClick={openModal} type="button" />
        </ButtonContainer>
      )}
    </FileContainer>
  );
};
