import React, { useContext, useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';

import { FileType } from 'cogamika-back/types';
import { GameFormSteps, MemoryGameFormType } from 'types';
import { GameFormContext } from 'context';
import { Form, Image } from 'components/forms';
import { Button, FileSelector, Flex, Heading, Text } from 'components/common';

import {
  MemoryGameContainer,
  FormContainer,
  FilesSelectorWrapper,
  Wrapper,
  SelectedFiles,
  ButtonContainer,
} from './styles';

export const MemoryGameForm: React.FC = () => {
  const { setGameSettings, setStep, gameSettings } =
    useContext(GameFormContext);
  const [selectedImages, setSelectedImages] = useState(() => new Set<string>());
  const methods = useForm<MemoryGameFormType>({
    mode: 'onSubmit',
    defaultValues: { imagesIds: gameSettings?.memorySettings?.imageIds },
  });
  const { isSubmitting, isValid } = methods.formState;
  const isButtonDisabled = isSubmitting || !isValid;

  const handleFileSelect = (id: string) => {
    if (selectedImages.has(id)) {
      setSelectedImages((prev) => {
        const next = new Set(prev);
        next.delete(id);
        methods.setValue('imagesIds', Array.from(next));
        return next;
      });
    } else {
      setSelectedImages((prev) => {
        methods.setValue('imagesIds', [...Array.from(prev), id]);
        return new Set(prev).add(id);
      });
    }

    methods.trigger();
  };

  const submit = (data: MemoryGameFormType) => {
    setGameSettings({
      memorySettings: {
        imageIds: data.imagesIds,
      },
    });
    setStep(GameFormSteps.PublishGame);
  };

  const goBack = () => {
    setStep(GameFormSteps.Instruction);
  };

  const deleteImage = (id: string) => {
    if (!selectedImages.has(id)) return;

    setSelectedImages((prev) => {
      const next = new Set(prev);
      next.delete(id);
      methods.setValue('imagesIds', Array.from(next));
      return next;
    });
  };

  useEffect(() => {
    if (gameSettings?.memorySettings?.imageIds) {
      setSelectedImages(new Set(gameSettings?.memorySettings?.imageIds));
    }
  }, [gameSettings?.memorySettings?.imageIds]);

  return (
    <MemoryGameContainer>
      <Flex columnDirection gap="3xl">
        <Heading text="views.newGame.addImages" size="xl" />
        <Text
          text="views.newGame.numberOfFiles"
          translationArgs={{ files: selectedImages.size }}
          size="base"
        />
      </Flex>
      <FormContainer>
        <Form submitHandler={submit} methods={methods}>
          <Wrapper>
            <SelectedFiles className="files">
              {Array.from(selectedImages).map((id) => (
                <Image id={id} key={id} deleteImage={deleteImage} />
              ))}
            </SelectedFiles>
            <FilesSelectorWrapper>
              <FileSelector
                refreshList={false}
                onSelectFile={handleFileSelect}
                types={[FileType.Image]}
                selectedIds={selectedImages}
              />
            </FilesSelectorWrapper>
          </Wrapper>
          <ButtonContainer>
            <Button
              text="button.back"
              variant="primary"
              uppercase
              type="button"
              onClick={goBack}
            />
            <Button
              uppercase
              text="button.next"
              variant="secondary"
              type="submit"
              disabled={isButtonDisabled}
            />
          </ButtonContainer>
        </Form>
      </FormContainer>
    </MemoryGameContainer>
  );
};
