import { ButtonHTMLAttributes } from 'react';
import { __ } from 'utils/i18n';

interface Props extends ButtonHTMLAttributes<HTMLButtonElement> {
  text: string;
  noTranslate?: boolean;
  translationArgs?: Record<string, string | number>;
}

export const TranslateText = ({
  text,
  noTranslate,
  translationArgs,
}: Props) => <>{noTranslate ? text : __(text, translationArgs)}</>;
