import { FC, ReactNode } from 'react';
import { StyledList } from './styles';
import { Flex } from '../Flex';
import { Text } from '../Text';
import { GridContainer, GridItem } from '../Grid';
import { CartsPagination, LimitPagination } from '../BasePagination';

interface ListProps {
  title: string;
  noTranslate?: boolean;
  children?: ReactNode;
  withLimit?: boolean;
  withCard?: boolean;
}

export const List: FC<ListProps> = ({
  children,
  title,
  noTranslate,
  withCard,
  withLimit,
}) => {
  return (
    <Flex gap="md" columnDirection fullWidth>
      <Text
        text={title}
        noTranslate={noTranslate}
        size="md"
        weight="semibold"
      />
      <StyledList>{children}</StyledList>
      {(withCard || withLimit) && (
        <GridContainer size={[5, 1]} gap="7xl">
          {withCard && (
            <GridItem position={[3, 1, 4]}>
              <CartsPagination />
            </GridItem>
          )}
          {withLimit && (
            <GridItem position={[5, 1]}>
              <LimitPagination />
            </GridItem>
          )}
        </GridContainer>
      )}
    </Flex>
  );
};

export * from './ListItem';
