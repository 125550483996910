import React, { useContext, useState } from 'react';

import { ChronologyGameFormSteps, GameFormSteps } from 'types';
import { GameFormContext } from 'context';
import { Levels } from 'components/forms';

import { ChronologyForm } from './ChronologyForm';

export const Chronology: React.FC = () => {
  const { setStep, gameSettings } = useContext(GameFormContext);
  const [stage, setStage] = useState<ChronologyGameFormSteps>(
    ChronologyGameFormSteps.Levels
  );
  const [level, setLevel] = useState<number>();
  const levelsSettings = gameSettings?.chronologyLevelSettings;

  const selectLevel = (level: number) => {
    setLevel(level);
    setStage(ChronologyGameFormSteps.ChronologyForm);
  };

  const levelsGoBack = () => {
    setStep(GameFormSteps.Instruction);
  };

  const levelsSubmit = () => {
    setStep(GameFormSteps.PublishGame);
  };

  const getStage = (type: ChronologyGameFormSteps) => {
    switch (type) {
      case ChronologyGameFormSteps.Levels:
        return (
          <Levels
            levelsSettings={levelsSettings?.levels}
            selectLevel={selectLevel}
            goBack={levelsGoBack}
            submit={levelsSubmit}
          />
        );
      case ChronologyGameFormSteps.ChronologyForm:
        return <ChronologyForm level={level || 1} setStage={setStage} />;
      default:
        return <></>;
    }
  };

  return getStage(stage);
};
