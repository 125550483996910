import styled from 'styled-components';

interface HeaderProps {
  isOpen: boolean;
}

interface ContentProps {
  isOpen: boolean;
}

export const AccordionContainer = styled('div')(
  () => `
  display: flex;
  flex-direction: column;
`
);

export const AccordionHeader = styled('button')<HeaderProps>(
  ({ theme, isOpen }) => `
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  padding: ${theme.spaces.sm} 0px;
  gap: ${theme.spaces.md};
  border: none;
  cursor: pointer;
  background: transparent;
  border-bottom: solid 1px ${theme.colors.lightGray};

  &::after {
    margin-left: 8px; 
    display: block;
    content: "";
    border: solid ${theme.colors.primary};
    border-width: 0 2px 2px 0;
    display: inline-block;
    padding: 4px;
    transition: .5s;
    transform: rotate(${isOpen ? '-135' : '45'}deg);
    -webkit-transform: rotate(${isOpen ? '-135' : '45'}deg);
  }
`
);

export const AccordionContent = styled('div')<ContentProps>(
  ({ theme, isOpen }) => `
  display: flex;
  flex-direction: column;
  overflow: hidden;
  transition: .5s;
  height: ${isOpen ? 'auto' : '0px'};
`
);
