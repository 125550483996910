import styled from 'styled-components';

export const ImageContainer = styled.div(
  ({ theme: { colors, radius } }) => `
  border: 1px solid ${colors.secondaryBorder};
  border-radius: ${radius.md};
  position: relative;
  height: 160px;
  width: 160px;
`
);

export const StyledImage = styled.img(
  ({ theme: { radius } }) => `
  height: 100%;
  width: 100%;  
  border-radius: ${radius.xl};
  background: none;
`
);

export const ButtonContainer = styled.div`
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
`;
