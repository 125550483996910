import React from 'react';
import { useFieldArray, useFormContext } from 'react-hook-form';

import { MovieGameFormType } from 'types';
import { Button, Flex, Text } from 'components/common';

import { TimeRange } from '../TimeRange';
import { Container, ListContainer } from './styles';

export const TimeRanges: React.FC = () => {
  const { control } = useFormContext<MovieGameFormType>();
  const { fields, append, remove } = useFieldArray({
    control,
    name: 'timeRanges',
  });

  const addNewTimeRange = () => {
    append({
      startTimeMinutes: undefined,
      startTimeSeconds: undefined,
      endTimeMinutes: undefined,
      endTimeSeconds: undefined,
    });
  };

  return (
    <Container>
      <Flex columnDirection gap="sm">
        <Text text="views.newGame.movie.timeRangeHeader" size="base" />
        <Button
          text="button.newQuestion"
          variant="gray"
          iconName="plus"
          fullWidth
          onClick={addNewTimeRange}
          type="button"
          justifyContent="space-between"
        />
      </Flex>
      <ListContainer>
        {fields.map((field, index) => (
          <TimeRange
            field={field}
            key={index}
            number={index + 1}
            fieldIndex={index}
            remove={remove}
          />
        ))}
      </ListContainer>
    </Container>
  );
};
