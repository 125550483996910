import { DiscountType, PromoRes } from 'cogamika-back/types';

export const getDiscountPrice = (promo: PromoRes, basePrice: number) => {
  let discount = 0;
  switch (promo.type) {
    case DiscountType.Discount:
      discount = promo.value;
      break;
    case DiscountType.Percent:
      discount =
        Math.floor(((basePrice / 100) * promo.value + Number.EPSILON) * 100) /
        100;
      break;
    case DiscountType.SetPrice:
      discount = basePrice - promo.value;
      break;
  }

  if (discount > basePrice) {
    discount = basePrice;
  }

  return discount;
};

export const getDiscountedPrice = (promo: PromoRes, basePrice: number) => {
  let discountedPrice = basePrice;
  switch (promo.type) {
    case DiscountType.Discount:
      discountedPrice = basePrice - promo.value;
      break;
    case DiscountType.Percent:
      discountedPrice =
        basePrice -
        Math.floor(((basePrice / 100) * promo.value + Number.EPSILON) * 100) /
          100;
      break;
    case DiscountType.SetPrice:
      discountedPrice = promo.value;
  }

  if (discountedPrice < 0) discountedPrice = 0;

  return discountedPrice;
};
