import React, { useEffect, useState } from 'react';

import { GameWithAllLevels } from 'cogamika-back/types';
import {
  DetailsGameType,
  GameFormSteps,
  GamesFormSettings,
  PublishedGameType,
} from 'types';
import { GameFormContext } from 'context';
import { getGameSettings } from 'utils';

import {
  GameForm,
  DetailsForm,
  PublishGame,
  GameNameForm,
  InstructionForm,
  PublishInfo,
  DescriptionForm,
  SelectLanguage,
} from 'components/forms';

import { StyledContainer } from './styles';
import { useApi } from 'hooks';
import { useParams } from 'react-router-dom';

interface Props {
  gameId: string;
}

export const EditGameForm: React.FC<Props> = ({ gameId }) => {
  const { id } = useParams();
  const { get } = useApi();
  const [step, setStep] = useState<GameFormSteps>(GameFormSteps.SelectLanguage);
  const [gameSettings, setGameSettings] = useState<GamesFormSettings>();
  const [detailsForm, setDetailsForm] = useState<DetailsGameType>();

  const getGameDetails = async () => {
    const res = await get<GameWithAllLevels>(`/game/all-levels/${gameId}`);

    if (res) {
      const { detailsForm, gameSettings } = getGameSettings(res);
      setGameSettings(gameSettings);
      setDetailsForm(detailsForm);
    }
  };

  useEffect(() => {
    getGameDetails();
    // eslint-disable-next-line
  }, []);

  const renderForm = (step: GameFormSteps) => {
    switch (step) {
      case GameFormSteps.SelectLanguage:
        return <SelectLanguage />;
      case GameFormSteps.GameName:
        return <GameNameForm disabled />;
      case GameFormSteps.Details:
        return <DetailsForm disabled />;
      case GameFormSteps.Description:
        return <DescriptionForm />;
      case GameFormSteps.Instruction:
        return <InstructionForm />;
      case GameFormSteps.GameForm:
        return <GameForm gameType={detailsForm?.gameName?.gameType} />;
      case GameFormSteps.PublishGame:
        return <PublishGame gameId={id} />;
      case GameFormSteps.PublishedInfo:
        return <PublishInfo type={PublishedGameType.Ready} />;
      case GameFormSteps.PublishedDraft:
        return <PublishInfo type={PublishedGameType.Draft} />;
      default:
        return null;
    }
  };

  return (
    <GameFormContext.Provider
      value={{
        gameSettings,
        setGameSettings,
        detailsForm,
        setDetailsForm,
        step,
        setStep,
      }}
    >
      <StyledContainer>{renderForm(step)}</StyledContainer>
    </GameFormContext.Provider>
  );
};
