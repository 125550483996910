import { isBefore } from 'date-fns';
import jwt_decode from 'jwt-decode';

interface DecodedToken {
  exp: number;
}

export const decodeToken = (token: string) =>
  jwt_decode<DecodedToken>(token as string);

export const checkIsTokenExpired = (token: string) => {
  const decodedToken = jwt_decode<DecodedToken>(token as string);
  const tokenExpirationDate = new Date(decodedToken.exp * 1000);
  const currentDate = new Date();
  const isTokenExpired = isBefore(tokenExpirationDate, currentDate);

  return isTokenExpired;
};
