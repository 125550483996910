import styled from 'styled-components';

export const StyledNavigation = styled.nav(
  ({ theme: { colors } }) => `
  display: flex;
  align-items: center;
  justify-content: space-between;
  background-color: ${colors.secondaryBackground};
  padding: 41px 67px;
`
);

export const StyledLinksContainer = styled.div(
  ({ theme: { spaces } }) => `
  display: flex;
  gap: ${spaces['2xl']};
`
);
