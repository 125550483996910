import {
  CardImage,
  GameType,
  Press,
  Language,
  Element,
} from 'cogamika-back/types';

export interface GameState {
  isStarted: boolean;
  isInstructionOpen: boolean;
  instruction?: Instruction;
}

export interface Instruction {
  instruction: string;
  instructionFileId: string | null;
}

export interface GameNameFormType {
  title: string;
  gameType: GameType;
}

export interface DetailsFormType {
  domainId: string;
  subdomainId: string;
  categoryId: string;
}

export interface InstructionFormType {
  instruction: string;
  instructionId: string;
}

export interface DescriptionFormType {
  description: string;
  miniatureFileId: string;
}

export interface MemoryGameFormType {
  imagesIds: string[];
}

export interface DetailsGameType {
  isApproved?: boolean;
  gameName?: GameNameFormType;
  details?: DetailsFormType;
  instruction?: InstructionFormType;
  description?: DescriptionFormType;
  lang?: Language;
}

export enum GameFormSteps {
  SelectLanguage = 'SELECT_LANGUAGE',
  GameName = 'GAME_NAME',
  Details = 'DETAILS',
  Description = 'DESCRIPTION',
  Instruction = 'INSTRUCTION',
  GameForm = 'GAME_FORM',
  PublishGame = 'PUBLISH_GAME',
  PublishedInfo = 'PUBLISHED_INFO',
  PublishedDraft = 'PUBLISHED_DRAFT',
}

export enum SurveyQuestionResult {
  Success = 'SUCCESS',
  Failure = 'FAILURE',
  Pending = 'PENDING',
}

export enum GameResult {
  Win = 'WIN',
  Lose = 'LOSE',
}

export enum SurveyGameFormSteps {
  Levels = 'LEVELS',
  SurveyForm = 'SURVEY_FORM',
}

export enum GameStage {
  Instruction = 'INSTRUCTION',
  Intro = 'INTRO',
  Game = 'GAME',
  Result = 'RESULT',
}

export enum CollectionBucketStatus {
  Success = 'SUCESS',
  Failure = 'FAILURE',
  None = 'NONE',
}

export enum GameMode {
  Session = 'SESSION',
  OneGame = 'ONE_GAME',
  Free = 'FREE',
}

export enum ChronologyGameFormSteps {
  Levels = 'LEVELS',
  ChronologyForm = 'CHRONOLOGY_FORM',
}

export enum PublishedGameType {
  Draft = 'DRAFT',
  Ready = 'READY',
}

export enum MovieGameFormSteps {
  Levels = 'LEVELS',
  MovieForm = 'MOVIE_FORM',
}

export type TimeRange = Press & { isClicked: boolean };

export enum MoviePressedResult {
  Sucess = 'SUCCESS',
  Failure = 'FAILURE',
  InProgress = 'IN_PROGRESS',
}

export enum MosaicGameFormSteps {
  Levels = 'LEVELS',
  MosaicFormCards = 'MOSAIC_FORM_CARDS',
  MosaicFormDistractors = 'MOSAIC_FORM_DISTRACTORS',
}

export interface MosaicGameFormType {
  imagesIds: string[];
}

export interface GameSelectLanguageType {
  lang: Language;
}

export type MosaicCardType = CardImage & {
  imageId: string;
  isSelected: boolean;
  isCorrect: boolean;
};

export type ChronologyCardType = Element & {
  isCorrect?: boolean;
};

export type MosaicCardsType = {
  [id: string]: MosaicCardType;
};

export enum NonLanguage {
  NonLanguage = '',
}

export type FilterLanguageType = Language | NonLanguage;
