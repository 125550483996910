import React, { ReactNode } from 'react';

import { AlignItemsType, MarginType, SpaceType } from 'types';

import { FlexComponent } from './styles';

interface Props {
  children: ReactNode;
  columnDirection?: boolean;
  alignItems?: AlignItemsType;
  gap?: SpaceType;
  fullWidth?: boolean;
  margin?: MarginType;
}

export const FlexJustifyCenter: React.FC<Props> = ({
  children,
  columnDirection,
  alignItems,
  gap,
  fullWidth,
  margin,
}) => {
  return (
    <FlexComponent
      columnDirection={columnDirection}
      alignItems={alignItems}
      gap={gap}
      fullWidth={fullWidth}
      margin={margin}
    >
      {children}
    </FlexComponent>
  );
};
