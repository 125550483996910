import update from 'immutability-helper';
import React, { useCallback, useEffect, useState } from 'react';
import { DndProvider } from 'react-dnd';
import { HTML5Backend } from 'react-dnd-html5-backend';

import { AppConfig } from 'config';
import { Chronology } from 'games';
import { ChronologyCardType } from 'types';

import { Card } from './Card';
import { ListContainer } from './styles';

interface Props {
  chronology: Chronology;
  endGame: () => void;
}

export const Game: React.FC<Props> = ({ chronology, endGame }) => {
  const [cards, setCards] = useState<ChronologyCardType[]>(
    chronology.chronologyElements
  );
  const [isWin, setIsWin] = useState(false);

  const findCard = useCallback(
    (orderNumber: number) => {
      const card = cards.filter((c) => c.orderNumber === orderNumber)[0];
      return {
        card,
        index: cards.indexOf(card),
      };
    },
    [cards]
  );

  const moveCard = useCallback(
    (id: number, atIndex: number) => {
      const { card, index } = findCard(id);
      const updatedCards = update(cards, {
        $splice: [
          [index, 1],
          [atIndex, 0, card],
        ],
      });
      setCards(updatedCards);
    },
    [findCard, cards, setCards]
  );

  const dropCard = () => {
    chronology.checkWin(setIsWin, cards, setCards);
  };

  useEffect(() => {
    if (isWin) {
      const timer = setTimeout(() => {
        endGame();
      }, AppConfig.DELAY_AFTER_WIN);

      return () => {
        clearTimeout(timer);
      };
    }

    // eslint-disable-next-line
  }, [isWin]);

  return (
    <DndProvider backend={HTML5Backend}>
      <ListContainer>
        {cards.map((card) => (
          <Card
            isCorrect={card.isCorrect}
            elementType={chronology.settings.elementsType}
            key={card.orderNumber}
            id={card.orderNumber}
            text={card.description}
            moveCard={moveCard}
            dropCard={dropCard}
            findCard={findCard}
            isWin={isWin}
          />
        ))}
      </ListContainer>
    </DndProvider>
  );
};
