export enum ApiErrorCode {
  OtherError,
  FormValidationError,
  NoSuchObjectWithThisId,
  BadCredentials,
  UserNotFound,
  InvalidLanguage,
  EmailSendingFailure,
  EmailIsNotAvailable,
  CannotProperlySaveData,
  AccountIsNotActivated,
  GameWithSuchSettingsAlreadyExists,
  NotEnoughImagesProvided,
  NoValidAccount,
  AccountNotChosen,
  InvalidData,
  CannotFindGameInProvidedDomain,
  OperationFailure,
  InappropriateFileFormat,
  GameWithProvidedNameAlreadyExists,
  GameHandlerNotFound,
  WrongPromoCode,
  CodeAlreadyExist,
  CodeDoesNotExist,
  FaqTopicAlreadyExist,
  FaqQuestionAlreadyExist,
  SuchDomainNotFound,
  SuchGameNotFound,
  CannotMarkPublishedGameAsDraft,
  OnlyOneSessionPerDayIsAvailable,
  GameIsNotAvailableForFree,
  CannotMarkNotApprovedGameAsFree,
  CategoryWithProvidedNameAlreadyExists,
  CannotRemoveCategoryWhichIsInUse,
  SubdomainWithProvidedNameAlreadyExists,
  CannotRemoveSubdomainWhichIsInUse,
  CannotRemoveFileWhichIsInUse,
  InappropriateGameForChosenLanguage,
  UserCanNotPayYet,
}
