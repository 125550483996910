import styled from 'styled-components';

import { JustifyContentType, MarginType, SpaceType } from 'types';
import { getMargin } from 'utils';

interface Props {
  columnDirection?: boolean;
  justifyContent?: JustifyContentType;
  gap?: SpaceType;
  fullWidth?: boolean;
  margin?: MarginType;
}

export const FlexComponent = styled.div<Props>(
  ({
    theme: { spaces },
    columnDirection,
    justifyContent,
    gap,
    fullWidth,
    margin,
  }) => `
  display: flex;
  align-items: center;
  flex-direction: ${columnDirection ? 'column' : 'row'};
  justify-content: ${justifyContent ? `${justifyContent}` : 'flex-start'};
  gap: ${gap ? spaces[gap] : '0px'};
  width: ${fullWidth ? '100%' : 'auto'};
  ${margin && getMargin(margin, spaces)};
`
);
