import { FC, ReactNode, useState } from 'react';
import { FlexJustifyCenter } from 'components/common';
import { FaqQuestionAddForm } from './FaqAddQuestionForm';
import { FaqTopicEditForm } from './FaqEditTopic';
import { FaqTopicContent } from './FaqTopicContent';
import { Language } from 'cogamika-back/types';

interface FaqTopicProps {
  id: string;
  topic: string;
  lang: Language;
  children?: ReactNode;
}

export const FaqTopic: FC<FaqTopicProps> = ({ id, topic, children, lang }) => {
  const [isTopicEdit, setIsTopicEdit] = useState<boolean>(false);

  return (
    <FlexJustifyCenter columnDirection gap="lg" margin={['md', '', '', '']}>
      {isTopicEdit ? (
        <FaqTopicEditForm
          setTopicEditing={setIsTopicEdit}
          topicId={id}
          topic={topic}
          lang={lang}
        />
      ) : (
        <FaqTopicContent
          setTopicEditing={setIsTopicEdit}
          topicId={id}
          topic={topic}
        />
      )}
      {children}
      <FaqQuestionAddForm topicId={id} />
    </FlexJustifyCenter>
  );
};
