import styled from 'styled-components';

export const StyledDetailsForm = styled.div`
  width: 100%;
  max-width: 600px;
  display: flex;
  flex-direction: column;
  justify-content: center;
`;

export const FormContainer = styled.div(
  ({ theme: { spaces } }) => `
  margin-top: ${spaces['3xl']};
`
);
