import styled from 'styled-components';
import { LazyLoadImage } from 'react-lazy-load-image-component';
import 'react-lazy-load-image-component/src/effects/opacity.css';

interface Props {
  isSelected?: boolean;
}

export const StyledImage = styled(LazyLoadImage)<Props>(
  ({ theme, isSelected }) => `
  margin-top: 20px;
  height: 280px;
  width: 280px;
  filter: ${isSelected ? ' brightness(0.5)' : 'none'};
  border-radius: 20px;
  background: none;
`
);

export const StyledVideo = styled.video<Props>(
  ({ theme, isSelected }) => `
  margin-top: 20px;
  height: 280px;
  width: 280px;
  filter: ${isSelected ? ' brightness(0.5)' : 'none'};
`
);

export const StyledAudio = styled.audio<Props>(
  ({ theme, isSelected }) => `
  margin-top: 20px;
  height: 280px;
  width: 280px;
  filter: ${isSelected ? ' brightness(0.5)' : 'none'};
`
);

export const FileContainer = styled.div`
  position: relative;
`;

export const ButtonContainer = styled.div`
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
`;
