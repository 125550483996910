import {
  AccessibleCollectionItem,
  AnswerType,
  ChronologyGameLevelSettings,
  CollectionAssignmentType,
  CollectionGameReq,
  CollectionGameSettings,
  CollectionItemType,
  CreateGameReq,
  GameAllLevelsSettings,
  GameBaseReq,
  GameCategorisationReq,
  GameType,
  GameWithAllLevels,
  Language,
  LevelSettings,
  MemoryGameSettings,
  MosaicGameLevelSettingsReq,
  MosaicImageType,
  MovieGameLevelSettings,
  QuestionAnswerData,
  QuestionAnswerType,
  SurveyGameLevelSettings,
  UpdateGameReq,
} from 'cogamika-back/types';
import {
  ChronologyGameSettings,
  CollectionSettings,
  DescriptionFormType,
  DetailsFormType,
  DetailsGameType,
  GameNameFormType,
  GamesFormSettings,
  InstructionFormType,
  LevelSetting,
  MovieGameFormType,
  SurveyAnswer,
  SurveyGameFormType,
  SurveyLevelsSettings,
  SurveyQuestions,
} from 'types';
import { convertToMinutes, convertToSeconds } from 'utils';

interface Props {
  isNew: boolean;
  gameName: GameNameFormType;
  details: DetailsFormType;
  lang: Language;
  instructionData?: InstructionFormType;
  gameSettings?: GamesFormSettings;
  description?: DescriptionFormType;
}

export const prepareGameFormPayload = <
  T extends CreateGameReq | UpdateGameReq | null
>({
  isNew,
  gameName,
  details,
  instructionData,
  gameSettings,
  description,
  lang,
}: Props): T => {
  const { title, gameType } = gameName;
  const { categoryId, domainId, subdomainId } = details;

  const baseData: GameBaseReq = {
    lang,
    title,
    type: gameType,
    instruction: instructionData?.instruction || '',
    instructionFileId: instructionData?.instructionId || undefined,
    description: description?.description || '',
    miniatureFileId: description?.miniatureFileId,
  };

  let gameCategorization: GameCategorisationReq | {} = {};

  if (isNew) {
    gameCategorization = {
      domainId,
      subdomainId,
      categoryId,
    };
  }

  const getPayload = (
    gameType: GameType
  ): CreateGameReq | UpdateGameReq | null => {
    if (!gameSettings) return { ...baseData, ...gameCategorization };

    switch (gameType) {
      case GameType.Memory:
        return {
          ...baseData,
          ...gameCategorization,
          memorySettings: {
            imageIds: gameSettings.memorySettings?.imageIds || [],
          },
        };
      case GameType.Survey:
        return {
          ...baseData,
          ...gameCategorization,
          surveyLevelSettings: prepareSurveyData(
            gameSettings.surveyLevelSettings
          ),
        };
      case GameType.Collection:
        return {
          ...baseData,
          ...gameCategorization,
          collectionSettings: prepareCollectionData(
            gameSettings.collectionSettings
          ),
        };
      case GameType.Chronology:
        return {
          ...baseData,
          ...gameCategorization,
          chronologyLevelSettings: prepareChronologyData(
            gameSettings.chronologyLevelSettings
          ),
        };
      case GameType.Movie:
        return {
          ...baseData,
          ...gameCategorization,
          movieLevelSettings: prepareMovieData(gameSettings.movieLevelSettings),
        };
      case GameType.Mosaic:
        return {
          ...baseData,
          ...gameCategorization,
          mosaicLevelSettings: gameSettings.mosaicLevelSettings,
        };
      default:
        return null;
    }
  };

  return getPayload(gameType) as T;
};

export const prepareSurveyData = (
  data?: SurveyLevelsSettings
): GameAllLevelsSettings<SurveyGameLevelSettings> => {
  const mapQuestion = (surveyQuestion: SurveyQuestions): QuestionAnswerData => {
    const question = surveyQuestion.question.imageId
      ? {
          type: QuestionAnswerType.Image,
          description: surveyQuestion.question.imageId,
        }
      : {
          type: QuestionAnswerType.Text,
          description: surveyQuestion.question.description,
        };

    return question;
  };

  const mapAnswer = (answer: SurveyAnswer): AnswerType => {
    const updatedAnswer = answer.imageId
      ? {
          type: QuestionAnswerType.Image,
          description: answer.imageId,
          isCorrect: answer.isCorrect,
        }
      : {
          type: QuestionAnswerType.Text,
          description: answer.description,
          isCorrect: answer.isCorrect,
        };

    return updatedAnswer;
  };

  const mapLevel = (
    level: LevelSetting
  ): LevelSettings<SurveyGameLevelSettings> => {
    const updatedSettings: SurveyGameLevelSettings = {
      initialData: level.settings.initialData,
      surveyQuestions: level.settings.surveyQuestions.map((setting) => ({
        question: mapQuestion(setting),
        answers: setting.answers.map(mapAnswer),
      })),
    };

    return {
      settings: updatedSettings,
      level: level.level,
    };
  };

  const updatedLevels: LevelSettings<SurveyGameLevelSettings>[] =
    data?.levels.map(mapLevel) || [];

  return {
    levels: updatedLevels,
  };
};

interface GameSettingsRes {
  detailsForm: DetailsGameType;
  gameSettings: GamesFormSettings;
}

export const getGameSettings = (data: GameWithAllLevels): GameSettingsRes => {
  const {
    game: {
      title,
      type,
      domain,
      subdomain,
      category,
      instruction,
      instructionFileId,
      description,
      miniatureFileId,
      approved,
      settings,
      lang,
    },
    levels,
  } = data;
  const detailsForm: DetailsGameType = {
    isApproved: approved,
    gameName: {
      title,
      gameType: type,
    },
    details: {
      domainId: domain?.id || '',
      subdomainId: subdomain?.id || '',
      categoryId: category?.id || '',
    },
    instruction: {
      instruction: instruction || '',
      instructionId: instructionFileId || '',
    },
    description: {
      description: description || '',
      miniatureFileId: miniatureFileId || '',
    },
    lang,
  };

  const getGameSettings = (data: GameWithAllLevels): GamesFormSettings => {
    switch (data.game.type) {
      case GameType.Memory: {
        const memorySettings = settings as MemoryGameSettings;
        return {
          memorySettings: { imageIds: memorySettings.imageIds },
        };
      }
      case GameType.Survey: {
        const newLevels: LevelSettings<SurveyGameLevelSettings>[] =
          levels as LevelSettings<SurveyGameLevelSettings>[];

        const surveyLevels: LevelSettings<SurveyGameFormType>[] = newLevels.map(
          (level) => ({
            ...level,
            settings: {
              ...level.settings,
              surveyQuestions: level.settings?.surveyQuestions.map(
                (surveyQuestion) => {
                  return {
                    question: {
                      ...surveyQuestion.question,
                      ...(surveyQuestion.question.type ===
                        QuestionAnswerType.Image && {
                        imageId: surveyQuestion.question.description,
                      }),
                    },
                    answers: surveyQuestion.answers.map((answer) => ({
                      ...answer,
                      ...(answer.type === QuestionAnswerType.Image && {
                        imageId: answer.description,
                      }),
                    })),
                  };
                }
              ),
            },
          })
        );

        return {
          surveyLevelSettings: {
            levels: surveyLevels,
          },
        };
      }
      case GameType.Collection: {
        const settings = data.game.settings as CollectionGameSettings;
        const firstCollectionImages: string[] = [];
        const secondCollectionImages: string[] = [];

        settings.accessibleImages.forEach((item) => {
          if (item.collectionType === CollectionAssignmentType.First) {
            firstCollectionImages.push(item.description);
          } else {
            secondCollectionImages.push(item.description);
          }
        });
        return {
          collectionSettings: {
            firstCollection: {
              collection: settings.firstCollection,
              collectionImages: firstCollectionImages,
            },
            secondCollection: {
              collection: settings.secondCollection,
              collectionImages: secondCollectionImages,
            },
          },
        };
      }
      case GameType.Chronology: {
        const newLevels =
          levels as LevelSettings<ChronologyGameLevelSettings>[];

        return {
          chronologyLevelSettings: {
            levels: newLevels.map((level) => ({
              level: level.level,
              settings: {
                elementsType: level.settings.elementsType,
                elements: level.settings.elements.map((element) => ({
                  description: element.description,
                })),
              },
            })),
          },
        };
      }
      case GameType.Movie: {
        const newLevels = levels as LevelSettings<MovieGameLevelSettings>[];

        return {
          movieLevelSettings: {
            levels: newLevels.map((level) => ({
              level: level.level,
              settings: {
                video: {
                  id: level.settings.movieId,
                },
                timeRanges: level.settings.presses.map((press) => ({
                  startTimeMinutes: convertToMinutes(press.startTime).minutes,
                  startTimeSeconds: convertToMinutes(press.startTime).seconds,
                  endTimeMinutes: convertToMinutes(press.endTime).minutes,
                  endTimeSeconds: convertToMinutes(press.endTime).seconds,
                })),
              },
            })),
          },
        };
      }
      case GameType.Mosaic: {
        const newLevels = levels as LevelSettings<MosaicGameLevelSettingsReq>[];

        return {
          mosaicLevelSettings: {
            levels: newLevels.map((level) => ({
              level: level.level,
              settings: {
                distractors: level.settings.distractors,
                images: level.settings.images,
              },
            })),
          },
        };
      }
      default:
        return {};
    }
  };

  return {
    detailsForm,
    gameSettings: getGameSettings(data),
  };
};

const prepareCollectionData = (
  data?: CollectionSettings
): CollectionGameReq => {
  let firstCollection;
  if (data?.firstCollection?.collection?.imageId) {
    firstCollection = {
      imageId: data.firstCollection.collection.imageId,
      name: 'First collection',
      type: CollectionAssignmentType.First,
    };
  }

  let secondCollection;
  if (data?.secondCollection?.collection?.imageId) {
    secondCollection = {
      imageId: data.secondCollection.collection.imageId,
      name: 'Second Collection',
      type: CollectionAssignmentType.Second,
    };
  }

  const firstCollectionImages: AccessibleCollectionItem[] =
    data?.firstCollection?.collectionImages?.map((item) => ({
      collectionType: CollectionAssignmentType.First,
      description: item,
      type: CollectionItemType.Image,
    })) || [];

  const secondCollectionImages: AccessibleCollectionItem[] =
    data?.secondCollection?.collectionImages?.map((item) => ({
      collectionType: CollectionAssignmentType.Second,
      description: item,
      type: CollectionItemType.Image,
    })) || [];

  return {
    firstCollection: firstCollection || undefined,
    secondCollection: secondCollection || undefined,
    accessibleImages: [...firstCollectionImages, ...secondCollectionImages],
  };
};

const prepareChronologyData = (
  data?: GameAllLevelsSettings<ChronologyGameSettings>
): GameAllLevelsSettings<ChronologyGameLevelSettings> => {
  if (!data)
    return {
      levels: [],
    };

  return {
    levels: data.levels.map((item) => ({
      level: item.level,
      settings: {
        elementsType: item.settings.elementsType,
        elements: item.settings.elements.map((element, index) => ({
          description: element.description,
          orderNumber: index,
        })),
      },
    })),
  };
};

const prepareMovieData = (
  data?: GameAllLevelsSettings<MovieGameFormType>
): GameAllLevelsSettings<MovieGameLevelSettings> => {
  if (!data)
    return {
      levels: [],
    };

  return {
    levels: data.levels.map((item) => ({
      level: item.level,
      settings: {
        movieId: item.settings.video?.id || '',
        presses: item.settings.timeRanges.map((timeRange) => ({
          startTime: convertToSeconds(
            timeRange.startTimeMinutes || 0,
            timeRange.startTimeSeconds || 0
          ),
          endTime: convertToSeconds(
            timeRange.endTimeMinutes || 0,
            timeRange.endTimeSeconds || 0
          ),
        })),
      },
    })),
  };
};

export const getMosaicDefaultValues = (
  type: MosaicImageType,
  level: number,
  mosaicLevelSettings?: GameAllLevelsSettings<MosaicGameLevelSettingsReq>
) => {
  switch (type) {
    case MosaicImageType.Card:
      return mosaicLevelSettings?.levels
        .find((item) => item.level === level)
        ?.settings.images.map((image) => image.imageId);
    case MosaicImageType.Distractor:
      return mosaicLevelSettings?.levels
        .find((item) => item.level === level)
        ?.settings.distractors.map((image) => image.imageId);
  }
};
