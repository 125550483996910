import styled from 'styled-components';

export const InputWrapper = styled.div(
  ({ theme: { spaces } }) => `
  display: flex;
  flex-direction: column;
  gap: ${spaces.xs};
  width: 100%;
`
);
