import styled from 'styled-components';

export const MainContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 36px;
  width: 700px;
  background-color: #ffffff;
  padding: 54px 50px;
`;

export const InputsGroup = styled.div`
  display: flex;
  flex-direction: column;
  gap: 16px;
  width: 100%;
`;

export const DescriptionWrapper = styled.div`
  display: flex;
  gap: 4px;
  align-items: flex-end;
`;
