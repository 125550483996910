import styled from 'styled-components';

export const InputContainer = styled.div`
  position: relative;
  width: 100%;
`;

export const ButtonContainer = styled.div`
  display: flex;
  align-items: center;
  gap: 10px;
  position: absolute;
  right: 20px;
  top: 15px;
`;
