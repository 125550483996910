import styled, { keyframes } from 'styled-components';

const show = keyframes`
  0% {
    opacity: 0;
    margin-top: -10px
  }
  100% {
    opacity: 1;
    margin-top: 0;
  }
`;

export const StyledNotification = styled.div`
  animation-name: ${show};
  animation-duration: 150ms;
  border-radius: 10px;
  cursor: pointer;
  margin-top: 5px;
  margin-right: 5px;
  z-index: 999;
`;
