import styled from 'styled-components';

export const StyledList = styled('ul')(
  ({ theme: { spaces } }) => `
  position: relative;
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: ${spaces.md};
`
);
