import styled from 'styled-components';

export const StyledInputContainer = styled.div`
  width: 100%;
`;

export const StyledInput = styled.input(
  ({ theme: { fontSizes, colors } }) => `
  display: flex;
  border: 2px solid ${colors.gray};
  padding: 14px 24px;
  border-radius: 10px;
  font-size: ${fontSizes.sm};
  width: 100%;
  ::placeholder {
    color: black;
  }
`
);

export const Wrapper = styled.div`
  position: relative;
`;

export const StyledIconButton = styled.button`
  background: none;
  cursor: pointer;
  font-weight: 600;
  border-style: none;
  position: absolute;
  top: 14px;
  right: 25px;
`;
