import styled from 'styled-components';

export const StyledDetailsForm = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  width: 600px;
`;

export const FormContainer = styled.div`
  margin-top: 36px;
`;
