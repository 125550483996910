import styled from 'styled-components';
import { ColorType } from 'types';

interface Props {
  color?: ColorType;
}

export const StyledIconButton = styled.button<Props>(
  ({ theme: { colors, fontSizes }, color }) => `
  display: inline-flex;
  background: none;
  cursor: pointer;
  font-weight: 600;
  border-style: none;
  color: ${color ? colors[color] : colors.primary};
  &:hover {
    filter: brightness(0.9);
  }
`
);
