import { FC, ReactNode, useState } from 'react';
import { LangContext } from 'context';
import { Language } from 'cogamika-back/types';
import { i18n } from 'utils/i18n';

interface LanguageProviderProps {
  children?: ReactNode;
}

export const LanguageProvider: FC<LanguageProviderProps> = ({ children }) => {
  const [isLanguageLoaded, setIsLanguageLoaded] = useState<boolean>(false);
  const [lang, setLang] = useState<Language>(i18n.currentLang || Language.en);

  return (
    <LangContext.Provider
      value={{ isLanguageLoaded, setIsLanguageLoaded, lang, setLang }}
    >
      {children}
    </LangContext.Provider>
  );
};
