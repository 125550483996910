import styled from 'styled-components';

interface FormProps {
  fullWidth?: boolean;
}

export const StyledForm = styled('form')<FormProps>(
  ({ fullWidth }) => `
  width: ${fullWidth ? '100%' : 'auto'};
`
);
