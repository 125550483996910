import React from 'react';
import { ColorType, NotificationType } from 'types';

import { StyledAlert } from './styles';

import { Flex, Text } from 'components/common';

interface Props {
  type: NotificationType;
  text: string;
}

const headerText: { [key in NotificationType]: string } = {
  success: 'notifications.success',
  error: 'notifications.error',
  info: 'notifications.info',
};

const headerColor: { [key in NotificationType]: ColorType } = {
  success: 'success',
  error: 'error',
  info: 'info',
};

export const Alert: React.FC<Props> = ({ type, text }) => {
  return (
    <StyledAlert type={type}>
      <Flex columnDirection>
        <Text text={headerText[type]} size="sm" color={headerColor[type]} />
        <Text text={text} size="xs" color="gray" noTranslate />
      </Flex>
    </StyledAlert>
  );
};
