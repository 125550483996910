import styled from 'styled-components';

import {
  AlignItemsType,
  JustifyContentType,
  MarginType,
  SpaceType,
} from 'types';
import { getMargin } from 'utils';

interface Props {
  columnDirection?: boolean;
  alignItems?: AlignItemsType;
  justifyContent?: JustifyContentType;
  gap?: SpaceType;
  fullWidth?: boolean;
  margin?: MarginType;
  wrap?: boolean;
}

export const FlexComponent = styled.div<Props>(
  ({
    theme: { spaces },
    alignItems,
    columnDirection,
    justifyContent,
    gap,
    fullWidth,
    margin,
    wrap,
  }) => `
  display: flex;
  align-items: ${alignItems ? `${alignItems}` : 'flex-start'};
  flex-direction: ${columnDirection ? 'column' : 'row'};
  justify-content: ${justifyContent ? `${justifyContent}` : 'flex-start'};
  flex-wrap: ${wrap ? 'wrap' : 'nowrap'};
  gap: ${gap ? spaces[gap] : '0px'};
  width: ${fullWidth ? '100%' : 'auto'};
  ${margin && getMargin(margin, spaces)};
`
);
