import { configureStore } from '@reduxjs/toolkit';

import {
  modalSlice,
  gameSlice,
  userSlice,
  notificationsSlice,
  paymentSlice,
  gameControllerSlice,
} from 'slices';

export const store = configureStore({
  reducer: {
    modal: modalSlice.reducer,
    game: gameSlice.reducer,
    user: userSlice.reducer,
    notifications: notificationsSlice.reducer,
    payment: paymentSlice.reducer,
    gameController: gameControllerSlice.reducer,
  },
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware({
      serializableCheck: {
        ignoredActions: ['modal/showModal'],
      },
    }),
});

export type AppDispatch = typeof store.dispatch;
export type RootState = ReturnType<typeof store.getState>;
