export interface MosaicGameSettings {}

export interface CardImage {
  imageId: string;
  type: MosaicImageType;
}

export type CardImageKeys = keyof CardImage;

export const CardImageKeysArray: Array<CardImageKeys> = ['imageId', 'type'];

export interface MosaicGameLevelSettingsReq {
  distractors: CardImage[];
  images: CardImage[];
}

export interface MosaicGameLevelSettings extends MosaicGameLevelSettingsReq {
  imagesAmount: number;
}

export type MosaicGameLevelSettingsKeys = keyof MosaicGameLevelSettings;

export const MosaicGameLevelSettingsKeysArray: Array<MosaicGameLevelSettingsKeys> =
  ['imagesAmount', 'images', 'distractors'];

export type MosaicSettingsRes = MosaicGameLevelSettings & MosaicGameSettings;

export enum MosaicImageType {
  Card = 'CARD',
  Distractor = 'DISTRACTOR',
}
