import { FC, ReactNode } from 'react';
import {
  AccordionContainer,
  AccordionContent,
  AccordionHeader,
} from './styles';
import { Text } from '../..';

interface AccordionProps {
  isOpen: boolean;
  onClickHeader: () => void;
  children?: ReactNode;
  noTranslate?: boolean;
  header?: string;
}

export const Accordion: FC<AccordionProps> = ({
  children,
  isOpen,
  onClickHeader,
  noTranslate = false,
  header = '',
}) => {
  return (
    <AccordionContainer>
      <AccordionHeader isOpen={isOpen} onClick={onClickHeader}>
        <Text size="sm" text={header} noTranslate={noTranslate} />
      </AccordionHeader>
      <AccordionContent isOpen={isOpen}>{children}</AccordionContent>
    </AccordionContainer>
  );
};
