import styled from 'styled-components';

import { gtMedia, ltMedia } from 'config';
import { ColorType } from 'types';

interface Props {
  bgColor: ColorType;
}

export const StyledMainLayout = styled.div<Props>(
  ({ theme: { colors }, bgColor }) => `
  width: 100%;
  background-color: ${colors[bgColor]};
  min-height: 100vh;
  display: flex;
  flex-direction: column;
`
);

export const StyledMainContainer = styled.div(
  ({ theme }) => `
  width: 100%;
  padding: 60px 150px;
  width: 100%;

  
  &.fullHeight {
    flex: 1;
  }

  ${ltMedia('lg')} {
    padding: 60px 20px;
  }

  ${gtMedia('xl')} {
    max-width: 1820px;
  }
  display: flex;
  margin: 0 auto;
`
);
