import styled from 'styled-components';
import { Theme } from 'types';

interface Props {
  level: number;
}

const getVariant = (level: number) => {
  switch (level) {
    case 1:
      return `
        grid-template-columns: repeat(3, 1fr);        
    `;
    case 2:
      return `
        grid-template-columns: repeat(4, 1fr);
      `;
    case 3:
      return `
        grid-template-columns: repeat(6, 1fr);
      `;
    case 4:
      return `
        grid-template-columns: repeat(5, 1fr);
      `;
    case 5:
      return `
        grid-template-columns: repeat(6, 1fr);
      `;
    case 6:
      return `
        grid-template-columns: repeat(6, 1fr);
      `;
    case 7:
      return `
      grid-template-columns: repeat(9, 1fr);     
  `;
    case 8:
      return `
        grid-template-columns: repeat(8, 1fr);
      `;
    case 9:
      return `
        grid-template-columns: repeat(8, 1fr);
      `;
    case 10:
      return `
        grid-template-columns: repeat(9, 1fr);
      `;
  }
};

const getGap = (level: number, theme: Theme) => {
  switch (level) {
    case 1:
    case 2:
      return `
        gap: ${theme.spaces['2xl']};
      `;
    case 3:
    case 4:
    case 5:
    case 6:
    case 7:
      return `
        gap: ${theme.spaces.lg};
      `;
    case 8:
    case 9:
    case 10:
      return `
        gap: ${theme.spaces.base};
      `;
  }
};

export const MemoryCardContainer = styled.div<Props>(
  ({ theme, level }) => `
  display: grid;
  justify-content: center;
  align-items: center; 
  ${getGap(level, theme)}
  ${getVariant(level)};
`
);
