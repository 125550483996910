import { useForm } from 'react-hook-form';
import React, { useContext, useEffect, useState } from 'react';

import {
  Collection,
  CollectionAssignmentType,
  FileType,
} from 'cogamika-back/types';
import { CollectiomFormType } from 'types';
import { GameFormContext } from 'context';
import { Form, Image } from 'components/forms';
import {
  Button,
  FileSelector,
  Flex,
  FlexAlignCenter,
  GridItem,
  Icon,
  IconButton,
  Text,
} from 'components/common';

import { SelectCollectionImage } from '../SelectCollectionImage';
import {
  LeftWrapper,
  SelectedFiles,
  ButtonContainer,
  CollectionImage,
  FilesSelectorWrapper,
  CollectionContainer,
} from './styles';

interface Props {
  collectionType: CollectionAssignmentType;
  goBack: () => void;
  goNext: () => void;
}

export const CollectionGameForm: React.FC<Props> = ({
  collectionType,
  goBack,
  goNext,
}) => {
  const [isShow, setIsShow] = useState(false);
  const [selectedImages, setSelectedImages] = useState(() => new Set<string>());
  const { setGameSettings, gameSettings } = useContext(GameFormContext);
  const colectionName =
    collectionType === CollectionAssignmentType.First
      ? 'firstCollection'
      : 'secondCollection';
  const defaultSettings = gameSettings?.collectionSettings?.[colectionName];

  const methods = useForm<CollectiomFormType>({
    mode: 'onSubmit',
    defaultValues: defaultSettings,
  });
  const collectionImage = methods.watch('collection');

  const handleFileSelect = (id: string) => {
    if (selectedImages.has(id)) {
      setSelectedImages((prev) => {
        const next = new Set(prev);
        next.delete(id);
        methods.setValue('collectionImages', Array.from(next));
        return next;
      });
    } else {
      setSelectedImages((prev) => {
        methods.setValue('collectionImages', [...Array.from(prev), id]);
        return new Set(prev).add(id);
      });
    }

    methods.trigger();
  };

  const submit = (data: CollectiomFormType) => {
    let collectionData: Collection | undefined;

    if (data.collection?.imageId) {
      collectionData = {
        imageId: data.collection.imageId,
        name: data.collection.name,
        type: collectionType,
      };
    }

    if (collectionType === CollectionAssignmentType.First) {
      setGameSettings((prev) => ({
        collectionSettings: {
          ...prev?.collectionSettings,
          firstCollection: {
            collection: collectionData,
            collectionImages: data?.collectionImages,
          },
        },
      }));
    } else {
      setGameSettings((prev) => ({
        collectionSettings: {
          ...prev?.collectionSettings,
          secondCollection: {
            collection: collectionData,
            collectionImages: data?.collectionImages,
          },
        },
      }));
    }

    goNext();
  };

  const deleteCollectionImage = () => {
    methods.setValue('collection', undefined);
  };

  const getHeaderText = (collectionType: CollectionAssignmentType) => {
    switch (collectionType) {
      case CollectionAssignmentType.First:
        return 'views.newGame.collection.firstCollectionHeading';
      case CollectionAssignmentType.Second:
        return 'views.newGame.collection.secondCollectionHeading';
    }
  };

  const deleteImage = (id: string) => {
    if (!selectedImages.has(id)) return;

    setSelectedImages((prev) => {
      const next = new Set(prev);
      next.delete(id);
      methods.setValue('collectionImages', Array.from(next));
      return next;
    });
  };

  useEffect(() => {
    if (defaultSettings) {
      setSelectedImages(new Set(defaultSettings?.collectionImages));
    }
  }, [defaultSettings]);

  return (
    <Form methods={methods} submitHandler={submit} fullWidth>
      <CollectionContainer>
        <LeftWrapper>
          <Flex columnDirection gap="3xl">
            <Text
              text={getHeaderText(collectionType)}
              size="xl"
              weight="semibold"
            />
            <Flex columnDirection gap="sm" fullWidth>
              <Text text="views.newGame.collection.mainImage" size="base" />
              {collectionImage ? (
                <CollectionImage>
                  <FlexAlignCenter gap="sm">
                    <Icon name="secondary-check" color="secondary" />
                    <Text text={collectionImage.name} size="base" noTranslate />
                  </FlexAlignCenter>
                  <IconButton
                    name="cross"
                    onClick={deleteCollectionImage}
                    type="button"
                  />
                </CollectionImage>
              ) : (
                <Button
                  type="button"
                  text="button.addFile"
                  variant="outlined"
                  uppercase
                  onClick={() => setIsShow(true)}
                />
              )}
            </Flex>
          </Flex>
          <Flex columnDirection gap="sm">
            <Text
              text="views.newGame.numberOfFiles"
              translationArgs={{ files: selectedImages.size }}
              size="base"
            />
            <SelectedFiles className="files">
              {Array.from(selectedImages).map((id) => (
                <Image id={id} deleteImage={deleteImage} key={id} />
              ))}
            </SelectedFiles>
          </Flex>
        </LeftWrapper>
        <GridItem position={[2]}>
          <FilesSelectorWrapper>
            <FileSelector
              refreshList={false}
              onSelectFile={handleFileSelect}
              types={[FileType.Image]}
              selectedIds={selectedImages}
            />
          </FilesSelectorWrapper>
        </GridItem>
        {isShow && (
          <SelectCollectionImage
            setIsShow={setIsShow}
            collectionType={collectionType}
          />
        )}
      </CollectionContainer>
      <ButtonContainer>
        <Button
          text="button.back"
          variant="primary"
          type="button"
          uppercase
          onClick={goBack}
        />
        <Button
          text="button.next"
          variant="secondary"
          type="submit"
          disabled={false}
          uppercase
        />
      </ButtonContainer>
    </Form>
  );
};
