import styled from 'styled-components';

export const MainContainer = styled('div')(
  ({ theme: { colors, spaces, boxSizes } }) => `
  max-width: ${boxSizes.confirmModalBox};
  padding: ${spaces['7xl']};
  background-color: ${colors.secondaryBackground};
  box-shadow: ${colors.boxShadow};
`
);

export const StyledImage = styled('img')(
  ({ theme }) => `
  width: ${theme.sizes.lg};
  height: ${theme.sizes.lg};
  border-radius: 50%;
  `
);
