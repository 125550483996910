import React from 'react';

import { ColorType, IconName } from 'types';
import { Icon } from 'components/common/Icon';

import { StyledIconButton } from './styles';

interface Props {
  name: IconName;
  color?: ColorType;
  onClick: React.MouseEventHandler<HTMLButtonElement> | undefined;
  type: 'submit' | 'reset' | 'button' | undefined;
  dataCy?: string;
  disabled?: boolean;
}

export const IconButton: React.FC<Props> = ({
  name,
  type,
  onClick,
  color,
  dataCy,
  disabled,
}) => {
  return (
    <StyledIconButton
      type={type}
      onClick={onClick}
      color={color}
      data-cy={dataCy}
      disabled={disabled}
    >
      <Icon name={name} />
    </StyledIconButton>
  );
};
