import styled from 'styled-components';

export const FileListContainer = styled.div`
  display: flex;
  flex-wrap: wrap;
  gap: 25px;
`;

export const SelectContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 25px;
`;

export const SearchInput = styled.input(
  ({ theme: { colors, radius, fontSizes } }) => `
  background-color: ${colors.lightGray};
  border-radius: 67px;
  padding: 16px 24px 16px 66px;
  width: 100%;
  border: 2px solid ${colors.primaryBorder};
  font-size: ${fontSizes.md};  
  `
);

export const InputContainer = styled.div`
  margin-bottom: 54px;
  position: relative;
  display: inline-block;
`;

export const IconContainer = styled.div`
  position: absolute;
  top: 50%;
  left: 24px;
  transform: translateY(-50%);
`;
