import { ShowModalActionPayload } from 'types';
import { useAppDispatch, useAppSelector } from 'hooks';
import {
  showModal as showModalAction,
  hideModal as hideModalAction,
} from 'slices';

export const useModal = () => {
  const dispatch = useAppDispatch();
  const { isOpen, data } = useAppSelector((state) => state.modal);

  const showModal = (payload: ShowModalActionPayload) => {
    dispatch(showModalAction(payload));
  };

  const closeModal = () => {
    dispatch(hideModalAction());
  };

  const getData = <Data = unknown>() => {
    return data as Data;
  };

  return { showModal, closeModal, isOpen, getData };
};
