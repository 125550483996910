export enum ElementType {
  Text = 'TEXT',
  Image = 'IMAGE',
}

export type Element = {
  description: string;
  orderNumber: number;
};

export type ElementKeys = keyof Element;

export const ElementKeysArray: Array<ElementKeys> = [
  'description',
  'orderNumber',
];

export interface ChronologyGameSettings {}

export interface ChronologyGameLevelSettings {
  elementsType: ElementType;
  elements: Element[];
}

export type ChronologySettingsRes = ChronologyGameSettings &
  ChronologyGameLevelSettings;
