import { FC, useState } from 'react';

import { GridContainer, GridItem } from '../Grid';
import { Flex } from '../Flex';
import { Chart } from './Chart';
import { FunctionBox, PaginationButton } from './styles';
import { Text } from '../Text';
import {
  formatDate,
  getSelectOptionsFromEnum,
  goMonthEarlier,
  goMonthLater,
  goWeekEarlier,
  goWeekLater,
  myAddDays,
} from 'utils';
import {
  ChartViewMode,
  DomainBaseRes,
  ProfileChartData,
} from 'cogamika-back/types';
import { AppConfig } from 'config';
import { ViewModeSelect } from './ViewModeSelect';

interface ProgressChartProps {
  data: ProfileChartData[];
  domainLevel: number;
  functions?: DomainBaseRes[];
  onChangeFunction?: (id: string, index: number) => void;
  onChangeView?: (date: ChartViewMode) => void;
  onNextDate?: (date: Date) => void;
  onPreviousDate?: (date: Date) => void;
  chartHeight?: number;
}

export const ProgressChart: FC<ProgressChartProps> = ({
  data,
  functions,
  domainLevel,
  onChangeFunction,
  onChangeView,
  onNextDate,
  onPreviousDate,
  chartHeight,
}) => {
  const [currentFunctionIndex, setCurrentFunctionIndex] = useState<number>(0);
  const [date, setDate] = useState<Date>(new Date());
  const [mode, setMode] = useState<ChartViewMode>(ChartViewMode.Week);

  const xAxisDateFormat = () =>
    ChartViewMode.Week === mode
      ? AppConfig.DATA_FORMATS.CHART_WEEK_FORMAT_X_AXIS
      : AppConfig.DATA_FORMATS.CHART_MONTH_FORMAT_X_AXIS;

  const chartData = data.map(({ date, progress, level }) => ({
    progress,
    level,
    name: formatDate(new Date(date), xAxisDateFormat()),
  }));

  const handleOnFunction = (id: string, index: number) => {
    setCurrentFunctionIndex(index);
    if (onChangeFunction) {
      onChangeFunction(id, index);
    }
  };

  const getNextDate = (direction: 'previous' | 'next') => {
    switch (mode) {
      case ChartViewMode.Week:
        return direction === 'next' ? goWeekLater(date) : goWeekEarlier(date);
      case ChartViewMode.Month:
        return direction === 'next' ? goMonthLater(date) : goMonthEarlier(date);
    }
  };

  const handleOnNextDate = () => {
    const newDate = getNextDate('next');
    setDate(newDate);
    if (onNextDate) {
      onNextDate(newDate);
    }
  };

  const handleOnPreviousDate = () => {
    const newDate = getNextDate('previous');
    setDate(newDate);
    if (onPreviousDate) {
      onPreviousDate(newDate);
    }
  };

  const handleOnChangeViewMode = (newValue: ChartViewMode) => {
    setMode(newValue);
    if (onChangeView) {
      onChangeView(newValue);
    }
  };

  const displayData = () => {
    switch (mode) {
      case ChartViewMode.Week:
        return (
          <>
            <Text
              noTranslate
              text={formatDate(date, AppConfig.DATA_FORMATS.CHART_WEEK_FORMAT)}
              color="secondaryGray"
              weight="semibold"
            />
            <Text
              text="-"
              noTranslate
              color="secondaryGray"
              weight="semibold"
            />
            <Text
              noTranslate
              text={formatDate(
                myAddDays(date, 7),
                AppConfig.DATA_FORMATS.CHART_WEEK_FORMAT
              )}
              color="secondaryGray"
              weight="semibold"
            />
          </>
        );
      case ChartViewMode.Month:
        return (
          <Text
            noTranslate
            text={formatDate(date, AppConfig.DATA_FORMATS.CHART_MONTH_FORMAT)}
            color="secondaryGray"
            weight="semibold"
          />
        );
    }
  };

  const renderFunctions = functions?.map(({ name, id }, index) => (
    <FunctionBox
      active={index === currentFunctionIndex}
      onClick={() => handleOnFunction(id, index)}
      key={id}
    >
      <Text text={name} noTranslate />
    </FunctionBox>
  ));

  return (
    <GridContainer size={[1, 3]} gap="xl">
      <GridItem position={[1, 1]}>
        <Flex alignItems="center" justifyContent="space-between">
          <Flex alignItems="center" gap="xs">
            <ViewModeSelect
              onChange={handleOnChangeViewMode}
              options={getSelectOptionsFromEnum(ChartViewMode, true)}
            />
          </Flex>
          <Flex alignItems="center" gap="xs">
            <PaginationButton direction="left" onClick={handleOnPreviousDate} />
            {displayData()}
            <PaginationButton direction="right" onClick={handleOnNextDate} />
          </Flex>
        </Flex>
      </GridItem>
      <GridItem position={[1, 2]}>
        <Text
          text="views.user.account.currentLevel"
          translationArgs={{
            level: domainLevel,
          }}
          weight="semibold"
          size="md"
        />
      </GridItem>
      <GridItem position={[1, 3]}>
        <Chart data={chartData} height={chartHeight} viewMode={mode} />
      </GridItem>
      <GridItem position={[1, 4]}>
        <Flex alignItems="center" gap="sm" wrap>
          {renderFunctions}
        </Flex>
      </GridItem>
    </GridContainer>
  );
};
