import { useCallback, useContext } from 'react';
import { useApi } from './useApi';
import {
  FaqQuestionReq,
  FaqQuestionRes,
  FaqRes,
  FaqTopicReq,
  FaqTopicRes,
  StatusRes,
} from 'cogamika-back/types';
import { useAppDispatch } from './redux';
import { infoNotification, successNotification } from 'slices';
import { FaqContext } from 'context';

interface UseFaq {
  faqData: FaqRes;
  getFaqData: () => Promise<void>;
  deleteQuestion: (id: string) => Promise<void>;
  deleteTopic: (id: string) => Promise<void>;
  addEditFaqTopic: (payload: FaqTopicReq, id?: string) => Promise<void>;
  addEditFaqQuestion: (payload: FaqQuestionReq, id?: string) => Promise<void>;
}

export const useFaq = (): UseFaq => {
  const { faqData, setFaqData } = useContext(FaqContext);
  const { get, post, patch, remove } = useApi();
  const dispatch = useAppDispatch();

  const getFaqData = useCallback(async () => {
    const result = await get<FaqRes>('faq');
    if (result) {
      setFaqData(result);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const addEditFaqTopic = async (payload: FaqTopicReq, id?: string) => {
    let result;
    let notification;

    if (id) {
      result = await patch<FaqTopicRes, FaqTopicReq>(
        `/faq/topic/${id}`,
        payload
      );
      notification = 'notifications.successfullyEditedFaqTopic';
    } else {
      result = await post<FaqTopicRes, FaqTopicReq>(`/faq/topic`, payload);
      notification = 'notifications.successfullyAddedFaqTopic';
    }

    if (result) {
      dispatch(successNotification(notification));
      getFaqData();
    }
  };

  const addEditFaqQuestion = async (payload: FaqQuestionReq, id?: string) => {
    let result;
    let notification;

    if (id) {
      result = await patch<FaqQuestionRes, FaqQuestionReq>(
        `/faq/question/${id}`,
        payload
      );
      notification = 'notifications.successfullyAddedFaqQuestion';
    } else {
      result = await post<FaqQuestionRes, FaqQuestionReq>(
        `/faq/question`,
        payload
      );
      notification = 'notifications.successfullyEditedFaqQuestion';
    }

    if (result) {
      const updatedTopic = faqData.find(({ id }) => payload.topicId === id);
      if (updatedTopic) {
        dispatch(successNotification(notification));
        getFaqData();
      }
    }
  };

  const deleteTopic = async (id: string) => {
    const topicToDelete = faqData.find((item) => item.id === id);

    if (topicToDelete && topicToDelete.questions.length) {
      dispatch(infoNotification('notifications.infoTopicHasQuestions'));
      return;
    }

    const result = await remove<StatusRes>(`/faq/topic/${id}`);
    if (result) {
      dispatch(
        successNotification('notifications.successfullyDeletedFaqTopic')
      );
      setFaqData((prevState) => prevState.filter((item) => item.id !== id));
    }
  };

  const deleteQuestion = async (id: string) => {
    const result = await remove<StatusRes>(`/faq/question/${id}`);
    if (result) {
      dispatch(
        successNotification('notifications.successfullyDeletedFaqQuestion')
      );
      getFaqData();
    }
  };

  return {
    getFaqData,
    addEditFaqTopic,
    addEditFaqQuestion,
    deleteQuestion,
    deleteTopic,
    faqData,
  };
};
