import { FC } from 'react';
import { Bar, BarContainer, ProgressContainer } from './styles';
import { Heading, Text } from '../Text';

interface ProgressProps {
  progress: number;
  title: string;
  noTranslate?: boolean;
}

export const Progress: FC<ProgressProps> = ({
  progress,
  title,
  noTranslate,
}) => {
  return (
    <ProgressContainer>
      <Heading
        text={title}
        size="md"
        weight="medium"
        noTranslate={noTranslate}
      />
      <BarContainer>
        <Bar progress={progress} />
        <Text text={`${progress}%`} noTranslate size="sm" />
      </BarContainer>
    </ProgressContainer>
  );
};
