import { IconButton } from 'components/common/Button/IconButton';
import { Text } from 'components/common/Text';
import { FC, useContext } from 'react';
import { CartsContainer, LeftArrow, RightArrow } from './styles';
import { BasePaginationContext } from 'context';

export const CartsPagination: FC = () => {
  const { setPagination, pagesPossibility, pagination } = useContext(
    BasePaginationContext
  );
  const { page } = pagination;
  const { hasNextPage, hasPreviousPage } = pagesPossibility;

  const handleOnNextPage = () => {
    if (page) {
      setPagination({ ...pagination, page: `${Number(page) + 1}` });
    }
  };

  const handleOnPreviousPage = () => {
    if (page) {
      setPagination({ ...pagination, page: `${Number(page) - 1}` });
    }
  };

  return (
    <CartsContainer>
      <LeftArrow>
        <IconButton
          type="button"
          name="left-arrow"
          onClick={handleOnPreviousPage}
          disabled={!hasPreviousPage}
        />
      </LeftArrow>
      <Text noTranslate text={`${page}`} />
      <RightArrow>
        <IconButton
          type="button"
          name="right-arrow"
          onClick={handleOnNextPage}
          disabled={!hasNextPage}
        />
      </RightArrow>
    </CartsContainer>
  );
};
