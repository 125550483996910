import styled from 'styled-components';

import { getMargin } from 'utils';
import { ButtonType, JustifyContentType, MarginType, Theme } from 'types';

interface Props {
  variant: ButtonType;
  margin?: MarginType;
  fullWidth?: boolean;
  uppercase?: boolean;
  justifyContent?: JustifyContentType;
  noWrap?: boolean;
}

const getVariant = (variant: ButtonType, theme: Theme) => {
  switch (variant) {
    case 'primary':
      return `
          background-color: ${theme.colors.primary};
          color: ${theme.colors.light};
          &:hover {
            background-color: ${theme.colors.primaryHover};
          }
          &: disabled {
            background-color: ${theme.colors.disable};
          }
        `;
    case 'secondary':
      return `
        background-color: ${theme.colors.secondary};
        color: ${theme.colors.light};
        &:hover {
          background-color: ${theme.colors.secondaryHover};
        }
        &: disabled {
          background-color: ${theme.colors.disable};
        }
      `;
    case 'tertiary':
      return `
        background: none;
        border: none
        color: ${theme.colors.primary};
        &:hover {
          color: ${theme.colors.secondary};
        }
        &: disabled {
          color: ${theme.colors.disable};
        }
      `;
    case 'outlined':
      return `
        color: ${theme.colors.primary};
        border: 2px solid ${theme.colors.primary};
        background-color: transparent;
        &:hover {
          border-color: ${theme.colors.secondary};
          color: ${theme.colors.secondary};
        }
      `;
    case 'gray':
      return `
        background-color: ${theme.colors.gray};
        color: ${theme.colors.primary};
        &:hover {
          background-color: ${theme.colors.lightGray};
        }
      `;
    case 'outlined-success':
      return `
        background-color: ${theme.colors.secondarySuccess};
        color: ${theme.colors.darkGreen};
        border: 2px solid ${theme.colors.darkGreen};

        &:hover {
          background-color: ${theme.colors.darkGreen};
          color: ${theme.colors.light};
        }
      `;
    case 'outlined-error':
      return `
          background-color: ${theme.colors.warningBackground};
          color: ${theme.colors.error};
          border: 2px solid ${theme.colors.error};

          &:hover {
            background-color: ${theme.colors.error};
            color: ${theme.colors.light};
          }
        `;
    case 'domains-filter':
      return `
        padding: ${theme.spaces.xs} ${theme.spaces.base};
        background-color: ${theme.colors.gray};
        border: 2px solid ${theme.colors.secondaryBorder};
        border-radius: ${theme.radius['4xl']};
        height: 37px;
        font-size: ${theme.fontSizes.sm};
        font-weight: ${theme.fontWeight.medium};
        &:hover {
          background-color: ${theme.colors.lightGray};
        }
      `;
    case 'domains-filter-selected':
      return `
          color: ${theme.colors.light};
          padding: ${theme.spaces.xs} ${theme.spaces.base};
          background-color: ${theme.colors.secondary};
          border: 2px solid ${theme.colors.secondary};
          border-radius: ${theme.radius['4xl']};
          height: 37px;        
          font-size: ${theme.fontSizes.sm};
          font-weight: ${theme.fontWeight.medium};
          &:hover {
            background-color: ${theme.colors.violet};
            border-color: ${theme.colors.violet};
          }
        `;
    case 'cookie-cancel':
      return `
              color: ${theme.colors.secondaryBackground};
              background-color: transparent;
              border: 2px solid ${theme.colors.secondaryBackground};
              &:hover {
                background-color: ${theme.colors.violet};
                border-color: ${theme.colors.violet};
              }
            `;
    case 'cookie-accept':
      return `
              color: ${theme.colors.primary};
              background-color: ${theme.colors.secondaryBackground};
              &:hover {
                background-color: ${theme.colors.violet};
                color: ${theme.colors.secondaryBackground};
              }
            `;
  }
};

export const StyledButton = styled.button<Props>(
  ({
    theme: { fontSizes, lineHeights, spaces, fontWeight },
    theme,
    margin,
    fullWidth,
    variant,
    uppercase,
    justifyContent,
    noWrap,
  }) => `
  display: flex;
  justify-content: ${justifyContent};
  gap: 16px;
  align-items: center;
  cursor: pointer;
  font-weight: ${fontWeight.semibold};
  border-style: none;
  font-size: ${fontSizes.md};
  line-height: ${lineHeights.md};
  border-radius: ${theme.radius.md};
  box-sizing: border-box;
  height: 60px;
  padding: 16px 32px 16px 32px;
  text-transform: ${uppercase ? 'uppercase' : ''};
  width: ${fullWidth ? '100%' : 'auto'};
  &:focus-visible {
    outline: none;
  }
  &:disabled {
    cursor: not-allowed;
  }
  ${margin && getMargin(margin, spaces)};
  ${getVariant(variant, theme)};
  ${noWrap && 'white-space: nowrap'};
`
);
