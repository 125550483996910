import styled from 'styled-components';
import { FontSizeType } from 'types';

interface Props {
  uppercase?: boolean;
  underline?: boolean;
  size?: FontSizeType;
}

export const StyledFooterLinkButton = styled.button<Props>(
  ({
    theme: { colors, fontSizes, lineHeights, spaces, fontWeight },
    uppercase,
    underline,
    size,
  }) => `
  text-transform: ${uppercase ? 'uppercase' : ''};
  color: ${colors.primary}3C;
  cursor: pointer;
  border-style: none;
  background: none;
  font-size: ${size ? fontSizes[size] : fontSizes.base};
  line-height: ${lineHeights.base};
  font-weight: ${fontWeight.semibold};
  text-decoration: ${underline && 'underline'};
  display: flex;
  align-items: center;
  justify-content: center;
  gap: ${spaces.sm};
  justify-context: space-between;
  &:hover {
    color: ${colors.primary};
    text-decoration: underline;
  }
  `
);
