import styled from 'styled-components';
import { Button } from '../Button';
import { ColorType } from 'types';

interface ButtonProps {
  color: ColorType;
}

export const SubscriptionPackageWrapper = styled('div')(
  ({ theme: { colors } }) => `
  display: flex;
  align-items: center;
  background-color: ${colors.secondaryBackground};
  padding: 40px 80px;
  max-width: 460px;
  box-shadow: ${colors.boxShadow};
`
);

export const SubscriptionButton = styled(Button)<ButtonProps>(
  ({ color, theme }) => `
  background-color: ${theme.colors[color]};
`
);

export const SubscriptionContent = styled('div')(
  () => `
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 48px;
  justify-content: space-between;
`
);

export const SubscriptionDescriptionList = styled('ul')(
  () => `
  display: flex;
  align-items: left;
  flex-direction: column;
  gap: 15px;
`
);

export const SubscriptionDescriptionListItem = styled('li')(
  () => `
`
);
