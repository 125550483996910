import styled from 'styled-components';
import { Button } from '../Button';

export const CookieBoxWrapper = styled('div')(
  ({ theme }) => `
  position: fixed;
  left: 0;
  bottom: 0;
  padding: 30px 60px;
  display: flex;
  width: 100%;
  min-height: 160px;
  align-items: center;
  background-color: ${theme.colors.primary}; 
`
);

export const StyledCookieCancelButton = styled(Button)(
  ({ theme }) => `
  color: ${theme.colors.secondaryBackground};
  border-color: ${theme.colors.secondaryBackground};
`
);
