import React, { useState } from 'react';
import {
  Text,
  Heading,
  Flex,
  FlexAlignCenter,
  Button,
} from 'components/common';

import { useApi, useAppSelector, useModal } from 'hooks';
import { useNavigate } from 'react-router-dom';
import { userRoutes } from 'config';
import { StatusRes, UserGameSessionReq } from 'cogamika-back/types';
import { MainContainer } from './styles';

export const SessionSaveError: React.FC = () => {
  const [isErrorAgain, setIsErrorAgain] = useState<boolean>(false);
  const { closeModal } = useModal();
  const { post } = useApi();
  const { gamesPlayed } = useAppSelector((state) => state.gameController);
  const navigate = useNavigate();

  const handleOnSaveAgain = async () => {
    const result = await post<StatusRes, UserGameSessionReq>('session', {
      games: gamesPlayed,
    });
    if (!result) {
      setIsErrorAgain(true);
    }
  };

  const handleOnClose = () => {
    closeModal();
    navigate(userRoutes.dashboard);
  };

  return (
    <MainContainer>
      <Flex columnDirection gap="xl">
        <Heading
          text={`modals.sessionSaveError.${
            isErrorAgain ? 'headerBackToDashboard' : 'headerSaveAgain'
          }`}
          size="lg"
        />
        <Text
          text={`modals.sessionSaveError.${
            isErrorAgain ? 'descriptionBackToDashboard' : 'descriptionSaveAgain'
          }`}
          size="sm"
        />
        <FlexAlignCenter gap="md" justifyContent="center">
          <Button
            type="button"
            variant="secondary"
            onClick={isErrorAgain ? handleOnClose : handleOnSaveAgain}
            text={isErrorAgain ? 'button.backToDashboard' : 'button.saveAgain'}
          />
        </FlexAlignCenter>
      </Flex>
    </MainContainer>
  );
};
