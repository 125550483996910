import { __ } from './i18n';

interface Params {
  [key: string]: string;
}

export const getFormValidationError = (text: string, params: Params) => {
  const translatedParams = translateParams(params);

  return __(`formValidationError.${text}`, translatedParams);
};

export const translateParams = (params: Params): Params => {
  const translatedParams: Params = {};

  Object.keys(params).forEach((key) => {
    if (key === 'property') {
      translatedParams[key] = __(`fieldName.${params[key]}`);
    } else {
      translatedParams[key] = params[key];
    }
  });

  return translatedParams;
};
