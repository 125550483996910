import { PayloadAction } from '@reduxjs/toolkit';
import { ApiErrorCode } from 'cogamika-back/types';

export enum NotificationType {
  Info = 'info',
  Error = 'error',
  Success = 'success',
}

export interface SingleNotification {
  type: NotificationType;
  text: string;
  id: string;
  code?: ApiErrorCode;
}

export type NotificationsState = {
  notifications: SingleNotification[];
  formValidationErrors: string[];
  errorNotification: SingleNotification | null;
  deletedFileId?: string;
};

export type FormValidationError = {
  field: string;
  txt: string;
  params: {
    [key: string]: string;
  };
};

export type ErrorNotification = PayloadAction<ApiErrorCode>;

export type OtherNotification = PayloadAction<string>;

export type FormValidationNotification = PayloadAction<FormValidationError[]>;

export type DeletedFileId = PayloadAction<string>;
