import { v4 as uuidv4 } from 'uuid';

import { shuffle } from 'utils';
import { AppConfig } from 'config';
import { GameResult, MemoryCardType } from 'types';

import { Game } from 'games';
import { MemorySettingsRes } from 'cogamika-back/types';

export class Memory extends Game {
  public memoryCards: MemoryCardType[];
  public memoryImages: string[];
  private settings: MemorySettingsRes;
  private firstSelectedCard: MemoryCardType | null;
  private secondSelectedCard: MemoryCardType | null;
  private CardClickTimerId: NodeJS.Timeout | undefined;

  constructor(
    level: number,
    gameId: string,
    domainId: string,
    settings: MemorySettingsRes
  ) {
    super(level, gameId, domainId);
    this.memoryCards = [];
    this.memoryImages = [];
    this.settings = settings;
    this.firstSelectedCard = null;
    this.secondSelectedCard = null;
    this.CardClickTimerId = undefined;

    this.initGame();
  }

  public initGame(): void {
    this.shuffleAndDuplicate(
      this.settings.imageIds,
      this.settings.imagesAmount
    );
  }

  public get getMemoryCards() {
    return this.memoryCards;
  }

  public showMemoryCards(
    setMemoryCards: React.Dispatch<React.SetStateAction<MemoryCardType[]>>
  ): void {
    this.showCards();
    setMemoryCards(() => [...this.memoryCards]);
  }

  public startMemoryGame(
    setMemoryCards: React.Dispatch<React.SetStateAction<MemoryCardType[]>>
  ): void {
    setTimeout(() => {
      this.hideCards();
      super.startGame();
      setMemoryCards(() => [...this.memoryCards]);
    }, AppConfig.MEMORY_CARDS_DISPLAY_TIME);
  }

  public hideCards(): void {
    this.memoryCards = this.memoryCards.map((item) => ({
      ...item,
      isVisible: false,
    }));
  }

  public showCards(): void {
    this.memoryCards = this.memoryCards.map((item) => ({
      ...item,
      isVisible: true,
    }));
  }

  public clearAndHideSelected(): void {
    if (this.firstSelectedCard) this.firstSelectedCard.isVisible = false;
    this.firstSelectedCard = null;

    if (this.secondSelectedCard) this.secondSelectedCard.isVisible = false;
    this.secondSelectedCard = null;
  }

  public cardClick(
    cardId: string,
    endGame: (result: GameResult) => void,
    setMemoryCards: React.Dispatch<React.SetStateAction<MemoryCardType[]>>
  ): void {
    const selectedCardIndex = this.memoryCards.findIndex(
      (memoryCard) => memoryCard.id === cardId
    );
    const selectedCard = this.memoryCards[selectedCardIndex];
    selectedCard.isVisible = true;

    if (this.firstSelectedCard && this.secondSelectedCard) {
      // Two Cards revealed before clicked
      clearTimeout(this.CardClickTimerId);
      this.clearAndHideSelected();
    }

    if (!this.firstSelectedCard) {
      // This is the first card click
      this.firstSelectedCard = selectedCard;
    } else {
      // This is the second card click
      this.handleSecondCardClick(
        this.firstSelectedCard,
        selectedCard,
        setMemoryCards
      );
    }

    setMemoryCards(() => [...this.memoryCards]);

    const isWon = this.checkIfUserWon();
    if (isWon) {
      setTimeout(() => {
        endGame(GameResult.Win);
      }, 1500);
    }
  }

  private handleSecondCardClick(
    firstSelectedCard: MemoryCardType,
    secondSelectedCard: MemoryCardType,
    setMemoryCards: React.Dispatch<React.SetStateAction<MemoryCardType[]>>
  ): void {
    if (firstSelectedCard.imageId === secondSelectedCard.imageId) {
      this.handleMatchedCards(firstSelectedCard, secondSelectedCard);
    } else {
      this.handleMismatchedCards(secondSelectedCard, setMemoryCards);
    }
  }

  private handleMatchedCards(
    firstCard: MemoryCardType,
    secondCard: MemoryCardType
  ): void {
    const firstFoundElement = firstCard;
    const secondFoundElement = secondCard;
    this.firstSelectedCard = null;
    this.secondSelectedCard = null;
    setTimeout(() => {
      firstFoundElement.isFound = true;
      secondFoundElement.isFound = true;
      super.incrementCorrectAnswers();
    }, AppConfig.MATCHED_CARDS_DISPLAY_TIME);
  }

  private handleMismatchedCards(
    card: MemoryCardType,
    setMemoryCards: React.Dispatch<React.SetStateAction<MemoryCardType[]>>
  ): void {
    this.secondSelectedCard = card;
    super.incrementIncorrectAnswers();

    this.CardClickTimerId = setTimeout(() => {
      this.clearAndHideSelected();
      setMemoryCards(() => [...this.memoryCards]);
    }, AppConfig.MISMATCHED_CARDS_DISPLAY_TIME);
  }

  private shuffleAndDuplicate(imageIds: string[], imagesAmount: number) {
    const shuffled = shuffle(imageIds);
    const trimmed = shuffled.slice(0, imagesAmount);
    this.memoryImages = trimmed;
    const duplicated = [...trimmed, ...trimmed];

    const shuffledAndDuplicated = shuffle(duplicated);

    this.memoryCards = shuffledAndDuplicated.map((item) => ({
      imageId: item,
      id: uuidv4(),
      isVisible: false,
      isFound: false,
    }));
  }

  private checkIfUserWon = (): boolean => {
    const allCardAreFound = this.memoryCards.every((card) => card.isVisible);

    if (!allCardAreFound) {
      return false;
    }

    super.endGame(true);
    return true;
  };
}
