import { useContext, useState } from 'react';
import { useApi } from './useApi';
import { prepareApiParamsObj } from 'utils';
import {
  AddPromoReq,
  BasePaginationRes,
  SuperAdminPromoRes,
} from 'cogamika-back/types';
import { BasePaginationContext } from 'context';
import { useAppDispatch } from './redux';
import { successNotification } from 'slices';

interface UsePromoCodes {
  getPromoCodes: () => Promise<void>;
  addPromoCodes: (data: AddPromoReq) => Promise<void>;
  removePromoCodes: (id: string) => Promise<void>;
  promoCodesData: SuperAdminPromoRes[];
}

export const usePromoCodes = (): UsePromoCodes => {
  const { setPagesPossibility, pagination } = useContext(BasePaginationContext);
  const [promoCodesData, setPromoCodesData] = useState<SuperAdminPromoRes[]>(
    []
  );
  const { get, post, remove } = useApi();
  const dispatch = useAppDispatch();

  const getPromoCodes = async () => {
    const result = await get<BasePaginationRes<SuperAdminPromoRes>>(
      'promo/list',
      prepareApiParamsObj({ ...pagination })
    );

    if (result) {
      const { items, hasNextPage, hasPreviousPage } = result;
      setPromoCodesData(items);
      setPagesPossibility({
        hasNextPage,
        hasPreviousPage,
      });
    }
  };

  const addPromoCodes = async (data: AddPromoReq) => {
    const result = await post<SuperAdminPromoRes, AddPromoReq>('promo', data);

    if (result) {
      dispatch(successNotification('notifications.successfullyAddedCode'));
      getPromoCodes();
    }
  };

  const removePromoCodes = async (id: string) => {
    const result = await remove<SuperAdminPromoRes>(`promo/${id}`);

    if (result) {
      dispatch(successNotification('notifications.successfullyDeletedCode'));
      getPromoCodes();
    }
  };

  return {
    promoCodesData,
    getPromoCodes,
    addPromoCodes,
    removePromoCodes,
  };
};
