export type UserPayReq = {
  promoCode?: string;
};

export enum SubscribePlans {
  Month = 'MONTH',
  HalfYear = 'HALF_YEAR',
  Year = 'YEAR',
}

export type SystemPaymentPackageData = {
  price: number;
  period: number;
  itemInfoAmount?: number;
};

export type SystemPaymentPackagesData = {
  [key in SubscribePlans]: SystemPaymentPackageData;
};
