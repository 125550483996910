import { createSlice } from '@reduxjs/toolkit';

import { PaymentState } from 'types';

const initialState: PaymentState = {
  chosenSubscription: null,
};

export const paymentSlice = createSlice({
  name: 'payment',
  initialState,
  reducers: {
    setChosenSubscription: (state, action) => {
      state.chosenSubscription = action.payload;
    },
  },
});

export const { setChosenSubscription } = paymentSlice.actions;
