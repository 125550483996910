import React, { useState } from 'react';

import {
  DetailsGameType,
  GameFormSteps,
  GamesFormSettings,
  PublishedGameType,
} from 'types';
import { GameFormContext } from 'context';

import {
  GameForm,
  DetailsForm,
  PublishGame,
  GameNameForm,
  InstructionForm,
  PublishInfo,
  DescriptionForm,
  SelectLanguage,
} from 'components/forms';

import { StyledContainer } from './styles';

interface Props {}

export const NewGameForm: React.FC<Props> = () => {
  const [step, setStep] = useState<GameFormSteps>(GameFormSteps.SelectLanguage);
  const [gameSettings, setGameSettings] = useState<GamesFormSettings>();
  const [detailsForm, setDetailsForm] = useState<DetailsGameType>();

  const renderForm = (step: GameFormSteps) => {
    switch (step) {
      case GameFormSteps.SelectLanguage:
        return <SelectLanguage />;
      case GameFormSteps.GameName:
        return <GameNameForm />;
      case GameFormSteps.Details:
        return <DetailsForm />;
      case GameFormSteps.Description:
        return <DescriptionForm />;
      case GameFormSteps.Instruction:
        return <InstructionForm />;
      case GameFormSteps.GameForm:
        return <GameForm gameType={detailsForm?.gameName?.gameType} />;
      case GameFormSteps.PublishGame:
        return <PublishGame />;
      case GameFormSteps.PublishedInfo:
        return <PublishInfo type={PublishedGameType.Ready} />;
      case GameFormSteps.PublishedDraft:
        return <PublishInfo type={PublishedGameType.Draft} />;
      default:
        return null;
    }
  };

  return (
    <GameFormContext.Provider
      value={{
        gameSettings,
        setGameSettings,
        detailsForm,
        setDetailsForm,
        step,
        setStep,
      }}
    >
      <StyledContainer>{renderForm(step)}</StyledContainer>
    </GameFormContext.Provider>
  );
};
