import { LevelSettings } from 'cogamika-back/types';

export const getUpdatedLevels = (
  newLevelSettings: LevelSettings<any>,
  levelsSettings: LevelSettings<any>[] | undefined,
  level: number
) => {
  if (levelsSettings && levelsSettings.length > 0) {
    const foundIndex = levelsSettings.findIndex((item) => item.level === level);

    if (foundIndex === -1) {
      return [...levelsSettings, newLevelSettings];
    } else {
      return [
        ...levelsSettings.filter((item) => item.level !== level),
        newLevelSettings,
      ];
    }
  } else {
    return [newLevelSettings];
  }
};
