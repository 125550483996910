import { useContext, useState } from 'react';
import { useApi } from './useApi';
import { prepareApiParamsObj } from 'utils';
import {
  BasePaginationRes,
  StatusRes,
  SubdomainReq,
  SubdomainRes,
} from 'cogamika-back/types';
import { BasePaginationContext } from 'context';
import { useAppDispatch } from './redux';
import { successNotification } from 'slices';

interface UseSubdomain {
  getSubdomain: () => Promise<void>;
  addSubdomain: (data: SubdomainReq) => Promise<void>;
  editSubdomain: (data: SubdomainReq, id: string) => Promise<void>;
  removeSubdomain: (id: string) => Promise<void>;
  subdomainData: SubdomainRes[];
}

export const useSubdomain = (): UseSubdomain => {
  const { setPagesPossibility, pagination } = useContext(BasePaginationContext);
  const [subdomainData, setSubdomainData] = useState<SubdomainRes[]>([]);
  const { get, post, remove, patch } = useApi();
  const dispatch = useAppDispatch();

  const getSubdomain = async () => {
    const result = await get<BasePaginationRes<SubdomainRes>>(
      'subdomain/paginated',
      prepareApiParamsObj({ ...pagination })
    );

    if (result) {
      const { items, hasNextPage, hasPreviousPage } = result;
      setSubdomainData(items);
      setPagesPossibility({
        hasNextPage,
        hasPreviousPage,
      });
    }
  };

  const addSubdomain = async (data: SubdomainReq) => {
    const result = await post<SubdomainRes, SubdomainReq>('subdomain', data);

    if (result) {
      dispatch(successNotification('notifications.successfullyAddedSubdomain'));
      getSubdomain();
    }
  };

  const editSubdomain = async (data: SubdomainReq, id: string) => {
    const result = await patch<SubdomainRes, SubdomainReq>(
      `subdomain/${id}`,
      data
    );

    if (result) {
      dispatch(
        successNotification('notifications.successfullyEditedSubdomain')
      );
      getSubdomain();
    }
  };

  const removeSubdomain = async (id: string) => {
    const result = await remove<StatusRes>(`subdomain/${id}`);

    if (result) {
      dispatch(
        successNotification('notifications.successfullyDeletedSubdomain')
      );
      getSubdomain();
    }
  };

  return {
    subdomainData,
    getSubdomain,
    addSubdomain,
    editSubdomain,
    removeSubdomain,
  };
};
