import { useState } from 'react';
import { Language } from 'cogamika-back/types';

type UseCurrency = [
  number | undefined,
  (price: number, lang?: Language) => void
];

type NBPCurrencyObj = {
  effectiveDate: string; // (2023-09-04)
  mid: number; // Currency Rate
  no: string; // Table number
};

type NBPResponse = {
  code: string; //ISO_4217 Code
  currency: string; //Name
  rates: NBPCurrencyObj[]; //Currency Object
  table: string; // A | B | C
};

export const useCurrency = (): UseCurrency => {
  const [counted, setCounted] = useState<number>();

  const countPrice = (price: number, rates: number) => {
    const countedPrice =
      Math.round((price / rates + Number.EPSILON) * 100) / 100;
    setCounted(countedPrice);
  };

  const getCurrencyCode = (lang: Language) => {
    switch (lang) {
      case Language.pl:
        return 'PLN';
      case Language.en:
        return 'EUR';
      case Language.sv:
        return 'SEK';
    }
  };

  const countCurrency = async (price: number, lang: Language = Language.pl) => {
    if (lang === Language.pl) return;
    const result = await fetch(
      `https://api.nbp.pl/api/exchangerates/rates/A/${getCurrencyCode(
        lang
      )}/today/?format=json`
    );

    if (result.status === 200) {
      const data: NBPResponse = await result.json();
      const rate = data.rates[0].mid;
      countPrice(price, rate);
    } else {
      setCounted(undefined);
    }
  };

  return [counted, countCurrency];
};
