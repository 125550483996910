import React from 'react';

import { useAppSelector } from 'hooks';
import { Notification } from 'components/notification';

import { StyledNotificationContainer } from './styles';

export const NotificationContainer: React.FC = () => {
  const { notifications } = useAppSelector((state) => state.notifications);

  return (
    <StyledNotificationContainer>
      {notifications.map((notification) => (
        <Notification key={notification.id} notification={notification} />
      ))}
    </StyledNotificationContainer>
  );
};
