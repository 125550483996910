import styled from 'styled-components';

export const Wrapper = styled.div(
  ({ theme: { spaces, colors, radius } }) => `
  width: 100%;
  display: flex;
  align-items: center; 
  justify-content: space-between;
  padding: ${spaces.md} ${spaces.lg};
  background-color: ${colors.lightGray};
  border-radius: ${radius.md};
`
);
