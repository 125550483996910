import React from 'react';

import { ChronologyGameSettings, MovieGameFormType } from 'types';
import {
  LevelSettings,
  MosaicGameLevelSettingsReq,
  SurveyGameLevelSettings,
} from 'cogamika-back/types';
import { Button, Flex, GridContainer, Heading } from 'components/common';

import { Level } from './Level';
import { ButtonContainer, StyledLevels } from './styles';

interface Props {
  selectLevel: (level: number) => void;
  levelsSettings:
    | LevelSettings<
        | SurveyGameLevelSettings
        | ChronologyGameSettings
        | MovieGameFormType
        | MosaicGameLevelSettingsReq
      >[]
    | undefined;
  goBack: () => void;
  submit: () => void;
}

export const Levels: React.FC<Props> = ({
  goBack,
  submit,
  selectLevel,
  levelsSettings,
}) => {
  const checkIfLevelIsCompleted = (level: number) =>
    levelsSettings?.some((item) => item.level === level);

  return (
    <StyledLevels>
      <Flex columnDirection gap="3xl">
        <Heading
          text="views.newGame.levelsHeading"
          size="xl"
          as="h1"
          weight="semibold"
        />
      </Flex>
      <GridContainer size={[2]} gap="lg">
        {Array.from({ length: 10 }).map((_, index) => (
          <Level
            key={index}
            level={index + 1}
            selectLevel={selectLevel}
            checkIfLevelIsCompleted={checkIfLevelIsCompleted}
          />
        ))}
      </GridContainer>
      <ButtonContainer>
        <Button
          text="button.back"
          variant="primary"
          type="button"
          uppercase
          onClick={goBack}
        />
        <Button
          text="button.next"
          variant="secondary"
          uppercase
          onClick={submit}
        />
      </ButtonContainer>
    </StyledLevels>
  );
};
