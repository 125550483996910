import { useForm } from 'react-hook-form';
import { FC, useEffect, useState } from 'react';
import {
  AccordionWrapper,
  Button,
  FaqQuestion,
  FaqTopic,
  Flex,
  FlexAlignCenter,
  Heading,
} from 'components/common';
import { useFaq } from 'hooks';
import { FaqAddEditTopicSchema } from 'types';
import { addEditTopicSchema } from 'config';
import { yupResolver } from '@hookform/resolvers/yup';
import { Form, TextInput } from 'components/forms';
import { BoxContainer } from './styles';
import { Language } from 'cogamika-back/types';
import { FaqChangeLangSelect } from '../FaqChangeLangSelect';
import { getSelectOptionsFromEnum } from 'utils';

export const FaqSetup: FC = () => {
  const [lang, setLang] = useState<Language>(Language.en);
  const { faqData, getFaqData, addEditFaqTopic } = useFaq();

  const addTopicMethods = useForm<FaqAddEditTopicSchema>({
    mode: 'onSubmit',
    resolver: yupResolver(addEditTopicSchema),
  });

  useEffect(() => {
    getFaqData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const renderFaq = faqData
    .filter(({ lang: topicLang }) => topicLang === lang)
    .map(({ topic, id: topicId, questions, lang }) => ({
      header: topic,
      noTranslate: true,
      content: (
        <FaqTopic topic={topic} id={topicId} key={topicId} lang={lang}>
          {questions.map(({ id: questionId, question, answer }) => (
            <FaqQuestion
              topicId={topicId}
              id={questionId}
              question={question}
              key={questionId}
              answer={answer}
            />
          ))}
        </FaqTopic>
      ),
    }));

  return (
    <Flex gap="xl" columnDirection fullWidth>
      <FlexAlignCenter justifyContent="space-between" gap="lg" fullWidth>
        <Heading size="md" text="views.settings.faqSetup.heading" />
        <FaqChangeLangSelect
          lang={lang}
          onChange={setLang}
          options={getSelectOptionsFromEnum(Language, true, 'Shortcut')}
        />
      </FlexAlignCenter>
      <BoxContainer>
        <Flex columnDirection fullWidth gap="md">
          <AccordionWrapper items={renderFaq} />
          <Form
            submitHandler={(data) => addEditFaqTopic({ ...data, lang })}
            methods={addTopicMethods}
            fullWidth
          >
            <FlexAlignCenter gap="md" columnDirection>
              <TextInput name="topic" placeholder="topic" fullWidth required />
              <Button
                variant="secondary"
                text="button.addTopic"
                type="submit"
              />
            </FlexAlignCenter>
          </Form>
        </Flex>
      </BoxContainer>
    </Flex>
  );
};
