import React, { useState } from 'react';
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';

import { filesSchema } from 'config';
import { UploadFilesForm } from 'types';
import { clearErrorNotification } from 'slices';
import { useApi, useAppDispatch, useAppSelector } from 'hooks';
import { Form, FileInput } from 'components/forms';
import { Button, Flex, Spinner, Text, Warning } from 'components/common';

import { StyledContainer } from './styles';

interface Props {
  setIsUploadSuccessful: React.Dispatch<React.SetStateAction<boolean>>;
}

export const UploadFiles: React.FC<Props> = ({ setIsUploadSuccessful }) => {
  const { post, inProgress } = useApi();
  const dispatch = useAppDispatch();
  const fileList = [] as unknown as FileList;
  const [uploadedFilesCounter, setUploadedFilesCounter] = useState(0);
  const { errorNotification, formValidationErrors } = useAppSelector(
    (state) => state.notifications
  );
  const { isOpen } = useAppSelector((state) => state.modal);
  const methods = useForm<UploadFilesForm>({
    mode: 'onSubmit',
    resolver: yupResolver(filesSchema),
    defaultValues: {
      files: fileList,
    },
  });

  const isSubmitBtnDisabled =
    methods.formState.isSubmitting ||
    !methods.formState.isValid ||
    !methods.watch('files');

  const submit = async (data: UploadFilesForm) => {
    setIsUploadSuccessful(false);
    setUploadedFilesCounter(0);

    let successfullyUploadedFiles = 0;
    dispatch(clearErrorNotification());
    const unuploadedFiles = await Promise.all(
      Array.from(data.files).map(async (file: File) => {
        const formData = new FormData();
        formData.append('file', file);
        formData.append('isInstruction', 'false');
        formData.append('isBadge', 'false');
        const res = await post('/file/upload', formData, undefined, true);

        if (!res) {
          return file;
        } else {
          setIsUploadSuccessful(true);
          successfullyUploadedFiles += 1;
          return null;
        }
      })
    );

    const fileList = new DataTransfer();
    unuploadedFiles.forEach((file) => {
      if (file) {
        fileList.items.add(file);
      }
    });

    methods.setValue('files', fileList.files);
    methods.trigger();
    setUploadedFilesCounter(successfullyUploadedFiles);
  };

  return (
    <StyledContainer>
      <Form methods={methods} submitHandler={submit}>
        {inProgress && <Spinner />}
        <Flex columnDirection gap="base" fullWidth>
          <FileInput name="files" />
          {errorNotification && !isOpen && (
            <Warning
              text={errorNotification?.text}
              isError={!!errorNotification || formValidationErrors.length > 0}
              formValidationErrors={formValidationErrors}
              noTranslate
            />
          )}
          {uploadedFilesCounter > 0 && (
            <Text
              text="views.files.numberOfUploadedFiles"
              translationArgs={{ counter: uploadedFilesCounter }}
            />
          )}
          <Button
            variant="primary"
            text="button.send"
            type="submit"
            uppercase
            disabled={isSubmitBtnDisabled}
          />
        </Flex>
      </Form>
    </StyledContainer>
  );
};
