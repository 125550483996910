import { MovieSettingsRes } from 'cogamika-back/types';
import { GameResult, GameStage, MoviePressedResult, TimeRange } from 'types';
import { Game } from 'games';
import { isCurrentTimeInRange } from 'utils';

interface PressButtonReturn {
  result: MoviePressedResult;
  duration?: number;
}

export class Movie extends Game {
  public settings: MovieSettingsRes;
  public stage: GameStage;
  public movieId: string;
  public timeRanges: TimeRange[];

  constructor(
    level: number,
    gameId: string,
    domainId: string,
    settings: MovieSettingsRes
  ) {
    super(level, gameId, domainId);
    this.settings = settings;
    this.stage = GameStage.Instruction;
    this.movieId = settings.movieId;
    this.timeRanges = [];

    this.initGame();
  }

  public startMovieGame() {
    super.startGame();
  }

  private initGame() {
    this.timeRanges = this.settings.presses.map((press) => ({
      ...press,
      isClicked: false,
    }));
  }

  public pressButton(currentTime: number): PressButtonReturn {
    const foundElement = this.timeRanges.find((timeRange) =>
      isCurrentTimeInRange(currentTime, timeRange.startTime, timeRange.endTime)
    );

    super.incrementIncorrectAnswers();
    if (!foundElement) {
      return { result: MoviePressedResult.Failure };
    }

    if (foundElement && !foundElement.isClicked) {
      const duration = foundElement.endTime - currentTime;
      foundElement.isClicked = true;

      super.incrementCorrectAnswers();
      return { result: MoviePressedResult.Sucess, duration };
    }

    return { result: MoviePressedResult.InProgress };
  }

  public endMovieGame(resultGame?: GameResult): void {
    if (resultGame === GameResult.Lose) {
      super.endGame(false);
      return;
    }

    const isWin = this.result.incorrectAnswers === 0;

    super.endGame(isWin);
  }
}
