import React from 'react';

import { SurveyQuestionResult } from 'types';

import { Failure } from './Failure';
import { Success } from './Success';
import { ResultContainer } from './styles';

interface Props {
  result: SurveyQuestionResult;
  correctAnswerIndex: number;
  handleNext: () => void;
}

export const Result: React.FC<Props> = ({
  result,
  correctAnswerIndex,
  handleNext,
}) => {
  const getResult = (result: SurveyQuestionResult) => {
    switch (result) {
      case SurveyQuestionResult.Success:
        return <Success handleNext={handleNext} />;
      case SurveyQuestionResult.Failure:
        return (
          <Failure
            correctAnswerIndex={correctAnswerIndex}
            handleNext={handleNext}
          />
        );
      default:
        return <ResultContainer></ResultContainer>;
    }
  };

  return getResult(result);
};
