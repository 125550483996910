import React, { useEffect, useState } from 'react';

import { AppConfig } from 'config';
import { Mosaic } from 'games';
import { GameMode, GameResult, GameStage, MosaicCardType } from 'types';
import { useGameController } from 'hooks';
import { Instruction, Result } from 'components/games';

import { MosaicContainer } from './styles';
import { MosaicCards } from './MosaicCards';

interface Props {
  mosaic: Mosaic;
  instruction: string;
  instructionFileId: string | null;
  gameMode: GameMode;
}

export const MosaicGame: React.FC<Props> = ({
  gameMode,
  mosaic,
  instruction,
  instructionFileId,
}) => {
  const [isUserWon, setIsUserWon] = useState(false);
  const [intervalId, setIntervalId] = useState<NodeJS.Timer>();
  const [stage, setStage] = useState<GameStage>(GameStage.Instruction);
  const [mosaicCards, setMosaicCards] = useState<
    Map<string, MosaicCardType> | undefined
  >();

  const {
    nextGame,
    saveGameResult,
    runDomainTimer,
    domainGameTimer,
    stopDomainAfterGameTimeout: domainGameTimeout,
    saveSession,
    isSessionEnd,
  } = useGameController();

  const handleOnNextGame = () => {
    if (nextGame()) {
      setStage(GameStage.Instruction);
    } else {
      saveSession(gameMode);
      clearInterval(intervalId);
    }
  };

  const startGame = () => {
    setIsUserWon(false);
    setStage(GameStage.Game);
    mosaic.startMosaicGame(setMosaicCards);
  };

  const endGame = (result: GameResult) => {
    mosaic.endGame(result !== GameResult.Lose);
    saveGameResult(mosaic.gameData, gameMode);
    clearInterval(intervalId);
    setStage(GameStage.Result);
  };

  const handleClick = (cardId: string) => {
    mosaic.cardClick(cardId, endGame, setMosaicCards, setIsUserWon);
  };

  useEffect(() => {
    if (stage === GameStage.Game) {
      setIntervalId(runDomainTimer());
    }
    return () => {
      clearInterval(intervalId);
    };

    // eslint-disable-next-line
  }, [stage]);

  useEffect(() => {
    if (domainGameTimer > AppConfig.MAX_GAME_TIME) {
      endGame(GameResult.Lose);
      domainGameTimeout();
    }

    // eslint-disable-next-line
  }, [domainGameTimer]);

  const getStage = (stage: GameStage) => {
    switch (stage) {
      case GameStage.Instruction:
        return (
          <Instruction
            instruction={instruction}
            instructionFileId={instructionFileId}
            startGame={startGame}
          />
        );
      case GameStage.Game:
        return (
          <MosaicCards
            level={mosaic.gameData.level}
            mosaicCards={mosaicCards}
            handleClick={handleClick}
            isUserWon={isUserWon}
          />
        );
      case GameStage.Result:
        return (
          <Result
            action={handleOnNextGame}
            gameMode={gameMode}
            isSessionEnd={isSessionEnd}
          />
        );

      default:
        return null;
    }
  };

  return <MosaicContainer>{getStage(stage)}</MosaicContainer>;
};
