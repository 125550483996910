import React from 'react';
import { useDrop } from 'react-dnd';

import {
  CollectionAssignmentType,
  CollectionItemType,
  Collection as CollectionType,
} from 'cogamika-back/types';
import { apiUrl } from 'config';
import { CollectionBucketStatus } from 'types';

import { BucketContainer, ImageContainer, StyledImage } from './styles';

interface Props {
  bucketItem: CollectionType;
  justify: 'start' | 'end';
  status: CollectionBucketStatus;
  dropImage: (
    isSuccess: boolean,
    collectionType: CollectionAssignmentType
  ) => void;
}

export const Bucket: React.FC<Props> = ({
  bucketItem,
  dropImage,
  justify,
  status,
}) => {
  const onDrop = ({
    collectionType,
  }: {
    collectionType: CollectionAssignmentType;
  }) => {
    if (collectionType === bucketItem.type) {
      dropImage(true, bucketItem.type);
    } else {
      dropImage(false, bucketItem.type);
    }
  };

  const [{ isOver, canDrop }, drop] = useDrop({
    accept: CollectionItemType.Image,
    drop: onDrop,
    collect: (monitor) => ({
      isOver: monitor.isOver(),
      canDrop: monitor.canDrop(),
    }),
  });

  const isActive = isOver && canDrop;

  return (
    <BucketContainer justify={justify}>
      <ImageContainer ref={drop} status={status}>
        <StyledImage
          canDrop={isActive}
          src={`${apiUrl}file/${bucketItem.imageId}`}
          effect="opacity"
          data-testid="dustbin"
        />
      </ImageContainer>
    </BucketContainer>
  );
};
