import styled from 'styled-components';

interface Props {
  level: number;
  isSelected: boolean;
  isCorrect: boolean;
  isWon: boolean;
}

const getVariant = (level: number) => {
  switch (level) {
    case 1:
    case 2:
    case 3:
      return `
        width: 140px;
        height: 140px;
      `;
    case 4:
    case 5:
      return `
        width: 110px;
        height: 110px;
  `;
    case 6:
    case 7:
    case 8:
    case 9:
    case 10:
      return `
      width: 90px;
      height: 90px;
`;
    default:
      return '';
  }
};

export const StyledImage = styled.img<Props>(
  ({ theme: { radius, colors }, level, isSelected, isWon, isCorrect }) => `
  border-radius: ${radius.md};
  border: 4px solid transparent;
  cursor: pointer;
  ${isSelected && isCorrect && `border-color: ${colors.success}`};
  ${isSelected && !isCorrect && `border-color: ${colors.error}`};
  ${isWon && `border-color: ${colors.success}`};
  ${getVariant(level)}; 
  
`
);
