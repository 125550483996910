import styled from 'styled-components';

import { gtMedia } from 'config';
import { ColorType } from 'types';

interface Props {
  bgColor: ColorType;
}

export const StyledGameLayout = styled.div<Props>(
  ({ theme: { colors }, bgColor }) => `
  width: 100%;
  background-color: ${colors[bgColor]};
  min-height: 100vh;
  display: flex;
  flex-direction: column;
`
);

export const StyledMainContainer = styled.div(
  ({ theme }) => `
  flex: 1;
  display: flex;
  margin: 0 auto;  
  width:100%;

  ${gtMedia('xl')} {
    max-width: 1820px;
  }
`
);

export const StyledLevelContainer = styled.div(
  ({ theme }) => `
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  gap: ${theme.spaces.xs};
  padding: ${theme.spaces.md} ${theme.spaces['7xl']};
`
);
