import { Dispatch, FC, SetStateAction } from 'react';
import {
  Flex,
  Text,
  FlexJustifyCenter,
  FlexCenter,
  IconButton,
} from 'components/common';
import { useFaq } from 'hooks';

interface FaqQuestionContentProps {
  setQuestionEditing: Dispatch<SetStateAction<boolean>>;
  questionId: string;
  question: string;
  answer: string;
}

export const FaqQuestionContent: FC<FaqQuestionContentProps> = ({
  setQuestionEditing,
  question,
  answer,
  questionId,
}) => {
  const { deleteQuestion } = useFaq();

  return (
    <Flex alignItems="center" justifyContent="space-between" gap="md" fullWidth>
      <FlexJustifyCenter gap="md" columnDirection>
        <Text text={question} noTranslate />
        <Text text={answer} noTranslate size="xs" weight="light" />
      </FlexJustifyCenter>
      <FlexCenter gap="sm">
        <IconButton
          name="delete"
          onClick={() => deleteQuestion(questionId)}
          type="button"
        />
        <IconButton
          name="edit"
          onClick={() => setQuestionEditing(true)}
          type="button"
        />
      </FlexCenter>
    </Flex>
  );
};
