import QueryString from 'qs';

export const filterEmptyParams = <T>(
  obj: T | unknown
): Record<string, string> => {
  let params = {};
  for (const [key, value] of Object.entries(obj as any)) {
    if (value === undefined) continue;
    if (value === null) continue;
    if (typeof value === 'string' && value.length === 0) continue;
    params = { ...params, [key as string]: value };
  }
  return params;
};

export const prepareApiParamsObj = <T>(obj: T): Record<string, string> => {
  const filteredParams = filterEmptyParams(obj);
  let params: Record<string, string> = {};
  for (const [key, value] of Object.entries(filteredParams)) {
    if (typeof value === 'string' && value.length) {
      params = { ...params, [key as string]: value };
      continue;
    }
  }
  return params;
};

// @prepareURLParamsString is use due to complicated query params object and problem with params types. For simple object use prepareApiParamsObj and axios params config

export const prepareURLParamsString = <T>(
  obj: T,
  options?: QueryString.IStringifyOptions
): string => {
  const filteredParams = filterEmptyParams(obj);
  return `?${QueryString.stringify(filteredParams, options)}`;
};

export const parseFromQueryParamsStringToObj = (
  params: string,
  options?: QueryString.IParseOptions
) => QueryString.parse(params, options);
