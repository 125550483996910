import React from 'react';

import { TranslateText } from 'components/common';
import {
  TextAlignType,
  ColorType,
  FontSizeType,
  MarginType,
  TextType,
  FontWeightType,
  WhiteSpaceType,
} from 'types';

import { StyledParagraphText, StyledSpanText } from './styles';

interface Props {
  text: string;
  size?: FontSizeType;
  margin?: MarginType;
  noTranslate?: boolean;
  color?: ColorType;
  textAlign?: TextAlignType;
  type?: TextType;
  weight?: FontWeightType;
  translationArgs?: Record<string, string | number>;
  className?: string;
  upperaces?: boolean;
  whiteSpace?: WhiteSpaceType;
}

const Text: React.FC<Props> = ({
  text,
  margin,
  noTranslate,
  textAlign,
  size = 'sm',
  color = 'primary',
  type = 'p',
  weight,
  translationArgs,
  className,
  upperaces,
  whiteSpace,
}) => {
  if (type === 'p') {
    return (
      <StyledParagraphText
        size={size}
        color={color}
        margin={margin}
        textAlign={textAlign}
        weight={weight}
        className={className}
        upperaces={upperaces}
        whiteSpace={whiteSpace}
      >
        <TranslateText
          text={text}
          noTranslate={noTranslate}
          translationArgs={translationArgs}
        />
      </StyledParagraphText>
    );
  }

  if (type === 'span') {
    return (
      <StyledSpanText
        size={size}
        color={color}
        margin={margin}
        textAlign={textAlign}
        weight={weight}
        className={className}
        upperaces={upperaces}
        whiteSpace={whiteSpace}
      >
        <TranslateText text={text} noTranslate={noTranslate} />
      </StyledSpanText>
    );
  }

  return null;
};

export default Text;
