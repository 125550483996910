import styled from 'styled-components';

export const InputContainer = styled.div`
  position: relative;
  width: 100%;
`;

export const ButtonContainer = styled.div`
  position: absolute;
  right: 20px;
  top: 15px;
`;

export const ImageInfoContainer = styled.div(
  ({ theme: { spaces, colors, radius } }) => `
  display: flex;
  align-items: center;
  gap: ${spaces.sm};
  padding: ${spaces.md} ${spaces.lg};
  background-color: ${colors.lightGray};
  border-radius: ${radius.md};
  position: relative;
  width: 100%;
`
);
