import { FC, ForwardedRef, forwardRef } from 'react';
import { Controller, useFormContext } from 'react-hook-form';
import DatePicker, { registerLocale } from 'react-datepicker';
import { TextInput } from 'components/forms';
import pl from 'date-fns/locale/pl';
import { DateWrapper } from './styles';

interface Props {
  name: string;
  placeholder: string;
  label?: string;
  minDate?: Date;
  fullWidth?: boolean;
  required?: boolean;
  disabled?: boolean;
}

type DateInputType = {
  onClick?: () => void;
};

export const DatePickerInput: FC<Props> = ({
  name,
  placeholder,
  minDate,
  label,
  fullWidth,
  required,
  disabled,
}) => {
  const { control } = useFormContext();
  registerLocale('pl', pl);

  const DateInput = forwardRef(
    ({ onClick }: DateInputType, ref: ForwardedRef<HTMLInputElement>) => {
      return (
        <TextInput
          name={name}
          placeholder={placeholder}
          onClick={onClick}
          required={required}
          disabled={disabled}
          label={label}
          fullWidth
        />
      );
    }
  );

  return (
    <Controller
      control={control}
      name={name}
      render={({ field: { onChange, value } }) => (
        <DateWrapper fullWidth={fullWidth}>
          <DatePicker
            selectsStart
            name={name}
            minDate={minDate || new Date()}
            selected={value || new Date()}
            onChange={onChange}
            startDate={value || new Date()}
            calendarStartDay={1}
            placeholderText={placeholder}
            locale="pl"
            dateFormat="yyyy-MM-dd"
            customInput={<DateInput />}
          />
        </DateWrapper>
      )}
    />
  );
};
