import { useFormContext } from 'react-hook-form';
import React, { useContext, useEffect, useState } from 'react';

import { AppConfig } from 'config';
import { FileType, MosaicImageType } from 'cogamika-back/types';
import { GameFormContext } from 'context';
import { getMosaicDefaultValues } from 'utils';
import { Image } from 'components/forms';
import { FileSelector, Flex, Text } from 'components/common';

import { FilesSelectorWrapper, SelectedFiles, Wrapper } from './styles';

interface Props {
  level: number;
  type: MosaicImageType;
}

export const Files: React.FC<Props> = ({ level, type }) => {
  const { setValue, trigger } = useFormContext();
  const { gameSettings } = useContext(GameFormContext);
  const [selectedImages, setSelectedImages] = useState(() => new Set<string>());

  const getFileAmount = (type: MosaicImageType) => {
    switch (type) {
      case MosaicImageType.Card:
        return (
          gameSettings?.mosaicLevelSettings?.levels.find(
            (item) => item.level === level
          )?.settings.distractors.length || 0
        );
      case MosaicImageType.Distractor:
        return (
          gameSettings?.mosaicLevelSettings?.levels.find(
            (item) => item.level === level
          )?.settings.images.length || 0
        );
      default:
        return 0;
    }
  };

  const filesNumber = selectedImages.size + getFileAmount(type);
  const maximumFilesNumber = AppConfig.mosaicCardsPerLevel[level - 1];

  const defaultValues = getMosaicDefaultValues(
    type,
    level,
    gameSettings?.mosaicLevelSettings
  );

  const deleteImage = (id: string) => {
    if (!selectedImages.has(id)) return;

    setSelectedImages((prev) => {
      const next = new Set(prev);
      next.delete(id);
      setValue('imagesIds', Array.from(next));
      return next;
    });
  };

  const handleFileSelect = (id: string) => {
    if (selectedImages.has(id)) {
      setSelectedImages((prev) => {
        const next = new Set(prev);
        next.delete(id);
        setValue('imagesIds', Array.from(next));
        return next;
      });
    } else {
      if (filesNumber === maximumFilesNumber) {
        return;
      }
      setSelectedImages((prev) => {
        setValue('imagesIds', [...Array.from(prev), id]);
        return new Set(prev).add(id);
      });
    }

    trigger();
  };

  useEffect(() => {
    if (defaultValues) {
      setSelectedImages(new Set(defaultValues));
    }

    // eslint-disable-next-line
  }, [gameSettings?.mosaicLevelSettings?.levels]);

  return (
    <Wrapper>
      <Flex columnDirection gap="sm">
        <Flex gap="sm">
          <Text
            size="base"
            text="views.newGame.mosaic.countDescription"
            translationArgs={{
              filesNumber: filesNumber,
              maximumFilesNumber: maximumFilesNumber,
            }}
          />
          <Text
            size="base"
            weight="semibold"
            text="views.newGame.mosaic.countFiles2"
            translationArgs={{
              filesNumber: filesNumber,
              maximumFilesNumber: maximumFilesNumber,
            }}
          />
        </Flex>
        <SelectedFiles className="files">
          {Array.from(selectedImages).map((id) => (
            <Image id={id} key={id} deleteImage={deleteImage} />
          ))}
        </SelectedFiles>
      </Flex>
      <FilesSelectorWrapper>
        <FileSelector
          refreshList={false}
          onSelectFile={handleFileSelect}
          types={[FileType.Image]}
          selectedIds={selectedImages}
        />
      </FilesSelectorWrapper>
    </Wrapper>
  );
};
