import styled from 'styled-components';

export const TrainingEndedContainer = styled.div(
  ({ theme: { spaces, colors } }) => `
  min-width: 550px;
  display: flex;
  flex-direction: column;
  gap: ${spaces['2xl']};
  padding: ${spaces['7xl']};
  background-color: ${colors.secondaryBackground};
`
);
