import { SurveyInitialData, SurveySettingsRes } from 'cogamika-back/types';
import { GameStage } from 'types';
import { Game } from 'games';
import { shuffle } from 'utils';

export class Survey extends Game {
  public settings: SurveySettingsRes;
  public initialData?: SurveyInitialData;
  public stage: GameStage;

  constructor(
    level: number,
    gameId: string,
    domainId: string,
    settings: SurveySettingsRes
  ) {
    super(level, gameId, domainId);
    this.settings = settings;
    this.initialData = settings.initialData;
    this.stage = GameStage.Instruction;
  }

  public get getStage() {
    return this.stage;
  }

  public getQuestion(index: number) {
    return this.settings.surveyQuestions[index];
  }

  public checkIsNextQuestion(index: number) {
    return this.settings.surveyQuestions.length - 1 >= index;
  }

  public initGame(
    setStage: React.Dispatch<React.SetStateAction<GameStage>>
  ): void {
    this.shuffleAnswers();
    this.stage = GameStage.Intro;
    if (this.initialData) {
      setStage(GameStage.Intro);

      setTimeout(() => {
        setStage(GameStage.Game);
        this.startSurveyGame();
      }, this.initialData.time * 1000);
    } else {
      setStage(GameStage.Game);
      this.startSurveyGame();
    }
  }

  public startSurveyGame(): void {
    super.startGame();
  }

  private shuffleAnswers(): void {
    const shuffledAnswers = this.settings.surveyQuestions.map((question) => {
      return {
        ...question,
        answers: shuffle(question.answers),
      };
    });

    this.settings = {
      ...this.settings,
      surveyQuestions: shuffledAnswers,
    };
  }
}
