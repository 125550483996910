import React from 'react';
import { useFieldArray, useFormContext } from 'react-hook-form';

import {
  QuestionAnswerType,
  SurveyGameLevelSettings,
} from 'cogamika-back/types';
import { Button, Flex } from 'components/common';

import { Question } from '../Question';
import { QuestionsContainer } from './styles';

interface Props {
  level: number;
}

export const Questions: React.FC<Props> = ({ level }) => {
  const { control, clearErrors } = useFormContext<SurveyGameLevelSettings>();

  const { fields, append, remove } = useFieldArray({
    control,
    name: 'surveyQuestions',
  });

  const addNewQuestion = (e: React.MouseEvent<HTMLButtonElement>) => {
    e.preventDefault();
    append({
      question: {
        description: '',
        type: QuestionAnswerType.Text,
      },
      answers: [
        {
          type: QuestionAnswerType.Text,
          description: '',
          isCorrect: true,
        },
        {
          type: QuestionAnswerType.Text,
          description: '',
          isCorrect: false,
        },
        {
          type: QuestionAnswerType.Text,
          description: '',
          isCorrect: false,
        },
        {
          type: QuestionAnswerType.Text,
          description: '',
          isCorrect: false,
        },
      ],
    });

    clearErrors();
  };

  return (
    <Flex columnDirection>
      <Button
        text="button.newQuestion"
        variant="gray"
        iconName="plus"
        fullWidth
        onClick={addNewQuestion}
        type="button"
        justifyContent="space-between"
      />
      <QuestionsContainer>
        {fields.map((field, index) => (
          <Question
            field={field}
            key={index}
            questionNumber={index + 1}
            questionIndex={index}
            remove={remove}
          />
        ))}
      </QuestionsContainer>
    </Flex>
  );
};
