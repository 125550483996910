import React, { useContext, useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';

import { apiUrl, instructionSchema } from 'config';
import { FileType } from 'cogamika-back/types';
import { GameFormSteps, InstructionFormType } from 'types';
import { GameFormContext } from 'context';
import { Form, TextArea } from 'components/forms';
import { Button, FileSelector, Flex, Heading } from 'components/common';

import {
  StyledInstructionForm,
  FormContainer,
  StyledVideo,
  ButtonContainer,
  FilesWrapper,
} from './styles';

export const InstructionForm: React.FC = () => {
  const { setDetailsForm, setStep, detailsForm } = useContext(GameFormContext);
  const [selectedFile, setSelectedFile] = useState(() => new Set<string>());

  const methods = useForm<InstructionFormType>({
    mode: 'onSubmit',
    resolver: yupResolver(instructionSchema),
  });
  const { isSubmitting, isValid } = methods.formState;
  const isButtonDisabled = isSubmitting || !isValid;

  const handleFileSelect = (id: string) => {
    if (selectedFile.has(id)) {
      setSelectedFile(new Set());
      methods.setValue('instructionId', '');
    } else {
      methods.setValue('instructionId', id);
      setSelectedFile(new Set([id]));
    }

    methods.trigger('instructionId');
  };

  const submit = (data: InstructionFormType) => {
    setDetailsForm((prev) => ({ ...prev, instruction: data }));
    setStep(GameFormSteps.GameForm);
  };

  const goBack = () => {
    setStep(GameFormSteps.Description);
  };

  useEffect(() => {
    methods.reset({
      instruction: detailsForm?.instruction?.instruction,
      instructionId: detailsForm?.instruction?.instructionId,
    });

    if (detailsForm?.instruction?.instructionId)
      setSelectedFile(new Set([detailsForm?.instruction?.instructionId]));
  }, [methods, detailsForm?.instruction]);

  return (
    <StyledInstructionForm>
      <Heading text="views.newGame.instruction" size="xl" />
      <FormContainer>
        <Form submitHandler={submit} methods={methods}>
          <Flex columnDirection gap="lg">
            <TextArea
              name="instruction"
              placeholder="empty"
              label="labels.instruction"
            />
            <FilesWrapper>
              <div>
                <FileSelector
                  types={[FileType.Video]}
                  refreshList={false}
                  onSelectFile={handleFileSelect}
                  selectedIds={selectedFile}
                />
              </div>
              <div>
                {selectedFile.size > 0 && (
                  <StyledVideo
                    key={Array.from(selectedFile)[0]}
                    controls
                    src={`${apiUrl}file/${Array.from(selectedFile)[0]}`}
                    width="280"
                    disablePictureInPicture
                  ></StyledVideo>
                )}
              </div>
            </FilesWrapper>
            <ButtonContainer>
              <Button
                text="button.back"
                variant="primary"
                type="button"
                uppercase
                onClick={goBack}
              />
              <Button
                text="button.next"
                variant="secondary"
                type="submit"
                disabled={isButtonDisabled}
                uppercase
              />
            </ButtonContainer>
          </Flex>
        </Form>
      </FormContainer>
    </StyledInstructionForm>
  );
};
