import styled from 'styled-components';

export const MainContainer = styled.div(
  ({ theme: { spaces, colors } }) => `
  display: flex;
  flex-direction: column;
  gap: ${spaces['3xl']};
  width: 700px;
  background-color: ${colors.light};
  padding: 54px 50px;
`
);
