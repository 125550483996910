import React from 'react';

import { ModalType } from 'types';
import {
  Info,
  Login,
  Register,
  Instruction,
  RegisterConfirmation,
  PaymentFinished,
  Confirmation,
  DeleteFile,
  BadgeInfo,
  SessionUnavailableError,
  SessionSaveError,
  SubscriptionExpiring,
} from 'components/modals';

interface Props {
  content: ModalType | null;
}

const ModalSelector: React.FC<Props> = ({ content }) => {
  switch (content) {
    case ModalType.Instruction:
      return <Instruction />;
    case ModalType.Info:
      return <Info />;
    case ModalType.Login:
      return <Login />;
    case ModalType.Register:
      return <Register />;
    case ModalType.RegisterConfirmation:
      return <RegisterConfirmation />;
    case ModalType.PaymentFinished:
      return <PaymentFinished />;
    case ModalType.Confirmation:
      return <Confirmation />;
    case ModalType.DeleteFile:
      return <DeleteFile />;
    case ModalType.BadgeInfo:
      return <BadgeInfo />;
    case ModalType.SessionUnavailableError:
      return <SessionUnavailableError />;
    case ModalType.SaveSessionError:
      return <SessionSaveError />;
    case ModalType.SubscriptionExpiring:
      return <SubscriptionExpiring />;
    default:
      return null;
  }
};

export default ModalSelector;
