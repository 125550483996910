import styled from 'styled-components';

export const StyledInput = styled.input(
  ({ theme: { colors } }) => `
  display: none;
`
);

export const StyledLabel = styled.label(
  ({ theme: { colors, fontSizes, radius } }) => `
  display: inline-flex;
  align-items: center;
  justify-content: center;
  -webkit-tap-highlight-color: transparent;
  cursor: pointer;
  user-select: none;
  appearance: none;
  font-weight: 600;
  font-size: ${fontSizes.md};
  padding: 16px 32px;
  border-radius: ${radius.md};
  color: ${colors.light};
  background-color: ${colors.secondary};
  min-width: max-content;
  text-transform: uppercase; 
  &:hover {
    background-color: ${colors.violet};
  }
`
);
