import {
  UserBaseDataWithoutCredentials,
  Profile,
  Language,
} from 'cogamika-back/types';

export interface UserState {
  user: UserBaseDataWithoutCredentials | null;
}

export enum CookieAcceptance {
  Accepted = 'accepted',
  Rejected = 'rejected',
}

export type RegisterIndividualUser = Profile;

export type ActivateUserFormType = {
  password: string;
  repeatPassword: string;
};

export type EditProfile = {
  nick: string;
  email: string;
  firstName: string;
  lastName: string;
  lang: Language;
};

export type EditPassword = {
  password: string;
  newPassword: string;
  repeatNewPassword: string;
};

export enum AccountSubPagesRoute {
  Help = 'help',
}

export enum AccountSubPages {
  Results,
  SubscriptionPlan,
  Help,
  EditProfile,
}

export enum SuperAdminSettingsPages {
  EditProfile,
  AddAdmin,
  FaqSetup,
  CsvFiles,
  PromoCodes,
  Users,
  Subdomain,
  Category,
}

export enum AdminSettingsPages {
  EditProfile,
  FaqSetup,
  CsvFiles,
}

export type AddAdmin = {
  email: string;
  firstName: string;
  lastName: string;
};
