import { Colors } from 'types';

export const colors: Colors = {
  primary: '#0F1618',
  secondary: '#7957FF',
  light: '#FFFFFF',
  gray: '#E7E8E8',
  red: 'red',
  green: '#56E097',
  orange: '#FF6900',
  error: '#FF000F',
  info: '#7957FF',
  success: '#18B070',
  boxShadow: '0 0 5 0 rgba(0, 0, 0, 0.25)',
  primaryBackground: '#EDEDED',
  secondaryBackground: '#FFFFFF',
  warningBackground: '#FEEFF2',
  lightGray: '#F5F5F5',
  primaryBorder: '#E7E8E8',
  secondaryBorder: '#CFD0D1',
  secondarySuccess: '#E6FBF0',
  darkGreen: '#06986C',
  lightGreen: '#99EDC1',
  lightPink: '#FFB1BB',
  tertiaryBorder: '#9FA2A3',
  disable: '#CFD0D1',
  darkGray: '#3F4546',
  violet: '#9479FF',
  secondaryGray: '#6F7374',
  teritaryGray: '#9FA2A3',
  primaryHover: '#6F7374',
  secondaryHover: '#473396',
};
