import React from 'react';

import { apiUrl } from 'config';
import { SurveyInitialData, SurveyInitialDataType } from 'cogamika-back/types';

import { Container, StyledAudio, StyledImage } from './styles';

interface Props {
  data: SurveyInitialData;
}

export const Intro: React.FC<Props> = ({ data }) => {
  const getIntro = (type: SurveyInitialDataType) => {
    switch (type) {
      case SurveyInitialDataType.Image:
        return (
          <StyledImage
            src={`${apiUrl}file/${data.description}`}
            alt={data.description}
            effect="opacity"
          />
        );
      case SurveyInitialDataType.Audio:
        return (
          <StyledAudio controls src={`${apiUrl}file/${data.description}`} />
        );
      default:
        return null;
    }
  };

  return <Container>{getIntro(data.type)}</Container>;
};
