import React from 'react';
import SVG from 'react-inlinesvg';

import { colors } from 'config/theme/basicStyleValues/colors';
import { ColorType, IconName } from 'types';

import { StyledIconContainer } from './styles';

interface Props {
  name: IconName;
  color?: ColorType;
}

export const Icon: React.FC<Props> = ({ name, color }) => {
  return (
    <StyledIconContainer>
      <SVG
        src={`/assets/images/icons/${name}.svg`}
        color={`${color && colors[color]}`}
      />
    </StyledIconContainer>
  );
};
