import { Language } from 'cogamika-back/types';
import { createContext, Dispatch, SetStateAction } from 'react';

type LangContextType = {
  isLanguageLoaded: boolean;
  lang: Language;
  setLang: Dispatch<SetStateAction<Language>>;
  setIsLanguageLoaded: Dispatch<SetStateAction<boolean>>;
};

export const LangContext = createContext<LangContextType>({
  isLanguageLoaded: false,
  lang: Language.en,
  setLang: () => {},
  setIsLanguageLoaded: () => {},
});
