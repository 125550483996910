import styled from 'styled-components';

interface ItemProps {
  noBackground?: boolean;
}

export const StyledItem = styled('li')<ItemProps>(
  ({ theme: { spaces, colors }, noBackground }) => `
  width: 100%;
  box-shadow: ${colors.boxShadow};
  list-style: none;
  display: flex;
  gap: ${spaces.md};
  align-items: center;
  padding: ${spaces.sm};
  justify-content: space-between;
  background-color: ${
    noBackground ? 'transparent' : colors.secondaryBackground
  };
`
);
