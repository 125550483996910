import styled, { keyframes } from 'styled-components';

const show = keyframes`
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
`;

export const Overlay = styled.div`
  position: fixed;
  inset: 0;
  overflow-y: auto;
  animation-name: '${show}';
  animation-duration: 150ms;
  background-color: rgba(15, 22, 24, 0.1);
  display: flex;
  align-items: center;
  justify-content: center;
`;

export const Content = styled.div`
  height: auto;
  width: 70%;
  margin: 0 auto;
`;

export const MainContainer = styled.div(
  ({ theme: { spaces, colors } }) => `
  display: flex;
  flex-direction: column;
  gap: ${spaces['3xl']};
  background-color: ${colors.light};
  padding: ${spaces['7xl']} ${spaces['6xl']};
`
);

export const StyledVideo = styled.video`
  margin: 0 auto;
  width: 100%;
  object-fit: scale-down;
`;
