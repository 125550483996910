import { FC, useState } from 'react';
import { FlexJustifyCenter } from 'components/common';
import { FaqQuestionWrapper } from './styles';
import { FaqQuestionEditForm } from './FaqQuestionEditForm';
import { FaqQuestionContent } from './FaqQuestionContent';

interface FaqQuestionProps {
  topicId: string;
  question: string;
  answer: string;
  id: string;
}

export const FaqQuestion: FC<FaqQuestionProps> = ({
  question,
  id,
  answer,
  topicId,
}) => {
  const [isQuestionEdit, setIsQuestionEdit] = useState<boolean>(false);

  return (
    <FaqQuestionWrapper>
      <FlexJustifyCenter gap="xs" columnDirection fullWidth>
        {isQuestionEdit ? (
          <FaqQuestionEditForm
            setQuestionEditing={setIsQuestionEdit}
            question={question}
            answer={answer}
            topicId={topicId}
            questionId={id}
          />
        ) : (
          <FaqQuestionContent
            setQuestionEditing={setIsQuestionEdit}
            question={question}
            answer={answer}
            questionId={id}
          />
        )}
      </FlexJustifyCenter>
    </FaqQuestionWrapper>
  );
};
