import styled from 'styled-components';

export const SpinnerContainer = styled.div`
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
`;

export const Overlay = styled.div`
  position: fixed;
  inset: 0;
  overflow-y: auto;
  background-color: rgba(15, 22, 24, 0.1);
  display: flex;
  align-items: center;
  justify-content: center;
`;
