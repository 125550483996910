import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import React, { useContext, useEffect, useState } from 'react';

import { detailsGame } from 'config/validationSchemas/detailsGame';
import { CategoryRes, DomainBaseRes, SubdomainRes } from 'cogamika-back/types';
import { DetailsFormType, GameFormSteps } from 'types';
import { GameFormContext } from 'context';
import { useApi } from 'hooks';
import { Form, Select } from 'components/forms';
import { Button, Flex, Heading } from 'components/common';

import { StyledDetailsForm, FormContainer } from './styles';

interface Props {
  disabled?: boolean;
}

export const DetailsForm: React.FC<Props> = ({ disabled }) => {
  const { setDetailsForm, setStep, detailsForm } = useContext(GameFormContext);
  const { get } = useApi();
  const [doamins, setDomains] = useState<DomainBaseRes[]>([]);
  const [subdomains, setSubdomains] = useState<SubdomainRes[]>([]);
  const [categories, setCategories] = useState<CategoryRes[]>([]);

  const methods = useForm<DetailsFormType>({
    mode: 'onSubmit',
    resolver: yupResolver(detailsGame),
  });

  const { isSubmitting, isValid } = methods.formState;
  const isButtonDisabled = isSubmitting || !isValid;

  const getDomains = async () => {
    const domainsRes = await get<DomainBaseRes[]>('/domain');
    if (domainsRes) {
      setDomains(domainsRes);
    }
  };

  const getSubdomains = async () => {
    const subdomainsRes = await get<SubdomainRes[]>('/subdomain');

    if (subdomainsRes) {
      setSubdomains(subdomainsRes);
    }
  };

  const getCategories = async () => {
    const categoriesRes = await get<CategoryRes[]>('/category');

    if (categoriesRes) {
      setCategories(categoriesRes);
    }
  };

  const getDetails = () => {
    getDomains();
    getSubdomains();
    getCategories();
  };

  const submit = (data: DetailsFormType) => {
    setDetailsForm((prev) => ({ ...prev, details: data }));
    setStep(GameFormSteps.Description);
  };

  const goBack = () => {
    setStep(GameFormSteps.GameName);
  };

  useEffect(() => {
    getDetails();

    // eslint-disable-next-line
  }, []);

  useEffect(() => {
    methods.reset({
      categoryId: detailsForm?.details?.categoryId,
      domainId: detailsForm?.details?.domainId,
      subdomainId: detailsForm?.details?.subdomainId,
    });
  }, [methods, detailsForm, categories, doamins, subdomains]);

  return (
    <StyledDetailsForm>
      <Heading text="views.newGame.completeData" size="xl" />
      <FormContainer>
        <Form submitHandler={submit} methods={methods}>
          <Flex columnDirection gap="lg">
            <Select
              label="labels.domain"
              name="domainId"
              options={doamins.map((option) => ({
                label: option.name,
                value: option.id,
              }))}
              noTranslate
              disabled={disabled}
            />
            <Select
              label="labels.subdomain"
              name="subdomainId"
              options={subdomains.map((option) => ({
                label: option.name,
                value: option.id,
              }))}
              noTranslate
              disabled={disabled}
            />
            <Select
              label="labels.category"
              name="categoryId"
              options={categories.map((option) => ({
                label: option.name,
                value: option.id,
              }))}
              noTranslate
              disabled={disabled}
            />
            <Button
              text="button.next"
              variant="secondary"
              fullWidth
              type="submit"
              disabled={isButtonDisabled}
              uppercase
            />
            <Button
              text="button.back"
              variant="primary"
              fullWidth
              type="button"
              uppercase
              onClick={goBack}
            />
          </Flex>
        </Form>
      </FormContainer>
    </StyledDetailsForm>
  );
};
