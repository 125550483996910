import React, { useContext, useEffect } from 'react';

import { CreateGameReq, StatusRes, UpdateGameReq } from 'cogamika-back/types';
import { GameFormSteps } from 'types';
import { GameFormContext } from 'context';
import { clearErrorNotification } from 'slices';
import { useApi, useAppDispatch, useAppSelector } from 'hooks';
import { prepareGameFormPayload } from 'utils';
import { Button, Spinner, Warning } from 'components/common';

import { PublishContainer } from './styles';

interface Props {
  gameId?: string;
}

export const PublishGame: React.FC<Props> = ({ gameId }) => {
  const { post, patch, inProgress } = useApi();
  const { setStep } = useContext(GameFormContext);
  const dispatch = useAppDispatch();
  const { detailsForm, gameSettings } = useContext(GameFormContext);
  const { errorNotification, formValidationErrors } = useAppSelector(
    (state) => state.notifications
  );

  const submit = async () => {
    dispatch(clearErrorNotification());
    if (
      !detailsForm ||
      !detailsForm.details ||
      !detailsForm.gameName ||
      !detailsForm.lang
    ) {
      return;
    }

    const formData = prepareGameFormPayload<CreateGameReq>({
      isNew: !gameId,
      details: detailsForm.details,
      gameName: detailsForm.gameName,
      instructionData: detailsForm?.instruction,
      gameSettings: gameSettings,
      description: detailsForm.description,
      lang: detailsForm.lang,
    });

    if (formData) {
      if (!gameId) {
        const res = await post<StatusRes, CreateGameReq>(
          '/game',
          formData,
          undefined
        );

        if (res) {
          setStep(GameFormSteps.PublishedInfo);
        }
      } else {
        const res = await patch<StatusRes, UpdateGameReq>(
          `/game/${gameId}`,
          formData,
          undefined
        );

        if (res) {
          setStep(GameFormSteps.PublishedInfo);
        }
      }
    }
  };

  const sendDraft = async () => {
    if (
      !detailsForm ||
      !detailsForm.details ||
      !detailsForm.gameName ||
      !detailsForm.lang
    ) {
      return;
    }

    const formData = prepareGameFormPayload<CreateGameReq>({
      isNew: !gameId,
      details: detailsForm.details,
      gameName: detailsForm.gameName,
      instructionData: detailsForm?.instruction,
      gameSettings: gameSettings,
      description: detailsForm.description,
      lang: detailsForm.lang,
    });

    if (formData) {
      if (!gameId) {
        const res = await post<StatusRes, CreateGameReq>(
          '/game/draft',
          formData,
          undefined
        );

        if (res) {
          setStep(GameFormSteps.PublishedDraft);
        }
      } else {
        const res = await patch<StatusRes, UpdateGameReq>(
          `/game/draft/${gameId}`,
          formData,
          undefined
        );

        if (res) {
          setStep(GameFormSteps.PublishedDraft);
        }
      }
    }
  };

  const goBack = () => {
    setStep(GameFormSteps.GameForm);
  };

  useEffect(() => {
    dispatch(clearErrorNotification());
    return () => {
      dispatch(clearErrorNotification());
    };

    // eslint-disable-next-line
  }, []);

  return (
    <PublishContainer>
      {inProgress && <Spinner />}
      <Button
        text="button.publishGame"
        variant="secondary"
        onClick={submit}
        fullWidth
        uppercase
      />
      {!detailsForm?.isApproved && (
        <Button
          text="button.saveDraft"
          variant="outlined"
          onClick={sendDraft}
          fullWidth
          uppercase
        />
      )}
      <Button
        text="button.back"
        variant="primary"
        onClick={goBack}
        fullWidth
        uppercase
      />
      <Warning
        text={errorNotification?.text}
        isError={!!errorNotification || formValidationErrors.length > 0}
        formValidationErrors={formValidationErrors}
        noTranslate
      />
    </PublishContainer>
  );
};
