import { Spaces } from 'types';

export const spaces: Spaces = {
  none: '0px',
  '2xs': '4px',
  xs: '8px',
  sm: '12px',
  base: '16px',
  md: '20px',
  lg: '24px',
  xl: '28px',
  '2xl': '32px',
  '3xl': '36px',
  '4xl': '40px',
  '5xl': '44px',
  '6xl': '48px',
  '7xl': '54px',
};
