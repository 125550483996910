import styled from 'styled-components';
import { LazyLoadImage } from 'react-lazy-load-image-component';
import 'react-lazy-load-image-component/src/effects/opacity.css';

export const StyledImage = styled(LazyLoadImage)`
  width: 100%;
  object-fit: contain;
  margin: 0 auto;
  max-height: 250px;
`;
