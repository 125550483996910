import React from 'react';

import { paymentRoutes } from 'config';

const PaymentSummary = React.lazy(() => import('views/Payment/Summary'));
const PaymentFinished = React.lazy(() => import('views/Payment/Finished'));

export const PaymentRoutes = [
  {
    path: paymentRoutes.root,
    element: <PaymentSummary />,
  },
  {
    path: paymentRoutes.finished,
    element: <PaymentFinished />,
  },
];
