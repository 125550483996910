import React, { useEffect } from 'react';
import { useFormContext } from 'react-hook-form';

import { QuestionAnswerType } from 'cogamika-back/types';
import { TextInput } from 'components/forms';
import { IconButton } from 'components/common';

import { ButtonContainer, InputContainer } from './styles';
import { useApi } from 'hooks';
import { SurveyGameFormType } from 'types';

interface Props {
  questionIndex: number;
  answerIndex: number;
  isQuestion: boolean;
  openModal: (isQuestion: boolean, answerIndex: number) => void;
  deleteInput: (isQuestion: boolean, answerIndex: number) => void;
}

export const Answer: React.FC<Props> = ({
  questionIndex,
  answerIndex,
  isQuestion,
  openModal,
  deleteInput,
}) => {
  const { get } = useApi();
  const { watch, getValues, setValue } = useFormContext<SurveyGameFormType>();

  const answerType = watch(
    `surveyQuestions.${questionIndex}.answers.${answerIndex}.type`
  );
  const answer = getValues(
    `surveyQuestions.${questionIndex}.answers.${answerIndex}`
  );

  const fethcImageName = async (id: string) => {
    return await get<string>(`/file/name/${id}`);
  };

  useEffect(() => {
    if (
      answer.type === QuestionAnswerType.Image &&
      answer?.imageId &&
      answer.description === answer.imageId
    ) {
      fethcImageName(answer.imageId).then((imageName) => {
        setValue(
          `surveyQuestions.${questionIndex}.answers.${answerIndex}.description`,
          imageName || ''
        );
      });
    }

    // eslint-disable-next-line
  }, []);

  return (
    <InputContainer>
      <TextInput
        placeholder="surveyAnswer"
        name={`surveyQuestions.${questionIndex}.answers.${answerIndex}.description`}
        disabled={answerType === QuestionAnswerType.Image}
      />
      <ButtonContainer>
        {answerType === QuestionAnswerType.Text && (
          <IconButton
            type="button"
            onClick={() => openModal(isQuestion, answerIndex)}
            name="plus"
          />
        )}
        {answerType === QuestionAnswerType.Image && (
          <IconButton
            type="button"
            onClick={() => deleteInput(false, answerIndex)}
            name="delete"
          />
        )}
      </ButtonContainer>
    </InputContainer>
  );
};
