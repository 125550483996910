import styled from 'styled-components';

export const LevelContainer = styled.button(
  ({ theme: { colors, radius, spaces } }) => `
  display: flex;
  padding: ${spaces.md} ${spaces.lg};
  justify-content: space-between;
  border: 2px solid ${colors.gray};
  border-radius: ${radius.md};
  background-color: ${colors.light};
  cursor: pointer;
`
);
