import React, { useContext } from 'react';
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';

import { MovieGameSchema } from 'config';
import { LevelSettings } from 'cogamika-back/types';
import { MovieGameFormSteps, MovieGameFormType } from 'types';
import { Form, LevelHeader } from 'components/forms';
import { GameFormContext } from 'context';
import { getUpdatedLevels } from 'utils';

import { SelectMovie } from '../SelectMovie';
import { TimeRanges } from '../TimeRanges';
import { FormWrapper, MovieFormContainer } from './styles';

interface Props {
  level: number;
  setStage: React.Dispatch<React.SetStateAction<MovieGameFormSteps>>;
}

export const MovieForm: React.FC<Props> = ({ setStage, level }) => {
  const { setGameSettings, gameSettings } = useContext(GameFormContext);
  const defaultSettings = gameSettings?.movieLevelSettings?.levels.find(
    (item) => item.level === level
  );
  const methods = useForm<MovieGameFormType>({
    mode: 'onSubmit',
    defaultValues: defaultSettings?.settings,
    resolver: yupResolver(MovieGameSchema),
  });

  const submit = (data: MovieGameFormType) => {
    const newLevelSettings: LevelSettings<MovieGameFormType> = {
      level,
      settings: {
        ...data,
      },
    };

    const levelsSettings = gameSettings?.movieLevelSettings?.levels;
    const updatedLevelSettings = getUpdatedLevels(
      newLevelSettings,
      levelsSettings,
      level
    );

    setGameSettings({
      movieLevelSettings: {
        levels: updatedLevelSettings,
      },
    });
    setStage(MovieGameFormSteps.Levels);
  };

  const goBack = () => {
    setStage(MovieGameFormSteps.Levels);
  };

  return (
    <MovieFormContainer>
      <Form submitHandler={submit} methods={methods}>
        <LevelHeader
          goBack={goBack}
          level={level}
          errorMessage={methods.formState.errors?.timeRanges?.message}
        />
        <FormWrapper>
          <SelectMovie />
          <TimeRanges />
        </FormWrapper>
      </Form>
    </MovieFormContainer>
  );
};
