import styled from 'styled-components';

export const StyledLevels = styled.div(
  ({ theme: { spaces } }) => `
  display: flex;
  flex-direction: column;
  justify-content: center;
  gap: ${spaces['3xl']};
  width: 100%;
`
);

export const LevelsContainer = styled.div``;

export const ButtonContainer = styled.div(
  ({ theme: { spaces } }) => `
  display: flex;
  justify-content: flex-end;
  gap: ${spaces.base};
`
);
