import React, { useEffect } from 'react';
import { Text, Heading, Flex } from 'components/common';

import { MainContainer } from './styles';
import { useNavigate } from 'react-router-dom';
import { userRoutes } from 'config';

export const PaymentFinished: React.FC = () => {
  const navigate = useNavigate();

  useEffect(() => {
    const timeout = setTimeout(() => navigate(userRoutes.dashboard), 3000);
    return () => {
      clearTimeout(timeout);
    };

    // eslint-disable-next-line
  }, []);

  return (
    <MainContainer>
      <Flex columnDirection gap="sm">
        <Heading text="modals.paymentFinished.heading" size="xl" />
        <Text text="modals.paymentFinished.description" size="sm" />
      </Flex>
    </MainContainer>
  );
};
