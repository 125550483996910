import React from 'react';

import { Button, Flex, Text } from 'components/common';

import { ResultContainer } from '../styles';

interface SingleGameEndProps {
  action?: () => void;
}

export const SingleGameEnd: React.FC<SingleGameEndProps> = ({ action }) => {
  return (
    <ResultContainer>
      <Flex columnDirection gap="base">
        <Text
          text="views.game.singleGameEndHeading"
          size="lg"
          weight="semibold"
        />
        <Text text="views.game.singleGameEnd" />
      </Flex>
      <Button
        variant="secondary"
        text="button.finish"
        uppercase
        onClick={action}
        dataCy="next-game"
      />
    </ResultContainer>
  );
};
