import { FC } from 'react';
import { useCookies } from 'react-cookie';

import { CookieAcceptance } from 'types';

import { CookieBoxWrapper } from './styles';
import { GridContainer, GridItem } from '../Grid';
import { Text } from '../Text';
import { FlexAlignCenter } from '../Flex';
import { Button } from '../Button';

export const CookieBox: FC = () => {
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [__, setCookie] = useCookies();

  const handleOnAccept = () => {
    setCookie('cookieAcceptance', CookieAcceptance.Accepted);
  };
  const handleOnCancel = () => {
    setCookie('cookieAcceptance', CookieAcceptance.Rejected);
  };

  return (
    <CookieBoxWrapper>
      <GridContainer size={[5, 1]} gap="xl">
        <GridItem position={[1, 1, 4]}>
          <Text text="views.cookie" color="secondaryBackground" />
        </GridItem>
        <GridItem position={[5, 1]}>
          <FlexAlignCenter justifyContent="center" gap="xl">
            <Button
              variant="cookie-accept"
              color="secondary"
              text="button.accept"
              uppercase
              onClick={handleOnAccept}
            />
            <Button
              variant="cookie-cancel"
              color="secondary"
              text="button.reject"
              uppercase
              onClick={handleOnCancel}
            />
          </FlexAlignCenter>
        </GridItem>
      </GridContainer>
    </CookieBoxWrapper>
  );
};
