import React from 'react';

import { QuestionAnswerType } from 'cogamika-back/types';
import { GameResult } from 'types';
import { Survey } from 'games';
import { useSurveyGame } from 'hooks';
import { Text, GridContainer } from 'components/common';

import { Answer } from './Answer';
import { Question } from './Question';
import { Result } from './Result';
import { Container, AnswersContainer } from './styles';

interface Props {
  survey: Survey;
  endGame: (result?: GameResult) => void;
}

export const Game: React.FC<Props> = ({ survey, endGame }) => {
  const {
    currentQuestionIndex,
    questionWithAnswers,
    selectedAnswer,
    result,
    correctAnswerIndex,
    answerType,
    selectAnswer,
    nextQuestion,
  } = useSurveyGame({ survey, endGame });

  return (
    <Container>
      <Question question={questionWithAnswers.question} />
      <AnswersContainer>
        <Text
          text="views.survey.answersHeader"
          size="md"
          color="teritaryGray"
          weight="semibold"
        />
        <GridContainer
          size={answerType === QuestionAnswerType.Text ? [2, 2] : [4]}
          gap="2xl"
        >
          {questionWithAnswers.answers.map((answer, index) => (
            <Answer
              key={index}
              answer={answer}
              selectAnswer={selectAnswer}
              index={index}
              selectedAnswerIndex={selectedAnswer}
              survey={survey}
            />
          ))}
        </GridContainer>
      </AnswersContainer>
      <Result
        result={result}
        correctAnswerIndex={correctAnswerIndex}
        handleNext={() => nextQuestion(currentQuestionIndex + 1)}
      />
    </Container>
  );
};
