import React from 'react';
import { useNavigate } from 'react-router-dom';

import { superAdminRoutes } from 'config';
import { Button, Text } from 'components/common';

import { PublishInfoContainer } from './styles';
import { PublishedGameType } from 'types';

interface Props {
  type: PublishedGameType;
}

export const PublishInfo: React.FC<Props> = ({ type }) => {
  const navigate = useNavigate();
  const handleNavigate = () => {
    navigate(superAdminRoutes.dashboard);
  };

  const getText = (variant: PublishedGameType) => {
    switch (variant) {
      case PublishedGameType.Draft:
        return 'views.newGame.publishedDraftSuccess';
      case PublishedGameType.Ready:
        return 'views.newGame.publishedSuccess';
      default:
        return '';
    }
  };

  return (
    <PublishInfoContainer>
      <Text text={getText(type)} size="lg" weight="bold" />
      <Button
        text="button.next"
        variant="secondary"
        onClick={handleNavigate}
        uppercase
      />
    </PublishInfoContainer>
  );
};
