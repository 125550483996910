import React, { useState } from 'react';
import { useFormContext } from 'react-hook-form';

import { FileType, SurveyInitialDataType } from 'cogamika-back/types';
import { SurveyGameFormType } from 'types';

import { SelectFile } from 'components/forms';

interface Props {
  setIsShow: React.Dispatch<React.SetStateAction<boolean>>;
}

export const SelectInitialFile: React.FC<Props> = ({ setIsShow }) => {
  const { setValue } = useFormContext<SurveyGameFormType>();
  const [selectedFile, setSelectedFile] = useState(() => new Set<string>());
  const [fileType, setFileType] = useState<SurveyInitialDataType>();

  const handleFileSelect = (
    id: string,
    type?: FileType,
    originalName?: string
  ) => {
    if (selectedFile.has(id)) {
      setFileType(undefined);
      setSelectedFile(new Set());
    } else {
      setSelectedFile(new Set([id]));

      if (type === FileType.Audio) {
        setFileType(SurveyInitialDataType.Audio);
      }
      if (type === FileType.Image) {
        setFileType(SurveyInitialDataType.Image);
      }
    }
  };

  const save = () => {
    if (fileType) {
      setValue('initialData.description', Array.from(selectedFile)[0]);
      setValue('initialData.type', fileType);
    }
  };

  return (
    <SelectFile
      handleFileSelect={handleFileSelect}
      selectedFile={selectedFile}
      setIsShow={setIsShow}
      onSave={save}
    />
  );
};
