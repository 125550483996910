import { ChronologySettingsRes } from 'cogamika-back/types';
import { Game } from 'games';
import { ChronologyCardType, GameStage } from 'types';
import { shuffle } from 'utils';

export class Chronology extends Game {
  public settings: ChronologySettingsRes;
  public stage: GameStage;
  public cardElements: ChronologyCardType[];

  constructor(
    level: number,
    gameId: string,
    domainId: string,
    settings: ChronologySettingsRes
  ) {
    super(level, gameId, domainId);
    this.settings = settings;
    this.stage = GameStage.Instruction;
    this.cardElements = [];
    this.initGame();
  }

  public initGame() {
    this.shuffleElements();
  }

  public startChronologyGame() {
    super.startGame();
  }

  public get chronologyElements() {
    return this.settings.elements;
  }

  private assignCorrectElements(elements: ChronologyCardType[]) {
    return elements.map((item, index) =>
      item.orderNumber === index
        ? { ...item, isCorrect: true }
        : { ...item, isCorrect: false }
    );
  }

  private shuffleElements() {
    let shuffledElements = shuffle(this.settings.elements);
    let isSameOrder = this.checkOrder(this.settings.elements, shuffledElements);

    while (isSameOrder && this.settings.elements.length > 1) {
      shuffledElements = shuffle(this.settings.elements);
      isSameOrder = this.checkOrder(this.settings.elements, shuffledElements);
    }

    this.settings = {
      ...this.settings,
      elements: this.assignCorrectElements(shuffledElements),
    };

    this.cardElements = shuffledElements;
  }

  public checkWin(
    setWin: React.Dispatch<React.SetStateAction<boolean>>,
    cards: ChronologyCardType[],
    setCards: React.Dispatch<React.SetStateAction<ChronologyCardType[]>>
  ) {
    if (cards.length <= 1) {
      return true;
    }

    setCards(this.assignCorrectElements(cards));

    for (let i = 1; i < cards.length; i++) {
      if (cards[i].orderNumber < cards[i - 1].orderNumber) {
        return;
      }
    }

    setWin(true);
    super.endGame(true);
  }

  private checkOrder(
    array1: ChronologyCardType[],
    array2: ChronologyCardType[]
  ) {
    return !array1.some((el, i) => el.orderNumber !== array2[i].orderNumber);
  }
}
