import React, { useState } from 'react';
import { useFormContext } from 'react-hook-form';

import { FileType, QuestionAnswerType } from 'cogamika-back/types';
import { SurveyGameFormType } from 'types';
import { SelectFile } from 'components/forms';

interface Props {
  setIsShow: React.Dispatch<React.SetStateAction<boolean>>;
  index: number;
  isQuestion: boolean;
  answerIndex: number;
}

interface FileData {
  id: string;
  type: QuestionAnswerType;
  originalName: string;
}

export const SelectQuestionAnswerFile: React.FC<Props> = ({
  setIsShow,
  index,
  isQuestion,
  answerIndex,
}) => {
  const { setValue } = useFormContext<SurveyGameFormType>();
  const [selectedFile, setSelectedFile] = useState(() => new Set<string>());
  const [fileData, setFileData] = useState<FileData>();

  const handleFileSelect = (
    id: string,
    type?: FileType,
    originalName?: string
  ) => {
    if (selectedFile.has(id)) {
      setSelectedFile(new Set());
      setFileData(undefined);
    } else {
      setSelectedFile(new Set([id]));
      setFileData({
        type: QuestionAnswerType.Image,
        id,
        originalName: originalName || 'image',
      });
    }
  };

  const save = () => {
    if (fileData) {
      if (isQuestion) {
        setValue(
          `surveyQuestions.${index}.question.description`,
          fileData.originalName
        );
        setValue(`surveyQuestions.${index}.question.imageId`, fileData.id);
        setValue(`surveyQuestions.${index}.question.type`, fileData.type);
      } else {
        setValue(
          `surveyQuestions.${index}.answers.${answerIndex}.description`,
          fileData.originalName
        );
        setValue(
          `surveyQuestions.${index}.answers.${answerIndex}.imageId`,
          fileData.id
        );
        setValue(
          `surveyQuestions.${index}.answers.${answerIndex}.type`,
          fileData.type
        );
      }
    }
  };

  return (
    <SelectFile
      handleFileSelect={handleFileSelect}
      selectedFile={selectedFile}
      setIsShow={setIsShow}
      onSave={save}
    />
  );
};
