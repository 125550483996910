import React, { useContext, useState } from 'react';

import { CollectionAssignmentType } from 'cogamika-back/types';
import { GameFormSteps } from 'types';
import { GameFormContext } from 'context';

import { CollectionGameForm } from './CollectionGameForm';

export const Collection: React.FC = () => {
  const { setStep } = useContext(GameFormContext);
  const [collectionStage, setCollectionStage] =
    useState<CollectionAssignmentType>(CollectionAssignmentType.First);

  const goBack = () => {
    if (collectionStage === CollectionAssignmentType.First) {
      setStep(GameFormSteps.Instruction);
    } else if (collectionStage === CollectionAssignmentType.Second) {
      setCollectionStage(CollectionAssignmentType.First);
    }
  };

  const goNext = () => {
    if (collectionStage === CollectionAssignmentType.First) {
      setCollectionStage(CollectionAssignmentType.Second);
    } else if (collectionStage === CollectionAssignmentType.Second) {
      setStep(GameFormSteps.PublishGame);
    }
  };

  const getStage = (type: CollectionAssignmentType) => {
    switch (type) {
      case CollectionAssignmentType.First:
        return (
          <CollectionGameForm
            key={CollectionAssignmentType.First}
            collectionType={CollectionAssignmentType.First}
            goBack={goBack}
            goNext={goNext}
          />
        );
      case CollectionAssignmentType.Second:
        return (
          <CollectionGameForm
            key={CollectionAssignmentType.Second}
            collectionType={CollectionAssignmentType.Second}
            goBack={goBack}
            goNext={goNext}
          />
        );
      default:
        return <></>;
    }
  };

  return getStage(collectionStage);
};
