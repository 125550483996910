import React, { useEffect, useState } from 'react';
import { UseFieldArrayRemove, useFormContext } from 'react-hook-form';

import { QuestionAnswerType, QuestionWithAnswers } from 'cogamika-back/types';
import { TextInput } from 'components/forms';
import { Flex, IconButton, Text } from 'components/common';

import { Answer } from '../Answer';
import { AnswersContainer, InputContainer, ButtonContainer } from './styles';
import { SelectQuestionAnswerFile } from '../SelectQuestionAnswerFile';
import { useApi } from 'hooks';
import { SurveyGameFormType } from 'types';

interface Props {
  field: QuestionWithAnswers;
  questionNumber: number;
  questionIndex: number;
  remove: UseFieldArrayRemove;
}

export const Question: React.FC<Props> = ({
  questionNumber,
  questionIndex,
  field,
  remove,
}) => {
  const { get } = useApi();
  const [isShow, setIsShow] = useState(false);
  const [isQuestion, setIsQuestion] = useState(false);
  const [answerIndex, setAnswerIndex] = useState<number>(0);
  const { setValue, watch, formState, getValues } =
    useFormContext<SurveyGameFormType>();
  const question = getValues(`surveyQuestions.${questionIndex}.question`);

  const openModal = (isQuestion: boolean, answerIndex: number) => {
    setIsQuestion(isQuestion);
    setAnswerIndex(answerIndex);
    setIsShow((prev) => !prev);
  };

  const deleteInput = (isQuestion: boolean, answerIndex: number) => {
    if (isQuestion) {
      setValue(`surveyQuestions.${questionIndex}.question.description`, '');
      setValue(
        `surveyQuestions.${questionIndex}.question.type`,
        QuestionAnswerType.Text
      );
    } else {
      setValue(
        `surveyQuestions.${questionIndex}.answers.${answerIndex}.description`,
        ''
      );
      setValue(
        `surveyQuestions.${questionIndex}.answers.${answerIndex}.type`,
        QuestionAnswerType.Text
      );
    }
  };

  const deleteQuestion = () => {
    remove(questionIndex);
  };

  const fethcImageName = async (id: string) => {
    return await get<string>(`/file/name/${id}`);
  };
  useEffect(() => {
    if (
      question.type === QuestionAnswerType.Image &&
      question?.imageId &&
      question.description === question.imageId
    ) {
      fethcImageName(question?.imageId).then((imageName) => {
        setValue(
          `surveyQuestions.${questionIndex}.question.description`,
          imageName || ''
        );
      });
    }

    // eslint-disable-next-line
  }, []);

  const error =
    formState.errors.surveyQuestions?.[questionIndex]?.answers?.message;

  return (
    <Flex columnDirection gap="sm">
      <Flex gap="sm" alignItems="center">
        <Text
          text="views.newGame.survey.questionNumber"
          translationArgs={{ questionNumber }}
          size="base"
          weight="semibold"
        />
        <IconButton type="button" onClick={deleteQuestion} name="delete" />
      </Flex>
      <InputContainer>
        <TextInput
          name={`surveyQuestions.${questionIndex}.question.description`}
          placeholder="surveyQuestion"
          fullWidth
          disabled={
            watch(`surveyQuestions.${questionIndex}.question.type`) ===
            QuestionAnswerType.Image
          }
        />
        <ButtonContainer>
          {field.question.type === QuestionAnswerType.Text && (
            <IconButton
              type="button"
              onClick={() => openModal(true, 0)}
              name="plus"
            />
          )}
          {field.question.type === QuestionAnswerType.Image && (
            <IconButton
              type="button"
              onClick={() => deleteInput(true, 0)}
              name="delete"
            />
          )}
        </ButtonContainer>
      </InputContainer>
      <AnswersContainer>
        <Text text="labels.correctAnswer" size="base" />
        {error && <Text text={error} size="xs" color="error" />}
        <Answer
          answerIndex={0}
          isQuestion={false}
          openModal={openModal}
          questionIndex={questionIndex}
          deleteInput={deleteInput}
        />
        <Text text="labels.incorrectAnswers" size="base" />
        {Array.from({ length: 3 }).map((_, index) => (
          <Answer
            key={index}
            answerIndex={index + 1}
            isQuestion={false}
            openModal={openModal}
            questionIndex={questionIndex}
            deleteInput={deleteInput}
          />
        ))}
      </AnswersContainer>
      {isShow && (
        <SelectQuestionAnswerFile
          setIsShow={setIsShow}
          isQuestion={isQuestion}
          answerIndex={answerIndex}
          index={questionIndex}
        />
      )}
    </Flex>
  );
};
