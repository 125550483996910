import React from 'react';

import { apiUrl } from 'config';
import { AnswerType, QuestionAnswerType } from 'cogamika-back/types';
import { getLetterByIndex } from 'utils';
import { Survey } from 'games';

import {
  AnswerContainer,
  ImageContainer,
  ImageLetter,
  StyledImage,
  TextAnswer,
} from './styles';

enum Status {
  Correct = 'CORRECT',
  Incorrect = 'INCORRECT',
}

interface Props {
  answer: AnswerType;
  index: number;
  selectAnswer: (index: number) => void;
  survey: Survey;
  selectedAnswerIndex?: number;
}

export const Answer: React.FC<Props> = ({
  answer,
  index,
  selectAnswer,
  survey,
  selectedAnswerIndex,
}) => {
  const { isCorrect } = answer;
  const handleSelectAnswer = () => {
    selectAnswer(index);

    if (isCorrect) {
      survey.incrementCorrectAnswers();
    } else {
      survey.incrementIncorrectAnswers();
    }
  };

  const getStatus = (isCorrect: boolean, selectedAnswerIndex?: number) => {
    if (selectedAnswerIndex === undefined) return;
    if (!isCorrect && selectedAnswerIndex === index) {
      return Status.Incorrect;
    }
    if (isCorrect) return Status.Correct;
  };

  const status = getStatus(isCorrect, selectedAnswerIndex);

  const getAnswer = (type: QuestionAnswerType) => {
    switch (type) {
      case QuestionAnswerType.Text:
        return (
          <TextAnswer
            onClick={handleSelectAnswer}
            status={status}
            data-cy="answer"
            disabled={selectedAnswerIndex !== undefined}
          >{`${getLetterByIndex(index)}. ${answer.description}`}</TextAnswer>
        );
      case QuestionAnswerType.Image:
        return (
          <ImageContainer data-cy="answer">
            <StyledImage
              src={`${apiUrl}file/${answer.description}`}
              status={status}
              onClick={handleSelectAnswer}
              effect="opacity"
            />
            <ImageLetter>{getLetterByIndex(index)}</ImageLetter>
          </ImageContainer>
        );
      default:
        return null;
    }
  };
  return <AnswerContainer>{getAnswer(answer.type)}</AnswerContainer>;
};
