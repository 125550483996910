import React from 'react';

import { FileRes, FileType } from 'cogamika-back/types';
import { Text, IconButton } from 'components/common';

import { Container } from './styles';

interface Props {
  file: FileRes;
  onSelect: (id: string, type?: FileType, originalName?: string) => void;
  isSelected: boolean;
}

export const SingleFile: React.FC<Props> = ({
  file: { id, originalName, type },
  onSelect,
  isSelected,
}) => {
  const handleSelect = () => {
    onSelect(id, type, originalName);
  };

  return (
    <Container isSelected={isSelected}>
      <Text text={originalName} noTranslate />
      <IconButton
        name={isSelected ? 'cross' : 'plus'}
        type="button"
        onClick={handleSelect}
      />
    </Container>
  );
};
