import React, { useState } from 'react';

import { apiUrl } from 'config';

import { ButtonContainer, ImageContainer, StyledImage } from './styles';
import { IconButton } from 'components/common';

interface Props {
  id: string;
  deleteImage: (id: string) => void;
}

export const Image: React.FC<Props> = ({ id, deleteImage }) => {
  const [isHovered, setIsHovered] = useState(false);
  const handleMouseEnter = () => {
    setIsHovered(true);
  };

  const handleMouseLeave = () => {
    setIsHovered(false);
  };

  return (
    <ImageContainer
      onMouseEnter={handleMouseEnter}
      onMouseLeave={handleMouseLeave}
    >
      <StyledImage src={`${apiUrl}file/${id}`} alt={'originalName'} />
      {isHovered && (
        <ButtonContainer>
          <IconButton
            name="remove"
            onClick={() => deleteImage(id)}
            type="button"
          />
        </ButtonContainer>
      )}
    </ImageContainer>
  );
};
