import React from 'react';

import { Button, Flex, Heading, Text } from 'components/common';

import { MainContainer } from './styles';

export const Info: React.FC = () => {
  return (
    <MainContainer>
      <Flex columnDirection gap="2xl">
        <Flex columnDirection gap="sm">
          <Heading text="Gra ukończona!" size="lg" />
          <Text
            text="Vorem ipsum dolor sit amet, consectetur adipiscing elit"
            size="md"
          />
        </Flex>
        <Button text="DALEJ" variant="primary" fullWidth />
      </Flex>
    </MainContainer>
  );
};
