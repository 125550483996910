import styled from 'styled-components';

export const QuestionsContainer = styled.div`
  display: flex;
  flex-direction: column;
  margin-top: 32px;
  width: 100%;
  height: 490px;
  overflow-y: scroll;
  padding-right: 16px;
  gap: 32px;
`;
