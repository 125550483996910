import { colors } from './colors';
import { fontSizes, lineHeights } from './fontSizes';
import { spaces } from './spaces';
import { radius } from './radius';
import { breakPoints } from './breakPoints';
import { fontWeight } from './fontWeight';
import { sizes, boxSizes } from './sizes';

import { Theme } from 'types';

export const theme: Theme = {
  colors,
  fontSizes,
  lineHeights,
  spaces,
  radius,
  breakPoints,
  fontWeight,
  sizes,
  boxSizes,
};
