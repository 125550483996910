import React, { useMemo } from 'react';
import { ResultContainer } from '../styles';
import { Button, Flex, Icon, Text } from 'components/common';
import { getLetterByIndex } from 'utils';

interface Props {
  handleNext: () => void;
  correctAnswerIndex: number;
}

export const Failure: React.FC<Props> = ({
  handleNext,
  correctAnswerIndex,
}) => {
  const correctAnswer = useMemo(
    () => getLetterByIndex(correctAnswerIndex),
    [correctAnswerIndex]
  );

  return (
    <ResultContainer variant="failure">
      <Flex alignItems="center" gap="base">
        <Icon name="error-check" />
        <Text
          text="views.survey.incorrectAnswer"
          size="md"
          weight="semibold"
          color="error"
          translationArgs={{ correctAnswer }}
        />
      </Flex>
      <Button
        text="button.next"
        variant="outlined-error"
        uppercase
        iconName="right-arrow"
        onClick={handleNext}
        dataCy="nextQuestion"
      />
    </ResultContainer>
  );
};
