export enum CollectionItemType {
  Image = 'IMAGE',
  Text = 'TEXT',
}

export type CollectionItem = {
  description: string;
  type: CollectionItemType;
};

export enum CollectionAssignmentType {
  First = 1,
  Second = 2,
}

export type AccessibleCollectionItem = CollectionItem & {
  collectionType: CollectionAssignmentType;
};

export type AccessibleCollectionItemKeys = keyof AccessibleCollectionItem;

export const AccessibleCollectionItemKeysArray: Array<AccessibleCollectionItemKeys> =
  ['collectionType', 'description', 'type'];

export type Collection = {
  name: string;
  imageId: string;
  type: CollectionAssignmentType;
};

export type CollectionKeys = keyof Collection;

export const CollectionKeysArray: Array<CollectionKeys> = [
  'name',
  'imageId',
  'type',
];

export interface CollectionGameReq {
  firstCollection?: Collection;
  secondCollection?: Collection;
  accessibleImages?: AccessibleCollectionItem[];
}

export interface CollectionGameSettings {
  firstCollection: Collection;
  secondCollection: Collection;
  accessibleImages: AccessibleCollectionItem[];
}

export type CollectionGameSettingsKeys = keyof CollectionGameSettings;

export const CollectionGameSettingsKeysArray: Array<CollectionGameSettingsKeys> =
  ['firstCollection', 'secondCollection', 'accessibleImages'];

export interface CollectionGameLevelSettings {
  accessibleImagesAmount: number;
}

export type CollectionGameLevelSettingsKeys = keyof CollectionGameLevelSettings;

export const CollectionGameLevelSettingsKeysArray: Array<CollectionGameLevelSettingsKeys> =
  ['accessibleImagesAmount'];

export type CollectionSettingsRes = CollectionGameSettings &
  CollectionGameLevelSettings;
