import styled from 'styled-components';

export const CollectionContainer = styled.div(
  ({ theme: { spaces } }) => `
  width: 100%;
  display: grid;
  grid-template-columns: 3fr 2fr;
  gap: ${spaces.lg};
`
);

export const LeftWrapper = styled.div(
  ({ theme: { spaces } }) => `
  display: flex;
  flex-direction: column;
  gap: ${spaces['3xl']};
`
);

export const SelectedFiles = styled.div(
  ({ theme: { spaces } }) => `
  display: flex;
  flex-wrap: wrap;
  gap: ${spaces.sm};
  max-height: 400px;
  overflow-y: scroll;
`
);

export const FilesSelectorWrapper = styled.div``;

export const CollectionImage = styled.div(
  ({ theme: { spaces, colors, radius } }) => `
  width: 70%;
  display: flex;
  align-items: center; 
  justify-content: space-between;
  padding: ${spaces.md} ${spaces.lg};
  background-color: ${colors.lightGray};
  border-radius: ${radius.md};
`
);

export const ButtonContainer = styled.div(
  ({ theme: { spaces } }) => `
  margin-top: ${spaces.lg};
  width: 100%;
  display: flex;
  justify-content: flex-end;
  gap: ${spaces.md};
`
);
