import styled from 'styled-components';

export const StyledFiledErrorMessage = styled.p(
  ({ theme: { colors, fontSizes } }) => `
  margin-top: 5px;
  max-width: 100%;
  color: ${colors.error};
  font-size: ${fontSizes.xs};
`
);
