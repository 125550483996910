import React, { useContext, useState } from 'react';

import { GameFormSteps, SurveyGameFormSteps } from 'types';
import { GameFormContext } from 'context';
import { Levels } from 'components/forms';

import { SurveyGameForm } from './SurveyGameForm';

export const Survey: React.FC = () => {
  const { setStep, gameSettings } = useContext(GameFormContext);

  const [stage, setStage] = useState<SurveyGameFormSteps>(
    SurveyGameFormSteps.Levels
  );
  const [level, setLevel] = useState<number>();
  const levelsSettings = gameSettings?.surveyLevelSettings;

  const selectLevel = (level: number) => {
    setLevel(level);
    setStage(SurveyGameFormSteps.SurveyForm);
  };

  const levelsGoBack = () => {
    setStep(GameFormSteps.Instruction);
  };

  const levelsSubmit = () => {
    setStep(GameFormSteps.PublishGame);
  };

  const getStage = (type: SurveyGameFormSteps) => {
    switch (type) {
      case SurveyGameFormSteps.Levels:
        return (
          <Levels
            levelsSettings={levelsSettings?.levels}
            selectLevel={selectLevel}
            goBack={levelsGoBack}
            submit={levelsSubmit}
          />
        );
      case SurveyGameFormSteps.SurveyForm:
        return <SurveyGameForm level={level || 1} setStage={setStage} />;
      default:
        return <></>;
    }
  };

  return getStage(stage);
};
