import React from 'react';

import { Icon, Text } from 'components/common';

import { LevelContainer } from './styles';

interface Props {
  level: number;
  selectLevel: (level: number) => void;
  checkIfLevelIsCompleted: (level: number) => boolean | undefined;
}

export const Level: React.FC<Props> = ({
  level,
  selectLevel,
  checkIfLevelIsCompleted,
}) => {
  const handleSelectLevel = () => {
    selectLevel(level);
  };

  const isLevelComplete = checkIfLevelIsCompleted(level);

  return (
    <LevelContainer onClick={handleSelectLevel} type="button">
      <Text
        text="views.newGame.survey.level"
        translationArgs={{ level }}
        size="base"
      />
      {isLevelComplete && <Icon name="checked" />}
    </LevelContainer>
  );
};
