import { createContext, Dispatch, SetStateAction } from 'react';

import { DetailsGameType, GameFormSteps, GamesFormSettings } from 'types';

type ProjectWorkersContextType = {
  gameSettings: GamesFormSettings | undefined;
  setGameSettings: Dispatch<SetStateAction<GamesFormSettings | undefined>>;
  detailsForm: DetailsGameType | undefined;
  setDetailsForm: Dispatch<SetStateAction<DetailsGameType | undefined>>;
  step: GameFormSteps;
  setStep: Dispatch<SetStateAction<GameFormSteps>>;
};

export const GameFormContext = createContext<ProjectWorkersContextType>({
  gameSettings: undefined,
  setGameSettings: () => {},
  detailsForm: undefined,
  setDetailsForm: () => {},
  step: GameFormSteps.GameName,
  setStep: () => {},
});
