import { v4 as uuid } from 'uuid';
import { createSlice } from '@reduxjs/toolkit';

import { __ } from 'utils/i18n';
import { getErrorMessage, getFormValidationError } from 'utils';
import {
  DeletedFileId,
  ErrorNotification,
  FormValidationNotification,
  NotificationsState,
  NotificationType,
  OtherNotification,
} from 'types';

const initialState: NotificationsState = {
  notifications: [],
  formValidationErrors: [],
  errorNotification: null,
  deletedFileId: '',
};

export const notificationsSlice = createSlice({
  name: 'notifications',
  initialState,
  reducers: {
    hideNotification: (state, { payload }: OtherNotification) => {
      state.notifications = state.notifications.filter(
        ({ id }) => id !== payload
      );
    },
    formValidationNotification: (
      state,
      { payload }: FormValidationNotification
    ) => {
      const formValidationErrors: string[] = [];

      payload.forEach((element: any) => {
        formValidationErrors.push(
          getFormValidationError(element.txt, element.params)
        );
      });

      state.formValidationErrors = formValidationErrors;
    },

    errorNotification: (state, { payload }: ErrorNotification) => {
      const text = getErrorMessage(payload);

      state.errorNotification = {
        type: NotificationType.Error,
        id: uuid(),
        text,
        code: payload,
      };
      state.formValidationErrors = [];
    },
    clearErrorNotification: (state) => {
      state.errorNotification = null;
      state.formValidationErrors = [];
    },
    infoNotification: (state, { payload }: OtherNotification) => {
      const text = __(payload);
      state.notifications.push({
        type: NotificationType.Info,
        id: uuid(),
        text,
      });
    },
    successNotification: (state, { payload }: OtherNotification) => {
      const text = __(payload);

      state.notifications.push({
        type: NotificationType.Success,
        id: uuid(),
        text,
      });
    },
    setDeletedFileId: (state, { payload }: DeletedFileId) => {
      state.deletedFileId = payload;
    },
    clearDeletedFileId: (state) => {
      state.deletedFileId = '';
    },
  },
});

export const {
  hideNotification,
  errorNotification,
  clearErrorNotification,
  infoNotification,
  successNotification,
  formValidationNotification,
  setDeletedFileId,
  clearDeletedFileId,
} = notificationsSlice.actions;
