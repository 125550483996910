import { createSlice } from '@reduxjs/toolkit';
import {
  BadgeRes,
  DomainBaseRes,
  GameResourcesRes,
  UserGameReq,
} from 'cogamika-back/types';

type Domains = DomainBaseRes & {
  isDone: boolean;
  isCurrent: boolean;
};

interface GameControllerState {
  isSessionEnd: boolean;
  currentGameIndex: number;
  currentGameSettings?: GameResourcesRes;
  gamesToPlay: GameResourcesRes[];
  gamesPlayed: UserGameReq[];
  gameDomains: Domains[];
  currentDomainIndex: number;
  badges: BadgeRes[];
}

const initialState: GameControllerState = {
  isSessionEnd: false,
  currentGameIndex: 0,
  currentGameSettings: undefined,
  gamesToPlay: [],
  gamesPlayed: [],
  gameDomains: [],
  currentDomainIndex: 0,
  badges: [],
};

export const gameControllerSlice = createSlice({
  name: 'gameController',
  initialState,
  reducers: {
    clearSessionGames: (state) => {
      state.currentGameIndex = initialState.currentGameIndex;
      state.isSessionEnd = initialState.isSessionEnd;
      state.currentGameSettings = initialState.currentGameSettings;
      state.gamesPlayed = initialState.gamesPlayed;
      state.gamesToPlay = initialState.gamesToPlay;
      state.gameDomains = [];
      state.currentDomainIndex = 0;
    },
    setSessionGames: (state, action) => {
      state.gamesToPlay = action.payload;
      state.currentGameSettings = state.gamesToPlay[state.currentGameIndex];
    },
    setNextGame: (state) => {
      const newIndex = state.currentGameIndex + 1;
      const newDomainIndex = state.currentDomainIndex + 1;
      state.currentGameIndex = newIndex;
      state.currentDomainIndex = newDomainIndex;
      if (newIndex <= state.gamesToPlay.length - 1) {
        state.currentGameSettings = state.gamesToPlay[newIndex];
        state.gameDomains[newDomainIndex - 1].isDone = true;
        state.gameDomains[newDomainIndex].isCurrent = true;
      }
    },
    setSessionIsEnded: (state) => {
      state.isSessionEnd = true;
    },
    setGameWithNextLevel: (state, action) => {
      const newGamesToPlay = state.gamesToPlay;
      const newIndex = state.currentGameIndex + 1;
      newGamesToPlay.splice(state.currentGameIndex + 1, 0, action.payload);
      state.gamesToPlay = newGamesToPlay;
      state.currentGameSettings = newGamesToPlay[newIndex];
      state.currentGameIndex = newIndex;
    },
    addPlayedGame: (state, action) => {
      state.gamesPlayed = [...state.gamesPlayed, action.payload];
    },
    setGameDomains: (state, action) => {
      state.gameDomains = action.payload;
    },
    setBadges: (state, action) => {
      state.badges = action.payload;
    },
  },
});

export const {
  setSessionGames,
  setNextGame,
  setSessionIsEnded,
  addPlayedGame,
  setGameWithNextLevel,
  clearSessionGames,
  setGameDomains,
  setBadges,
} = gameControllerSlice.actions;
