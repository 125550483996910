import styled from 'styled-components';

export const StyledContainer = styled.div`
  width: 100%;
`;

export const StyledTextarea = styled.textarea(
  ({ theme: { colors } }) => `
  display: flex;
  border: 2px solid ${colors.gray};
  height: 180px;
  padding: 14px 24px;
  border-radius: 10px;
  font-size: 18px;
  width: 100%;
  ::placeholder {
    color: black;
  }
`
);

export const StyledLabel = styled.label(
  ({ theme: { fontSizes } }) => `
  font-size: ${fontSizes.base};
  `
);

export const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 10px;
`;
