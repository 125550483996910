import React from 'react';

import { Button, Icon, TranslateText } from 'components/common';

import { FilesFilterContainer, StyledButton } from './styles';

interface Props {
  enumOptions: string[];
  setFilterName: (id: string) => void;
  selectedFilterName?: string;
}

export const FilesFilter: React.FC<Props> = ({
  enumOptions,
  setFilterName,
  selectedFilterName,
}) => {
  const selectDomainId = (name: string) => {
    setFilterName(name);
  };

  const clearFilter = () => {
    setFilterName('');
  };

  const fileFilters = enumOptions.map((name) => (
    <Button
      key={name}
      text={`enum.${name.toLocaleLowerCase()}`}
      variant={
        selectedFilterName === name
          ? 'domains-filter-selected'
          : 'domains-filter'
      }
      onClick={() => selectDomainId(name)}
    />
  ));

  return (
    <FilesFilterContainer>
      {fileFilters}{' '}
      {selectedFilterName && (
        <StyledButton onClick={clearFilter}>
          <Icon name="cross" />
          <TranslateText text="button.clearFilters" />
        </StyledButton>
      )}
    </FilesFilterContainer>
  );
};
