import React, { useContext, useState } from 'react';

import { GameFormSteps, MovieGameFormSteps } from 'types';
import { GameFormContext } from 'context';
import { Levels } from 'components/forms';

import { MovieForm } from './MovieForm';

export const Movie: React.FC = () => {
  const { setStep, gameSettings } = useContext(GameFormContext);
  const [stage, setStage] = useState<MovieGameFormSteps>(
    MovieGameFormSteps.Levels
  );
  const [level, setLevel] = useState<number>();
  const levelsSettings = gameSettings?.movieLevelSettings;

  const selectLevel = (level: number) => {
    setLevel(level);
    setStage(MovieGameFormSteps.MovieForm);
  };

  const levelsGoBack = () => {
    setStep(GameFormSteps.Instruction);
  };

  const levelsSubmit = () => {
    setStep(GameFormSteps.PublishGame);
  };

  const getStage = (type: MovieGameFormSteps) => {
    switch (type) {
      case MovieGameFormSteps.Levels:
        return (
          <Levels
            levelsSettings={levelsSettings?.levels}
            selectLevel={selectLevel}
            goBack={levelsGoBack}
            submit={levelsSubmit}
          />
        );
      case MovieGameFormSteps.MovieForm:
        return <MovieForm level={level || 1} setStage={setStage} />;
    }
  };

  return getStage(stage);
};
