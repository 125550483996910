import React, { useState } from 'react';

import { Flex, Text } from 'components/common';
import { SelectVideo } from 'components/forms';

import { Container } from './styles';
import { VideoInfo } from './VideoInfo';

export const SelectMovie: React.FC = () => {
  const [isShow, setIsShow] = useState(false);

  return (
    <Container>
      <Flex columnDirection gap="3xl">
        <Flex columnDirection gap="sm" fullWidth>
          <Text text="views.newGame.movie.video" size="base" />
          <VideoInfo setIsShow={setIsShow} />
        </Flex>
        {isShow && <SelectVideo setIsShow={setIsShow} name="video" />}
      </Flex>
    </Container>
  );
};
