import { object } from 'yup';
import {
  checkEmail,
  checkLastName,
  checkName,
  checkNick,
  checkRepeatedNewPassword,
  checkPassword,
  checkLanguageSelect,
} from 'utils';

export const editProfileSchema = object().shape({
  nick: checkNick(),
  firstName: checkName(),
  lastName: checkLastName(),
  email: checkEmail(),
  lang: checkLanguageSelect,
});

export const editPasswordSchema = object().shape({
  password: checkPassword(),
  newPassword: checkPassword(),
  repeatNewPassword: checkRepeatedNewPassword(),
});
