import { FC } from 'react';
import { AppConfig } from 'config';

import { FooterWrapper } from './styles';
import { GridContainer, GridItem } from '../Grid';
import { FooterLinkButton } from '../Button';
import Policy from '../../../files/policy.pdf';
import Rules from '../../../files/rules.pdf';

export const Footer: FC = () => {
  const handleOnClickCogamikaOrg = () =>
    window.location.replace(AppConfig.ABOUT_US_REDIRECT);

  const handleOnOpenFile = (path: string) => {
    const link = document.createElement('a');
    link.href = path;
    link.target = '_blank';
    document.body.appendChild(link);
    link.click();
  };

  return (
    <FooterWrapper>
      <GridContainer size={[5, 1]} gap="xs">
        <GridItem position={[1, 1, 3]}>
          <GridContainer size={[2, 1]} gap="xs">
            <GridItem position={[1, 1]}>
              <FooterLinkButton
                text="views.footer.policy"
                onClick={() => handleOnOpenFile(Policy)}
              />
            </GridItem>
            <GridItem position={[2, 1]}>
              <FooterLinkButton
                text="views.footer.rules"
                onClick={() => handleOnOpenFile(Rules)}
              />
            </GridItem>
          </GridContainer>
        </GridItem>
        <GridItem position={[4, 1, 6]}>
          <GridContainer size={[2, 1]} gap="xs">
            <GridItem position={[1, 1]}>
              <FooterLinkButton
                text="cogamika.org"
                noTranslate
                onClick={handleOnClickCogamikaOrg}
              />
            </GridItem>
            <GridItem position={[2, 1]}>
              <FooterLinkButton
                text="views.footer.contact"
                translationArgs={{ mail: 'info@cogamika.org' }}
              />
            </GridItem>
          </GridContainer>
        </GridItem>
      </GridContainer>
    </FooterWrapper>
  );
};
