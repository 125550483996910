import styled from 'styled-components';

import { ColorType, FontSizeType, FontWeightType } from 'types';

interface Props {
  size: FontSizeType;
  color: ColorType;
  weight?: FontWeightType;
  uppercase?: boolean;
}

export const BasicHeading = styled.h1<Props>(
  ({
    theme: { colors, fontSizes, lineHeights, fontWeight },
    color,
    size,
    weight,
    uppercase,
  }) => `
  display: flex;
  font-weight: ${weight ? fontWeight[weight] : '500'};
  color: ${colors[color]};
  font-size: ${fontSizes[size]};
  line-height: ${lineHeights[size]};
  text-transform: ${uppercase && 'uppercase'};
`
);
