import React from 'react';

import { useAppSelector } from 'hooks';
import { Logo } from 'components/common';

import { BaseLinks } from './BaseLinks';
import { AuthLinks } from './AuthLinks';
import { StyledNavigation } from './styles';

export const Navigation: React.FC = () => {
  const { user } = useAppSelector((state) => state.user);
  return (
    <StyledNavigation>
      <Logo />
      {!user ? <BaseLinks /> : <AuthLinks role={user.role} />}
    </StyledNavigation>
  );
};
