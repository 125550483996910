import styled from 'styled-components';

interface Props {
  isError: boolean;
}

export const StyledWarningContainer = styled.div<Props>(
  ({ theme: { spaces, colors, radius }, isError }) => `
  display: flex;
  align-items: center;
  gap: ${spaces.sm};
  color: ${colors.error};
  background-color: ${isError ? colors.warningBackground : 'none'};
  width: 100%;
  border-radius: ${radius.md};
  padding: ${spaces.base} ${spaces.xl};
`
);

export const ErrorsContainer = styled.div(
  ({ theme: { spaces } }) => ` 
  display: flex;
  flex-direction: column;
  gap: ${spaces.xs};
`
);
