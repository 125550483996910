import React, { useEffect } from 'react';
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';

import { useApi } from 'hooks/useApi';
import { userRegisterSchema } from 'config';
import { Form, TextInput } from 'components/forms';
import { useAppDispatch, useAppSelector, useAnalytics } from 'hooks';
import { clearErrorNotification, showModal } from 'slices';
import { ModalType, RegisterIndividualUser } from 'types';
import {
  Button,
  Flex,
  Heading,
  LinkButton,
  RegisterLinkButton,
  Spinner,
  Text,
  Warning,
} from 'components/common';
import { i18n } from 'utils/i18n';
import { Profile, UserRes } from 'cogamika-back/types';

import { MainContainer } from './styles';
import Policy from '../../../files/policy.pdf';
import Rules from '../../../files/rules.pdf';

export const Register: React.FC = () => {
  const { post, inProgress } = useApi();
  const { sendRegisterEvent } = useAnalytics();
  const dispatch = useAppDispatch();
  const { errorNotification, formValidationErrors } = useAppSelector(
    (state) => state.notifications
  );

  const methods = useForm<RegisterIndividualUser>({
    mode: 'onSubmit',
    resolver: yupResolver(userRegisterSchema),
  });
  const { isSubmitting } = methods.formState;
  const isSubmitBtnDisabled = isSubmitting;

  const submit = async (data: RegisterIndividualUser) => {
    let payload: RegisterIndividualUser = {
      email: data.email,
      firstName: data.firstName,
      lastName: data.lastName,
      nick: data.nick,
    };

    if (data.nick) {
      payload.nick = data.nick;
    }

    dispatch(clearErrorNotification());

    const res = await post<UserRes, Profile>('/user/register-individual', {
      email: data.email,
      firstName: data.firstName,
      lastName: data.lastName,
      nick: data.nick,
      lang: i18n.currentLang,
    });

    if (res) {
      sendRegisterEvent({ method: 'Email' });
      dispatch(
        showModal({
          type: ModalType.RegisterConfirmation,
          data: null,
        })
      );
    }
  };

  const openLoginModal = () => {
    dispatch(
      showModal({
        type: ModalType.Login,
        data: null,
      })
    );
  };

  const handleOnOpenFile = (path: string) => {
    const link = document.createElement('a');
    link.href = path;
    link.target = '_blank';
    document.body.appendChild(link);
    link.click();
  };

  useEffect(() => {
    dispatch(clearErrorNotification());
    return () => {
      dispatch(clearErrorNotification());
    };

    // eslint-disable-next-line
  }, []);

  return (
    <MainContainer>
      {inProgress && <Spinner />}
      <Flex columnDirection gap="sm">
        <Heading text="modals.register.heading" size="xl" />
        <Flex gap="2xs">
          <Text text="modals.register.description" size="sm" />
          <LinkButton text="modals.register.login" onClick={openLoginModal} />
        </Flex>
      </Flex>
      <Form submitHandler={submit} methods={methods}>
        <Flex columnDirection gap="3xl">
          <Flex columnDirection gap="base" fullWidth>
            <TextInput name="email" placeholder="email" fullWidth required />
            <TextInput name="nick" placeholder="nick" fullWidth />
            <Flex gap="base" fullWidth>
              <TextInput
                name="firstName"
                placeholder="firstName"
                fullWidth
                required
              />
              <TextInput
                name="lastName"
                placeholder="lastName"
                fullWidth
                required
              />
            </Flex>
          </Flex>
          <Warning
            text={errorNotification?.text}
            isError={!!errorNotification || formValidationErrors.length > 0}
            formValidationErrors={formValidationErrors}
            noTranslate
          />
          <Button
            variant="primary"
            text="modals.register.button"
            type="submit"
            fullWidth
            uppercase
            disabled={isSubmitBtnDisabled}
            dataCy="confirm-register"
          />
        </Flex>
      </Form>
      <Flex justifyContent="center" wrap>
        <Text type="span" text="modals.register.description2" size="sm" />
        &nbsp;
        <RegisterLinkButton
          text="modals.register.termsOfUse"
          onClick={() => handleOnOpenFile(Rules)}
          size="sm"
        />
        &nbsp;
        <Text type="span" text="modals.register.description3" size="sm" />
        &nbsp;
        <RegisterLinkButton
          text="modals.register.privacyPolicy"
          onClick={() => handleOnOpenFile(Policy)}
          size="sm"
        />
        &nbsp;
        <Text type="span" text="modals.register.appName" size="sm" />
      </Flex>
    </MainContainer>
  );
};
