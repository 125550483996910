import React, { ReactElement } from 'react';
import { ThemeProvider } from 'styled-components';

import { theme } from './basicStyleValues';
import { GlobalStyle } from './globalStyle';

interface Props {
  children: ReactElement;
}

export const Theme: React.FC<Props> = ({ children }) => {
  return (
    <ThemeProvider theme={theme}>
      {children}
      <GlobalStyle />
    </ThemeProvider>
  );
};
