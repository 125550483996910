import styled from 'styled-components';

export const StyledContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 36px;
  width: 700px;
  background-color: #ffffff;
  padding: 54px 50px;
  margin: 0 auto;
  align-self: center;
`;

export const StyledParagraph = styled.p`
  text-align: center;
  width: 90%;
  margin: 0 auto;
`;

export const InputsGroup = styled.div`
  display: flex;
  flex-direction: column;
  gap: 16px;
  width: 100%;
`;
