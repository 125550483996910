import styled from 'styled-components';
import { Link } from 'react-router-dom';

export const StyledNavLink = styled(Link)(
  ({ theme: { colors, spaces, fontSizes, lineHeights } }) => `
  display: flex;
  align-items: center;
  gap: ${spaces.sm};
  justify-context: space-between;
  color: ${colors.primary};
  text-decoration: none;
  text-transform: uppercase;
  font-weight: 600;
  font-size: ${fontSizes.base};
  line-height: ${lineHeights.base};
  &:hover {    
    color: ${colors.secondary};
  }
`
);
