import React, { useEffect, useState } from 'react';
import InfiniteScroll from 'react-infinite-scroll-component';

import { BasePaginationRes, FileRes } from 'cogamika-back/types';
import { useApi, useAppDispatch, useAppSelector } from 'hooks';
import { clearDeletedFileId } from 'slices';
import { Text } from 'components/common';

import { SingleFile } from './SingleFile';
import { FileListContainer, FileViewerContainer } from './styles';
import { filterEmptyParams } from 'utils';

interface Props {
  refreshList: boolean;
  selectedFilter?: string;
  selectedType?: string;
}

export const FileViewer: React.FC<Props> = ({
  refreshList,
  selectedFilter,
  selectedType,
}) => {
  const dispatch = useAppDispatch();
  const { deletedFileId } = useAppSelector((state) => state.notifications);
  const { get } = useApi();
  const [files, setFiles] = useState<FileRes[]>([]);
  const [hasMore, setHasMore] = useState(true);
  const [page, setPage] = useState(1);

  const getFiles = async (currentPage: number) => {
    const res = await get<BasePaginationRes<FileRes>>(
      '/file',
      filterEmptyParams({
        page: String(currentPage),
        limit: String(20),
        filter: selectedFilter,
        ...(selectedType && { type: [selectedType] }),
      })
    );

    if (res && res.items.length > 0) {
      setFiles((prevFiles) => [...prevFiles, ...res.items]);
      setPage((prevPage) => prevPage + 1);
      setHasMore(res.hasNextPage);
    } else {
      setHasMore(false);
    }
  };

  useEffect(() => {
    getFiles(1);
    setFiles([]);
    setPage(1);

    // eslint-disable-next-line
  }, [selectedFilter, selectedType]);

  useEffect(() => {
    if (refreshList) {
      getFiles(1);
      setFiles([]);
      setPage(1);
    }

    // eslint-disable-next-line
  }, [refreshList]);

  useEffect(() => {
    if (deletedFileId) {
      setFiles((prevFiles) =>
        prevFiles.filter((file) => file.id !== deletedFileId)
      );
      dispatch(clearDeletedFileId());
    }

    // eslint-disable-next-line
  }, [deletedFileId]);

  return (
    <FileViewerContainer>
      <InfiniteScroll
        dataLength={files.length}
        next={() => getFiles(page)}
        hasMore={hasMore}
        loader={<Text text="views.files.loading" />}
      >
        <FileListContainer>
          {files.map((file) => (
            <SingleFile key={file.id} file={file} />
          ))}
        </FileListContainer>
      </InfiniteScroll>
    </FileViewerContainer>
  );
};
