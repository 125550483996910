import React, { ReactNode } from 'react';

import { MarginType, SpaceType } from 'types';

import { FlexComponent } from './styles';

interface Props {
  children: ReactNode;
  columnDirection?: boolean;
  gap?: SpaceType;
  fullWidth?: boolean;
  margin?: MarginType;
  wrap?: boolean;
}

export const FlexCenter: React.FC<Props> = ({
  children,
  columnDirection,
  gap,
  fullWidth,
  margin,
}) => {
  return (
    <FlexComponent
      columnDirection={columnDirection}
      gap={gap}
      fullWidth={fullWidth}
      margin={margin}
    >
      {children}
    </FlexComponent>
  );
};
