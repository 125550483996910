import { Dispatch, FC, SetStateAction } from 'react';

import { Language } from 'cogamika-back/types';

import { StyledSelect, Option } from './styles';

interface OptionType {
  label: string;
  value: string;
}

interface FaqChangeLangSelectProps {
  options: OptionType[];
  lang: Language;
  onChange: Dispatch<SetStateAction<Language>>;
}

export const FaqChangeLangSelect: FC<FaqChangeLangSelectProps> = ({
  options,
  onChange,
  lang,
}) => {
  return (
    <StyledSelect
      name="changeLang"
      onChange={(e: React.ChangeEvent<HTMLSelectElement>) =>
        onChange(e.currentTarget.value as Language)
      }
      value={lang}
    >
      {options.map(({ value, label }) => (
        <Option value={value} key={value}>
          {label}
        </Option>
      ))}
    </StyledSelect>
  );
};
