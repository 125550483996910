import React from 'react';
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import { useNavigate, useParams } from 'react-router-dom';

import { routes, userActivationSchema } from 'config';
import { SetPassword } from 'cogamika-back/types';
import { ActivateUserFormType } from 'types';
import { hideModal } from 'slices';
import { useAppDispatch, useAppSelector, useApi } from 'hooks';
import { Form, PasswordInput } from 'components/forms';
import {
  Button,
  Flex,
  Heading,
  Spinner,
  Text,
  Warning,
} from 'components/common';

import { InputsGroup, StyledContainer, StyledParagraph } from './styles';

export const ActivateUserForm: React.FC = () => {
  const navigate = useNavigate();
  const { token, userId } = useParams();
  const { post, inProgress } = useApi();
  const dispatch = useAppDispatch();
  const { errorNotification, formValidationErrors } = useAppSelector(
    (state) => state.notifications
  );

  const methods = useForm<ActivateUserFormType>({
    mode: 'onSubmit',
    resolver: yupResolver(userActivationSchema),
  });
  const { isSubmitting } = methods.formState;
  const isButtonDisabled = isSubmitting;

  const submit = async (data: ActivateUserFormType) => {
    const res = await post<undefined, SetPassword>(
      `/auth/activation/${userId}/${token}`,
      {
        password: data.password,
      }
    );

    if (res) {
      navigate(routes.login);
      dispatch(hideModal());
    }
  };

  return (
    <StyledContainer>
      {inProgress && <Spinner />}
      <Flex columnDirection gap="sm">
        <Heading text="views.activateUser.heading" size="xl" />
      </Flex>
      <Form submitHandler={submit} methods={methods}>
        <Flex columnDirection gap="3xl" fullWidth>
          <Flex columnDirection gap="base" fullWidth>
            <InputsGroup>
              <PasswordInput
                name="password"
                placeholder="createPassword"
                required
              />
              <PasswordInput
                name="repeatPassword"
                placeholder="repeatPassword"
                required
              />
            </InputsGroup>
            <Warning
              text={errorNotification?.text}
              isError={!!errorNotification || formValidationErrors.length > 0}
              formValidationErrors={formValidationErrors}
              noTranslate
            />
          </Flex>
          <Button
            variant="primary"
            text="button.next"
            type="submit"
            fullWidth
            uppercase
            disabled={isButtonDisabled}
          />
        </Flex>
      </Form>
      <StyledParagraph>
        <Text type="span" text="modals.register.description2" size="sm" />
        &nbsp;
        <Text
          type="span"
          text="modals.register.termsOfUse"
          size="sm"
          weight="semibold"
        />
        &nbsp;
        <Text type="span" text="modals.register.description3" size="sm" />
        &nbsp;
        <Text
          type="span"
          text="modals.register.privacyPolicy"
          size="sm"
          weight="semibold"
        />
        &nbsp;
        <Text type="span" text="modals.register.appName" size="sm" />
      </StyledParagraph>
    </StyledContainer>
  );
};
