import React, { ReactNode } from 'react';

import { GridItemComponent } from './styles';

interface Props {
  children: ReactNode | null;
  position?: number[];
  className?: string;
}

export const GridItem: React.FC<Props> = ({
  position,
  children,
  className,
}) => (
  <GridItemComponent position={position} className={className}>
    {children}
  </GridItemComponent>
);
