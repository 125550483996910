import React from 'react';
import {
  Text,
  Heading,
  Flex,
  FlexAlignCenter,
  Button,
} from 'components/common';

import { MainContainer } from './styles';
import { useModal } from 'hooks';
import { ConfirmationModalData } from 'types';

export const Confirmation: React.FC = () => {
  const { closeModal, getData } = useModal();
  const { action, text, header, noTranslate } =
    getData<ConfirmationModalData>();

  return (
    <MainContainer>
      <Flex columnDirection gap="xl">
        {header && (
          <Heading text={header} noTranslate={noTranslate} size="lg" />
        )}
        {text && <Text text={text} noTranslate={noTranslate} size="sm" />}
        <FlexAlignCenter gap="md">
          <Button
            type="button"
            variant="secondary"
            onClick={action.confirm}
            text={action.text}
            noTranslate={action.noTranslate}
          />
          <Button
            type="button"
            variant="outlined"
            onClick={closeModal}
            text="button.cancel"
          />
        </FlexAlignCenter>
      </Flex>
    </MainContainer>
  );
};
