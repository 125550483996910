import { v4 as uuidv4 } from 'uuid';

import { GameResult, MosaicCardType } from 'types';
import { shuffle } from 'utils';

import {
  CardImage,
  MosaicImageType,
  MosaicSettingsRes,
} from 'cogamika-back/types';
import { apiUrl } from 'config';
import { Game } from 'games';

export class Mosaic extends Game {
  public mosaicCards: Map<string, MosaicCardType>;
  private settings: MosaicSettingsRes;
  private cardImagesAmount: number;
  private numberOfFoundCards: number;
  private numberOfSelectedDistractors: number;

  constructor(
    level: number,
    gameId: string,
    domainId: string,
    settings: MosaicSettingsRes
  ) {
    super(level, gameId, domainId);
    this.mosaicCards = new Map<string, MosaicCardType>();
    this.settings = settings;
    this.cardImagesAmount = settings.images.length;
    this.numberOfFoundCards = 0;
    this.numberOfSelectedDistractors = 0;

    this.initGame();
  }

  public startMosaicGame(
    setMosaicCards: React.Dispatch<
      React.SetStateAction<Map<string, MosaicCardType> | undefined>
    >
  ): void {
    setMosaicCards(new Map(this.mosaicCards));
    super.startGame();
  }

  public initGame(): void {
    this.prepareMosaicCards();
  }

  public cardClick(
    cardId: string,
    endGame: (result: GameResult) => void,
    setMosaicCards: React.Dispatch<
      React.SetStateAction<Map<string, MosaicCardType> | undefined>
    >,
    setIsUserWon: React.Dispatch<React.SetStateAction<boolean>>
  ): void {
    const clickedCard = this.mosaicCards.get(cardId);

    if (clickedCard) {
      let isCorrect = false;

      if (!clickedCard.isSelected) {
        isCorrect = this.checkCard(clickedCard.type, endGame, setIsUserWon);
      } else {
        this.unCheckCard(clickedCard.type, endGame, setIsUserWon);
      }

      this.mosaicCards.set(cardId, {
        ...clickedCard,
        isCorrect,
        isSelected: !clickedCard.isSelected,
      });
    }

    setMosaicCards(new Map(this.mosaicCards));
  }

  private checkCard(
    type: MosaicImageType,
    endGame: (result: GameResult) => void,
    setIsUserWon: React.Dispatch<React.SetStateAction<boolean>>
  ) {
    if (type === MosaicImageType.Card) {
      this.numberOfFoundCards++;
      super.incrementCorrectAnswers();
      this.checkIfUserWon(endGame, setIsUserWon);
      return true;
    } else if (type === MosaicImageType.Distractor) {
      this.numberOfSelectedDistractors++;
      super.incrementIncorrectAnswers();
      return false;
    }
    return false;
  }

  private unCheckCard(
    type: MosaicImageType,
    endGame: (result: GameResult) => void,
    setIsUserWon: React.Dispatch<React.SetStateAction<boolean>>
  ) {
    if (type === MosaicImageType.Card) {
      this.numberOfFoundCards--;
      super.decrementCorrectAnswers();
    } else if (type === MosaicImageType.Distractor) {
      this.numberOfSelectedDistractors--;
      this.checkIfUserWon(endGame, setIsUserWon);
    }
  }

  private checkIfUserWon(
    endGame: (result: GameResult) => void,
    setIsUserWon: React.Dispatch<React.SetStateAction<boolean>>
  ) {
    if (
      this.numberOfFoundCards === this.cardImagesAmount &&
      this.numberOfSelectedDistractors === 0
    ) {
      setIsUserWon(true);
      setTimeout(() => {
        endGame(GameResult.Win);
      }, 1500);
    }
  }

  private prepareMosaicCards() {
    const imagesAndDistractors = [
      ...this.settings.distractors,
      ...this.settings.images,
    ];

    const shuffledCards = shuffle(imagesAndDistractors) as CardImage[];

    shuffledCards.forEach((image) => {
      const link = document.createElement('link');
      link.href = `${apiUrl}file/${image.imageId}`;

      link.rel = 'preload';
      link.as = 'image';
      document.head.appendChild(link);
      return () => {
        document.head.removeChild(link);
      };
    });

    shuffledCards.forEach((card) => {
      this.mosaicCards.set(uuidv4(), {
        imageId: card.imageId,
        isSelected: false,
        type: card.type,
        isCorrect: false,
      });
    });
  }
}
