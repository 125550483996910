import React from 'react';
import { useNavigate } from 'react-router';

import { routes } from 'config';
import { ModalType } from 'types';
import { useModal } from 'hooks';
import { Icon, Flex, Text, Button } from 'components/common';

import { ButtonContainer, StyledContainer } from './styles';

export const DisabledActivationLink: React.FC = () => {
  const navigate = useNavigate();
  const { showModal } = useModal();

  const goHome = () => {
    navigate(routes.root);
  };

  const openRegisterModal = () => {
    showModal({
      type: ModalType.Register,
      data: null,
    });
  };

  return (
    <StyledContainer>
      <Icon name="secondary-clock" />
      <Flex columnDirection gap="sm">
        <Text
          text="views.disabledActivationLink.heading"
          size="xl"
          weight="semibold"
        />
        <Text
          text="views.disabledActivationLink.description"
          size="md"
          weight="medium"
        />
      </Flex>
      <ButtonContainer>
        <Button
          text="button.registration"
          variant="primary"
          uppercase
          onClick={openRegisterModal}
        />
        <Button
          text="button.close"
          variant="outlined"
          uppercase
          onClick={goHome}
        />
      </ButtonContainer>
    </StyledContainer>
  );
};
