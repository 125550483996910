import styled from 'styled-components';
import { SizeType } from 'types';
import { Heading, Text } from '../Text';

interface ContainerProps {
  direction?: 'column' | 'row';
  size?: SizeType;
  showModal?: boolean;
}

interface ImageProps {
  size?: SizeType;
  isAchieved: boolean;
}

interface HeadingProps {
  align?: 'left' | 'center' | 'right';
}

export const StyledBadgeContainer = styled('div')<ContainerProps>(
  ({ direction, size, theme, showModal }) => `
  display: flex;
  flex-direction: ${direction || 'row'};
  align-items: center;
  gap: 15px;
  cursor: ${showModal ? 'pointer' : 'default'};
  max-width: ${
    direction === 'column'
      ? size
        ? theme.sizes[size]
        : theme.sizes.lg
      : '100%'
  }
`
);

export const StyledImage = styled('img')<ImageProps>(
  ({ size, theme, isAchieved }) => `
  width: ${size ? theme.sizes[size] : theme.sizes.lg};
  height: ${size ? theme.sizes[size] : theme.sizes.lg};
  border-radius: 50%;

  ${
    !isAchieved &&
    `
    filter: grayscale(100%);
  `
  }
  `
);

export const StyledBadgeHeading = styled(Heading)<HeadingProps>(
  ({ align }) => `
  width: 100%;
  text-align: ${align || 'center'};
`
);

export const StyledBadgeText = styled(Text)(
  () => `
  max-width: 280px;
`
);

export const StyledBadgeTextContainer = styled('div')<ContainerProps>(
  () => `
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 10px;
`
);

export const StyledImagePlaceholder = styled('div')<ImageProps>(
  ({ size, theme }) => `
  background-color: ${theme.colors.primaryBackground};
  width: ${size ? theme.sizes[size] : theme.sizes.lg};
  height: ${size ? theme.sizes[size] : theme.sizes.lg};
  border-radius: 50%;
`
);

export const StyledBadgeHeadingPlaceholder = styled('div')(
  ({ theme }) => `
  border-radius: 25px;
  background-color: ${theme.colors.primaryBackground};
  width: 100px;
  max-width: 90%;
  height: ${theme.spaces.md};
`
);

export const StyledBadgeTextPlaceholder = styled('div')(
  ({ theme }) => `
  border-radius: 25px;
  background-color: ${theme.colors.primaryBackground};
  max-width: 280px;
  width: 125px;
  max-width: 100%;
  height: ${theme.spaces.md};
`
);
