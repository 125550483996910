import React, { useState } from 'react';
import { useFormContext } from 'react-hook-form';

import { apiUrl } from 'config';
import { FileType } from 'cogamika-back/types';
import { Button, FileSelector, GridContainer } from 'components/common';

import { Content, MainContainer, Overlay, StyledVideo } from './styles';

interface Props {
  setIsShow: React.Dispatch<React.SetStateAction<boolean>>;
  name: string;
}

export const SelectVideo: React.FC<Props> = ({ setIsShow, name }) => {
  const { setValue } = useFormContext<any>();
  const [selectedFile, setSelectedFile] = useState(() => new Set<string>());
  const [fileName, setFileName] = useState<string>('');

  const handleFileSelect = (
    id: string,
    type?: FileType,
    originalName?: string
  ) => {
    if (selectedFile.has(id)) {
      setSelectedFile(new Set());
      setFileName('');
    } else {
      setSelectedFile(new Set([id]));
      setFileName(originalName || 'image');
    }
  };

  const save = () => {
    setValue(`${name}.id`, Array.from(selectedFile)[0]);
    setValue(`${name}.name`, fileName);
    setIsShow(false);
  };

  const hide = () => {
    setIsShow(false);
  };

  return (
    <Overlay onClick={hide} role="button" tabIndex={0}>
      <Content
        id="modal-content"
        onClick={(e) => e.stopPropagation()}
        role="button"
        tabIndex={0}
      >
        <MainContainer>
          <GridContainer size={[2]} gap="7xl">
            <FileSelector
              types={[FileType.Video]}
              refreshList={false}
              onSelectFile={handleFileSelect}
              selectedIds={selectedFile}
            />
            {selectedFile.size > 0 && (
              <StyledVideo
                controls
                src={`${apiUrl}file/${Array.from(selectedFile)[0]}`}
              ></StyledVideo>
            )}
          </GridContainer>
          <Button
            text="button.save"
            variant="primary"
            onClick={save}
            uppercase
          />
        </MainContainer>
      </Content>
    </Overlay>
  );
};
