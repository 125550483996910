export enum ModalType {
  Instruction = 'instruction',
  Info = 'info',
  Login = 'login',
  Register = 'register',
  RegisterConfirmation = 'register-confirmation',
  PaymentFinished = 'payment-finished',
  Confirmation = 'confirmation',
  DeleteFile = 'delete-file',
  BadgeInfo = 'badge-info',
  SessionUnavailableError = 'session-unavailable-error',
  SaveSessionError = 'save-session-error',
  SubscriptionExpiring = 'subscription-expiring',
}
export type ShowModalActionPayload =
  | InstructionModalPayload
  | InfoModalPayload
  | LoginModalPayload
  | RegisterModalPayload
  | RegisterConfirmationModalPayload
  | PaymentFinishedModalPayload
  | ConfirmationModalPayload
  | DeleteFilePayload
  | BadgeInfoModalPayload
  | SessionUnavailableErrorModalPayload
  | SaveSessionErrorModalPayload
  | SubscriptionExpiringPayload;

export type ModalState =
  | InitialModalState
  | InstructionModalState
  | InfoModalState
  | LoginModalState
  | RegisterModalState
  | RegisterConfirmationModalState
  | PaymentFinishedModalState
  | ConfirmationModalState
  | DeleteFileModalState
  | BadgeInfoModalState
  | SessionUnavailableErrorModalState
  | SaveSessionErrorModalState
  | SubscriptionExpiringModalState;

export interface BaseModal {
  isOpen: boolean;
}

export type ModalData =
  | InstructionModalData
  | InfoModalData
  | ConfirmationModalData
  | DeleteFileModalData
  | BadgeInfoModalData;

export interface InitialModalState extends BaseModal {
  type: ModalType | null;
  data: ModalData | null;
}

export interface InstructionModalData {
  instruction: string;
  instructionFileId: string | null;
}

export interface InstructionModalPayload {
  type: ModalType.Instruction;
  data: InstructionModalData;
}

export type InstructionModalState = BaseModal & InstructionModalPayload;

export interface InfoModalData {
  text: string;
}

export interface InfoModalPayload {
  type: ModalType.Info;
  data: InfoModalData;
}

export type InfoModalState = BaseModal & InfoModalPayload;

export interface LoginModalPayload {
  type: ModalType.Login;
  data: null;
}

export type LoginModalState = BaseModal & LoginModalPayload;

export interface RegisterModalPayload {
  type: ModalType.Register;
  data: null;
}

export type RegisterModalState = BaseModal & RegisterModalPayload;

export interface SessionUnavailableErrorModalPayload {
  type: ModalType.SessionUnavailableError;
  data: null;
}

export type SessionUnavailableErrorModalState = BaseModal &
  SessionUnavailableErrorModalPayload;

export interface SaveSessionErrorModalPayload {
  type: ModalType.SaveSessionError;
  data: null;
}

export type SaveSessionErrorModalState = BaseModal &
  SaveSessionErrorModalPayload;

export interface RegisterConfirmationModalPayload {
  type: ModalType.RegisterConfirmation;
  data: null;
}

export type RegisterConfirmationModalState = BaseModal &
  RegisterConfirmationModalPayload;

export interface PaymentFinishedModalPayload {
  type: ModalType.PaymentFinished;
  data: null;
}

export type PaymentFinishedModalState = BaseModal & PaymentFinishedModalPayload;

export type BadgeInfoModalData = {
  imgId: string;
  description: string;
  name: string;
};

export interface BadgeInfoModalPayload {
  type: ModalType.BadgeInfo;
  data: BadgeInfoModalData;
}

export type BadgeInfoModalState = BaseModal & BadgeInfoModalPayload;

export type ConfirmationModalData = {
  action: {
    confirm: () => void;
    text: string;
    noTranslate?: boolean;
  };
  header?: string;
  text?: string;
  noTranslate?: boolean;
};

export interface ConfirmationModalPayload {
  type: ModalType.Confirmation;
  data: ConfirmationModalData;
}

export type ConfirmationModalState = BaseModal & ConfirmationModalPayload;

export interface DeleteFileModalData {
  fileId: string;
}

export interface DeleteFilePayload {
  type: ModalType.DeleteFile;
  data: DeleteFileModalData;
}

export type DeleteFileModalState = BaseModal & DeleteFilePayload;

export interface SubscriptionExpiringPayload {
  type: ModalType.SubscriptionExpiring;
  data: null;
}

export type SubscriptionExpiringModalState = BaseModal &
  SubscriptionExpiringPayload;
