import { LazyLoadImage } from 'react-lazy-load-image-component';
import 'react-lazy-load-image-component/src/effects/opacity.css';
import styled from 'styled-components';

import { ltMedia } from 'config';

interface Props {
  isWin: boolean;
  opacity: number;
  isCorrect: boolean;
}

export const StyledCard = styled.div<Props>(
  ({ theme: { colors, spaces, radius }, isWin, opacity, isCorrect }) => `
  width: 600px;
  border: 2px solid ${colors.tertiaryBorder};
  cursor: move;
  padding: ${spaces.base} ${spaces.lg};
  border-radius: ${radius.md};
  border-color: ${(isWin || isCorrect) && colors.success};
  color: ${(isWin || isCorrect) && colors.success};
  opacity: ${opacity};

  ${ltMedia('md')} {
    width: 450px;
  }
  `
);

export const StyledImage = styled(LazyLoadImage)<Props>(
  ({ theme: { colors, radius }, isWin, opacity, isCorrect }) => `
  min-width: 500px;
  width: 100%;
  max-height: 120px;
  height: 100%;
  border: 2px solid ${colors.tertiaryBorder};
  cursor: move;
  border-radius: ${radius.md};
  border-color: ${(isWin || isCorrect) && colors.success};
  opacity: ${opacity};
  `
);
