import styled from 'styled-components';

export const StyledContainer = styled.div(
  ({ theme: { spaces, colors } }) => `
  align-self: center;
  margin: 0 auto;
  justify-content: center;
  display: flex;
  flex-direction: column;
  gap: ${spaces['3xl']};
  width: 100%;
  max-width: 700px;
  background-color: ${colors.light};
  padding: ${spaces['7xl']} ${spaces['6xl']};
`
);

export const ButtonContainer = styled.div(
  ({ theme: { spaces } }) => `
  display: flex;
  gap: ${spaces.lg};
  `
);
