import { Language } from 'cogamika-back/types';
import { AppConfig } from 'config';

export const getRedirectLinkToAboutUs = (lang?: Language) => {
  switch (lang) {
    case Language.pl:
      return AppConfig.ABOUT_US_REDIRECT;
    case Language.en:
      return `${AppConfig.ABOUT_US_REDIRECT}/en`;
    case Language.sv:
      return `${AppConfig.ABOUT_US_REDIRECT}/en`;
    default:
      return AppConfig.ABOUT_US_REDIRECT;
  }
};

export const replaceParams = (
  route: string,
  params: Record<string, any>
): string => {
  let routeWithParams = route;
  for (const [key, value] of Object.entries(params)) {
    routeWithParams = routeWithParams.replace(`:${key}`, value);
  }
  return routeWithParams;
};
