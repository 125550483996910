import { UserGameReq } from 'cogamika-back/types';
import { differenceInSeconds } from 'date-fns';

export class Game {
  private data: UserGameReq;
  private isStarted: boolean;
  private startTime: Date;

  constructor(level: number, gameId: string, domainId: string) {
    this.data = {
      endDate: new Date(),
      gameId,
      correctAnswers: 0,
      incorrectAnswers: 0,
      timeSec: 0,
      domainId,
      level,
      win: false,
    };
    this.isStarted = false;
    this.startTime = new Date();
  }

  public startGame(): void {
    this.startTime = new Date();
    this.isStarted = true;
  }

  public get result() {
    return {
      correctAnswers: this.data.correctAnswers,
      incorrectAnswers: this.data.incorrectAnswers,
    };
  }

  public get gameStartTime() {
    return this.startTime;
  }

  public get isGameStarted() {
    return this.isStarted;
  }

  public get gameData(): UserGameReq {
    return this.data;
  }

  public endGame(win: boolean): void {
    const endDate = new Date();
    this.data.endDate = endDate;
    const duration = differenceInSeconds(endDate, this.startTime);

    if (duration > 360) {
      this.data.timeSec = 360;
    } else {
      this.data.timeSec = duration;
    }

    this.data.win = win;
  }

  public incrementCorrectAnswers(): void {
    this.data.correctAnswers++;
  }

  public decrementCorrectAnswers(): void {
    this.data.correctAnswers--;
  }

  public incrementIncorrectAnswers(): void {
    this.data.incorrectAnswers++;
  }
}
