import React from 'react';

import { Button, Flex, Heading, Text, Warning } from 'components/common';

import { Container } from './styles';

interface Props {
  level: number;
  errorMessage?: string;
  additionalText?: string;
  goBack?: () => void;
}

export const LevelHeader: React.FC<Props> = ({
  level,
  goBack,
  errorMessage,
  additionalText,
}) => {
  return (
    <Container>
      <Flex gap="sm">
        <Heading
          text="views.newGame.levelHeader"
          translationArgs={{ level }}
          size="xl"
          weight="semibold"
          as="h1"
        />
        {additionalText && (
          <Text text={additionalText} size="xl" weight="normal" />
        )}
      </Flex>
      <Flex columnDirection gap="sm" alignItems="flex-end">
        <Flex gap="md">
          <Button
            text="button.saveLevel"
            variant="secondary"
            uppercase
            type="submit"
          />
          {goBack && (
            <Button
              text="button.back"
              variant="primary"
              uppercase
              type="button"
              onClick={goBack}
            />
          )}
        </Flex>
        <Warning isError={!!errorMessage} text={errorMessage} />
      </Flex>
    </Container>
  );
};
