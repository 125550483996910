import React from 'react';

import { Text, Flex, Button, FlexAlignCenter } from 'components/common';
import { useAppSelector, useModal } from 'hooks';
import { useNavigate } from 'react-router-dom';
import { routes } from 'config';
import { __ } from 'utils/i18n';
import { getDayToEnd } from 'utils';

import { MainContainer } from './styles';

export const SubscriptionExpiring: React.FC = () => {
  const navigate = useNavigate();
  const { user } = useAppSelector((state) => state.user);
  const { closeModal } = useModal();

  const renewSubscription = () => {
    closeModal();
    navigate(routes.subscriptions);
  };

  const daysToEnd = user?.paidTo
    ? getDayToEnd(new Date(user.paidTo), user.lang)
    : '';

  return (
    <MainContainer>
      <Flex columnDirection gap="xl">
        <Text
          text={`${__('modals.subscription.expiring')}${daysToEnd}`}
          size="lg"
          weight="semibold"
          noTranslate
        />
        <Text text="modals.subscription.remind" size="sm" />
        <FlexAlignCenter gap="md" fullWidth>
          <Button
            type="button"
            variant="outlined"
            onClick={closeModal}
            text="button.close"
          />
          <Button
            fullWidth
            type="button"
            variant="secondary"
            onClick={renewSubscription}
            text="button.renewSubscription"
          />
        </FlexAlignCenter>
      </Flex>
    </MainContainer>
  );
};
