import React, { ButtonHTMLAttributes } from 'react';

import { FontSizeType, IconName } from 'types';
import { Icon, TranslateText } from 'components/common';

import { StyledFooterLinkButton } from './styles';

interface Props extends ButtonHTMLAttributes<HTMLButtonElement> {
  text: string;
  noTranslate?: boolean;
  uppercase?: boolean;
  underline?: boolean;
  iconName?: IconName;
  size?: FontSizeType;
  translationArgs?: Record<string, string | number>;
  dataCy?: string;
}

export const FooterLinkButton: React.FC<Props> = ({
  text,
  noTranslate,
  uppercase,
  underline,
  onClick,
  iconName,
  dataCy,
  type,
  translationArgs,
  size,
}) => {
  if (iconName) {
    return (
      <StyledFooterLinkButton
        size={size}
        onClick={onClick}
        uppercase={uppercase}
        underline={underline}
        type={type}
      >
        <Icon name={iconName} />
        <TranslateText
          text={text}
          noTranslate={noTranslate}
          translationArgs={translationArgs}
        />
      </StyledFooterLinkButton>
    );
  }

  return (
    <StyledFooterLinkButton
      size={size}
      onClick={onClick}
      uppercase={uppercase}
      underline={underline}
      data-cy={dataCy}
      type={type}
    >
      <TranslateText
        text={text}
        noTranslate={noTranslate}
        translationArgs={translationArgs}
      />
    </StyledFooterLinkButton>
  );
};
