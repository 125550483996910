import styled from 'styled-components';

export const Container = styled.div(
  ({ theme: { spaces } }) => `
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: ${spaces['6xl']};
`
);

export const Line = styled.div(
  ({ theme: { colors, radius } }) => `
  width: 23px;
  height: 2px;
  background-color: ${colors.teritaryGray}; 
  border-radius: ${radius.md};
  margin: 0 auto;
`
);
