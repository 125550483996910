import { MarginType, Spaces } from 'types';

export const getMargin = (margin: MarginType, spaces: Spaces) => {
  const marginTop = margin[0] ? spaces[margin[0]] : '0';
  const marginRight = margin[1] ? spaces[margin[1]] : '0';
  const marginBottom = margin[2] ? spaces[margin[2]] : '0';
  const marginLeft = margin[3] ? spaces[margin[3]] : '0';

  return `
    margin-top: ${marginTop};
    margin-right: ${marginRight};
    margin-bottom: ${marginBottom};
    margin-left: ${marginLeft};
  `;
};
