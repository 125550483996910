import { FC } from 'react';
import { StyledSelect, Option } from './styles';
import { ChartViewMode } from 'cogamika-back/types';

interface OptionType {
  label: string;
  value: string;
}

interface ViewModeSelectProps {
  options: OptionType[];
  onChange: (newValue: ChartViewMode) => void;
}

export const ViewModeSelect: FC<ViewModeSelectProps> = ({
  onChange,
  options,
}) => {
  return (
    <StyledSelect
      name="chartViewMode"
      onChange={(e: React.ChangeEvent<HTMLSelectElement>) =>
        onChange(e.currentTarget.value as ChartViewMode)
      }
    >
      {options.map(({ value, label }) => (
        <Option value={value} key={value}>
          {label}
        </Option>
      ))}
    </StyledSelect>
  );
};
