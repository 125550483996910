import React from 'react';

import { superAdminRoutes } from 'config';

const SuperAdminSettings = React.lazy(
  () => import('views/SuperAdmin/Settings')
);
const AdminTestGame = React.lazy(() => import('views/SuperAdmin/GameTest'));
const Files = React.lazy(() => import('views/SuperAdmin/Files'));
const NewGame = React.lazy(() => import('views/SuperAdmin/NewGame'));
const DashboardWithGames = React.lazy(
  () => import('views/SuperAdmin/Dashboard')
);
const EditGame = React.lazy(() => import('views/SuperAdmin/EditGame'));
const EditFreeGames = React.lazy(
  () => import('views/SuperAdmin/EditFreeGames')
);

export const SuperAdminRoutes = [
  {
    path: superAdminRoutes.dashboard,
    element: <DashboardWithGames />,
  },
  {
    path: superAdminRoutes.settings,
    element: <SuperAdminSettings />,
  },
  {
    path: superAdminRoutes.gameTest,
    element: <AdminTestGame />,
  },
  {
    path: superAdminRoutes.files,
    element: <Files />,
  },
  {
    path: superAdminRoutes.newGame,
    element: <NewGame />,
  },
  {
    path: superAdminRoutes.editGame,
    element: <EditGame />,
  },
  {
    path: superAdminRoutes.editFreeGames,
    element: <EditFreeGames />,
  },
];
