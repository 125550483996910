import React, { useContext, useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';

import { apiUrl, descriptionSchema } from 'config';
import { FileType } from 'cogamika-back/types';
import { DescriptionFormType, GameFormSteps } from 'types';
import { GameFormContext } from 'context';
import { Button, FileSelector, Flex, Text, Warning } from 'components/common';
import { Form, TextArea } from 'components/forms';

import {
  ButtonContainer,
  FilesWrapper,
  FormContainer,
  StyledImage,
  StyledInstructionForm,
} from './styles';

export const DescriptionForm: React.FC = () => {
  const { setDetailsForm, setStep, detailsForm } = useContext(GameFormContext);
  const [selectedFile, setSelectedFile] = useState(() => new Set<string>());

  const methods = useForm<DescriptionFormType>({
    mode: 'onSubmit',
    resolver: yupResolver(descriptionSchema),
  });
  const { isSubmitting, isValid } = methods.formState;
  const isButtonDisabled = isSubmitting || !isValid;

  const handleFileSelect = (id: string) => {
    if (selectedFile.has(id)) {
      setSelectedFile(new Set());
      methods.setValue('miniatureFileId', '');
    } else {
      methods.setValue('miniatureFileId', id);
      setSelectedFile(new Set([id]));
    }

    methods.trigger('miniatureFileId');
  };

  const submit = (data: DescriptionFormType) => {
    setDetailsForm((prev) => ({ ...prev, description: data }));
    setStep(GameFormSteps.Instruction);
  };

  const goBack = () => {
    setStep(GameFormSteps.Details);
  };

  useEffect(() => {
    methods.reset({
      description: detailsForm?.description?.description,
      miniatureFileId: detailsForm?.description?.miniatureFileId,
    });
    if (detailsForm?.description?.miniatureFileId) {
      setSelectedFile(new Set([detailsForm?.description?.miniatureFileId]));
    }
  }, [methods, detailsForm]);

  return (
    <StyledInstructionForm>
      <Text text="views.newGame.description" size="xl" weight="semibold" />
      <FormContainer>
        <Form submitHandler={submit} methods={methods}>
          <Flex columnDirection gap="lg">
            <TextArea
              name="description"
              placeholder="empty"
              label="labels.gameDescription"
            />
            <FilesWrapper>
              <FileSelector
                types={[FileType.Image]}
                refreshList={false}
                onSelectFile={handleFileSelect}
                selectedIds={selectedFile}
              />
              {selectedFile.size > 0 && (
                <StyledImage
                  key={Array.from(selectedFile)[0]}
                  src={`${apiUrl}file/${Array.from(selectedFile)[0]}`}
                  width="280"
                />
              )}
            </FilesWrapper>
            <ButtonContainer>
              <Warning
                isError={!!methods.formState.errors.miniatureFileId}
                text={methods.formState.errors?.miniatureFileId?.message}
              />
              <Button
                text="button.back"
                variant="primary"
                type="button"
                uppercase
                onClick={goBack}
              />
              <Button
                text="button.next"
                variant="secondary"
                type="submit"
                disabled={isButtonDisabled}
                uppercase
              />
            </ButtonContainer>
          </Flex>
        </Form>
      </FormContainer>
    </StyledInstructionForm>
  );
};
