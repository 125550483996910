import { FC, ReactNode, useEffect, useState } from 'react';
import { Accordion } from '../AccordionItem';
import {
  AccordionWrapperContainer,
  AccordionWrapperContent,
  AccordionWrapperHeader,
} from './styles';
import { Heading } from 'components/common';

interface AccordionItem {
  content: ReactNode;
  header?: string;
  noTranslate?: boolean;
}

interface AccordionWrapperProps {
  items: AccordionItem[];
  header?: string;
  noTranslate?: boolean;
  onChangeCurrentItemOpen?: (currentOpen: number | undefined) => void;
}

export const AccordionWrapper: FC<AccordionWrapperProps> = ({
  items,
  header,
  noTranslate = false,
  onChangeCurrentItemOpen,
}) => {
  const [currentOpen, setCurrentOpen] = useState<number>();

  const handleOnClickItem = (index: number) =>
    setCurrentOpen((prev) => (prev === index ? undefined : index));

  const renderItems = items.map(({ content, header, noTranslate }, index) => (
    <Accordion
      key={`${header || '' + index}`}
      isOpen={currentOpen === index}
      onClickHeader={() => handleOnClickItem(index)}
      noTranslate={noTranslate}
      header={header}
    >
      {content}
    </Accordion>
  ));

  useEffect(() => {
    if (onChangeCurrentItemOpen) {
      onChangeCurrentItemOpen(currentOpen);
    }
  }, [currentOpen, onChangeCurrentItemOpen]);

  return (
    <AccordionWrapperContainer>
      {header && (
        <AccordionWrapperHeader>
          <Heading size="md" text={header} noTranslate={noTranslate} />
        </AccordionWrapperHeader>
      )}
      <AccordionWrapperContent>{renderItems}</AccordionWrapperContent>
    </AccordionWrapperContainer>
  );
};
