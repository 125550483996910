import { Dispatch, FC, SetStateAction, useEffect } from 'react';
import { useForm } from 'react-hook-form';
import { Button } from 'components/common';
import { FlexAlignCenter, FlexCenter } from 'components/common/Flex';
import { Form, TextInput } from 'components/forms';
import { FaqAddEditQuestionSchema } from 'types';
import { addEditQuestionSchema } from 'config';
import { yupResolver } from '@hookform/resolvers/yup';
import { useFaq } from 'hooks';

interface FaqQuestionEditFormProps {
  setQuestionEditing: Dispatch<SetStateAction<boolean>>;
  questionId: string;
  question: string;
  answer: string;
  topicId: string;
}

export const FaqQuestionEditForm: FC<FaqQuestionEditFormProps> = ({
  setQuestionEditing,
  question,
  questionId,
  answer,
  topicId,
}) => {
  const { addEditFaqQuestion } = useFaq();

  const editQuestionMethods = useForm<FaqAddEditQuestionSchema>({
    mode: 'onSubmit',
    resolver: yupResolver(addEditQuestionSchema),
  });

  const handleOnEditTopic = (data: FaqAddEditQuestionSchema) => {
    addEditFaqQuestion({ ...data, topicId }, questionId);
    setQuestionEditing(false);
  };

  useEffect(() => {
    const { setValue } = editQuestionMethods;
    setValue('question', question);
    setValue('answer', answer);
    setQuestionEditing(true);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <Form
      submitHandler={handleOnEditTopic}
      methods={editQuestionMethods}
      fullWidth
    >
      <FlexAlignCenter gap="md" columnDirection>
        <TextInput name="question" placeholder="question" fullWidth required />
        <TextInput name="answer" placeholder="answer" fullWidth required />
        <FlexCenter gap="md">
          <Button
            variant="secondary"
            text="button.editQuestion"
            type="submit"
          />
          <Button
            variant="outlined"
            text="button.cancel"
            type="button"
            onClick={() => setQuestionEditing(false)}
          />
        </FlexCenter>
      </FlexAlignCenter>
    </Form>
  );
};
