import styled from 'styled-components';

export const ChronologyFormContainer = styled.div`
  width: 100%;
`;

export const FormContainer = styled.div`
  display: flex;
  flex-direction: column;
`;

export const AddInputButton = styled.button(
  ({ theme: { colors, radius, spaces, fontSizes, fontWeight } }) => `
  background-color: ${colors.lightGray};
  border-radius: ${radius.md}; 
  padding: ${spaces.md} ${spaces.lg};
  font-size: ${fontSizes.base};
  border: none;
  width: 100%;
  display: flex;
  justify-content: space-between;
  font-weight: ${fontWeight.medium};
  cursor: pointer;

  &:hover {
    background-color: ${colors.gray};
  }
`
);

export const ListContainer = styled.div(
  ({ theme: { spaces } }) => `
  margin-top: ${spaces.sm};
display: flex;
flex-direction: column;
gap: ${spaces.sm};
`
);

export const ButtonContainer = styled.div(
  ({ theme: { spaces } }) => `
  display: flex;
  margin-top: ${spaces.sm};  
  gap: ${spaces.sm};
  `
);
