import { FC } from 'react';
import { useForm } from 'react-hook-form';
import { Button } from 'components/common';
import { FlexAlignCenter } from 'components/common/Flex';
import { Form, TextInput } from 'components/forms';
import { FaqAddEditQuestionSchema } from 'types';
import { yupResolver } from '@hookform/resolvers/yup';
import { addEditQuestionSchema } from 'config';
import { useFaq } from 'hooks';

interface FaqQuestionAddFormProps {
  topicId: string;
}

export const FaqQuestionAddForm: FC<FaqQuestionAddFormProps> = ({
  topicId,
}) => {
  const { addEditFaqQuestion } = useFaq();

  const addQuestionMethods = useForm<FaqAddEditQuestionSchema>({
    mode: 'onSubmit',
    resolver: yupResolver(addEditQuestionSchema),
  });

  return (
    <Form
      submitHandler={(data) => addEditFaqQuestion({ ...data, topicId })}
      methods={addQuestionMethods}
      fullWidth
    >
      <FlexAlignCenter gap="md" columnDirection>
        <TextInput name="question" placeholder="question" fullWidth required />
        <TextInput name="answer" placeholder="answer" fullWidth required />
        <Button variant="secondary" text="button.addQuestion" type="submit" />
      </FlexAlignCenter>
    </Form>
  );
};
