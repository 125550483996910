import React, { useContext, useEffect, useState } from 'react';
import { yupResolver } from '@hookform/resolvers/yup';
import { useFieldArray, useForm } from 'react-hook-form';

import { ChronologyGameSchema } from 'config';
import { LevelSettings, ElementType } from 'cogamika-back/types';
import {
  ChronologyGameFormSteps,
  ChronologyGameFormType,
  ChronologyGameSettings,
} from 'types';
import { GameFormContext } from 'context';
import { Icon, Text, Button, TranslateText } from 'components/common';
import { Form, LevelHeader } from 'components/forms';

import { ChronologyItem } from '../ChronologItem';
import { SelectChronologyImage } from '../SelectChronologyFile';
import {
  FormContainer,
  ListContainer,
  AddInputButton,
  ButtonContainer,
  ChronologyFormContainer,
} from './styles';

interface Props {
  setStage: React.Dispatch<React.SetStateAction<ChronologyGameFormSteps>>;
  level: number;
}

export const ChronologyForm: React.FC<Props> = ({ level, setStage }) => {
  const [formType, setFormType] = useState<ElementType>();
  const [showFileModal, setShowFileModal] = useState(false);
  const { setGameSettings, gameSettings } = useContext(GameFormContext);
  const defaultSettings = gameSettings?.chronologyLevelSettings?.levels.find(
    (item) => item.level === level
  );
  const methods = useForm<ChronologyGameFormType>({
    mode: 'onSubmit',
    defaultValues: { elements: defaultSettings?.settings?.elements },
    resolver: yupResolver(ChronologyGameSchema),
  });
  const { fields, append, remove } = useFieldArray({
    control: methods.control,
    name: 'elements',
  });

  const setLevelSettings = (
    level: number,
    data: ChronologyGameFormType,
    formType?: ElementType
  ) => {
    const newLevelSettings: LevelSettings<ChronologyGameSettings> = {
      level,
      settings: {
        elementsType: formType as ElementType,
        elements: data.elements,
      },
    };

    const levelsSettings = gameSettings?.chronologyLevelSettings?.levels;

    if (levelsSettings && levelsSettings.length > 0) {
      const findIndex = levelsSettings.findIndex(
        (item) => item.level === level
      );

      if (findIndex === -1) {
        return [...levelsSettings, newLevelSettings];
      } else {
        return [
          ...levelsSettings.filter((item) => item.level !== level),
          newLevelSettings,
        ];
      }
    } else {
      return [newLevelSettings];
    }
  };

  const submit = (data: ChronologyGameFormType) => {
    const updatedLevelSettings = setLevelSettings(level, data, formType);

    setGameSettings({
      chronologyLevelSettings: {
        levels: updatedLevelSettings,
      },
    });
    setStage(ChronologyGameFormSteps.Levels);
  };

  const goBack = () => {
    setStage(ChronologyGameFormSteps.Levels);
  };

  const addInput = () => {
    methods.clearErrors();
    setFormType(ElementType.Text);
    append({ description: '' }, { shouldFocus: true });
  };

  const addFile = () => {
    setFormType(ElementType.Image);
    setShowFileModal(true);
  };

  useEffect(() => {
    if (fields.length === 0) {
      setFormType(undefined);
    }
  }, [fields]);

  useEffect(() => {
    setFormType(defaultSettings?.settings.elementsType);
  }, [defaultSettings?.settings.elementsType]);

  return (
    <ChronologyFormContainer>
      <Form submitHandler={submit} methods={methods}>
        <LevelHeader
          goBack={goBack}
          level={level}
          errorMessage={methods.formState.errors?.elements?.message}
        />
        <FormContainer>
          <Text
            text="views.newGame.chronology.description"
            translationArgs={{ level }}
            size="base"
          />
          <ListContainer>
            {fields.map((field, index) => (
              <ChronologyItem
                field={field}
                key={field.id}
                formType={formType}
                chronologyIndex={index}
                remove={remove}
              />
            ))}
          </ListContainer>
          <ButtonContainer>
            {formType !== ElementType.Image && (
              <AddInputButton onClick={addInput} type="button">
                <TranslateText text="button.addElement" />
                <Icon name="plus" />
              </AddInputButton>
            )}
            {formType !== ElementType.Text && (
              <Button
                type="button"
                variant="outlined"
                text="button.addFile"
                fullWidth
                uppercase
                onClick={addFile}
              />
            )}
          </ButtonContainer>
        </FormContainer>
        {showFileModal && (
          <SelectChronologyImage setIsShow={setShowFileModal} append={append} />
        )}
      </Form>
    </ChronologyFormContainer>
  );
};
