import { FC } from 'react';

import { Language } from 'cogamika-back/types';
import { useLang } from 'hooks';

import { StyledSelect, Option } from './styles';

interface OptionType {
  label: string;
  value: string;
}

interface ChangeLangSelectProps {
  options: OptionType[];
}

export const ChangeLangSelect: FC<ChangeLangSelectProps> = ({ options }) => {
  const { changeLanguage, lang } = useLang();

  return (
    <StyledSelect
      name="changeLang"
      onChange={(e: React.ChangeEvent<HTMLSelectElement>) =>
        changeLanguage(e.currentTarget.value as Language)
      }
      value={lang}
    >
      {options.map(({ value, label }) => (
        <Option value={value} key={value}>
          {label}
        </Option>
      ))}
    </StyledSelect>
  );
};
