import React from 'react';

import { startGame } from 'slices';
import { InstructionModalData } from 'types';
import { useModal, useAppDispatch } from 'hooks';
import {
  Flex,
  Text,
  Button,
  Heading,
  GridItem,
  GridContainer,
} from 'components/common';

import { MainContainer } from './style';

export const Instruction: React.FC = () => {
  const dispatch = useAppDispatch();
  const { getData, closeModal } = useModal();
  const data = getData<InstructionModalData>();

  const startGameHandler = () => {
    dispatch(startGame());
    closeModal();
  };

  return (
    <MainContainer>
      <GridContainer size={[2]} gap="7xl">
        <GridItem position={[1]}>
          <div
            style={{
              height: '264px',
              width: '100%',
              background: '#0F16181A',
            }}
          />
        </GridItem>
        <GridItem position={[2]}>
          <Heading text="Lorem ipsum" size="lg" color="primary" />
          <Text
            size="md"
            margin={['base', '', '2xl', '']}
            text={data.instruction}
          />
          <Flex gap="lg">
            <Button variant="primary" text="START" onClick={startGameHandler} />
            <Button variant="outlined" text="ANULUJ" onClick={closeModal} />
          </Flex>
        </GridItem>
      </GridContainer>
    </MainContainer>
  );
};
