import React from 'react';

import { Text } from 'components/common';
import { LevelHeader } from 'components/forms';
import { MosaicImageType } from 'cogamika-back/types';
import { useFormContext } from 'react-hook-form';
import { MemoryGameFormType } from 'types';

interface Props {
  level: number;
  type: MosaicImageType;
}

export const MosaicHeader: React.FC<Props> = ({ level, type }) => {
  const { formState } = useFormContext<MemoryGameFormType>();
  const getDescription = (type: MosaicImageType) => {
    switch (type) {
      case MosaicImageType.Card:
        return 'views.newGame.mosaic.cardDescription';
      case MosaicImageType.Distractor:
        return 'views.newGame.mosaic.distractorDescription';
    }
  };

  const getTitle = (type: MosaicImageType) => {
    switch (type) {
      case MosaicImageType.Card:
        return 'views.newGame.mosaic.cardTitle';
      case MosaicImageType.Distractor:
        return 'views.newGame.mosaic.distractorTitle';
    }
  };

  return (
    <>
      <LevelHeader
        level={level}
        additionalText={getTitle(type)}
        errorMessage={formState.errors?.imagesIds?.message}
      />
      <Text text={getDescription(type)} size="base" />
    </>
  );
};
