import React, { useEffect, useState } from 'react';

import { AppConfig } from 'config';
import { GameMode, GameResult, GameStage } from 'types';
import { Movie } from 'games';
import { useGameController } from 'hooks';
import { Instruction, Result } from 'components/games';

import { Game } from './Game';
import { MovieContainer } from './styles';

interface Props {
  movie: Movie;
  instruction: string;
  instructionFileId: string | null;
  gameMode: GameMode;
}

export const MovieGame: React.FC<Props> = ({
  movie,
  instruction,
  instructionFileId,
  gameMode,
}) => {
  const [intervalId, setIntervalId] = useState<NodeJS.Timer>();
  const [stage, setStage] = useState<GameStage>(GameStage.Instruction);

  const {
    nextGame,
    saveGameResult,
    runDomainTimer,
    domainGameTimer,
    stopDomainAfterGameTimeout: domainGameTimeout,
    saveSession,
    isSessionEnd,
  } = useGameController();

  const startGame = () => {
    setStage(GameStage.Game);
  };

  const handleOnNextGame = () => {
    if (nextGame()) {
      setStage(GameStage.Instruction);
    } else {
      saveSession(gameMode);
      clearInterval(intervalId);
    }
  };

  const endGame = (result?: GameResult) => {
    movie.endMovieGame(result);

    saveGameResult(movie.gameData, gameMode);
    clearInterval(intervalId);
    setStage(GameStage.Result);
  };

  useEffect(() => {
    if (stage === GameStage.Game) {
      setIntervalId(runDomainTimer());
    }
    return () => {
      clearInterval(intervalId);
    };

    // eslint-disable-next-line
  }, [stage]);

  useEffect(() => {
    if (domainGameTimer > AppConfig.MAX_GAME_TIME) {
      endGame(GameResult.Lose);
      domainGameTimeout();
    }

    // eslint-disable-next-line
  }, [domainGameTimer]);

  const getStage = (stage: GameStage) => {
    switch (stage) {
      case GameStage.Instruction:
        return (
          <Instruction
            instruction={instruction}
            instructionFileId={instructionFileId}
            startGame={startGame}
          />
        );
      case GameStage.Game:
        return <Game movie={movie} endGame={endGame} />;
      case GameStage.Result:
        return (
          <Result
            action={handleOnNextGame}
            isSessionEnd={isSessionEnd}
            gameMode={gameMode}
          />
        );
      default:
        return null;
    }
  };
  return <MovieContainer>{getStage(stage)}</MovieContainer>;
};
