import React from 'react';

import { GameType, GameResourcesRes } from 'cogamika-back/types';
import { Memory, Survey, Collection, Chronology, Movie, Mosaic } from 'games';
import { MemoryGame, SurveyGame, CollectionGame } from 'components/games';
import { GameMode } from 'types';
import { ChronologyGame } from '../ChronologyGame';
import { MovieGame } from '../MovieGame';
import { MosaicGame } from '../MosaicGame';

interface Props {
  gameSettings: GameResourcesRes;
  gameMode?: GameMode;
}

export const Game: React.FC<Props> = ({
  gameSettings,
  gameMode = GameMode.Session,
}) => {
  const { type, level, id, settings, instruction, instructionFileId, domain } =
    gameSettings;

  if (!domain?.id) return <></>;

  switch (type) {
    case GameType.Memory:
      return (
        <MemoryGame
          memory={new Memory(level, id, domain.id, settings)}
          instruction={instruction}
          instructionFileId={instructionFileId || ''}
          gameMode={gameMode}
        />
      );
    case GameType.Survey:
      return (
        <SurveyGame
          survey={new Survey(level, id, domain.id, settings)}
          instruction={instruction}
          instructionFileId={instructionFileId || ''}
          gameMode={gameMode}
        />
      );
    case GameType.Collection:
      return (
        <CollectionGame
          collection={new Collection(level, id, domain.id, settings)}
          instruction={instruction}
          instructionFileId={instructionFileId || ''}
          gameMode={gameMode}
        />
      );
    case GameType.Chronology:
      return (
        <ChronologyGame
          chronology={new Chronology(level, id, domain.id, settings)}
          instruction={instruction}
          instructionFileId={instructionFileId}
          gameMode={gameMode}
        />
      );
    case GameType.Movie:
      return (
        <MovieGame
          movie={new Movie(level, id, domain.id, settings)}
          instruction={instruction}
          instructionFileId={instructionFileId}
          gameMode={gameMode}
        />
      );
    case GameType.Mosaic:
      return (
        <MosaicGame
          mosaic={new Mosaic(level, id, domain.id, settings)}
          instruction={instruction}
          instructionFileId={instructionFileId}
          gameMode={gameMode}
        />
      );
    default:
      return <></>;
  }
};
