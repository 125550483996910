import { useForm } from 'react-hook-form';
import React, { useContext } from 'react';

import {
  LevelSettings,
  MosaicImageType,
  MosaicGameLevelSettingsReq,
} from 'cogamika-back/types';
import { MemoryGameFormType, MosaicGameFormSteps } from 'types';
import { GameFormContext } from 'context';
import { Form } from 'components/forms';
import { Button } from 'components/common';

import { Files } from './Files';
import { MosaicHeader } from './MosaicHeader';
import { FormContainer, ButtonContainer, MemoryGameContainer } from './styles';
import { getMosaicDefaultValues } from 'utils';

interface Props {
  setStage: React.Dispatch<React.SetStateAction<MosaicGameFormSteps>>;
  level: number;
  type: MosaicImageType;
}

export const MosaicGameForm: React.FC<Props> = ({ level, type, setStage }) => {
  const { setGameSettings, gameSettings } = useContext(GameFormContext);

  const methods = useForm<MemoryGameFormType>({
    mode: 'onSubmit',
    defaultValues: {
      imagesIds: getMosaicDefaultValues(
        type,
        level,
        gameSettings?.mosaicLevelSettings
      ),
    },
  });
  const { isSubmitting, isValid } = methods.formState;
  const isButtonDisabled = isSubmitting || !isValid;

  const setLevelSettings = (
    level: number,
    data: MemoryGameFormType,
    type?: MosaicImageType
  ) => {
    const newLevelSettings: LevelSettings<MosaicGameLevelSettingsReq> = {
      level,
      settings: {
        images: [],
        distractors: [],
      },
    };

    if (type === MosaicImageType.Card) {
      newLevelSettings.settings.images = data.imagesIds.map((image) => ({
        imageId: image,
        type: MosaicImageType.Card,
      }));
      newLevelSettings.settings.distractors =
        gameSettings?.mosaicLevelSettings?.levels.find(
          (item) => item.level === level
        )?.settings.distractors || [];
    } else {
      newLevelSettings.settings.images =
        gameSettings?.mosaicLevelSettings?.levels.find(
          (item) => item.level === level
        )?.settings.images || [];
      newLevelSettings.settings.distractors = data.imagesIds.map((image) => ({
        imageId: image,
        type: MosaicImageType.Distractor,
      }));
    }

    const levelsSettings = gameSettings?.mosaicLevelSettings?.levels;

    if (levelsSettings && levelsSettings.length > 0) {
      const findIndex = levelsSettings.findIndex(
        (item) => item.level === level
      );

      if (findIndex === -1) {
        return [...levelsSettings, newLevelSettings];
      } else {
        return [
          ...levelsSettings.filter((item) => item.level !== level),
          newLevelSettings,
        ];
      }
    } else {
      return [newLevelSettings];
    }
  };

  const submit = (data: MemoryGameFormType) => {
    const updatedLevelSettings = setLevelSettings(level, data, type);

    setGameSettings({
      mosaicLevelSettings: {
        levels: updatedLevelSettings,
      },
    });
    setStage(MosaicGameFormSteps.Levels);
  };

  const goNext = (data: MemoryGameFormType) => {
    const updatedLevelSettings = setLevelSettings(level, data, type);

    setGameSettings({
      mosaicLevelSettings: {
        levels: updatedLevelSettings,
      },
    });

    switch (type) {
      case MosaicImageType.Card:
        setStage(MosaicGameFormSteps.MosaicFormDistractors);
        break;
      case MosaicImageType.Distractor:
        setStage(MosaicGameFormSteps.Levels);
    }
  };

  const goBack = () => {
    switch (type) {
      case MosaicImageType.Card:
        setStage(MosaicGameFormSteps.Levels);
        break;
      case MosaicImageType.Distractor:
        setStage(MosaicGameFormSteps.MosaicFormCards);
        break;
    }
  };

  return (
    <MemoryGameContainer>
      <FormContainer>
        <Form submitHandler={submit} methods={methods}>
          <MosaicHeader level={level} type={type} />
          <Files level={level} type={type} />
          <ButtonContainer>
            <Button
              text="button.back"
              variant="primary"
              uppercase
              type="button"
              onClick={goBack}
            />
            <Button
              uppercase
              text="button.next"
              variant="secondary"
              type="submit"
              disabled={isButtonDisabled}
              onClick={methods.handleSubmit(goNext)}
            />
          </ButtonContainer>
        </Form>
      </FormContainer>
    </MemoryGameContainer>
  );
};
