import React from 'react';
import { useDrag, useDrop } from 'react-dnd';

import { apiUrl } from 'config';
import { ElementType } from 'cogamika-back/types';
import { Text } from 'components/common';

import { StyledCard, StyledImage } from './styles';

export interface Props {
  id: number;
  text: string;
  isWin: boolean;
  isCorrect?: boolean;
  elementType: ElementType;
  moveCard: (id: number, to: number) => void;
  findCard: (id: number) => { index: number };
  dropCard: () => void;
}

interface Item {
  id: number;
  originalIndex: number;
}

export const Card: React.FC<Props> = ({
  moveCard,
  isWin,
  id,
  text,
  dropCard,
  elementType,
  findCard,
  isCorrect = false,
}) => {
  const originalIndex = findCard(id).index;

  const [{ isDragging }, drag] = useDrag(
    () => ({
      canDrag: !isWin,
      type: elementType,
      item: { id, originalIndex },
      collect: (monitor) => ({
        isDragging: monitor.isDragging(),
      }),
      end: () => {
        dropCard();
      },
    }),
    [id, originalIndex, isWin]
  );

  const [, drop] = useDrop(
    () => ({
      accept: elementType,
      hover({ id: draggedId }: Item) {
        if (draggedId !== id) {
          const { index: overIndex } = findCard(id);

          moveCard(draggedId, overIndex);
        }
      },
    }),
    [findCard, moveCard]
  );

  const opacity = isDragging ? 0 : 1;

  if (elementType === ElementType.Image) {
    return (
      <div ref={(node: any) => drag(drop(node))}>
        <StyledImage
          isCorrect={isCorrect}
          src={`${apiUrl}file/${text}`}
          style={{ opacity }}
          isWin={isWin}
          opacity={opacity}
          effect="opacity"
        />
      </div>
    );
  } else {
    return (
      <StyledCard
        isCorrect={isCorrect}
        ref={(node) => drag(drop(node))}
        isWin={isWin}
        opacity={opacity}
      >
        <Text
          text={text}
          size="base"
          weight="medium"
          noTranslate
          color={isWin || isCorrect ? 'success' : 'primary'}
        />
      </StyledCard>
    );
  }
};
