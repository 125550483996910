import styled from 'styled-components';

export const InputContainer = styled.div`
  width: 100%;
`;

export const Input = styled.input(
  ({ theme: { colors, fontSizes, radius, spaces } }) => `
  display: flex;
  border: 2px solid ${colors.gray};
  padding: ${spaces.base} ${spaces.lg};
  border-radius: ${radius.md};
  font-size: ${fontSizes.sm};
  width: 100%;

  &::-webkit-outer-spin-button;
  &::-webkit-inner-spin-button {
      -webkit-appearance: none;
      margin: 0;
 };
`
);

export const Wrapper = styled.div(
  ({ theme: { spaces } }) => `
  display: flex;
  flex-direction: column;
  gap: ${spaces.sm};
`
);

export const StyledLabel = styled.label(
  ({ theme: { fontSizes } }) => `
  font-size: ${fontSizes.base};
  `
);
