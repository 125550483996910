import React, { useEffect } from 'react';

import { Button, Flex, Text } from 'components/common';
import { GameMode } from 'types';

import { Background, ResultContainer } from './styles';
import { TrainingEnded } from './TrainingEnded';
import { SingleGameEnd } from './SingleGameEnd';

interface ResultProps {
  gameMode: GameMode;
  isSessionEnd: boolean;
  action?: () => void;
}

export const Result: React.FC<ResultProps> = ({
  action,
  isSessionEnd,
  gameMode,
}) => {
  useEffect(() => {
    if (isSessionEnd && gameMode === GameMode.Session && action) action();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isSessionEnd]);

  if (isSessionEnd) {
    switch (gameMode) {
      case GameMode.Session:
        return (
          <Background>
            <TrainingEnded />
          </Background>
        );
      case GameMode.OneGame:
        return (
          <Background>
            <SingleGameEnd action={action} />
          </Background>
        );
      case GameMode.Free:
        return (
          <Background>
            <SingleGameEnd action={action} />
          </Background>
        );
    }
  }

  return (
    <Background>
      <ResultContainer>
        <Flex columnDirection gap="base">
          <Text text="views.game.resultHeading" size="lg" weight="semibold" />
          <Text text="views.game.result" />
        </Flex>
        <Button
          variant="secondary"
          text="button.next"
          uppercase
          onClick={action}
          dataCy="next-game"
        />
      </ResultContainer>
    </Background>
  );
};
