import { useState } from 'react';

import {
  AccessibleCollectionItem,
  CollectionAssignmentType,
} from 'cogamika-back/types';
import { CollectionBucketStatus } from 'types';
import { Collection } from 'games';

interface Props {
  collection: Collection;
  endGame: () => void;
}

interface CollectionsStatus {
  firstCollection: CollectionBucketStatus;
  secondCollection: CollectionBucketStatus;
}

interface UseColectionGame {
  status: CollectionsStatus;
  canDrag: boolean;
  currentImage: AccessibleCollectionItem;
  leftArrowClick: () => void;
  rightArrowClick: () => void;
  onDrop: (
    isSuccess: boolean,
    collectionType: CollectionAssignmentType
  ) => void;
}

export const useCollectionGame = ({
  collection,
  endGame,
}: Props): UseColectionGame => {
  const [canDrag, setCanDrag] = useState(true);
  const [currentImageIndex, setCurrentImageIndex] = useState<number>(0);
  const [currentImage, setCurrentImage] = useState<AccessibleCollectionItem>(
    collection.getImage(0)
  );
  const [status, setStatus] = useState<CollectionsStatus>({
    firstCollection: CollectionBucketStatus.None,
    secondCollection: CollectionBucketStatus.None,
  });

  const onDrop = (
    isSuccess: boolean,
    collectionType: CollectionAssignmentType
  ) => {
    if (isSuccess) {
      collection.incrementCorrectAnswers();
    } else {
      collection.incrementIncorrectAnswers();
    }

    updateStatus(
      collectionType === CollectionAssignmentType.First
        ? 'firstCollection'
        : 'secondCollection',
      isSuccess
    );

    nextImage();
  };

  const nextImage = () => {
    const isNextImage = collection.checkIsNextImage(currentImageIndex + 1);
    if (!isNextImage) {
      setTimeout(() => {
        endGame();
      }, 1000);
      return;
    }

    setCanDrag(false);
    setTimeout(() => {
      setCurrentImage(collection.getImage(currentImageIndex + 1));
      setCurrentImageIndex((prev) => prev + 1);
      setCanDrag(true);
      setStatus({
        firstCollection: CollectionBucketStatus.None,
        secondCollection: CollectionBucketStatus.None,
      });
    }, 1000);
  };

  const leftArrowClick = () => {
    if (currentImage.collectionType === CollectionAssignmentType.First) {
      collection.incrementCorrectAnswers();
      updateStatus('firstCollection', true);
    } else {
      collection.incrementIncorrectAnswers();
      updateStatus('firstCollection', false);
    }
    nextImage();
  };

  const rightArrowClick = () => {
    if (currentImage.collectionType === CollectionAssignmentType.Second) {
      collection.incrementCorrectAnswers();
      updateStatus('secondCollection', true);
    } else {
      collection.incrementIncorrectAnswers();
      updateStatus('secondCollection', false);
    }
    nextImage();
  };

  const updateStatus = (
    collectionName: 'firstCollection' | 'secondCollection',
    isSuccess: boolean
  ) => {
    setStatus((prev) => ({
      ...prev,
      [collectionName]: isSuccess
        ? CollectionBucketStatus.Success
        : CollectionBucketStatus.Failure,
    }));
  };

  return {
    status,
    canDrag,
    currentImage,
    rightArrowClick,
    leftArrowClick,
    onDrop,
  };
};
