import { useNavigate } from 'react-router-dom';
import React, { useState } from 'react';

import { apiUrl, routes } from 'config';
import { clearSessionGames } from 'slices';
import { useAppDispatch } from 'hooks';
import {
  Flex,
  Text,
  Button,
  Heading,
  GridItem,
  GridContainer,
} from 'components/common';

import { DomainsInfo } from './DomainsInfo';
import {
  DescriptionContainer,
  InstructionContainer,
  StyledVideo,
} from './style';
import { CloseGame } from '../CloseGame';

interface Props {
  instruction: string;
  instructionFileId: string | null;
  startGame: () => void;
}

export const Instruction: React.FC<Props> = ({
  instruction,
  instructionFileId,
  startGame,
}) => {
  const [isLeavingGame, setIsLeavingGame] = useState<boolean>(false);

  const dispatch = useAppDispatch();
  const navigate = useNavigate();

  const startGameHandler = () => {
    startGame();
  };
  const onLeaveGame = () => {
    dispatch(clearSessionGames());
    navigate(routes.root);
  };

  const onShowPopUp = () => setIsLeavingGame(true);

  const onClosePopUp = () => setIsLeavingGame(false);

  return (
    <InstructionContainer>
      <DomainsInfo />
      <GridContainer size={[2]} gap="7xl">
        <GridItem position={[1]}>
          {instructionFileId && (
            <StyledVideo
              controls
              src={`${apiUrl}file/${instructionFileId}`}
              disablePictureInPicture
              autoPlay
            ></StyledVideo>
          )}
        </GridItem>
        <GridItem position={[2]}>
          <Flex columnDirection justifyContent="space-between">
            <Flex columnDirection gap="md">
              <Heading
                text="views.instruction.heading"
                size="lg"
                color="primary"
                weight="semibold"
              />
              <DescriptionContainer>
                <Text
                  whiteSpace="pre-wrap"
                  size="md"
                  margin={['base', '', '2xl', '']}
                  text={instruction}
                  noTranslate
                />
              </DescriptionContainer>
            </Flex>
            <Flex gap="lg">
              <Button
                variant="secondary"
                text="button.start"
                onClick={startGameHandler}
                dataCy="start-game"
                uppercase
              />
              <Button
                variant="outlined"
                text="button.cancel"
                onClick={onShowPopUp}
                dataCy="cancel-session"
                uppercase
              />
            </Flex>
          </Flex>
        </GridItem>
      </GridContainer>
      {isLeavingGame && (
        <CloseGame onClose={onClosePopUp} onLeave={onLeaveGame} />
      )}
    </InstructionContainer>
  );
};
