import { createSlice, PayloadAction } from '@reduxjs/toolkit';

import { ModalState, ModalType, ShowModalActionPayload } from 'types';

const initialState: ModalState = {
  isOpen: false,
  type: null,
  data: null,
};

export const modalSlice = createSlice({
  name: 'modal',
  initialState,
  reducers: {
    showModal: (state, { payload }: PayloadAction<ShowModalActionPayload>) => {
      state.isOpen = true;
      if (Object.values(ModalType).includes(payload.type)) {
        state.type = payload.type;
        state.data = payload.data;
      }
    },
    hideModal: (state) => {
      state.isOpen = false;
      state.type = null;
      state.data = null;
    },
  },
});

export const { showModal, hideModal } = modalSlice.actions;
