import { useContext } from 'react';

import { Language } from 'cogamika-back/types';
import { AppConfig } from 'config';
import { LangContext } from 'context';
import { i18n } from 'utils/i18n';

import { useAppSelector } from './redux';

interface UseLang {
  isLanguageLoaded: boolean;
  lang: Language;
  changeLanguage: (newLanguage: Language) => void;
  setDefaultUserLanguage: () => void;
  setDefaultLanguage: () => void;
}

export const useLang = (): UseLang => {
  const { isLanguageLoaded, setIsLanguageLoaded, lang, setLang } =
    useContext(LangContext);
  const { user } = useAppSelector((state) => state.user);

  const reloadActiveTranslations = () => setIsLanguageLoaded(true);

  const changeLanguage = (newLanguage: Language) => {
    setIsLanguageLoaded(false);
    setLang(newLanguage);
    i18n.load(newLanguage, reloadActiveTranslations);
  };

  const setDefaultUserLanguage = () => {
    if (user) {
      setLang(user.lang);
      i18n.load(user.lang, reloadActiveTranslations);
    }
  };

  const setDefaultLanguage = () => {
    const windowLanguage = window.navigator.language.slice(0, 2).toLowerCase();
    const language = AppConfig.LANGUAGES.find(
      (lang: string) => lang === windowLanguage
    )
      ? windowLanguage
      : AppConfig.DEFAULT_LANG;
    setLang(language as Language);
    i18n.load(language, reloadActiveTranslations);
  };

  return {
    isLanguageLoaded,
    lang,
    changeLanguage,
    setDefaultUserLanguage,
    setDefaultLanguage,
  };
};
