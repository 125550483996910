import styled from 'styled-components';
import { LazyLoadImage } from 'react-lazy-load-image-component';
import 'react-lazy-load-image-component/src/effects/opacity.css';

export const Container = styled.div`
  max-height: calc(100vh - 120px);
  margin: 0 auto;
`;

export const StyledImage = styled(LazyLoadImage)(
  ({ theme }) => `
  max-height: 100%;
  width: 100%;
  border-radius: 20px;
  background: none;
  object-fit: scale-down;
`
);

export const StyledAudio = styled.audio`
  width: 600px;
`;
