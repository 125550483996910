import React, { ReactNode } from 'react';

import {
  AlignItemsType,
  JustifyContentType,
  MarginType,
  SpaceType,
} from 'types';

import { FlexComponent } from './styles';

interface Props {
  children: ReactNode;
  columnDirection?: boolean;
  alignItems?: AlignItemsType;
  justifyContent?: JustifyContentType;
  gap?: SpaceType;
  fullWidth?: boolean;
  margin?: MarginType;
  wrap?: boolean;
  className?: string;
}

export const Flex: React.FC<Props> = ({
  children,
  columnDirection,
  alignItems,
  justifyContent,
  gap,
  fullWidth,
  margin,
  wrap,
  className,
}) => {
  return (
    <FlexComponent
      columnDirection={columnDirection}
      alignItems={alignItems}
      gap={gap}
      fullWidth={fullWidth}
      justifyContent={justifyContent}
      margin={margin}
      wrap={wrap}
      className={className}
    >
      {children}
    </FlexComponent>
  );
};

export * from './FlexAlignCenter';
export * from './FlexJustifyCenter';
export * from './FlexCenter';
export * from './FlexWrapped';
