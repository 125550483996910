import React from 'react';
import { useNavigate } from 'react-router-dom';

import { ModalType } from 'types';
import { showModal } from 'slices';
import { useAppDispatch } from 'hooks';
import { LinkButton } from 'components/common';
import { routes } from 'config';
import { getRedirectLinkToAboutUs, getSelectOptionsFromEnum } from 'utils';
import { Language } from 'cogamika-back/types';
import { ChangeLangSelect } from 'components/common/ChangeLangSelect';

import { StyledLinksContainer } from '../styles';
import { i18n } from 'utils/i18n';

export const BaseLinks: React.FC = () => {
  const dispatch = useAppDispatch();
  const navigate = useNavigate();

  const openLoginModal = () => {
    dispatch(
      showModal({
        type: ModalType.Login,
        data: null,
      })
    );
  };

  const openRegisterModal = () => {
    dispatch(
      showModal({
        type: ModalType.Register,
        data: null,
      })
    );
  };

  const redirectToAboutUs = () =>
    window.location.replace(getRedirectLinkToAboutUs(i18n.currentLang));

  const handleOnSubscriptionClick = () => {
    navigate(routes.subscriptions);
  };

  return (
    <StyledLinksContainer>
      <LinkButton
        text="button.subscription"
        uppercase
        onClick={handleOnSubscriptionClick}
      />
      <LinkButton text="button.aboutUs" uppercase onClick={redirectToAboutUs} />
      <LinkButton
        text="button.register"
        uppercase
        onClick={openRegisterModal}
        dataCy="open-register-modal"
      />
      <LinkButton
        text="button.login"
        uppercase
        underline
        onClick={openLoginModal}
        dataCy="open-login-modal"
      />
      <ChangeLangSelect
        options={getSelectOptionsFromEnum(Language, true, 'Shortcut')}
      />
    </StyledLinksContainer>
  );
};
