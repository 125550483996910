import styled from 'styled-components';

export const SelectContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 10px;
  width: 100%;
`;

export const StyledSelect = styled.select(
  ({ theme: { radius, colors, fontSizes } }) => `
  width: 100%;
  padding: 14px 24px;
  border-radius: ${radius.md};
  background-color: ${colors.light};
  font-size: ${fontSizes.base};
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  background-image: url('/assets/images/icons/dropdown.svg');
  background-repeat: no-repeat;
  background-position: right center;
  background-color: transparent;
  border: 2px solid ${colors.gray};
`
);

export const Option = styled.option``;
