import React, { useEffect, useState } from 'react';
import InfiniteScroll from 'react-infinite-scroll-component';

import { BasePaginationRes, GameItemToList } from 'cogamika-back/types';
import { FilterLanguageType, NonLanguage } from 'types';
import { useApi } from 'hooks';
import { SearchInput } from 'components/forms';
import { Icon, Text, TranslateText } from 'components/common';

import {
  FilterContainer,
  GamesContainer,
  GamesListContainer,
  GamesViewerContainer,
  IconContainer,
  InputContainer,
  StyledButton,
} from './styles';
import { SingleGame } from './SingleGame';
import { DomainsFilter } from './DomainsFilter';
import { LanguageFilter } from './LanguageFilter';

interface GameListProps {
  onChooseGame?: (id: string, gameObj: GameItemToList) => void;
  onLanguageChange?: (lang: FilterLanguageType) => void;
  disableActionButton?: boolean;
}

export const GamesList: React.FC<GameListProps> = ({
  onChooseGame,
  onLanguageChange,
  disableActionButton,
}) => {
  const { get } = useApi();
  const [games, setGames] = useState<GameItemToList[]>([]);
  const [selectedDomainId, setSelectedDomainId] = useState<string>();
  const [hasMore, setHasMore] = useState(true);
  const [page, setPage] = useState(1);
  const [search, setSearch] = useState<string>('');
  const [selectedLanguage, setSelectedLanguage] = useState<FilterLanguageType>(
    NonLanguage.NonLanguage
  );

  const getGames = async (
    currentPage: number,
    search: string,
    domainId?: string,
    lang?: FilterLanguageType
  ) => {
    const res = await get<BasePaginationRes<GameItemToList>>('/game', {
      page: String(currentPage),
      limit: String(20),
      search: String(search),
      ...(domainId && { domainId }),
      ...(lang && { lang }),
    });

    if (res && res.items.length > 0) {
      setGames((prevFiles) => [...prevFiles, ...res.items]);
      setPage((prevPage) => prevPage + 1);
      setHasMore(res.hasNextPage);
    } else {
      setHasMore(false);
    }
  };

  const handleSearch = (e: React.ChangeEvent<HTMLInputElement>): void => {
    getGames(1, e.target.value, selectedDomainId, selectedLanguage);
    setSearch(e.target.value);
  };

  const handleSelectDomain = (id: string) => {
    if (id === selectedDomainId) {
      setSelectedDomainId(undefined);
      getGames(1, search, undefined, selectedLanguage);
    } else {
      setSelectedDomainId(id);
      getGames(1, search, id, selectedLanguage);
    }
  };

  const openGame = (id: string, gameObj: GameItemToList) => {
    if (onChooseGame) {
      onChooseGame(id, gameObj);
    }
  };

  const handleSelectLanguage = (lang: FilterLanguageType) => {
    setSelectedLanguage(lang);
    if (onLanguageChange) {
      onLanguageChange(lang);
    }
    getGames(1, search, selectedDomainId, lang);
  };

  const clearFilters = () => {
    setSelectedDomainId('');
    setSearch('');
    setSelectedLanguage(NonLanguage.NonLanguage);
    if (onLanguageChange) {
      onLanguageChange(NonLanguage.NonLanguage);
    }
    getGames(1, '');
  };

  useEffect(() => {
    setGames([]);
    setPage(1);
  }, [selectedDomainId, search, selectedLanguage]);

  useEffect(() => {
    getGames(1, '');
    // eslint-disable-next-line
  }, []);

  return (
    <GamesContainer>
      <Text text="views.games.heading" size="2xl" weight="semibold" />
      <FilterContainer>
        <DomainsFilter
          setDomainId={handleSelectDomain}
          selectedDomainId={selectedDomainId}
        />
        {(selectedDomainId || selectedLanguage !== NonLanguage.NonLanguage) && (
          <StyledButton onClick={clearFilters}>
            <Icon name="cross" />
            <TranslateText text="button.clearFilters" />
          </StyledButton>
        )}
        <LanguageFilter
          handleSelectLanguage={handleSelectLanguage}
          value={selectedLanguage}
        />
        <InputContainer>
          <SearchInput
            onChange={handleSearch}
            placeholder="search"
            value={search}
          />
          <IconContainer>
            <Icon name="search" />
          </IconContainer>
        </InputContainer>
      </FilterContainer>
      <GamesViewerContainer>
        <InfiniteScroll
          dataLength={games.length}
          next={() =>
            getGames(page, search, selectedDomainId, selectedLanguage)
          }
          hasMore={hasMore}
          loader={<Text text="views.files.loading" />}
        >
          <GamesListContainer>
            {games.map((game) => (
              <SingleGame
                key={game.id}
                miniatureFileId={game.miniatureFileId}
                title={game.title}
                description={game.description}
                onClick={() => openGame(game.id, game)}
                gameId={game.id}
                disableActionButton={disableActionButton}
                updateGames={() =>
                  getGames(page, search, selectedDomainId, selectedLanguage)
                }
              />
            ))}
          </GamesListContainer>
        </InfiniteScroll>
      </GamesViewerContainer>
    </GamesContainer>
  );
};

export * from './SingleGame';
