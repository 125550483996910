import React, { ButtonHTMLAttributes } from 'react';

import { ButtonType, IconName, JustifyContentType, MarginType } from 'types';
import { Icon, TranslateText } from 'components/common';

import { StyledButton } from './style';

interface Props extends ButtonHTMLAttributes<HTMLButtonElement> {
  text: string;
  variant: ButtonType;
  iconName?: IconName;
  margin?: MarginType;
  noTranslate?: boolean;
  fullWidth?: boolean;
  uppercase?: boolean;
  className?: string;
  dataCy?: string;
  justifyContent?: JustifyContentType;
  leftIcon?: IconName;
  noWrap?: boolean;
}

export const Button: React.FC<Props> = ({
  text,
  variant,
  margin,
  fullWidth,
  uppercase,
  onClick,
  iconName,
  className,
  type,
  noTranslate,
  dataCy,
  disabled,
  leftIcon,
  noWrap,
  justifyContent = 'center',
}) => {
  return (
    <StyledButton
      variant={variant}
      margin={margin}
      onClick={onClick}
      fullWidth={fullWidth}
      uppercase={uppercase}
      className={className}
      type={type}
      data-cy={dataCy}
      disabled={disabled}
      justifyContent={justifyContent}
      noWrap={noWrap}
    >
      {leftIcon && <Icon name={leftIcon} />}
      <TranslateText text={text} noTranslate={noTranslate} />
      {iconName && <Icon name={iconName} />}
    </StyledButton>
  );
};
