import { ReactNode, FC, useState } from 'react';
import { FormProvider, useForm } from 'react-hook-form';
import { BasePagination, PagesPossibility } from 'types';
import { yupResolver } from '@hookform/resolvers/yup';
import { BasePaginationContext } from 'context';
import { basePagination } from 'config';

interface PaginationProviderProps {
  children: ReactNode;
}

export const PaginationProvider: FC<PaginationProviderProps> = ({
  children,
}) => {
  const [pagination, setPagination] = useState<BasePagination>({
    page: '1',
    limit: '10',
  });
  const [pagesPossibility, setPagesPossibility] = useState<PagesPossibility>({
    hasNextPage: false,
    hasPreviousPage: false,
  });
  const methods = useForm<BasePagination>({
    mode: 'onSubmit',
    resolver: yupResolver(basePagination),
  });

  return (
    <BasePaginationContext.Provider
      value={{
        pagination,
        setPagination,
        pagesPossibility,
        setPagesPossibility,
      }}
    >
      <FormProvider {...methods}>{children}</FormProvider>
    </BasePaginationContext.Provider>
  );
};
