import React, { ReactNode } from 'react';

import { SpaceType } from 'types';

import { GridComponent } from './styles';

interface Props {
  children: ReactNode;
  size: number[];
  className?: string;
  gap?: SpaceType;
}

export const GridContainer: React.FC<Props> = ({
  children,
  size,
  className,
  gap,
}) => {
  return (
    <GridComponent size={size} className={className} gap={gap}>
      {children}
    </GridComponent>
  );
};
