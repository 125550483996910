import styled from 'styled-components';
import { gtMedia } from 'config';

export const Container = styled.div(
  ({ theme: { spaces } }) => `
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: ${spaces['6xl']};
  padding: 60px 252px;

  ${gtMedia('xl')} {
    padding: 60px 420px;
  }

`
);

export const AnswersContainer = styled.div(
  ({ theme: { spaces } }) => `
  display: flex;
  flex-direction: column;
  gap: ${spaces['2xl']};
`
);
