import { FC, useEffect } from 'react';
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import { Button, Flex, FlexAlignCenter } from 'components/common';
import { Form, TextInput } from 'components/forms';
import { editSubdomainSchema } from 'config';
import { useAppDispatch } from 'hooks';
import { SubdomainReq } from 'cogamika-back/types';
import { EditSubdomainSchema } from 'types';
import { clearErrorNotification } from 'slices';
import { BoxContainer } from './styles';

interface EditSubdomainProps {
  id: string;
  defaultValue: string;
  submit: (data: SubdomainReq, id: string) => void;
  cancel: () => void;
}

export const EditSubdomain: FC<EditSubdomainProps> = ({
  id,
  defaultValue,
  submit,
  cancel,
}) => {
  const dispatch = useAppDispatch();

  const methodsEditSubdomain = useForm<EditSubdomainSchema>({
    mode: 'onSubmit',
    resolver: yupResolver(editSubdomainSchema),
  });

  const { setValue } = methodsEditSubdomain;

  const submitEditSubdomain = (data: EditSubdomainSchema) => {
    dispatch(clearErrorNotification());
    const { name } = data;
    const preparedData: SubdomainReq = {
      name,
    };
    submit(preparedData, id);
    setValue('name', '');
  };

  useEffect(() => {
    setValue('name', defaultValue);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <Flex gap="xl" columnDirection fullWidth>
      <Form
        submitHandler={submitEditSubdomain}
        methods={methodsEditSubdomain}
        fullWidth
      >
        <Flex gap="xl" columnDirection fullWidth>
          <BoxContainer>
            <Flex gap="lg" columnDirection fullWidth alignItems="center">
              <TextInput name="name" placeholder="name" fullWidth required />
              <FlexAlignCenter gap="md">
                <Button type="submit" variant="secondary" text="button.save" />
                <Button
                  type="button"
                  variant="outlined"
                  text="button.cancel"
                  onClick={cancel}
                />
              </FlexAlignCenter>
            </Flex>
          </BoxContainer>
        </Flex>
      </Form>
    </Flex>
  );
};
