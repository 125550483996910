import { Flex, Text } from 'components/common';
import { NumberInput } from 'components/forms';
import React from 'react';
import { InputWrapper } from './styles';

interface Props {
  fieldIndex: number;
  firstInputName: string;
  secondInputName: string;
  label: string;
}

export const TimeRangeInput: React.FC<Props> = ({
  fieldIndex,
  firstInputName,
  secondInputName,
  label,
}) => {
  return (
    <InputWrapper>
      <Text text={label} />
      <Flex alignItems="center" gap="xs">
        <NumberInput
          name={`timeRanges.${fieldIndex}.${firstInputName}`}
          placeholder="minutes"
        />
        :
        <NumberInput
          name={`timeRanges.${fieldIndex}.${secondInputName}`}
          placeholder="seconds"
        />
      </Flex>
    </InputWrapper>
  );
};
