import React from 'react';
import { ResultContainer } from '../styles';
import { Button, Flex, Icon, Text } from 'components/common';

interface Props {
  handleNext: () => void;
}

export const Success: React.FC<Props> = ({ handleNext }) => {
  return (
    <ResultContainer variant="success">
      <Flex alignItems="center" gap="base">
        <Icon name="check" />
        <Text
          text="views.survey.correctAnswer"
          size="md"
          weight="semibold"
          color="darkGreen"
        />
      </Flex>
      <Button
        text="button.next"
        variant="outlined-success"
        uppercase
        iconName="right-arrow"
        onClick={handleNext}
        dataCy="nextQuestion"
      />
    </ResultContainer>
  );
};
