import styled from 'styled-components';

import { getMargin } from 'utils';
import {
  ColorType,
  FontSizeType,
  MarginType,
  TextAlignType,
  FontWeightType,
  WhiteSpaceType,
} from 'types';

interface Props {
  size: FontSizeType;
  color: ColorType;
  margin?: MarginType;
  textAlign?: TextAlignType;
  weight?: FontWeightType;
  upperaces?: boolean;
  whiteSpace?: WhiteSpaceType;
}

export const StyledParagraphText = styled.p<Props>(
  ({
    theme: { colors, fontSizes, lineHeights, spaces, fontWeight },
    margin,
    textAlign,
    size,
    color,
    weight,
    upperaces,
    whiteSpace,
  }) => `
  color: ${colors[color]};
  font-size: ${fontSizes[size]};
  line-height: ${lineHeights[size]};
  text-align: ${textAlign ? textAlign : 'start'}; 
  font-weight: ${weight ? fontWeight[weight] : '500'};
  text-transform: ${upperaces && 'uppercase'};
  white-space: ${whiteSpace && whiteSpace};
  ${margin && getMargin(margin, spaces)};
`
);

export const StyledSpanText = styled.span<Props>(
  ({
    theme: { colors, fontSizes, lineHeights, spaces, fontWeight },
    margin,
    textAlign,
    size,
    color,
    weight,
    upperaces,
    whiteSpace,
  }) => `
  color: ${colors[color]};
  font-size: ${fontSizes[size]};
  line-height: ${lineHeights[size]};
  text-align: ${textAlign ? textAlign : 'start'};
  font-weight: ${weight ? fontWeight[weight] : '500'};
  text-transform: ${upperaces && 'uppercase'};
  white-space: ${whiteSpace && whiteSpace};
  ${margin && getMargin(margin, spaces)};
`
);
