import styled from 'styled-components';

import { ltMedia } from 'config';

export const GamesViewerContainer = styled.div``;

export const GamesContainer = styled.div(
  ({ theme: { spaces } }) => `
display: flex;
flex-direction: column;
gap: ${spaces['2xl']};
width: 100%;
margin-top: 50px;
`
);

export const GamesListContainer = styled.div(
  ({ theme: { spaces } }) => `
  width: 100%;
  display: grid;
  justify-content: center;
  grid-template-columns: repeat(3, 1fr);
  gap: ${spaces['2xl']};

  ${ltMedia('lg')} {
    grid-template-columns: repeat(2, 1fr);
  }

  ${ltMedia('md')} {
    grid-template-columns: repeat(1, 1fr);
  }


`
);

export const InputContainer = styled.div`
  position: relative;
  display: inline-block;
`;

export const IconContainer = styled.div`
  position: absolute;
  top: 50%;
  left: 24px;
  transform: translateY(-50%);
`;

export const FilterContainer = styled.div(
  ({ theme: { spaces } }) => `
  display: flex;
  justify-content: space-between;
  align-items: center;
  flex-flow: wrap;
  gap: ${spaces.lg};
`
);

export const StyledButton = styled.button(
  ({ theme: { spaces, fontSizes, fontWeight, colors } }) => `
  margin-left: auto;
  color: ${colors.primary};
  display: flex;
  align-items: center;
  gap: ${spaces.sm};
  border: none;
  background: none;
  cursor: pointer;
  font-size: ${fontSizes.sm};
  font-weight: ${fontWeight.medium};

  &:hover {
    color: ${colors.secondaryGray};
  }
`
);
