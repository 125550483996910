import { BasePaginationWithSearch } from '../../base-classes';
import { CategoryBaseData, CategoryFullData } from '../category';
import { SubdomainBaseData, SubdomainFullData } from '../subdomain';
import { DomainBaseRes, DomainFullData } from '../domain';
import { GameLevelFullData, GameLevelRes } from '../game-level';
import { FileFullData } from '../../file';
import { UserGameFullData } from '../../session';
import { Language } from '../../translate';
import {
  ChronologyGameSettings,
  ChronologySettingsRes,
  CollectionGameSettings,
  CollectionSettingsRes,
  MemoryGameSettings,
  MemorySettingsRes,
  MosaicGameSettings,
  MosaicSettingsRes,
  MovieGameSettings,
  MovieSettingsRes,
  SurveyGameSettings,
  SurveySettingsRes,
} from './game-settings';

export enum GameType {
  Memory = 'MEMORY',
  Mosaic = 'MOSAIC',
  Survey = 'SURVEY',
  Movie = 'MOVIE',
  Collection = 'COLLECTION',
  Chronology = 'CHRONOLOGY',
}

export type GameSettings =
  | MemoryGameSettings
  | SurveyGameSettings
  | MosaicGameSettings
  | MovieGameSettings
  | CollectionGameSettings
  | ChronologyGameSettings;

export interface GameBaseData {
  id: string;
  lang: Language;
  title: string;
  description: string | null;
  type: GameType;
  approved: boolean;
  free: boolean;
  instruction: string | null;
  settings: GameSettings;
}

export interface GameFullData extends GameBaseData {
  instructionFile: FileFullData;
  miniatureFile: FileFullData;
  domain: DomainFullData;
  subdomain: SubdomainFullData;
  category: CategoryFullData;
  levels: GameLevelFullData[];
  userGames: UserGameFullData[];
}

export type GameCategorisationWithFile = {
  domain: DomainBaseRes | null;
  subdomain: SubdomainBaseData | null;
  category: CategoryBaseData | null;
  instructionFileId: string | null;
  miniatureFileId: string | null;
};

export type GameItemToList = Omit<GameBaseData, 'settings'> &
  GameCategorisationWithFile;

export type GameItem = GameBaseData & GameCategorisationWithFile;

export type GameListQuery = BasePaginationWithSearch & {
  domainId?: string;
  free?: number;
  lang?: Language;
};

export type ChooseFreeGamesInOneLangReq = {
  lang: Language;
  gamesIds: string[];
};

export type FreeGameDataReq = {
  gameId: string;
  level?: number;
};

export interface GameWithAllLevels {
  game: GameItem;
  levels: GameLevelRes[];
}

export interface BaseGameResourcesRes {
  id: string;
  title: string;
  instruction: string;
  description: string | null;
  miniatureFileId: string | null;
  instructionFileId: string | null;
  domain: DomainBaseRes | null;
  subdomain: SubdomainBaseData | null;
  category: CategoryBaseData | null;
  level: number;
}

export interface MemoryTypedSettings {
  type: GameType.Memory;
  settings: MemorySettingsRes;
}

export type MemoryGameResourcesRes = BaseGameResourcesRes & MemoryTypedSettings;

export interface SurveyTypedSettings {
  type: GameType.Survey;
  settings: SurveySettingsRes;
}

export type SurveyGameResourcesRes = BaseGameResourcesRes & SurveyTypedSettings;

export interface MosaicTypedSettings {
  type: GameType.Mosaic;
  settings: MosaicSettingsRes;
}

export type MosaicGameResourcesRes = BaseGameResourcesRes & MosaicTypedSettings;

export interface MovieTypedSettings {
  type: GameType.Movie;
  settings: MovieSettingsRes;
}

export type MovieGameResourcesRes = BaseGameResourcesRes & MovieTypedSettings;

export interface CollectionTypedSettings {
  type: GameType.Collection;
  settings: CollectionSettingsRes;
}

export type CollectionGameResourcesRes = BaseGameResourcesRes &
  CollectionTypedSettings;

export interface ChronologyTypedSettings {
  type: GameType.Chronology;
  settings: ChronologySettingsRes;
}

export type ChronologyGameResourcesRes = BaseGameResourcesRes &
  ChronologyTypedSettings;

export type SettingsByGameType =
  | MemoryTypedSettings
  | SurveyTypedSettings
  | MosaicTypedSettings
  | MovieTypedSettings
  | CollectionTypedSettings
  | ChronologyTypedSettings;

export type GameAndLevelSettingsRes =
  | ChronologySettingsRes
  | CollectionSettingsRes
  | MovieSettingsRes
  | MosaicSettingsRes
  | SurveySettingsRes
  | MemorySettingsRes;

export type GameResourcesRes =
  | MemoryGameResourcesRes
  | SurveyGameResourcesRes
  | MosaicGameResourcesRes
  | MovieGameResourcesRes
  | CollectionGameResourcesRes
  | ChronologyGameResourcesRes;

export interface UpperLevelGameReq {
  currentGameId: string;
  currentGameLevel: number;
}
