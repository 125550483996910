import React from 'react';
import { useNavigate } from 'react-router-dom';

import { adminRoutes, apiUrl, superAdminRoutes } from 'config';
import { StatusRes, UserRole } from 'cogamika-back/types';
import { useApi, useAppDispatch, useAppSelector, useModal } from 'hooks';
import { Flex, IconButton, Text } from 'components/common';
import { ModalType } from 'types';
import { successNotification } from 'slices';
import { replaceParams } from 'utils';

import {
  Description,
  GameContainer,
  StyledImage,
  EditContainer,
} from './styles';

interface Props {
  title: string;
  miniatureFileId: string | null;
  description: string | null;
  gameId: string;
  disableActionButton?: boolean;
  onClick: () => void;
  updateGames?: () => void;
}

export const SingleGame: React.FC<Props> = ({
  title,
  description,
  miniatureFileId,
  gameId,
  disableActionButton,
  onClick,
  updateGames,
}) => {
  const { remove } = useApi();
  const { user } = useAppSelector((state) => state.user);
  const { showModal, closeModal } = useModal();
  const navigate = useNavigate();
  const dispatch = useAppDispatch();

  const editGame = () => {
    const url =
      user?.role === UserRole.SuperAdmin
        ? superAdminRoutes.editGame
        : adminRoutes.editGame;
    navigate(replaceParams(url, { id: gameId }));
  };

  const testGame = () => {
    const url =
      user?.role === UserRole.SuperAdmin
        ? superAdminRoutes.gameTest
        : adminRoutes.gameTest;
    navigate(replaceParams(url, { gameId, level: 1 }));
  };

  const handleOnConfirmDelete = async () => {
    const result = await remove<StatusRes>(`game/${gameId}`);

    if (result) {
      dispatch(successNotification('notifications.successfullyDeletedGame'));
      closeModal();
      if (updateGames) {
        updateGames();
      }
    }
  };

  const deleteGame = () => {
    showModal({
      type: ModalType.Confirmation,
      data: {
        action: {
          confirm: handleOnConfirmDelete,
          text: 'button.delete',
        },
        header: 'modals.confirmation.deleteGameHeader',
      },
    });
  };

  const shouldRenderActionButton =
    !disableActionButton &&
    user &&
    (user.role === UserRole.Admin || user.role === UserRole.SuperAdmin);

  return (
    <GameContainer onClick={onClick}>
      <StyledImage
        src={
          miniatureFileId
            ? `${apiUrl}file/${miniatureFileId}`
            : '/assets/images/game_miniature.png'
        }
        effect="opacity"
      />
      <Flex columnDirection gap="base">
        <Text text={title} size="lg" weight="semibold" noTranslate />
        {description && (
          <Description
            text={description}
            size="md"
            noTranslate
            weight="medium"
          />
        )}
      </Flex>
      {shouldRenderActionButton && (
        <EditContainer>
          <IconButton onClick={editGame} type="button" name="edit" />
          <IconButton onClick={deleteGame} type="button" name="delete" />
          <IconButton onClick={testGame} type="button" name="play" />
        </EditContainer>
      )}
    </GameContainer>
  );
};
