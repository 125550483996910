import styled, { css } from 'styled-components';

interface Props {
  fullWidth?: boolean;
  error?: boolean;
}

const baseNavArrow = css`
  top: ${({ theme: { sizes } }) => sizes.xs};
  z-index: 1;
  background-color: transparent;
  display: flex;
  justify-content: center;
  align-items: center;
  width: ${({ theme: { sizes } }) => sizes.sm};
  height: ${({ theme: { sizes } }) => sizes.sm};
  border: none;
  cursor: pointer;
`;

const DateWrapper = styled.div<Props>`
  width: ${({ fullWidth }) => (fullWidth ? '100%' : 'auto')};

  .react-datepicker__aria-live {
    display: none;
  }

  .react-datepicker {
    display: flex;
    background-color: ${({ theme: { colors } }) => colors.secondaryBackground};
    box-shadow: ${({ theme: { colors } }) => colors.boxShadow};

    .react-datepicker__header {
      ::first-letter {
        text-transform: uppercase;
      }
      text-align: center;
    }

    .react-datepicker__aria-live {
      display: none;
    }
  }

  .react-datepicker__month-container {
    padding: ${({ theme: { spaces } }) => spaces.md};
  }

  .react-datepicker__day-name,
  .react-datepicker__day {
    display: inline-block;
    text-align: center;
    color: ${({ theme: { colors } }) => colors.primary};
    width: ${({ theme: { sizes } }) => sizes.sm};
    line-height: ${({ theme: { sizes } }) => sizes.sm};
    font-size: ${({ theme: { fontSizes } }) => fontSizes.xs};
    margin: 0px ${({ theme: { spaces } }) => spaces.xs};
  }
  .react-datepicker__day--outside-month {
    visibility: hidden;
  }

  .react-datepicker__current-month {
    margin-bottom: ${({ theme: { spaces } }) => spaces.md};
    color: ${({ theme: { colors } }) => colors.darkGray};
    font-size: ${({ theme: { fontSizes } }) => fontSizes.xs};
  }

  .react-datepicker-popper {
    z-index: 3;
  }

  .react-datepicker__navigation {
    cursor: pointer;
    position: absolute;
    top: ${({ theme: { sizes } }) => sizes.md};
    & span {
      display: none;
    }
  }
  .react-datepicker__navigation--previous {
    left: ${({ theme: { sizes } }) => sizes.xs};
    ${baseNavArrow};

    &::after {
      display: block;
      content: '';
      border: solid ${({ theme: { colors } }) => colors.primary};
      border-width: 0 0 3px 3px;
      display: inline-block;
      padding: 3px;
      transform: rotate(45deg);
      -webkit-transform: rotate(45deg);
    }
  }
  .react-datepicker__navigation--next {
    right: ${({ theme: { sizes } }) => sizes.xs};
    ${baseNavArrow};

    &::after {
      margin-left: -8px;
      display: block;
      content: '';
      border: solid ${({ theme: { colors } }) => colors.primary};
      border-width: 0 3px 3px 0;
      display: inline-block;
      padding: 3px;
      transform: rotate(-45deg);
      -webkit-transform: rotate(-45deg);
    }
  }

  .react-datepicker__day--selected {
    color: ${({ theme: { colors } }) => colors.light};
    background-color: ${({ theme: { colors } }) => colors.secondary};
    border-radius: ${({ theme: { radius } }) => radius.sm};
  }

  .react-datepicker__day:not(.react-datepicker__day--disabled) {
    cursor: pointer;
    :hover {
      color: ${({ theme: { colors } }) => colors.light};
      background-color: ${({ theme: { colors } }) => colors.primary};
      border-radius: ${({ theme: { radius } }) => radius.sm};
    }
  }

  .react-datepicker__day--today {
    font-weight: bold;
  }

  .react-datepicker__week {
    padding: ${({ theme: { spaces } }) => spaces.xs} 0px;
  }
  .react-datepicker__day--disabled {
    color: ${({ theme: { colors } }) => colors.disable};
    cursor: default;
  }
`;

export { DateWrapper };
