import React from 'react';

import {
  Text,
  Heading,
  Flex,
  FlexAlignCenter,
  Button,
} from 'components/common';
import { MainContainer, Wrapper } from './styles';

interface Props {
  onLeave: () => void;
  onClose: () => void;
}

export const CloseGame: React.FC<Props> = ({ onClose, onLeave }) => {
  return (
    <Wrapper>
      <MainContainer>
        <Flex columnDirection gap="xl">
          <Heading text="modals.closeGame.header" size="lg" />
          <Text text="modals.closeGame.description" size="sm" />
          <FlexAlignCenter gap="md">
            <Button
              type="button"
              variant="secondary"
              onClick={onLeave}
              text={'button.leaveGame'}
              uppercase
            />
            <Button
              type="button"
              variant="outlined"
              onClick={onClose}
              text="button.backToGame"
              uppercase
            />
          </FlexAlignCenter>
        </Flex>
      </MainContainer>
    </Wrapper>
  );
};
