import React from 'react';

import { IconName } from 'types';
import { Icon, TranslateText } from 'components/common';

import { StyledNavLink } from './styles';

interface Props {
  iconName?: IconName;
  to: string;
  text: string;
}

export const NavLink: React.FC<Props> = ({ iconName, to, text }) => {
  return (
    <StyledNavLink to={to}>
      {iconName && <Icon name={iconName} />}
      <TranslateText text={text} />
    </StyledNavLink>
  );
};
