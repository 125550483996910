import { FC, ReactNode } from 'react';
import { StyledItem } from './styled';

interface ListItemProps {
  children: ReactNode;
}

export const ListItem: FC<ListItemProps> = ({ children }) => {
  return <StyledItem>{children}</StyledItem>;
};
