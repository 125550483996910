import React from 'react';

import { Instruction } from 'types';
import { Flex, Icon, IconButton, Text } from 'components/common';

import { Container, HeaderContainer, StyledVideo } from './styles';
import { apiUrl } from 'config';

interface Props {
  instruction: Instruction;
  onCloseInstruction?: () => void;
}

export const SidebarInstruction: React.FC<Props> = ({
  instruction: { instructionFileId, instruction },
  onCloseInstruction,
}) => {
  return (
    <Container>
      <HeaderContainer>
        <Flex alignItems="center" gap="sm">
          <Icon name="book" />
          <Text
            text="views.instruction.secondaryHeading"
            size="base"
            upperaces
            weight="semibold"
          />
        </Flex>
        <IconButton
          name="left-arrow"
          onClick={onCloseInstruction}
          type="button"
          dataCy="close-rules"
        />
      </HeaderContainer>
      {instructionFileId && (
        <StyledVideo
          controls
          src={`${apiUrl}file/${instructionFileId}`}
          disablePictureInPicture
        ></StyledVideo>
      )}
      <Flex columnDirection gap="md">
        <Text
          text="views.instruction.heading"
          size="lg"
          color="primary"
          weight="semibold"
        />
        <Text size="md" text={instruction} noTranslate whiteSpace="pre-wrap" />
      </Flex>
    </Container>
  );
};
