import React from 'react';
import { useNavigate } from 'react-router-dom';

import { logout } from 'slices';
import { AccountSubPagesRoute, IconName } from 'types';
import { useAppDispatch } from 'hooks';
import { UserRole } from 'cogamika-back/types';
import { LinkButton, NavLink } from 'components/common';
import { superAdminRoutes, routes, userRoutes, adminRoutes } from 'config';

import { StyledLinksContainer } from '../styles';

interface Props {
  role: UserRole;
}

interface NavLinkType {
  iconName: IconName;
  to: string;
  text: string;
}

const getLinks = (role: UserRole): NavLinkType[] => {
  switch (role) {
    case UserRole.SuperAdmin:
      return [
        {
          iconName: 'games',
          to: superAdminRoutes.dashboard,
          text: 'button.games',
        },
        {
          iconName: 'folder',
          to: superAdminRoutes.files,
          text: 'button.files',
        },
        {
          iconName: 'settings',
          to: superAdminRoutes.settings,
          text: 'button.settings',
        },
      ];
    case UserRole.Admin:
      return [
        {
          iconName: 'games',
          to: adminRoutes.dashboard,
          text: 'button.games',
        },
        {
          iconName: 'folder',
          to: adminRoutes.files,
          text: 'button.files',
        },
        {
          iconName: 'settings',
          to: adminRoutes.settings,
          text: 'button.settings',
        },
      ];
    case UserRole.User:
      return [
        {
          iconName: 'profile',
          to: userRoutes.account,
          text: 'button.profile',
        },
        { iconName: 'games', to: userRoutes.games, text: 'button.games' },
        {
          iconName: 'help',
          to: `${userRoutes.account}/${AccountSubPagesRoute.Help}`,
          text: 'button.help',
        },
      ];
    default:
      return [];
  }
};

export const AuthLinks: React.FC<Props> = ({ role }) => {
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const links = getLinks(role);

  const logoutHandle = async () => {
    dispatch(logout())
      .unwrap()
      .then(() => {
        navigate(routes.root);
      });
  };

  return (
    <StyledLinksContainer>
      {links.map((link) => (
        <NavLink
          key={link.text}
          iconName={link.iconName}
          to={link.to}
          text={link.text}
        />
      ))}
      <LinkButton
        text="button.logout"
        uppercase
        onClick={logoutHandle}
        iconName="logout"
      />
    </StyledLinksContainer>
  );
};
