import styled from 'styled-components';

export const ResultContainer = styled.div(
  ({ theme: { spaces, colors } }) => `
  min-width: 500px;
  display: flex;
  flex-direction: column;
  padding: ${spaces['7xl']};
  gap: ${spaces['2xl']};
  background-color: ${colors.secondaryBackground};
`
);

export const Background = styled.div(({theme: {spaces, colors}}) => `
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100vw;
  height: 100vh;
  position: absolute;
  left: 0;
  top: 0;
  padding: ${spaces.xl};
  background-color: ${colors.gray};
`)
