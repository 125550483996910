import React, { useState, useEffect } from 'react';

import { AppConfig, apiUrl } from 'config';
import { GameMode, GameResult, MemoryCardType, GameStage } from 'types';
import { Memory } from 'games';
import { useGameController } from 'hooks';
import { Instruction, Result } from 'components/games';

import MemoryCards from './MemoryCards';
import { MemoryContainer } from './styles';

interface Props {
  memory: Memory;
  instruction: string;
  instructionFileId: string | null;
  gameMode: GameMode;
}

export const MemoryGame: React.FC<Props> = ({
  memory,
  instruction,
  instructionFileId,
  gameMode,
}) => {
  const [intervalId, setIntervalId] = useState<NodeJS.Timer>();
  const [stage, setStage] = useState<GameStage>(GameStage.Instruction);
  const [memoryCards, setMemoryCards] = useState<MemoryCardType[]>(
    memory.memoryCards
  );

  const {
    nextGame,
    saveGameResult,
    runDomainTimer,
    domainGameTimer,
    stopDomainAfterGameTimeout: domainGameTimeout,
    saveSession,
    isSessionEnd,
  } = useGameController();

  const handleOnNextGame = () => {
    if (nextGame()) {
      setStage(GameStage.Instruction);
    } else {
      saveSession(gameMode);
      clearInterval(intervalId);
    }
  };

  const startGame = () => {
    setStage(GameStage.Game);
    memory.showMemoryCards(setMemoryCards);
  };

  const endGame = (result: GameResult) => {
    memory.endGame(result !== GameResult.Lose);
    saveGameResult(memory.gameData, gameMode);
    clearInterval(intervalId);
    setStage(GameStage.Result);
  };

  const handleClick = (cardId: string) => {
    memory.cardClick(cardId, endGame, setMemoryCards);
  };

  useEffect(() => {
    if (stage === GameStage.Game) {
      setIntervalId(runDomainTimer());
    }
    return () => {
      clearInterval(intervalId);
    };

    // eslint-disable-next-line
  }, [stage]);

  useEffect(() => {
    if (domainGameTimer > AppConfig.MAX_GAME_TIME) {
      domainGameTimeout();
      endGame(GameResult.Lose);
    }

    // eslint-disable-next-line
  }, [domainGameTimer]);

  useEffect(() => {
    memory.memoryImages.forEach((image) => {
      const link = document.createElement('link');
      link.href = `${apiUrl}file/${image}`;

      link.rel = 'preload';
      link.as = 'image';
      document.head.appendChild(link);
      return () => {
        document.head.removeChild(link);
      };
    });

    // eslint-disable-next-line
  }, [memory.memoryImages]);

  const getStage = (stage: GameStage) => {
    switch (stage) {
      case GameStage.Instruction:
        return (
          <Instruction
            instruction={instruction}
            instructionFileId={instructionFileId}
            startGame={startGame}
          />
        );
      case GameStage.Game:
        return (
          <MemoryCards
            memory={memory}
            memoryCards={memoryCards}
            cardClick={handleClick}
            level={memory.gameData.level}
            setMemoryCards={setMemoryCards}
          />
        );
      case GameStage.Result:
        return (
          <Result
            action={handleOnNextGame}
            isSessionEnd={isSessionEnd}
            gameMode={gameMode}
          />
        );
      default:
        return null;
    }
  };

  return <MemoryContainer>{getStage(stage)}</MemoryContainer>;
};
