import React from 'react';
import { useFormContext } from 'react-hook-form';
import { ErrorMessage } from '@hookform/error-message';

import { __ } from 'utils/i18n';
import { TranslateText } from 'components/common';
import { FieldErrorMessage } from 'components/forms';

import { StyledTextarea, StyledContainer, StyledLabel, Wrapper } from './style';

interface Props {
  name: string;
  placeholder: string;
  required?: boolean;
  disabled?: boolean;
  autoComplete?: 'off' | 'new-password';
  password?: boolean;
  label?: string;
}

export const TextArea: React.FC<Props> = ({
  name,
  placeholder,
  required,
  disabled,
  password,
  label,
}) => {
  const { register, getFieldState, formState } = useFormContext();
  const { error } = getFieldState(name, formState);

  return (
    <StyledContainer>
      <Wrapper>
        {label && (
          <StyledLabel htmlFor={name}>
            <TranslateText text={label} />
          </StyledLabel>
        )}
        <StyledTextarea
          {...(register && register(name))}
          placeholder={
            required
              ? __(`placeholders.${placeholder}`) + '*'
              : __(`placeholders.${placeholder}`)
          }
          disabled={disabled}
          aria-invalid={!!error}
          required={required}
        />
      </Wrapper>
      {formState.errors && (
        <ErrorMessage
          errors={formState.errors}
          name={name}
          render={({ message }) => <FieldErrorMessage text={message} />}
        />
      )}
    </StyledContainer>
  );
};
