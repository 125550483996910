/* eslint-disable react-hooks/exhaustive-deps */
import { useCallback, useEffect, useState } from 'react';
import { axiosInstance, AppConfig } from 'config';
import { AxiosRequestConfig, AxiosResponse } from 'axios';
import { errorNotification, formValidationNotification } from 'slices';
import { useAppDispatch } from 'hooks';
import { getContentType } from 'utils';

export type ApiReqFnBasic = <T>(
  url: string,
  queryParams?: Record<string, string | string[]>,
  isFile?: boolean
) => Promise<void | T>;

export type ApiReqDownloadFn = (
  url: string,
  queryParams?: Record<string, string | string[]>
) => Promise<void | Blob>;

export type ApiReqFn = <T, K = undefined>(
  url: string,
  body?: K | undefined,
  queryParams?: Record<string, string>
) => Promise<void | T>;
export type ApiReqPostPutFn = <T, K = undefined>(
  url: string,
  body?: K | undefined,
  queryParams?: Record<string, string>,
  asFormData?: boolean
) => Promise<void | T>;
export type ApiReqDelete = <T>(url: string) => Promise<void | T>;

interface UseApi {
  inProgress: boolean;
  isError: boolean;
  get: ApiReqFnBasic;
  post: ApiReqPostPutFn;
  put: ApiReqPostPutFn;
  patch: ApiReqFn;
  remove: ApiReqDelete;
  download: ApiReqDownloadFn;
}

export type ApiPayload<T> = { payload: T };

interface ApiResponse<T> extends AxiosResponse<ApiPayload<T>> {
  isAxiosError?: boolean;
}

const returnPayload = <T>(res: ApiResponse<T>) => res.data?.payload;

export const useApi = (): UseApi => {
  const dispatch = useAppDispatch();
  const [inProgress, setInProgress] = useState(false);
  const [isError, setIsError] = useState(false);

  useEffect(
    () => () => {
      setInProgress(false);
      setIsError(false);
    },
    []
  );

  const get = useCallback(
    <T>(url: string, queryParams?: Record<string, string | string[]>) => {
      setInProgress(true);
      setIsError(false);
      return axiosInstance
        .get<ApiPayload<T>>(url, { params: queryParams })
        .then((res: ApiResponse<T>) => {
          if (res?.isAxiosError) throw res;
          return returnPayload<T>(res);
        })
        .catch((err) => {
          setInProgress(false);
          setIsError(true);
          dispatch(errorNotification(err.response.data.error_code));
        })
        .finally(() => {
          setInProgress(false);
        });
    },
    []
  );

  const download = useCallback(
    (url: string, queryParams?: Record<string, string | string[]>) => {
      setInProgress(true);
      setIsError(false);
      return axiosInstance
        .get<Blob>(url, {
          params: queryParams,
          responseType: 'blob',
        })
        .then((res: AxiosResponse<Blob>) => {
          return res.data;
        })
        .catch((err) => {
          setInProgress(false);
          setIsError(true);
          dispatch(errorNotification(err.response.data.error_code));
        })
        .finally(() => {
          setInProgress(false);
        });
    },
    []
  );

  const post = useCallback(
    <T, K>(
      url: string,
      body?: K,
      queryParams?: Record<string, string>,
      asFormData?: boolean
    ) => {
      setInProgress(true);
      setIsError(false);
      const config: AxiosRequestConfig = {
        headers: { 'Content-Type': getContentType(!!asFormData) },
        params: queryParams,
      };

      if (asFormData) {
        config['timeout'] = AppConfig.FORM_DATA_TIMEOUT_REQUEST;
      }

      return axiosInstance
        .post<ApiPayload<T>>(url, body, config)
        .then((res: ApiResponse<T>) => {
          if (res?.isAxiosError) throw res;
          return res.data.payload;
        })
        .catch((err) => {
          setIsError(true);
          setInProgress(false);
          if (err?.response?.data?.form_errors?.length > 0) {
            dispatch(
              formValidationNotification(err?.response.data.form_errors)
            );
          } else {
            dispatch(errorNotification(err?.response.data.error_code));
          }
        })
        .finally(() => {
          setInProgress(false);
        });
    },
    []
  );

  const put = useCallback(
    <T, K>(url: string, body?: K, queryParams?: Record<string, string>) => {
      setInProgress(true);
      setIsError(false);
      return axiosInstance
        .put<ApiPayload<T>>(url, body, { params: queryParams })
        .then((res: ApiResponse<T>) => {
          if (res?.isAxiosError) throw res;
          return res.data.payload;
        })
        .catch((err) => {
          setInProgress(false);
          setIsError(true);
          if (err?.response?.data?.form_errors?.length > 0) {
            dispatch(
              formValidationNotification(err?.response.data.form_errors)
            );
          } else {
            dispatch(errorNotification(err?.response.data.error_code));
          }
        })
        .finally(() => {
          setInProgress(false);
        });
    },
    []
  );

  const patch = useCallback(
    <T, K>(url: string, body?: K, queryParams?: Record<string, string>) => {
      setInProgress(true);
      setIsError(false);
      return axiosInstance
        .patch<ApiPayload<T>>(url, body, { params: queryParams })
        .then((res: ApiResponse<T>) => {
          if (res?.isAxiosError) throw res;
          return res.data.payload;
        })
        .catch((err) => {
          setInProgress(false);
          setIsError(true);
          if (err?.response?.data?.form_errors?.length > 0) {
            dispatch(
              formValidationNotification(err?.response.data.form_errors)
            );
          } else {
            dispatch(errorNotification(err?.response.data.error_code));
          }
        })
        .finally(() => {
          setInProgress(false);
        });
    },
    []
  );

  // Since delete is reserved we use custom name here
  const remove = useCallback(<T>(url: string) => {
    setInProgress(true);
    setIsError(false);
    return axiosInstance
      .delete<ApiPayload<T>>(url)
      .then((res: ApiResponse<T>) => {
        if (res?.isAxiosError) throw res;
        return res.data.payload;
      })
      .catch((err) => {
        setInProgress(false);
        setIsError(true);

        dispatch(errorNotification(err?.response.data.error_code));
      })
      .finally(() => {
        setInProgress(false);
      });
  }, []);

  return {
    get,
    post,
    patch,
    put,
    remove,
    download,
    inProgress,
    isError,
  };
};
