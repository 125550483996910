import React from 'react';

import { apiUrl } from 'config';
import { FileType } from 'cogamika-back/types';
import { Button, FileSelector, GridContainer } from 'components/common';

import { Content, MainContainer, Overlay, StyledImage } from './styles';

interface Props {
  setIsShow: React.Dispatch<React.SetStateAction<boolean>>;
  selectedFile: Set<string>;
  handleFileSelect: (
    id: string,
    type?: FileType,
    originalName?: string
  ) => void;
  onSave: () => void;
}

export const SelectFile: React.FC<Props> = ({
  setIsShow,
  selectedFile,
  handleFileSelect,
  onSave,
}) => {
  const save = () => {
    setIsShow(false);
    onSave();
  };

  const hide = () => {
    setIsShow(false);
  };

  return (
    <Overlay onClick={hide} role="button" tabIndex={0}>
      <Content
        id="modal-content"
        onClick={(e) => e.stopPropagation()}
        role="button"
        tabIndex={0}
      >
        <MainContainer>
          <GridContainer size={[2]} gap="7xl">
            <FileSelector
              types={[FileType.Image]}
              refreshList={false}
              onSelectFile={handleFileSelect}
              selectedIds={selectedFile}
            />
            {selectedFile.size > 0 && (
              <StyledImage
                src={`${apiUrl}file/${Array.from(selectedFile)[0]}`}
                width="280"
              ></StyledImage>
            )}
          </GridContainer>
          <Button text="button.save" variant="primary" onClick={save} />
        </MainContainer>
      </Content>
    </Overlay>
  );
};
