import { useState } from 'react';

import { QuestionAnswerType, QuestionWithAnswers } from 'cogamika-back/types';
import { GameResult, SurveyQuestionResult } from 'types';
import { Survey } from 'games';

interface Props {
  survey: Survey;
  endGame: (result?: GameResult) => void;
}

interface UseSurveyGame {
  currentQuestionIndex: number;
  questionWithAnswers: QuestionWithAnswers;
  selectedAnswer: number | undefined;
  result: SurveyQuestionResult;
  correctAnswerIndex: number;
  answerType: QuestionAnswerType;
  selectAnswer: (index: number) => void;
  nextQuestion: (nextQuestionIndex: number) => void;
}

export const useSurveyGame = ({ survey, endGame }: Props): UseSurveyGame => {
  const [currentQuestionIndex, setCurrentQuestionIndex] = useState<number>(0);
  const [questionWithAnswers, setQuestionWithAnswers] =
    useState<QuestionWithAnswers>(survey.getQuestion(0));
  const [selectedAnswer, setSelectedAnswer] = useState<number>();
  const [result, setResult] = useState<SurveyQuestionResult>(
    SurveyQuestionResult.Pending
  );
  const correctAnswerIndex = questionWithAnswers.answers.findIndex(
    (item) => item.isCorrect
  );
  const answerType = questionWithAnswers.answers[0].type;

  const selectAnswer = (index: number) => {
    if (selectedAnswer) return;

    if (correctAnswerIndex === index) {
      setResult(SurveyQuestionResult.Success);
    } else {
      setResult(SurveyQuestionResult.Failure);
    }

    setSelectedAnswer(index);
  };

  const clearGame = () => {
    setResult(SurveyQuestionResult.Pending);
    setSelectedAnswer(undefined);
  };

  const nextQuestion = (nextQuestionIndex: number) => {
    const isNextQuestion = survey.checkIsNextQuestion(nextQuestionIndex);
    if (!isNextQuestion) {
      if (survey.gameData.incorrectAnswers > 0) {
        endGame(GameResult.Lose);
      } else {
        endGame(GameResult.Win);
      }
      clearGame();
      return;
    }

    clearGame();
    setCurrentQuestionIndex(nextQuestionIndex);
    setQuestionWithAnswers(survey.getQuestion(nextQuestionIndex));
  };

  return {
    currentQuestionIndex,
    questionWithAnswers,
    selectedAnswer,
    result,
    correctAnswerIndex,
    answerType,
    selectAnswer,
    nextQuestion,
  };
};
