import { DndProvider } from 'react-dnd';
import React, { useCallback, useEffect } from 'react';
import { HTML5Backend } from 'react-dnd-html5-backend';

import { Collection } from 'games';
import { useCollectionGame } from 'hooks';

import { Box } from '../Box';
import { Bucket } from '../Bucket';
import { BucketContainer } from './styles';

interface Props {
  collection: Collection;
  endGame: () => void;
}

export const Game: React.FC<Props> = ({ collection, endGame }) => {
  const {
    status,
    canDrag,
    currentImage,
    leftArrowClick,
    rightArrowClick,
    onDrop,
  } = useCollectionGame({ collection, endGame });

  const handleArrowClick = useCallback(
    (e: KeyboardEvent) => {
      e.preventDefault();
      if (!canDrag) return;
      if (e.code === 'ArrowLeft') {
        leftArrowClick();
      } else if (e.code === 'ArrowRight') {
        rightArrowClick();
      }
    },
    [leftArrowClick, rightArrowClick, canDrag]
  );

  useEffect(() => {
    window.addEventListener('keyup', handleArrowClick);

    return () => {
      window.removeEventListener('keyup', handleArrowClick);
    };
  }, [handleArrowClick]);

  return (
    <DndProvider backend={HTML5Backend}>
      <BucketContainer>
        <Bucket
          status={status.firstCollection}
          justify="end"
          dropImage={onDrop}
          bucketItem={collection.firstCollection}
        />
        <Bucket
          status={status.secondCollection}
          justify="start"
          dropImage={onDrop}
          bucketItem={collection.secondCollection}
        />
        <Box
          key={currentImage.description}
          image={currentImage}
          canDrag={canDrag}
        />
      </BucketContainer>
    </DndProvider>
  );
};
