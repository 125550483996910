import { __ } from './i18n';

interface OptionType {
  label: string;
  value: string;
}

export const getSelectOptionsFromEnum = <T>(
  object: { [s: string]: T },
  withTranslation = false,
  addictionPhrase = ''
): OptionType[] =>
  Object.values(object).map(
    (option): OptionType => ({
      label: withTranslation
        ? __(`enum.${option}`.toLowerCase() + addictionPhrase)
        : `${option}`,
      value: `${option}`,
    })
  );
