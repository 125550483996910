import { ClipLoader } from 'react-spinners';

import { colors } from 'config/theme/basicStyleValues/colors';

import { Overlay, SpinnerContainer } from './styles';

export const Spinner = () => {
  return (
    <Overlay>
      <SpinnerContainer>
        <ClipLoader color={colors.primary} size={100} />
      </SpinnerContainer>
    </Overlay>
  );
};
