import styled from 'styled-components';

export const FooterWrapper = styled('div')(
  ({ theme }) => `
  padding: 30px 60px;
  margin-top: 30px;
  display: flex;
  width: 100%;
  min-height: 140px;
  align-items: center;
  background-color: ${theme.colors.primary}04; 
`
);
