import { createGlobalStyle } from 'styled-components';

export const GlobalStyle = createGlobalStyle`
*{
  margin: 0;
  padding: 0;
  outline:0;
  box-sizing:border-box;
  font-family: 'Work Sans', sans-serif; 
}
#root{
  margin:0 auto;
}
p,h1,h2,h3,h4,h5,h6{
  margin: 0;
}
`;
