import React from 'react';
import { Icon, Text } from 'components/common';
import { ErrorsContainer, StyledWarningContainer } from './styles';

interface Props {
  isError: boolean;
  text?: string;
  noTranslate?: boolean;
  formValidationErrors?: string[];
}

export const Warning: React.FC<Props> = ({
  text,
  isError,
  noTranslate,
  formValidationErrors,
}) => {
  if (!isError) return <StyledWarningContainer isError={false} />;

  return (
    <StyledWarningContainer isError={true}>
      <Icon name="warning" />
      {text && (
        <Text
          text={text}
          color="error"
          weight="semibold"
          noTranslate={noTranslate}
        />
      )}
      {formValidationErrors && formValidationErrors.length > 0 && (
        <ErrorsContainer>
          {formValidationErrors?.map((error) => (
            <Text
              text={error}
              noTranslate
              color="error"
              weight="semibold"
              key={error}
            />
          ))}
        </ErrorsContainer>
      )}
    </StyledWarningContainer>
  );
};
