import { ltMedia } from 'config';
import styled from 'styled-components';
import { LazyLoadImage } from 'react-lazy-load-image-component';
import 'react-lazy-load-image-component/src/effects/opacity.css';

export const BoxContainer = styled.div`
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
  border-radius: 20px;
  box-shadow: none;
`;

export const BoxImage = styled(LazyLoadImage)(
  ({ theme: { radius } }) => `
  width: 100%;
  height: 100%;
  max-width: 280px;
  max-height: 280px;
  cursor: grab;
  object-fit: scale-down;
  transform: translate(0, 0);
  border-radius: ${radius.md};

  ${ltMedia('md')} {
    max-width: 180px;
    max-height: 180px;
  }

  ${ltMedia('sm')} {
    max-width: 100px;
    max-height: 100px;
  }
`
);
