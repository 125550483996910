import React, { useEffect } from 'react';
import { useFormContext } from 'react-hook-form';
import { ErrorMessage } from '@hookform/error-message';

import { TranslateText } from 'components/common';
import { FieldErrorMessage } from 'components/forms';

import { StyledSelect, Option, Label, SelectContainer } from './styles';

interface OptionType {
  label: string;
  value: string;
}

interface Props {
  name: string;
  options: OptionType[];
  label: string;
  noTranslate?: boolean;
  disabled?: boolean;
  onChange?: (newValue: OptionType) => void;
  defaultValue?: OptionType;
}

export const Select: React.FC<Props> = ({
  options,
  name,
  label,
  noTranslate,
  disabled,
  defaultValue,
  onChange,
}) => {
  const { register, formState, setValue } = useFormContext();

  const handleOnChange = (newValue: React.ChangeEvent<HTMLSelectElement>) => {
    if (onChange)
      onChange({
        value: newValue.currentTarget.value,
        label: newValue.currentTarget.value,
      });
  };

  useEffect(() => {
    if (defaultValue) {
      setValue(name, defaultValue);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <SelectContainer>
      <Label htmlFor={name}>
        <TranslateText text={label} />
      </Label>
      <StyledSelect
        {...register(name, {
          onChange: handleOnChange,
        })}
        disabled={disabled}
      >
        <Option value="">
          <TranslateText text="button.choose" />
        </Option>
        {options.map((option) => (
          <Option key={option.value} value={option.value}>
            <TranslateText text={option.label} noTranslate={noTranslate} />
          </Option>
        ))}
      </StyledSelect>
      {formState.errors && (
        <ErrorMessage
          errors={formState.errors}
          name={name}
          render={({ message }) => <FieldErrorMessage text={message} />}
        />
      )}
    </SelectContainer>
  );
};
