import styled from 'styled-components';

interface Props {
  fullWidth?: boolean;
}

export const StyledInputContainer = styled.div<Props>`
  width: 100%;
`;

export const StyledInput = styled.input(
  ({ theme: { colors } }) => `
  display: flex;
  border: 2px solid ${colors.gray};
  padding: 14px 24px;
  border-radius: 10px;
  font-size: 18px;
  width: 100%;
  ::placeholder {
    color: black;
  }
`
);

export const StyledLabel = styled.label(
  ({ theme: { fontSizes } }) => `
  font-size: ${fontSizes.base};
  `
);

export const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 10px;
`;
