import React, { useState } from 'react';
import { useForm } from 'react-hook-form';
import { useNavigate } from 'react-router-dom';
import { yupResolver } from '@hookform/resolvers/yup';

import { resetPasswordSchema, routes } from 'config';
import { ResetPassword } from 'cogamika-back/types';
import { useAppSelector, useApi } from 'hooks';
import { Form, TextInput } from 'components/forms';
import { Button, Flex, Spinner, Text, Warning } from 'components/common';
import { i18n } from 'utils/i18n';

import { StyledContainer, TextContainer } from './styles';

export const ResetPasswordForm: React.FC = () => {
  const navigate = useNavigate();
  const [isSuccess, setIsSuccess] = useState(false);
  const { post, inProgress } = useApi();
  const { errorNotification, formValidationErrors } = useAppSelector(
    (state) => state.notifications
  );

  const methods = useForm<ResetPassword>({
    mode: 'onSubmit',
    resolver: yupResolver(resetPasswordSchema),
  });
  const { isSubmitting } = methods.formState;
  const isButtonDisabled = isSubmitting;

  const langQueryParams = i18n.currentLang && { lang: i18n.currentLang };

  const submit = async (data: ResetPassword) => {
    const res = await post<undefined, ResetPassword>(
      `/auth/reset-password`,
      {
        email: data.email,
      },
      langQueryParams
    );

    if (res) {
      setIsSuccess(true);
    }
  };

  const goToGome = () => {
    navigate(routes.root);
  };

  return (
    <StyledContainer>
      {inProgress && <Spinner />}
      {isSuccess ? (
        <Flex columnDirection gap="3xl">
          <Flex columnDirection gap="sm">
            <Text
              text="views.resetPassword.mailSentHeading"
              size="xl"
              weight="semibold"
            />
            <Text
              weight="medium"
              size="md"
              text="views.resetPassword.mailSentDescription"
              translationArgs={{ email: methods.getValues('email') }}
            />
          </Flex>
          <Button
            onClick={goToGome}
            variant="primary"
            text="button.close"
            type="submit"
            fullWidth
            uppercase
          />
        </Flex>
      ) : (
        <>
          <Flex columnDirection gap="sm">
            <Text
              text="views.resetPassword.heading"
              size="xl"
              weight="semibold"
            />
            <TextContainer>
              <Text
                text="views.resetPassword.description"
                size="sm"
                weight="normal"
              />
            </TextContainer>
          </Flex>
          <Form submitHandler={submit} methods={methods}>
            <Flex columnDirection gap="3xl" fullWidth>
              <Flex columnDirection gap="base" fullWidth>
                <TextInput
                  name="email"
                  placeholder="email"
                  fullWidth
                  required
                />
                <Warning
                  text={errorNotification?.text}
                  isError={
                    !!errorNotification || formValidationErrors.length > 0
                  }
                  formValidationErrors={formValidationErrors}
                  noTranslate
                />
              </Flex>
              <Button
                variant="primary"
                text="button.send"
                type="submit"
                fullWidth
                uppercase
                disabled={isButtonDisabled}
              />
            </Flex>
          </Form>
        </>
      )}
    </StyledContainer>
  );
};
