import styled from 'styled-components';

export const MovieFormContainer = styled.div`
  width: 100%;
`;

export const FormWrapper = styled.div(
  ({ theme: { spaces } }) => `
  display: flex;
  justify-content: space-between;
  gap: ${spaces['6xl']};
`
);
