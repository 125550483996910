import React, { useState } from 'react';
import { UseFieldArrayAppend } from 'react-hook-form';

import { FileType } from 'cogamika-back/types';
import { ChronologyGameFormType } from 'types';
import { SelectFile } from 'components/forms';

interface Props {
  setIsShow: React.Dispatch<React.SetStateAction<boolean>>;
  append: UseFieldArrayAppend<ChronologyGameFormType>;
}

export const SelectChronologyImage: React.FC<Props> = ({
  setIsShow,
  append,
}) => {
  const [selectedFile, setSelectedFile] = useState(() => new Set<string>());
  const [fileName, setFileName] = useState<string>('');

  const handleFileSelect = (
    id: string,
    type?: FileType,
    originalName?: string
  ) => {
    if (selectedFile.has(id)) {
      setSelectedFile(new Set());
      setFileName('');
    } else {
      setSelectedFile(new Set([id]));
      setFileName(originalName || 'image');
    }
  };

  const save = () => {
    append({ description: Array.from(selectedFile)[0], name: fileName });
  };

  return (
    <SelectFile
      handleFileSelect={handleFileSelect}
      selectedFile={selectedFile}
      setIsShow={setIsShow}
      onSave={save}
    />
  );
};
