import * as yup from 'yup';
import {
  reqiuredMovieRange,
  requiredFileSchema,
  requiredLangStringSchema,
  requiredOneCharacterSchema,
  requiredStringSchema,
} from 'utils';

export const detailsGame = yup.object().shape({
  domainId: requiredStringSchema,
  subdomainId: requiredStringSchema,
  categoryId: requiredStringSchema,
});

export const gameName = yup.object().shape({
  title: requiredStringSchema,
  gameType: requiredStringSchema,
});

export const instructionSchema = yup.object().shape({
  instruction: requiredStringSchema,
});

export const descriptionSchema = yup.object().shape({
  description: requiredStringSchema,
  miniatureFileId: yup.string().required('validation.miniaturefieldIsRequired'),
});

export const memoryGameSchema = yup.object().shape({
  imagesIds: yup
    .array()
    .of(yup.string())
    .min(1, 'validation.memoryImagesLength')
    .required('validation.fieldIsRequired'),
});

export const surveyGameSchema = yup.object().shape({
  initialData: yup
    .object()
    .shape({
      time: yup.number().min(1),
    })
    .default(undefined),
  surveyQuestions: yup
    .array()
    .of(
      yup.object().shape({
        question: yup.object().shape({
          description: requiredOneCharacterSchema,
          type: requiredOneCharacterSchema,
        }),
        answers: yup
          .array()
          .of(
            yup.object().shape({
              description: requiredOneCharacterSchema,
              type: requiredOneCharacterSchema,
            })
          )
          .min(4, 'validation.allSurveyAnswersRequired')
          .test(
            'same-answer-type',
            'validation.surveyAnswersIncorrectType',
            function (value) {
              if (!value || value.length === 0) {
                return true;
              }
              const firstAnswerValue = value[0].type;

              return value.every((answer) => answer.type === firstAnswerValue);
            }
          ),
      })
    )
    .min(1, 'validation.oneQuestionRequired'),
});

export const ChronologyGameSchema = yup.object().shape({
  elements: yup
    .array()
    .of(
      yup.object().shape({
        description: yup
          .string()
          .required('validation.fieldIsRequired')
          .min(1, 'validation.textTooShort'),
      })
    )
    .min(3, 'validation.chronologyMinimumElements'),
});

export const filesSchema = yup.object().shape({
  files: yup.array().min(1, 'validation.minimumOneFile'),
});

export const MovieGameSchema = yup.object().shape({
  video: yup.object().shape({
    id: requiredFileSchema,
  }),
  timeRanges: yup
    .array()
    .of(
      yup.object().shape({
        startTimeMinutes: reqiuredMovieRange,
        startTimeSeconds: reqiuredMovieRange,
        endTimeMinutes: reqiuredMovieRange,
        endTimeSeconds: reqiuredMovieRange,
      })
    )
    .min(1, 'validation.minimumOneRange'),
});

export const gameSelectLanguage = yup.object().shape({
  lang: requiredLangStringSchema,
});
