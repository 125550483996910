import axios, { AxiosRequestConfig } from 'axios';

import { apiUrl } from './url';
import { store } from './store';
import { clearUserData } from 'slices';

const REQ_TIMEOUT = 200000;

export const axiosRequestConfigurationJson: AxiosRequestConfig = {
  baseURL: apiUrl,
  withCredentials: true,
  timeout: REQ_TIMEOUT,
  headers: {
    'Content-Type': 'application/json',
  },
};

const axiosInstance = axios.create(axiosRequestConfigurationJson);

axiosInstance.interceptors.request.use(
  (c) => c,
  (error) => Promise.reject(error)
);

axiosInstance.interceptors.response.use(
  (res) => res,
  (error) => {
    if (error.response.status === 401) {
      store.dispatch(clearUserData());
    }
    return error;
  }
);

export { axiosInstance };
