import React from 'react';

import { Badge, Button, Flex, FlexAlignCenter, Text } from 'components/common';
import { clearSessionGames, setBadges } from 'slices';
import { useAppDispatch, useAppSelector } from 'hooks';
import { useNavigate } from 'react-router-dom';
import { userRoutes } from 'config';

import { TrainingEndedContainer } from './styles';
export const TrainingEnded: React.FC = () => {
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const { badges } = useAppSelector((state) => state.gameController);

  const handleOnFinished = () => {
    dispatch(clearSessionGames());
    navigate(userRoutes.dashboard);
  };

  const handleOnSeeProgress = () => {
    dispatch(clearSessionGames());
    navigate(userRoutes.account);
  };

  const closeBadges = () => dispatch(setBadges([]));

  return (
    <TrainingEndedContainer>
      {badges.length > 0 ? (
        <Flex columnDirection gap="base" fullWidth>
          <Text text="views.game.obtainedBadge" size="lg" weight="semibold" />
          <Flex columnDirection gap="lg" fullWidth>
            {badges.map(({ id, name, fileId, description }) => (
              <Badge
                key={id}
                direction="row"
                name={name}
                imgId={fileId || ''}
                description={description}
                canShowModal
                isAchieved
              />
            ))}
          </Flex>
          <Button
            fullWidth
            variant="secondary"
            text="button.next"
            uppercase
            onClick={closeBadges}
          />
        </Flex>
      ) : (
        <>
          <Flex columnDirection gap="base">
            <Text
              text="views.game.trainingEndedHeading"
              size="lg"
              weight="semibold"
            />
            <Text text="views.game.trainingEnded" />
          </Flex>
          <FlexAlignCenter gap="md">
            <Button
              variant="secondary"
              text="button.finish"
              uppercase
              onClick={handleOnFinished}
              dataCy="finish-session"
            />
            <Button
              variant="outlined"
              text="button.seeProgress"
              uppercase
              onClick={handleOnSeeProgress}
            />
          </FlexAlignCenter>
        </>
      )}
    </TrainingEndedContainer>
  );
};
