import styled from 'styled-components';

export const BucketContainer = styled.div(
  ({ theme: { spaces } }) => `
  display: flex;
  gap: ${spaces['7xl']};
  position: relative;
  width: 100%;
  height: calc(100vh - 112px);
  box-sizing: border-box;
  padding: ${spaces['2xl']};
`
);
