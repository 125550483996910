import React, { useContext, useEffect } from 'react';
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';

import { gameName } from 'config/validationSchemas/detailsGame';
import { GameType } from 'cogamika-back/types';
import { GameFormSteps, GameNameFormType } from 'types';
import { GameFormContext } from 'context';
import { Button, Flex, Heading } from 'components/common';
import { Form, TextInput, Select } from 'components/forms';

import { StyledDetailsForm, FormContainer } from './styles';

const GAME_OPTIONS = [
  {
    label: 'options.memory',
    value: GameType.Memory,
  },
  {
    label: 'options.survey',
    value: GameType.Survey,
  },
  {
    label: 'options.collection',
    value: GameType.Collection,
  },
  {
    label: 'options.chronology',
    value: GameType.Chronology,
  },
  {
    label: 'options.movie',
    value: GameType.Movie,
  },

  {
    label: 'options.mosaic',
    value: GameType.Mosaic,
  },
];

interface Props {
  disabled?: boolean;
}

export const GameNameForm: React.FC<Props> = ({ disabled }) => {
  const { setDetailsForm, detailsForm, setStep } = useContext(GameFormContext);

  const methods = useForm<GameNameFormType>({
    mode: 'onSubmit',
    resolver: yupResolver(gameName),
  });

  const { isSubmitting, isValid } = methods.formState;
  const isButtonDisabled = isSubmitting || !isValid;

  const submit = (data: GameNameFormType) => {
    setStep(GameFormSteps.Details);
    setDetailsForm((prev) => {
      return {
        ...prev,
        gameName: data,
      };
    });
  };

  const goBack = () => {
    setStep(GameFormSteps.SelectLanguage);
  };

  useEffect(() => {
    methods.reset({
      title: detailsForm?.gameName?.title,
      gameType: detailsForm?.gameName?.gameType,
    });
  }, [methods, detailsForm]);

  return (
    <StyledDetailsForm>
      <Heading text="views.newGame.heading" size="xl" />
      <FormContainer>
        <Form submitHandler={submit} methods={methods}>
          <Flex columnDirection gap="lg">
            <TextInput
              name="title"
              placeholder="empty"
              label="labels.gameName"
              fullWidth
            />
            <Select
              label="labels.gameType"
              name="gameType"
              options={GAME_OPTIONS}
              disabled={disabled}
            />
            <Button
              text="button.next"
              variant="secondary"
              fullWidth
              type="submit"
              uppercase
              disabled={isButtonDisabled}
            />
            <Button
              text="button.back"
              variant="primary"
              type="button"
              fullWidth
              uppercase
              onClick={goBack}
            />
          </Flex>
        </Form>
      </FormContainer>
    </StyledDetailsForm>
  );
};
