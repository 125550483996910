import { object } from 'yup';
import { checkFaqAnswer, checkFaqQuestion, checkFaqTopic } from 'utils';

export const addEditTopicSchema = object().shape({
  topic: checkFaqTopic(),
});

export const addEditQuestionSchema = object().shape({
  question: checkFaqQuestion(),
  answer: checkFaqAnswer(),
});
