import React from 'react';
import { Text, Flex } from 'components/common';
import { useModal } from 'hooks';
import { BadgeInfoModalData } from 'types';
import { apiUrl } from 'config';
import { MainContainer, StyledImage } from './styles';

export const BadgeInfo: React.FC = () => {
  const { getData } = useModal();
  const { imgId, name, description } = getData<BadgeInfoModalData>();

  return (
    <MainContainer>
      <Flex columnDirection gap="xl" fullWidth alignItems="center">
        <StyledImage src={`${apiUrl}file/${imgId}`} />
        <Text
          text={name}
          size="md"
          textAlign="center"
          weight="semibold"
          noTranslate
        />
        <Text text={description} size="sm" textAlign="center" noTranslate />
      </Flex>
    </MainContainer>
  );
};
