import { FC, useEffect } from 'react';
import { Flex } from '../Flex';
import { Heading } from '../Text';
import { Text } from '../Text';
import {
  SubscriptionButton,
  SubscriptionContent,
  SubscriptionDescriptionList,
  SubscriptionDescriptionListItem,
  SubscriptionPackageWrapper,
} from './styles';
import { SubscribePlans } from 'cogamika-back/types';
import { subscriptionColor, subscriptionsData } from 'config';
import { LinkButton } from 'components/common';
import { ModalType } from 'types';
import { useCurrency, useModal } from 'hooks';
import { i18n } from 'utils/i18n';

interface SubscriptionPackageProps {
  type: SubscribePlans;
  itemsAmount?: number;
  action?: () => void;
}

export const SubscriptionPackage: FC<SubscriptionPackageProps> = ({
  type,
  action,
  itemsAmount = 0,
}) => {
  const { showModal } = useModal();
  const [countedCurrency, countCurrency] = useCurrency();

  const openRegisterModal = () => {
    showModal({
      type: ModalType.Register,
      data: null,
    });
  };

  const openLoginModal = () => {
    showModal({
      type: ModalType.Login,
      data: null,
    });
  };

  let description: string[] = [];

  for (let i = 0; i < itemsAmount; i++) {
    description.push(`views.payment.packages.${type}.item${i}`);
  }

  useEffect(() => {
    countCurrency(subscriptionsData[type].price, i18n.currentLang);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const descriptionItems = description.map((text) => (
    <SubscriptionDescriptionListItem key={text}>
      <Text size="xs" text={text} />
    </SubscriptionDescriptionListItem>
  ));

  return (
    <SubscriptionPackageWrapper>
      <SubscriptionContent>
        <Flex columnDirection gap="md" alignItems="center" fullWidth>
          <Heading
            size="sm"
            text={`views.payment.packages.${type}.name`}
            color="teritaryGray"
          />
          <Heading
            size="xl"
            text="views.payment.subcriptionPrice"
            translationArgs={{ price: subscriptionsData[type].price }}
            color={subscriptionColor[type]}
            weight="semibold"
          />
          {countedCurrency && (
            <Heading
              size="sm"
              text="views.payment.otherCurrency"
              translationArgs={{ priceInOtherCurrency: countedCurrency || '' }}
              color="teritaryGray"
            />
          )}
          <Text
            size="xs"
            textAlign="start"
            text={`views.payment.packages.${type}.shortDescription`}
            color="primary"
          />
          <SubscriptionDescriptionList>
            {descriptionItems}
          </SubscriptionDescriptionList>
        </Flex>
        {action ? (
          <Flex columnDirection fullWidth alignItems="center">
            <SubscriptionButton
              text="button.choose"
              variant="primary"
              fullWidth
              color={subscriptionColor[type]}
              onClick={action}
              uppercase
            />
          </Flex>
        ) : (
          <Flex columnDirection justifyContent="center" alignItems="center">
            <Flex alignItems="flex-end" gap="2xs">
              <LinkButton
                onClick={openRegisterModal}
                text="button.register"
                underline
                size="sm"
              />
              <Text text="views.payment.or" weight="normal" />
              <LinkButton
                onClick={openLoginModal}
                text="button.subscriptionLogin"
                underline
                size="sm"
              />
            </Flex>
            <Text text="views.payment.selectPlan" weight="normal" />
          </Flex>
        )}
      </SubscriptionContent>
    </SubscriptionPackageWrapper>
  );
};
