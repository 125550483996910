import { UserFullData } from './user';
import { UserDomainLevelFullData } from './user-domain-level-history';
import { UserGameSessionFullData } from '../session';
import { AccountBadgeFullData, BadgeRes, CategorisedBadges } from './badge';
import { Language } from '../translate';

export interface UserAccountBaseData {
  id: string;
  nick: string;
  lang: Language;
}

export interface UserAccountFullData extends UserAccountBaseData {
  user: UserFullData;
  domainLevelHistory: UserDomainLevelFullData[];
  gameSession: UserGameSessionFullData[];
  badges: AccountBadgeFullData[];
}

export type AccountData = UserAccountBaseData;

export enum ChartViewMode {
  Week = 'WEEK',
  Month = 'MONTH',
}

export type ProfileStats = {
  daysInARow: number;
  currentTopLevel: number;
  hoursTotal: number;
  badges: CategorisedBadges[];
};

export type ProfileChartReq = {
  mode: ChartViewMode;
  date: string;
  domainId: string;
};

export type ChartDatesRange = {
  start: Date;
  end: Date;
};

export type ProfileChartData = {
  progress: number;
  level: number;
  date: string;
};

export type ChartDateRes = ProfileChartData[];

export type ChartDataWithCurrentDomainLevelRes = {
  chart: ProfileChartData[];
  currentDomainLevel: number;
};

export type AccountProgressByDomain = {
  progress: number;
  domainName: string;
};

export type UserDashboardDataRes = {
  isSessionDone: boolean;
  lastAccomplishedBadges: BadgeRes[];
  domainsStats: AccountProgressByDomain[];
};
