import {
  BadgeRes,
  GameResourcesRes,
  GameSessionRes,
  NextGameInSessionLevel,
  UserGameReq,
  UserGameSessionReq,
} from 'cogamika-back/types';
import { routes, userRoutes } from 'config';
import { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import {
  addPlayedGame,
  clearSessionGames,
  setBadges,
  setGameDomains,
  setGameWithNextLevel,
  setNextGame,
  setSessionGames,
  setSessionIsEnded,
} from 'slices';
import { GameMode, ModalType } from 'types';
import { prepareURLParamsString } from 'utils';
import { useAppDispatch, useAppSelector } from './redux';
import { useApi } from './useApi';
import { useModal } from './useModal';

interface GetGameOptions {
  gameMode: GameMode;
  level: number;
}

interface UseSession {
  getSessionGames: () => void;
  getGame: (gameId: string, options?: GetGameOptions) => void;
  nextGame: () => boolean;
  stopDomainAfterGameTimeout: () => void;
  runDomainTimer: () => NodeJS.Timer;
  saveSession: (gameMode: GameMode) => void;
  saveGameResult: (data: UserGameReq, gameMode: GameMode) => void;
  getGameForAdminTest: (gameId: string, level: string) => void;
  currentGame: number;
  domainGameTimer: number;
  inProgress: boolean;
  isSessionEnd: boolean;
  settings?: GameResourcesRes;
}

export const useGameController = (): UseSession => {
  const [domainGameTimer, setDomainGameTimer] = useState(0);
  const [isDomainEnded, setIsDomainEnded] = useState<boolean>(false);
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const { get, post, inProgress } = useApi();
  const { showModal } = useModal();
  const {
    currentGameIndex,
    gamesToPlay,
    gamesPlayed,
    currentGameSettings: currentGameInstruction,
    isSessionEnd,
  } = useAppSelector((state) => state.gameController);

  const levelUp = async (
    currentLevel: number,
    currentGameId: string,
    currentGameDomainId: string,
    isLevelUp: boolean
  ) => {
    const gamesAlreadyPlayedInDomainIds = [
      ...gamesPlayed
        .filter((game) => game.domainId === currentGameDomainId)
        .map((game) => game.gameId),
      currentGameId,
    ];
    const nextLevelGame = isLevelUp
      ? NextGameInSessionLevel.Next
      : NextGameInSessionLevel.Same;

    const queryParams = prepareURLParamsString(
      {
        gamesAlreadyPlayedInDomainIds,
        currentGameId,
        currentGameDomainId,
        nextLevelGame,
        currentLevel: String(currentLevel),
      },
      { encode: false }
    );

    const result = await get<GameResourcesRes>(
      `session/next-game${queryParams}`
    );

    if (result) {
      dispatch(setGameWithNextLevel(result));
    }
  };

  const levelUpSameGame = async (
    currentLevel: number,
    currentGameId: string
  ) => {
    const result = await get<GameResourcesRes>(`game/level/${currentGameId}`, {
      level: `${currentLevel + 1}`,
    });
    if (result) {
      dispatch(setGameWithNextLevel(result));
    }
  };

  const checkPossibilityToLevelUp = (data: UserGameReq): boolean => {
    const firstLastGameIndex = gamesPlayed.length - 1;

    if (firstLastGameIndex < 0) return false;

    const firstLastGame = gamesPlayed[firstLastGameIndex];
    const currentGame = data;

    const isSameLevel = firstLastGame.level === currentGame.level;
    const areBothWin = firstLastGame.win && currentGame.win;

    return isSameLevel && areBothWin;
  };

  const getGame = async (gameId: string, options?: GetGameOptions) => {
    let url = `game/${gameId}`;
    let query = {};
    if (options) {
      const { level, gameMode } = options;
      url = gameMode === GameMode.Free ? `game/free` : `game/${gameId}`;
      query =
        gameMode === GameMode.Free
          ? {
              gameId,
              level,
            }
          : {};
    }
    const result = await get<GameResourcesRes>(url, query);
    if (result) {
      dispatch(setSessionGames([result]));
    }
  };

  const getGameForAdminTest = async (gameId: string, level: string) => {
    const result = await get<GameResourcesRes>(`game/level/${gameId}`, {
      level,
    });
    if (result) {
      dispatch(setSessionGames([result]));
    }
  };

  const getSessionGames = async () => {
    const result = await get<GameSessionRes>('session');
    if (result) {
      const domains = result.games.map((game) => ({
        ...game.domain,
        isDone: false,
        isCurrent: false,
      }));
      domains[0].isCurrent = true;
      dispatch(setGameDomains(domains));
      dispatch(setSessionGames(result.games));
    } else {
      showModal({
        type: ModalType.SessionUnavailableError,
        data: null,
      });
    }
  };

  const saveSession = async (gameMode: GameMode) => {
    const payload = {
      games: gamesPlayed,
    };

    switch (gameMode) {
      case GameMode.Session:
        const result = await post<BadgeRes[], UserGameSessionReq>(
          'session',
          payload
        );
        if (!result) {
          showModal({
            type: ModalType.SaveSessionError,
            data: null,
          });
        } else {
          dispatch(setBadges(result));
        }
        break;
      case GameMode.OneGame:
        dispatch(clearSessionGames());
        navigate(userRoutes.games);
        break;
      case GameMode.Free:
        dispatch(clearSessionGames());
        navigate(routes.root);
        break;
    }
  };

  const nextGame = () => {
    if (isDomainEnded) {
      dispatch(setNextGame());
    }
    return !isSessionEnd;
  };

  const runDomainTimer = () => {
    const intervalId = setInterval(
      () => setDomainGameTimer((prev) => prev + 1),
      1000
    );
    setIsDomainEnded(false);
    return intervalId;
  };

  const stopDomainAfterGameTimeout = () => {
    setDomainGameTimer(0);
    setIsDomainEnded(true);

    const isOutOfPreparedGames = currentGameIndex + 1 > gamesToPlay.length - 1;

    if (isOutOfPreparedGames) dispatch(setSessionIsEnded());
  };

  const saveGameResult = (data: UserGameReq, gameMode: GameMode) => {
    dispatch(addPlayedGame(data));

    const { domainId, level, gameId } = data;

    const canUserLevelUp = checkPossibilityToLevelUp(data);

    switch (gameMode) {
      case GameMode.OneGame:
        if (!canUserLevelUp) return;
        levelUpSameGame(level, gameId);
        break;
      case GameMode.Free:
        if (!canUserLevelUp) return;
        getGame(gameId, { gameMode, level: level + 1 });
        break;
      default:
        levelUp(level, gameId, domainId, canUserLevelUp);
    }
  };

  return {
    getSessionGames,
    getGame,
    nextGame,
    stopDomainAfterGameTimeout,
    runDomainTimer,
    saveGameResult,
    saveSession,
    getGameForAdminTest,
    domainGameTimer,
    inProgress,
    isSessionEnd,
    settings: currentGameInstruction,
    currentGame: currentGameIndex,
  };
};
