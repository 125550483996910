import styled from 'styled-components';

export const StyledSearchInput = styled.input(
  ({ theme: { colors, fontSizes, fontWeight } }) => `
  background-color: ${colors.lightGray};
  border-radius: 67px;
  padding: 16px 24px 16px 66px;
  width: 100%;
  border: 2px solid ${colors.primaryBorder};
  font-size: ${fontSizes.md};  

  &::placeholder {
    color: ${colors.primary};
    font-weight: ${fontWeight.medium}
  }
  `
);
