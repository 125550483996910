import { GameFullData } from '../game';
import { BasePaginationWithSearch } from '../base-classes';
import { BadgeFullData } from '../user';

export enum FileType {
  Image = 'IMAGE',
  Audio = 'AUDIO',
  Video = 'VIDEO',
}

export interface FileBaseData {
  id: string;
  name: string;
  originalName: string;
  mimeType: string;
  tags: string[];
  type: FileType;
  isInstruction: boolean;
}

export interface FileFullData extends FileBaseData {
  games: GameFullData[];
  gamesInMiniature: GameFullData[];
  badge: BadgeFullData[];
}

export interface FileListReq extends BasePaginationWithSearch {
  type?: FileType[];
}

export type FileRes = FileBaseData;

export enum FileFilter {
  Instruction = 'instruction',
  Badge = 'badge',
}
