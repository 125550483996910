import React from 'react';

import { useAppSelector } from 'hooks';

import { Domain } from './Domain';
import { Container, Line } from './styles';

export const DomainsInfo: React.FC = () => {
  const { gameDomains } = useAppSelector((state) => state.gameController);

  return (
    <Container>
      {gameDomains.map((domain, index) => (
        <React.Fragment key={domain.id}>
          <Domain
            name={domain.name}
            isDone={domain.isDone}
            isCurrent={domain.isCurrent}
          />
          {index !== gameDomains.length - 1 && <Line />}
        </React.Fragment>
      ))}
    </Container>
  );
};
