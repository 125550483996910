import { Select } from 'components/forms';
import { BasePaginationContext } from 'context';
import { FC, useContext } from 'react';
import { OptionType } from 'types';

const limitOptions: OptionType[] = [
  { label: '5', value: '5' },
  { label: '10', value: '10' },
  { label: '15', value: '15' },
];

export const LimitPagination: FC = () => {
  const { setPagination, pagination } = useContext(BasePaginationContext);
  const { limit } = pagination;

  const handleOnChange = (option: OptionType) => {
    setPagination({ ...pagination, limit: option.value });
  };

  const defaultValue: OptionType = {
    label: limit || limitOptions[1].label,
    value: limit || limitOptions[1].value,
  };

  return (
    <Select
      label="label.limit"
      name="limit"
      options={limitOptions}
      onChange={handleOnChange}
      defaultValue={defaultValue}
      noTranslate
    />
  );
};
