import styled from 'styled-components';

export const FilesFilterContainer = styled.div(
  ({ theme: { spaces } }) => `
  display: flex;
  flex-flow: wrap;
  gap: ${spaces.md};
`
);

export const StyledButton = styled.button(
  ({ theme: { spaces, fontSizes, fontWeight, colors } }) => `
  margin-left: auto;
  color: ${colors.primary};
  display: flex;
  align-items: center;
  gap: ${spaces.sm};
  border: none;
  background: none;
  cursor: pointer;
  font-size: ${fontSizes.sm};
  font-weight: ${fontWeight.medium};

  &:hover {
    color: ${colors.secondaryGray};
  }
`
);
