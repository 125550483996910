import styled from 'styled-components';
import { ColorType } from 'types';

interface ContainerProps {
  color: ColorType;
  fullWidth: boolean;
}

export const Container = styled('div')<ContainerProps>(
  ({ theme: { colors, spaces }, color, fullWidth }) => `
  display: flex;
  gap: ${spaces.md};
  align-items: center;
  background-color: ${colors[color]}1A;
  padding: 20px 24px;
  border-radius: 11px;
  color: ${colors[color]};
  width: ${fullWidth ? '100%' : 'auto'};
`
);
