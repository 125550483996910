import styled from 'styled-components';

export const MovieContainer = styled.div`
  width: 100%;
  max-height: calc(100vh - 112px);
  display: flex;
  justify-content: center;
  align-items: center;
  overflow-y: hidden;
`;
