import styled from 'styled-components';

export const SurveyGameContainer = styled.div`
  width: 100%;
`;

export const StyledImage = styled.img(
  ({ theme: { spaces } }) => `
  display: flex;
  margin: 0 auto ${spaces.sm};
  object-fit: scale-down;
`
);

export const FormContainer = styled.div(
  ({ theme: { spaces } }) => `
  width: 100%;
  display: grid;
  grid-template-columns: 1fr 2fr;
  margin-top: ${spaces.md};
  gap: ${spaces['2xl']};
`
);
