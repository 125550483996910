import { FC } from 'react';
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import { Button, Flex, FlexAlignCenter, Heading } from 'components/common';
import { Form, TextInput } from 'components/forms';
import { addCategorySchema } from 'config';
import { useAppDispatch } from 'hooks';
import { CategoryReq } from 'cogamika-back/types';
import { AddCategorySchema } from 'types';
import { clearErrorNotification } from 'slices';
import { BoxContainer } from './styles';

interface AddCategoryProps {
  submit: (data: CategoryReq) => void;
}

export const AddCategory: FC<AddCategoryProps> = ({ submit }) => {
  const dispatch = useAppDispatch();

  const methodsAddCategory = useForm<AddCategorySchema>({
    mode: 'onSubmit',
    resolver: yupResolver(addCategorySchema),
  });

  const { setValue } = methodsAddCategory;

  const submitAddCategory = (data: AddCategorySchema) => {
    dispatch(clearErrorNotification());
    const { name } = data;
    const preparedData: CategoryReq = {
      name,
    };
    submit(preparedData);
    setValue('name', '');
  };

  return (
    <Flex gap="xl" columnDirection fullWidth>
      <Form
        submitHandler={submitAddCategory}
        methods={methodsAddCategory}
        fullWidth
      >
        <Flex gap="xl" columnDirection fullWidth>
          <FlexAlignCenter justifyContent="space-between" gap="lg" fullWidth>
            <Heading
              size="md"
              text="views.superAdmin.settings.headers.categories.add"
            />
            <Button
              text="button.add"
              variant="outlined"
              type="submit"
              uppercase
            />
          </FlexAlignCenter>
          <BoxContainer>
            <Flex gap="lg" columnDirection fullWidth>
              <TextInput name="name" placeholder="name" fullWidth required />
            </Flex>
          </BoxContainer>
        </Flex>
      </Form>
    </Flex>
  );
};
