import React from 'react';
import {
  Text,
  Heading,
  Flex,
  FlexAlignCenter,
  Button,
} from 'components/common';

import { useAppSelector, useModal } from 'hooks';
import { useNavigate } from 'react-router-dom';
import { userRoutes } from 'config';
import { MainContainer } from './styles';
import { ApiErrorCode } from 'cogamika-back/types';

export const SessionUnavailableError: React.FC = () => {
  const { closeModal } = useModal();
  const navigate = useNavigate();
  const errorCode = useAppSelector(
    (state) => state.notifications.errorNotification?.code
  );

  if (!errorCode) return null;

  const handleOnClose = () => {
    closeModal();
    navigate(userRoutes.dashboard);
  };

  const getErrorType = () => {
    switch (errorCode) {
      case ApiErrorCode.OnlyOneSessionPerDayIsAvailable:
        return 'onlyOneSessionPerDay';
      case ApiErrorCode.CannotFindGameInProvidedDomain:
        return 'tooLittleGames';
      default:
        return 'onlyOneSessionPerDay';
    }
  };

  return (
    <MainContainer>
      <Flex columnDirection gap="xl">
        <Heading text={`modals.${getErrorType()}.header`} size="lg" />
        <Text text={`modals.${getErrorType()}.description`} size="sm" />
        <FlexAlignCenter gap="md" justifyContent="center">
          <Button
            type="button"
            variant="secondary"
            onClick={handleOnClose}
            text="button.backToDashboard"
          />
        </FlexAlignCenter>
      </Flex>
    </MainContainer>
  );
};
