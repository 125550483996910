import React, { useEffect } from 'react';
import { useFormContext } from 'react-hook-form';

import { MovieGameFormType } from 'types';
import {
  Text,
  Flex,
  Icon,
  Button,
  Warning,
  IconButton,
  FlexAlignCenter,
} from 'components/common';

import { Wrapper } from './styles';
import { useApi } from 'hooks';

interface Props {
  setIsShow: React.Dispatch<React.SetStateAction<boolean>>;
}

export const VideoInfo: React.FC<Props> = ({ setIsShow }) => {
  const { get } = useApi();
  const { setValue, trigger, getValues, formState } =
    useFormContext<MovieGameFormType>();

  const video = getValues('video');
  const deleteCollectionImage = () => {
    setValue('video', undefined);
    trigger('video');
  };

  const fethcVideoName = async () => {
    if (video?.id) {
      const videoName = await get<string>(`/file/name/${video.id}`);
      if (videoName) {
        setValue('video.name', videoName);
      }
    }
  };

  useEffect(() => {
    if (!video?.name) {
      fethcVideoName();
    }

    // eslint-disable-next-line
  }, [video]);

  const errorMessage = formState.errors?.video?.id?.message;

  return video ? (
    <Wrapper>
      <FlexAlignCenter gap="sm">
        <Icon name="secondary-check" color="secondary" />
        <Text text={video.name || video.id} size="base" noTranslate />
      </FlexAlignCenter>
      <IconButton name="cross" onClick={deleteCollectionImage} type="button" />
    </Wrapper>
  ) : (
    <Flex fullWidth columnDirection gap="base">
      <Button
        type="button"
        text="button.addFile"
        variant="outlined"
        uppercase
        onClick={() => setIsShow(true)}
        fullWidth
      />
      <Warning isError={!!errorMessage} text={errorMessage} />
    </Flex>
  );
};
