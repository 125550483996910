import Sidebar from 'react-sidebar';
import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';

import { ColorType } from 'types';
import { GameNavigation } from 'components/common';
import { clearSessionGames } from 'slices';
import { routes } from 'config';
import { useAppDispatch, useAppSelector } from 'hooks';
import { CloseGame, SidebarInstruction } from 'components/games';
import { colors } from 'config/theme/basicStyleValues/colors';

import { StyledGameLayout, StyledMainContainer } from './styles';

interface Props {
  children: React.ReactNode;
  bgColor?: ColorType;
}

export const GameLayout: React.FC<Props> = ({
  children,
  bgColor = 'primaryBackground',
}) => {
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const { instruction } = useAppSelector((state) => state.game);
  const [isLeavingGame, setIsLeavingGame] = useState<boolean>(false);
  const [isInstructionOpen, setIsInstructionOpen] = useState<boolean>(false);

  const onShowPopUp = () => setIsLeavingGame(true);
  const onClosePopUp = () => setIsLeavingGame(false);

  const onOpenInstruction = () => setIsInstructionOpen(true);
  const onCloseInstruction = () => setIsInstructionOpen(false);

  const onLeaveGame = () => {
    dispatch(clearSessionGames());
    navigate(routes.root);
  };

  return (
    <Sidebar
      sidebar={
        instruction && (
          <SidebarInstruction
            instruction={instruction}
            onCloseInstruction={onCloseInstruction}
          />
        )
      }
      open={isInstructionOpen}
      styles={{ sidebar: { background: colors.light } }}
      onSetOpen={onCloseInstruction}
    >
      <StyledGameLayout bgColor={bgColor}>
        <GameNavigation
          onClose={onShowPopUp}
          onOpenInstruction={onOpenInstruction}
        />
        <StyledMainContainer>{children}</StyledMainContainer>
        {isLeavingGame && (
          <CloseGame onClose={onClosePopUp} onLeave={onLeaveGame} />
        )}
      </StyledGameLayout>
    </Sidebar>
  );
};
