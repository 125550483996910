import styled from 'styled-components';

export const FileViewerContainer = styled.div``;

export const FileListContainer = styled.div`
  display: flex;
  flex-wrap: wrap;
  gap: 25px;
`;

export const SelectContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 25px;
`;
