import { ApiErrorCode } from 'cogamika-back/types';
import { __ } from './i18n';

export const translate = (key: string): string => __(`apiErrorCode.${key}`);

export const getErrorMessage = (error: ApiErrorCode) => {
  switch (error) {
    case ApiErrorCode.OtherError:
      return translate('otherError');
    case ApiErrorCode.FormValidationError:
      return translate('formValidationError');
    case ApiErrorCode.NoSuchObjectWithThisId:
      return translate('noSuchObjectWithThisId');
    case ApiErrorCode.BadCredentials:
      return translate('badCredentials');
    case ApiErrorCode.UserNotFound:
      return translate('userNotFound');
    case ApiErrorCode.InvalidLanguage:
      return translate('invalidLanguage');
    case ApiErrorCode.EmailSendingFailure:
      return translate('emailSendingFailure');
    case ApiErrorCode.EmailIsNotAvailable:
      return translate('emailIsNotAvailable');
    case ApiErrorCode.CannotProperlySaveData:
      return translate('cannotProperlySaveData');
    case ApiErrorCode.AccountIsNotActivated:
      return translate('accountIsNotActivated');
    case ApiErrorCode.GameWithSuchSettingsAlreadyExists:
      return translate('gameWithSuchSettingsAlreadyExists');
    case ApiErrorCode.NotEnoughImagesProvided:
      return translate('notEnoughImagesProvided');
    case ApiErrorCode.NoValidAccount:
      return translate('noValidAccount');
    case ApiErrorCode.AccountNotChosen:
      return translate('accountNotChosen');
    case ApiErrorCode.InvalidData:
      return translate('invalidData');
    case ApiErrorCode.CannotFindGameInProvidedDomain:
      return translate('cannotFindGameInProvidedDomain');
    case ApiErrorCode.OperationFailure:
      return translate('operationFailure');
    case ApiErrorCode.InappropriateFileFormat:
      return translate('inappropriateFileFormat');
    case ApiErrorCode.GameWithProvidedNameAlreadyExists:
      return translate('gameWithProvidedNameAlreadyExists');
    case ApiErrorCode.GameHandlerNotFound:
      return translate('gameHandlerNotFound');
    case ApiErrorCode.WrongPromoCode:
      return translate('wrongPromoCode');
    case ApiErrorCode.CodeAlreadyExist:
      return translate('codeAlreadyExist');
    case ApiErrorCode.CodeDoesNotExist:
      return translate('codeDoesNotExist');
    case ApiErrorCode.FaqTopicAlreadyExist:
      return translate('faqTopicAlreadyExist');
    case ApiErrorCode.FaqQuestionAlreadyExist:
      return translate('faqQuestionAlreadyExist');
    case ApiErrorCode.SuchDomainNotFound:
      return translate('suchDomainNotFound');
    case ApiErrorCode.SuchGameNotFound:
      return translate('suchGameNotFound');
    case ApiErrorCode.CannotMarkPublishedGameAsDraft:
      return translate('cannotMarkPublishedGameAsDraft');
    case ApiErrorCode.OnlyOneSessionPerDayIsAvailable:
      return translate('onlyOneSessionPerDayIsAvailable');
    case ApiErrorCode.GameIsNotAvailableForFree:
      return translate('gameIsNotAvailableForFree');
    case ApiErrorCode.CannotMarkNotApprovedGameAsFree:
      return translate('cannotMarkNotApprovedGameAsFree');
    case ApiErrorCode.CategoryWithProvidedNameAlreadyExists:
      return translate('categoryWithProvidedNameAlreadyExists');
    case ApiErrorCode.CannotRemoveCategoryWhichIsInUse:
      return translate('cannotRemoveCategoryWhichIsInUse');
    case ApiErrorCode.SubdomainWithProvidedNameAlreadyExists:
      return translate('subdomainWithProvidedNameAlreadyExists');
    case ApiErrorCode.CannotRemoveSubdomainWhichIsInUse:
      return translate('cannotRemoveSubdomainWhichIsInUse');
    case ApiErrorCode.CannotRemoveFileWhichIsInUse:
      return translate('cannotRemoveFileWhichIsInUse');
    default:
      return translate('otherError');
  }
};
