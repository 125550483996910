import styled from 'styled-components';

interface Props {
  level: number;
  isFound?: boolean;
  isVisible?: boolean;
}

const getVariant = (level: number) => {
  switch (level) {
    case 1:
    case 2:
      return `
        width: 222px;
        height: 222px;
      `;
    case 3:
    case 4:
    case 5:
    case 6:
    case 7:
      return `
        width: 160px;
        height: 160px;
  `;
    case 8:
    case 9:
    case 10:
      return `
      width: 120px;
      height: 120px;
`;
  }
};

export const StyledImage = styled.img<Props>(
  ({ theme: { radius }, level, isFound, isVisible }) => `
  border-radius: ${radius.md};
  display: ${isVisible ? 'block' : 'none'};
  cursor: pointer;
  ${isFound ? 'visibility: hidden;' : ''};
  ${getVariant(level)}; 
`
);
