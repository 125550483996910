import styled from 'styled-components';

export const StyledContainer = styled.div(
  ({ theme: { spaces, colors } }) => `
  display: flex;
  flex-direction: column;
  gap: ${spaces['3xl']};
  width: 100%;
  max-width: 700px;
  background-color: ${colors.light};
  padding: ${spaces['7xl']}  ${spaces['6xl']};
  margin: 0 auto;
`
);

export const InputsGroup = styled.div(
  ({ theme: { spaces } }) => `
  display: flex;
  flex-direction: column;
  gap: ${spaces.base};
  width: 100%;
`
);

export const TextContainer = styled.div`
  width: 90%;
`;
