import React, { useState } from 'react';
import { useFormContext } from 'react-hook-form';
import { ErrorMessage } from '@hookform/error-message';

import { __ } from 'utils/i18n';
import { FieldErrorMessage } from 'components/forms';
import { Icon } from 'components/common';

import {
  StyledInput,
  StyledInputContainer,
  Wrapper,
  StyledIconButton,
} from './styles';
interface Props {
  name: string;
  placeholder: string;
  required?: boolean;
  disabled?: boolean;
  autoComplete?: 'off' | 'new-password';
}

export const PasswordInput: React.FC<Props> = ({
  name,
  placeholder,
  required,
  disabled,
}) => {
  const [isVisible, setIsVisible] = useState(false);
  const { register, getFieldState, formState } = useFormContext();
  const { error } = getFieldState(name, formState);

  const togglePassword = () => {
    setIsVisible((prev) => !prev);
  };

  return (
    <StyledInputContainer>
      <Wrapper>
        <StyledInput
          {...(register && register(name))}
          placeholder={
            required
              ? __(`placeholders.${placeholder}`) + '*'
              : __(`placeholders.${placeholder}`)
          }
          disabled={disabled}
          type={isVisible ? 'text' : 'password'}
          aria-invalid={!!error}
        />
        <StyledIconButton type="button" name="eye" onClick={togglePassword}>
          <Icon name="eye" />
        </StyledIconButton>
      </Wrapper>
      {formState.errors && (
        <ErrorMessage
          errors={formState.errors}
          name={name}
          render={({ message }) => <FieldErrorMessage text={message} />}
        />
      )}
    </StyledInputContainer>
  );
};
