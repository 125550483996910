import React from 'react';

import { GameType } from 'cogamika-back/types';

import {
  Movie,
  Survey,
  Chronology,
  Collection,
  MemoryGameForm,
  Mosaic,
} from 'components/forms';

interface Props {
  gameType?: GameType;
}

export const GameForm: React.FC<Props> = ({ gameType }) => {
  const renderGame = () => {
    switch (gameType) {
      case GameType.Memory:
        return <MemoryGameForm />;
      case GameType.Survey:
        return <Survey />;
      case GameType.Collection:
        return <Collection />;
      case GameType.Chronology:
        return <Chronology />;
      case GameType.Movie:
        return <Movie />;
      case GameType.Mosaic:
        return <Mosaic />;
      default:
        return null;
    }
  };

  return <>{renderGame()}</>;
};
