import {
  addSeconds,
  subWeeks,
  addWeeks,
  subMonths,
  addMonths,
  addDays,
  intervalToDuration,
  intlFormatDistance,
} from 'date-fns';
import { enUS, pl, sv } from 'date-fns/esm/locale';
import { format } from 'date-fns/esm';
import { AppConfig } from 'config';
import { i18n } from './i18n';
import { Language } from 'cogamika-back/types';

export const getLocale = (lang: Language | undefined) => {
  switch (lang) {
    case Language.pl:
      return pl;
    case Language.en:
      return enUS;
    case Language.sv:
      return sv;
    default:
      return enUS;
  }
};

export const getDayToEnd = (endDate: Date, lang: Language) =>
  intlFormatDistance(endDate, new Date(), {
    locale: lang,
    unit: 'day',
  });

export const formatTime = (seconds: number): string => {
  const helperDate = addSeconds(new Date(0), seconds);
  return format(helperDate, 'mm:ss');
};

export const formatDate = (date: Date, dateFormat: string) =>
  format(date, dateFormat, { locale: getLocale(i18n.currentLang) });

export const formatToStandardDateFormat = (date: string | Date) =>
  formatDate(new Date(date), AppConfig.DATA_FORMATS.STANDARD_FORMAT);

export const goWeekEarlier = (date: Date) => subWeeks(date, 1);

export const goWeekLater = (date: Date) => addWeeks(date, 1);

export const goMonthEarlier = (date: Date) => subMonths(date, 1);

export const goMonthLater = (date: Date) => addMonths(date, 1);

export const myAddDays = (date: Date, amount: number) => addDays(date, amount);

export const convertToSeconds = (minutes: number, seconds: number) =>
  minutes * 60 + seconds;

export const convertToMinutes = (seconds: number) =>
  intervalToDuration({ start: 0, end: seconds * 1000 });
