import styled from 'styled-components';

import { gamePadding } from 'mixins';

export const InstructionContainer = styled.div`
  flex-direction: column;
  justify-content: center;
  display: flex;
  align-items: center;
  height: 100%;
  width: 100%;
  ${gamePadding};
`;

export const StyledVideo = styled.video`
  height: 100%;
  width: 100%;
`;

export const DescriptionContainer = styled.div`
  min-height: 200px;
`;
