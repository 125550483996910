import { Suspense, useLayoutEffect } from 'react';
import { BrowserRouter } from 'react-router-dom';

import { BaseRoutes } from 'components/routes';
import { Spinner, Modal } from 'components/common';
import { NotificationContainer } from 'components/notification';
import { useLang } from 'hooks';

function App() {
  const { isLanguageLoaded, setDefaultLanguage } = useLang();

  useLayoutEffect(() => {
    setDefaultLanguage();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  if (!isLanguageLoaded) return <Spinner />;

  return (
    <Suspense fallback={<Spinner />}>
      <BrowserRouter>
        <Modal />
        <NotificationContainer />
        <BaseRoutes />
      </BrowserRouter>
    </Suspense>
  );
}

export default App;
