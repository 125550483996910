import React, { useId } from 'react';
import { useFormContext, useWatch } from 'react-hook-form';

import { AppConfig } from 'config';
import { Flex, IconButton, Text, TranslateText } from 'components/common';

import { StyledInput, StyledLabel } from './styles';

interface Props {
  name: string;
}

export const FileInput: React.FC<Props> = ({ name }) => {
  const { getFieldState, formState, control, setValue, trigger } =
    useFormContext();
  const { error } = getFieldState('files', formState);
  const id = useId();
  const files = useWatch({
    control,
    name,
  });

  const handleFileChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const input = event.target;
    if (!input.files?.length) {
      return;
    }

    const fileList = files ? [...files] : [];
    Array.from(input.files).forEach((file) => {
      fileList.push(file);
    });

    setValue(name, fileList);
    trigger();
  };

  const onDelete = (index: number) => {
    const filteredFiles = [...files].filter(
      (item, fileIndex) => fileIndex !== index
    );
    setValue(name, filteredFiles);
    trigger();
  };

  return (
    <Flex columnDirection gap="base">
      <StyledInput
        type="file"
        multiple
        id={id}
        name={name}
        aria-invalid={!!error}
        onChange={handleFileChange}
        accept={AppConfig.FILE_TYPES}
      />
      <StyledLabel htmlFor={id}>
        <TranslateText text="button.selectFiles" />
      </StyledLabel>
      <Flex columnDirection gap="xs">
        {files &&
          [...files].map((file, index) => (
            <Flex alignItems="center" gap="xs" key={index}>
              <Text
                text={file.name ?? file.hash}
                noTranslate
                size="sm"
                color="secondary"
              />
              <IconButton
                color="error"
                type="button"
                name="cross"
                onClick={() => onDelete(index)}
              />
            </Flex>
          ))}
      </Flex>
    </Flex>
  );
};
