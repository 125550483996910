import styled from 'styled-components';

import { AlignItemsType, MarginType, SpaceType } from 'types';
import { getMargin } from 'utils';

interface Props {
  columnDirection?: boolean;
  alignItems?: AlignItemsType;
  gap?: SpaceType;
  fullWidth?: boolean;
  margin?: MarginType;
}

export const FlexComponent = styled.div<Props>(
  ({
    theme: { spaces },
    alignItems,
    columnDirection,
    gap,
    fullWidth,
    margin,
  }) => `
  display: flex;
  align-items: ${alignItems ? `${alignItems}` : 'flex-start'};
  flex-direction: ${columnDirection ? 'column' : 'row'};
  justify-content: center;
  gap: ${gap ? spaces[gap] : '0px'};
  width: ${fullWidth ? '100%' : 'auto'};
  ${margin && getMargin(margin, spaces)};
`
);
