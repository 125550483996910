import ReactDOM from 'react-dom/client';
import { Provider } from 'react-redux';
import * as Sentry from '@sentry/react';
import { BrowserTracing } from '@sentry/browser';

import { store } from 'config';
import { Theme } from 'config/theme';
import { LanguageProvider } from 'components/common';

import App from './App';

Sentry.init({
  dsn: 'https://55bba5d2f2374b4abcdce12f94638882@sentry.etd24.pl/129',
  integrations: [new BrowserTracing()],

  // Set tracesSampleRate to 1.0 to capture 100%
  // of transactions for performance monitoring.
  // We recommend adjusting this value in production
  tracesSampleRate: 0.1,
});

const root = ReactDOM.createRoot(
  document.getElementById('root') as HTMLElement
);
root.render(
  <Provider store={store}>
    <LanguageProvider>
      <Theme>
        <App />
      </Theme>
    </LanguageProvider>
  </Provider>
);
