import { useForm } from 'react-hook-form';
import React, { useContext, useState } from 'react';
import { yupResolver } from '@hookform/resolvers/yup';

import { apiUrl, surveyGameSchema } from 'config';
import {
  SurveyInitialDataType,
  SurveyGameLevelSettings,
  LevelSettings,
} from 'cogamika-back/types';
import { SurveyGameFormSteps, SurveyGameFormType } from 'types';
import { GameFormContext } from 'context';
import { Form, LevelHeader, NumberInput } from 'components/forms';
import { Button, Flex } from 'components/common';

import { Questions } from './Questions';
import { SelectInitialFile } from './SelectInitialFile';
import { FormContainer, StyledImage, SurveyGameContainer } from './styles';

interface Props {
  setStage: React.Dispatch<React.SetStateAction<SurveyGameFormSteps>>;
  level: number;
}

export const SurveyGameForm: React.FC<Props> = ({ level, setStage }) => {
  const [isShow, setIsShow] = useState(false);
  const { setGameSettings, gameSettings } = useContext(GameFormContext);
  const defaultSettings = gameSettings?.surveyLevelSettings?.levels.find(
    (item) => item.level === level
  );

  const methods = useForm<SurveyGameFormType>({
    mode: 'onSubmit',
    defaultValues: defaultSettings?.settings,
    resolver: yupResolver(surveyGameSchema),
  });
  const initialData = methods.watch('initialData');

  const submit = (data: SurveyGameFormType) => {
    const newLevelSettings: LevelSettings<SurveyGameLevelSettings> = {
      level: level,
      settings: {
        surveyQuestions: data.surveyQuestions,
      },
    };

    if (data.initialData) {
      newLevelSettings.settings.initialData = {
        description: data.initialData.description,
        type: SurveyInitialDataType.Image,
        time: data.initialData.time,
      };
    }

    const levelsSettings = gameSettings?.surveyLevelSettings?.levels;

    if (levelsSettings && levelsSettings.length > 0) {
      const findIndex = levelsSettings.findIndex(
        (item) => item.level === level
      );

      if (findIndex === -1) {
        setGameSettings({
          surveyLevelSettings: {
            levels: [...levelsSettings, newLevelSettings],
          },
        });
      } else {
        levelsSettings[findIndex] = newLevelSettings;
        setGameSettings({
          surveyLevelSettings: {
            levels: [...levelsSettings],
          },
        });
      }
    } else {
      setGameSettings({
        surveyLevelSettings: {
          levels: [newLevelSettings],
        },
      });
    }
    setStage(SurveyGameFormSteps.Levels);
  };

  const goBack = () => {
    setStage(SurveyGameFormSteps.Levels);
  };

  const deleteInitialData = () => {
    methods.setValue('initialData', undefined);
  };

  return (
    <SurveyGameContainer>
      <Form submitHandler={submit} methods={methods}>
        <LevelHeader
          goBack={goBack}
          level={level}
          errorMessage={methods.formState.errors?.surveyQuestions?.message}
        />
        <FormContainer>
          <Flex columnDirection gap="md">
            {initialData?.description ? (
              <>
                <Button
                  type="button"
                  text="button.deleteFile"
                  variant="outlined"
                  uppercase
                  onClick={deleteInitialData}
                />
                <StyledImage
                  src={`${apiUrl}file/${initialData.description}`}
                  width="280"
                />
                <NumberInput name="initialData.time" placeholder="seconds" />
              </>
            ) : (
              <Button
                type="button"
                text="button.addFile"
                variant="outlined"
                uppercase
                onClick={() => setIsShow(true)}
              />
            )}
          </Flex>
          <Questions level={level} />
        </FormContainer>
        {isShow && <SelectInitialFile setIsShow={setIsShow} />}
      </Form>
    </SurveyGameContainer>
  );
};
