import React, { useEffect } from 'react';

import { StatusRes } from 'cogamika-back/types';
import { DeleteFileModalData } from 'types';
import {
  Text,
  Flex,
  Button,
  Warning,
  FlexAlignCenter,
  Spinner,
} from 'components/common';
import { useApi, useAppDispatch, useAppSelector, useModal } from 'hooks';
import {
  clearErrorNotification,
  setDeletedFileId,
  successNotification,
} from 'slices';

import { MainContainer } from './styles';

export const DeleteFile: React.FC = () => {
  const dispatch = useAppDispatch();
  const { remove, inProgress } = useApi();
  const { closeModal, getData } = useModal();
  const { fileId } = getData<DeleteFileModalData>();

  const { errorNotification, formValidationErrors } = useAppSelector(
    (state) => state.notifications
  );

  const deleteFile = async () => {
    const result = await remove<StatusRes>(`file/${fileId}`);

    if (result) {
      dispatch(successNotification('notifications.successfullyDeletedFile'));
      dispatch(setDeletedFileId(fileId));
      dispatch(clearErrorNotification());
      closeModal();
    }
  };

  useEffect(() => {
    return () => {
      dispatch(clearErrorNotification());
    };

    // eslint-disable-next-line
  }, []);

  return (
    <MainContainer>
      {inProgress && <Spinner />}
      <Flex columnDirection gap="xl">
        <Text text="modals.deleteFile.header" size="lg" />
        {errorNotification && (
          <Warning
            text={errorNotification?.text}
            isError={!!errorNotification || formValidationErrors.length > 0}
            formValidationErrors={formValidationErrors}
            noTranslate
          />
        )}
        <FlexAlignCenter gap="md">
          <Button
            type="button"
            variant="secondary"
            onClick={deleteFile}
            text="button.delete"
          />
          <Button
            type="button"
            variant="outlined"
            onClick={closeModal}
            text="button.cancel"
          />
        </FlexAlignCenter>
      </Flex>
    </MainContainer>
  );
};
