export enum QuestionAnswerType {
  Text = 'TEXT',
  Image = 'IMAGE',
}

export type QuestionAnswerData = {
  type: QuestionAnswerType;
  description: string;
};

export type QuestionAnswerDataKeys = keyof QuestionAnswerData;

export const QuestionAnswerDataKeysArray: Array<QuestionAnswerDataKeys> = [
  'type',
  'description',
];

export type AnswerType = QuestionAnswerData & { isCorrect: boolean };

export type AnswerTypeKeys = keyof AnswerType;

export const AnswerTypeKeysArray: Array<AnswerTypeKeys> = [
  'type',
  'description',
  'isCorrect',
];

export type QuestionWithAnswers = {
  question: QuestionAnswerData;
  answers: AnswerType[];
};

export type QuestionWithAnswersKeys = keyof QuestionWithAnswers;

export const QuestionWithAnswersKeysArray: Array<QuestionWithAnswersKeys> = [
  'question',
  'answers',
];

export enum SurveyInitialDataType {
  Text = 'TEXT',
  Image = 'IMAGE',
  Audio = 'AUDIO',
}

export type SurveyInitialData = {
  type: SurveyInitialDataType;
  description: string;
  time: number;
};

export type SurveyInitialDataKeys = keyof SurveyInitialData;

//cannot include optional properties due to further check only required ones
export const SurveyInitialDataArray: Array<SurveyInitialDataKeys> = [
  'type',
  'description',
];

export interface SurveyGameSettings {}

export interface SurveyGameLevelSettings {
  initialData?: SurveyInitialData;
  surveyQuestions: QuestionWithAnswers[];
}

export type SurveyGameLevelSettingsKeys = keyof SurveyGameLevelSettings;

//cannot include optional properties due to further check only required ones
export const SurveyGameLevelSettingsKeysArray: Array<SurveyGameLevelSettingsKeys> =
  ['surveyQuestions'];

export type SurveySettingsRes = SurveyGameSettings & SurveyGameLevelSettings;
