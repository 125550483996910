import { FC } from 'react';
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import { Button, Flex, FlexAlignCenter, Heading } from 'components/common';
import { DatePickerInput, Form, TextInput } from 'components/forms';
import { AppConfig, addPromoCodeSchema } from 'config';
import { useAppDispatch, usePromoCodes } from 'hooks';
import { AddPromoReq, DiscountType } from 'cogamika-back/types';
import { AddPromoCodeSchema } from 'types';
import { clearErrorNotification } from 'slices';
import { formatDate } from 'utils';
import { BoxContainer } from './styles';

export const AddPromoCode: FC = () => {
  const { addPromoCodes } = usePromoCodes();
  const dispatch = useAppDispatch();

  const methodsAddPromoCode = useForm<AddPromoCodeSchema>({
    mode: 'onSubmit',
    resolver: yupResolver(addPromoCodeSchema),
  });

  const { setValue } = methodsAddPromoCode;

  const submitAddPromoCode = (data: AddPromoCodeSchema) => {
    dispatch(clearErrorNotification());
    const { code, validTo, value } = data;
    const preparedData: AddPromoReq = {
      code,
      value,
      validTo: formatDate(
        new Date(validTo),
        AppConfig.DATA_FORMATS.API_STRING_FORMAT
      ),
      type: DiscountType.Percent,
    };

    addPromoCodes(preparedData);
    setValue('code', '');
    setValue('validTo', '');
    setValue('value', 0);
  };

  return (
    <Flex gap="xl" columnDirection fullWidth>
      <Form
        submitHandler={submitAddPromoCode}
        methods={methodsAddPromoCode}
        fullWidth
      >
        <Flex gap="xl" columnDirection fullWidth>
          <FlexAlignCenter justifyContent="space-between" gap="lg" fullWidth>
            <Heading
              size="md"
              text="views.superAdmin.settings.headers.promoCodes.add"
            />
            <Button
              text="button.add"
              variant="outlined"
              type="submit"
              uppercase
            />
          </FlexAlignCenter>
          <BoxContainer>
            <Flex gap="lg" columnDirection fullWidth>
              <TextInput name="code" placeholder="code" fullWidth required />
              <TextInput name="value" placeholder="value" fullWidth required />
              <DatePickerInput
                name="validTo"
                placeholder="validTo"
                fullWidth
                required
              />
            </Flex>
          </BoxContainer>
        </Flex>
      </Form>
    </Flex>
  );
};
