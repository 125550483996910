import { FC } from 'react';
import { colors } from 'config/theme/basicStyleValues/colors';

//@Cannot find correct type
interface Props {
  barHoveredIndex?: number;
  index?: number;
  x?: number;
  y?: number;
  width?: number;
  value?: number;
  //...and more
}

export const RenderCustomizedLabel: FC<Props> = ({
  x = 0,
  y = 0,
  width = 0,
  value = 0,
  index = 0,
  barHoveredIndex,
}) => {
  if (barHoveredIndex !== index) return null;

  const radius = 10;
  const rectX = x - 25 + width / 2;
  const rectY = y - 45;
  const textX = x + width / 2;
  const textY = y - 30;
  const polygonPointOneX = x + width / 2;
  const polygonPointOneY = y - 7;
  const polygonPointTwoX = x - 7 + width / 2;
  const polygonPointTwoY = y - 15;
  const polygonPointThreeX = x + 7 + width / 2;
  const polygonPointThreeY = y - 15;

  return (
    <g filter="drop-shadow(0px 0px 5px rgba(15, 22, 24, 0.2))">
      <rect
        width={50}
        height={30}
        x={rectX}
        y={rectY}
        fill={colors.secondaryBackground}
        rx={radius}
        ry={radius}
      />
      <polygon
        points={`${polygonPointOneX},${polygonPointOneY} ${polygonPointTwoX},${polygonPointTwoY} ${polygonPointThreeX},${polygonPointThreeY}`}
        fill={colors.secondaryBackground}
      />
      <text
        x={textX}
        y={textY}
        fill={colors.primary}
        textAnchor="middle"
        dominantBaseline="middle"
        fontWeight={600}
      >
        {value}%
      </text>
    </g>
  );
};
