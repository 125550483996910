import styled from 'styled-components';

export const StyledInstructionForm = styled.div`
  width: 1000px;
`;

export const FormContainer = styled.div`
  margin-top: 36px;
`;

export const GridItem = styled.div`
  display: grid;
  grid-template-columns: repeat(1, 1fr);
  width: 100%;
`;

export const StyledVideo = styled.video(
  ({ theme }) => `
  margin-top: 20px;
  width: 500px;
`
);

export const ButtonContainer = styled.div(
  ({ theme: { spaces } }) => `
  width: 100%;
  display: flex;
  justify-content: flex-end;
  gap: ${spaces.md};
`
);

export const FilesWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  gap: 50px;
  width: 100%;
`;
