import React from 'react';

import { StyledFiledErrorMessage } from './styles';
import { TranslateText } from 'components/common';

interface Props {
  text: string;
}

export const FieldErrorMessage: React.FC<Props> = ({ text }) => {
  return (
    <StyledFiledErrorMessage data-ref="error-message" role="alert">
      <TranslateText text={text} />
    </StyledFiledErrorMessage>
  );
};
