import * as yup from 'yup';

export const checkLanguageSelect = yup
  .string()
  .required('validation.fieldIsRequired')
  .min(2, 'validation.textTooShort');

export const checkEmail = () =>
  yup
    .string()
    .required('validation.fieldIsRequired')
    .matches(
      /[a-z0-9!#$%&'*+/=?^_`{|}~-]+(?:\.[a-z0-9!#$%&'*+/=?^_`{|}~-]+)*@(?:[a-z0-9](?:[a-z0-9-]*[a-z0-9])?\.)+[a-z0-9](?:[a-z0-9-]*[a-z0-9])?$/,
      'validation.invalidEmail'
    )
    .max(254, 'validation.emailTooLong');

export const checkPassword = () =>
  yup
    .string()
    .min(6, 'validation.passwordTooShort')
    .max(128, 'validation.passwordTooLong')
    .required('validation.fieldIsRequired');

export const checkNick = () =>
  yup
    .string()
    .test('nick-validation', 'validation.textTooShort', function (value) {
      const { createError, path } = this;
      if (value) {
        if (value.length < 3) {
          return false;
        } else if (value.length > 120) {
          return createError({
            path,
            message: 'validation.textTooLong',
          });
        }
      }

      return true;
    });

export const checkName = () =>
  yup
    .string()
    .required('validation.fieldIsRequired')
    .matches(
      /^([\p{L}\p{M}]+\s?)*([\p{L}\p{M}]+\s?)+$/u,
      'validation.onlyLetters'
    )
    .min(3, 'validation.textTooShort')
    .max(60, 'validation.textTooLong');

export const checkLastName = () =>
  yup
    .string()
    .required('validation.fieldIsRequired')
    .matches(
      /^([\p{L}\p{M}]+\s?)*([\p{L}\p{M}]+\s?)+$/u,
      'validation.onlyLetters'
    )
    .max(60, 'validation.textTooLong');

export const checkRepeatedNewPassword = () =>
  yup.string().oneOf([yup.ref('newPassword')], 'validation.passwordsMustMatch');

export const checkRepeatedPassword = () =>
  yup.string().oneOf([yup.ref('password')], 'validation.passwordsMustMatch');

export const requiredStringSchema = yup
  .string()
  .required('validation.fieldIsRequired')
  .min(3, 'validation.textTooShort');

export const requiredLangStringSchema = yup
  .string()
  .required('validation.fieldIsRequired')
  .min(2, 'validation.textTooShort');

export const requiredOneCharacterSchema = yup
  .string()
  .required('validation.fieldIsRequired')
  .min(1, 'validation.textTooShort');

export const checkPromoCode = () => yup.string();

export const checkFaqTopic = () => yup.string();

export const checkFaqQuestion = () => yup.string();

export const checkFaqAnswer = () => yup.string().max(1024);

export const reqiuredMovieRange = yup
  .number()
  .min(0)
  .max(59)
  .required('validation.fieldIsRequired');

export const requiredFileSchema = yup
  .string()
  .required('validation.fileIsRequired');
