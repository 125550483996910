import styled from 'styled-components';
import { LazyLoadImage } from 'react-lazy-load-image-component';
import 'react-lazy-load-image-component/src/effects/opacity.css';

import { Text } from 'components/common';

export const GameContainer = styled.div(
  ({ theme: { spaces, colors } }) => `
  display: flex;
  flex-direction: column;
  gap: ${spaces['2xl']};
  padding: ${spaces['2xl']};
  background-color: ${colors.light};
  max-height: 466px;
  cursor: pointer;
  position: relative;
`
);

export const StyledImage = styled(LazyLoadImage)`
  width: 100%;
  max-height: 160px;
  object-fit: fill;
`;

export const Description = styled(Text)`
  overflow: hidden;
  display: -webkit-box;
  -webkit-line-clamp: 3; /* number of lines to show */
  line-clamp: 3;
  -webkit-box-orient: vertical;
`;

export const EditContainer = styled.div`
  display: none;
  flex-direction: column;
  align-items: center;
  gap: ${({ theme }) => theme.spaces.md};
  position: absolute;
  top: 20px;
  right: 20px;

  ${GameContainer}:hover & {
    display: flex;
  }
`;
