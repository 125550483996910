import React from 'react';
import { UseFieldArrayRemove } from 'react-hook-form';

import { ElementType } from 'cogamika-back/types';
import { ChronologyElement } from 'types';
import { TextInput } from 'components/forms';
import { Icon, IconButton, Text } from 'components/common';

import { ButtonContainer, InputContainer, ImageInfoContainer } from './styles';

interface Props {
  field: ChronologyElement;
  chronologyIndex: number;
  remove: UseFieldArrayRemove;
  formType?: ElementType;
}

export const ChronologyItem: React.FC<Props> = ({
  formType,
  chronologyIndex,
  field,
  remove,
}) => {
  const getVariant = (formType: ElementType) => {
    switch (formType) {
      case ElementType.Text:
        return <TextItem chronologyIndex={chronologyIndex} remove={remove} />;
      case ElementType.Image:
        return (
          <ImageItem
            chronologyIndex={chronologyIndex}
            field={field}
            remove={remove}
          />
        );
      default:
        return <></>;
    }
  };

  if (!formType) return null;

  return getVariant(formType);
};

interface PropsItem {
  chronologyIndex: number;
  remove: UseFieldArrayRemove;
}

export const TextItem: React.FC<PropsItem> = ({ chronologyIndex, remove }) => {
  return (
    <InputContainer>
      <TextInput
        name={`elements.${chronologyIndex}.description`}
        placeholder="addText"
        fullWidth
      />
      <ButtonContainer>
        <IconButton
          type="button"
          onClick={() => remove(chronologyIndex)}
          name="delete"
        />
      </ButtonContainer>
    </InputContainer>
  );
};

interface PoprsImageItem {
  field: ChronologyElement;
  chronologyIndex: number;
  remove: UseFieldArrayRemove;
}

export const ImageItem: React.FC<PoprsImageItem> = ({
  chronologyIndex,
  remove,
  field,
}) => {
  return (
    <ImageInfoContainer>
      <Icon name="secondary-check" color="secondary" />
      <Text text={field?.name || 'image'} noTranslate />
      <ButtonContainer>
        <IconButton
          type="button"
          onClick={() => remove(chronologyIndex)}
          name="delete"
        />
      </ButtonContainer>
    </ImageInfoContainer>
  );
};
