import { UserGameFullData, UserGameReq } from './user-game';
import { UserAccountFullData } from '../user';
import { GameResourcesRes } from '../game';

export interface UserGameSessionBaseData {
  id: string;
  endDate: string | Date;
  totalNum: number;
  inRowNum: number;
}

export interface UserGameSessionFullData extends UserGameSessionBaseData {
  userGames: UserGameFullData[];
  account: UserAccountFullData;
}

export interface UserGameSessionReq {
  games: UserGameReq[];
}

export interface NextGameInSessionReq {
  currentLevel: number;
  currentGameDomainId: string;
  currentGameId: string;
  gamesAlreadyPlayedInDomainIds: string[] | null;
  nextLevelGame: NextGameInSessionLevel;
}

export enum NextGameInSessionLevel {
  Same = 'SAME',
  Next = 'NEXT',
}

export interface GameSessionRes {
  games: GameResourcesRes[];
}
