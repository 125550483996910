import styled from 'styled-components';
import { LazyLoadImage } from 'react-lazy-load-image-component';
import 'react-lazy-load-image-component/src/effects/opacity.css';

import { Theme } from 'types';

enum Status {
  Correct = 'CORRECT',
  Incorrect = 'INCORRECT',
}

interface Props {
  status?: Status;
}

const getVariant = (theme: Theme, status?: Status) => {
  switch (status) {
    case Status.Correct:
      return `
        background-color: ${theme.colors.success};
        border-color: ${theme.colors.success};
        color: ${theme.colors.light};
        &:disabled {
          color: ${theme.colors.light};
        }
        `;
    case Status.Incorrect:
      return `
        background-color: ${theme.colors.error};
        border-color: ${theme.colors.error};
        color: ${theme.colors.light};
        &:disabled {
          color: ${theme.colors.light};
        }
      `;
    default:
      return '';
  }
};

const getImageVariant = (theme: Theme, status?: Status) => {
  switch (status) {
    case Status.Correct:
      return `
        border-color: ${theme.colors.success};
        `;
    case Status.Incorrect:
      return `
        border-color: ${theme.colors.error};
      `;
    default:
      return '';
  }
};

export const TextAnswer = styled.button<Props>(
  ({
    theme,
    theme: { colors, radius, spaces, fontSizes, fontWeight },
    status,
    disabled,
  }) => `
  font-size: ${fontSizes.md};
  font-weight: ${fontWeight.semibold};
  background-color: ${colors.light};
  border: 2px solid ${colors.primary};
  text-align: left;
  border-radius: ${radius.md};
  padding: ${spaces['2xl']};
  cursor: pointer;
  &:disabled {
    color ${colors.primary};
  }
  ${getVariant(theme, status)}; 
  ${
    !disabled
      ? `&:hover {
      border-color: ${colors.primary};
      background-color: ${colors.primary};
      color: ${colors.light};
    }`
      : ''
  }
`
);

export const AnswerContainer = styled.div`
  display: grid;
  align-self: stretch;
`;

export const StyledImage = styled(LazyLoadImage)<Props>(
  ({ theme: { radius }, theme, status }) => `
  width: 210px;
  height: 180px;
  object-fit: contain;
  border-radius: ${radius.md};
  border: 4px solid transparent;
  ${getImageVariant(theme, status)};
`
);

export const ImageLetter = styled.div(
  ({ theme: { fontSizes, fontWeight } }) => `
  position: absolute;
  margin: 0;
  bottom: 18px;
  left: 24px;
  font-size: ${fontSizes.md};
  font-weight: ${fontWeight.bold};
`
);

export const ImageContainer = styled.div(
  ({ theme: { colors } }) => `
  width: 210px;
  height: 180px;
  position: relative;
`
);
