import React from 'react';

import { Language, UserRole } from 'cogamika-back/types';
import { FilterLanguageType, NonLanguage } from 'types';
import { getSelectOptionsFromEnum } from 'utils';
import { useAppSelector } from 'hooks';
import { TranslateText } from 'components/common';

import { Option, SelectContainer, StyledSelect } from './styles';

interface Props {
  handleSelectLanguage: (language: FilterLanguageType) => void;
  value: FilterLanguageType;
}

export const LanguageFilter: React.FC<Props> = ({
  handleSelectLanguage,
  value,
}) => {
  const { user } = useAppSelector((state) => state.user);
  if (user?.role !== UserRole.Admin && user?.role !== UserRole.SuperAdmin)
    return null;

  const options = getSelectOptionsFromEnum(Language, true);

  const handleChange = (newValue: React.ChangeEvent<HTMLSelectElement>) => {
    handleSelectLanguage(newValue.currentTarget.value as FilterLanguageType);
  };

  return (
    <div>
      <SelectContainer>
        <StyledSelect onChange={handleChange} value={value}>
          <Option value={NonLanguage.NonLanguage}>
            <TranslateText text="button.chooseLanguage" />
          </Option>
          {options.map((option) => (
            <Option key={option.value} value={option.value}>
              <TranslateText text={option.label} noTranslate />
            </Option>
          ))}
        </StyledSelect>
      </SelectContainer>
    </div>
  );
};
