import styled from 'styled-components';
import { NotificationType, Theme } from 'types';

interface Props {
  type: NotificationType;
}

const getVaraint = (variant: NotificationType, theme: Theme) => {
  const {
    colors: { success, info, error },
  } = theme;
  switch (variant) {
    case NotificationType.Success:
      return `
        color: ${success};
        border-color: ${success};
      `;
    case NotificationType.Error:
      return `
        color: ${error};
        border-color: ${error};
      `;
    case NotificationType.Info:
      return `
        color: ${info};
         border-color: ${info};
      `;
  }
};

export const StyledAlert = styled.div<Props>(
  ({ theme, type }) => `
  outline: none;
  padding: 10px 10px 10px 8px;
  width: 100%;
  background-color: ${theme.colors.light};
  border: 1px solid;
  border-left: 7px solid;
  border-radius: ${theme.radius.md};
  display: flex;
  align-items: center;
  margin: 0;
  ${getVaraint(type, theme)};
`
);
