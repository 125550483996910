import React from 'react';
import { __ } from 'utils/i18n';

import { StyledSearchInput } from './styles';

interface Props extends React.ButtonHTMLAttributes<HTMLInputElement> {}

export const SearchInput: React.FC<Props> = ({
  onChange,
  placeholder,
  value,
}) => {
  return (
    <StyledSearchInput
      onChange={onChange}
      placeholder={__(`placeholders.${placeholder}`)}
      value={value}
    />
  );
};
