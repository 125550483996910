import React, { useEffect } from 'react';
import { useForm } from 'react-hook-form';
import { useNavigate } from 'react-router-dom';
import { yupResolver } from '@hookform/resolvers/yup';

import { loginSchema, routes } from 'config';
import { ModalType } from 'types';
import { useApi } from 'hooks/useApi';
import { useAppDispatch, useAppSelector, useAnalytics, useModal } from 'hooks';
import { AuthLoginReq, UserRes } from 'cogamika-back/types';
import { Form, PasswordInput, TextInput } from 'components/forms';
import { clearErrorNotification, hideModal, setUser } from 'slices';
import {
  Button,
  Text,
  Heading,
  Flex,
  LinkButton,
  Warning,
} from 'components/common';

import { MainContainer, InputsGroup, DescriptionWrapper } from './styles';

export const Login: React.FC = () => {
  const navigate = useNavigate();
  const { showModal, closeModal } = useModal();
  const { sendLoginEvent } = useAnalytics();
  const { post } = useApi();
  const dispatch = useAppDispatch();
  const { errorNotification, formValidationErrors } = useAppSelector(
    (state) => state.notifications
  );

  const methods = useForm<AuthLoginReq>({
    mode: 'onSubmit',
    resolver: yupResolver(loginSchema),
  });
  const { isSubmitting } = methods.formState;
  const isButtonDisabled = isSubmitting;

  const submit = async (data: AuthLoginReq) => {
    dispatch(clearErrorNotification());
    const res = await post<UserRes, AuthLoginReq>('/auth/login', {
      email: data.email,
      password: data.password,
    });

    if (res) {
      sendLoginEvent({ method: 'EmailAndPassword' });
      dispatch(setUser(res));
      closeModal();
      if (res.isSubscriptionExpiring) {
        showModal({
          type: ModalType.SubscriptionExpiring,
          data: null,
        });
      }
    }
  };

  const openRegisterModal = () => {
    showModal({
      type: ModalType.Register,
      data: null,
    });
  };

  const goToResetPassword = () => {
    navigate(routes.resetPassword);

    dispatch(hideModal());
  };

  useEffect(() => {
    dispatch(clearErrorNotification());
    return () => {
      dispatch(clearErrorNotification());
    };

    // eslint-disable-next-line
  }, []);

  return (
    <MainContainer>
      <Flex columnDirection gap="sm">
        <Heading text="modals.login.heading" size="xl" />
        <DescriptionWrapper>
          <Text text="modals.login.description" size="sm" />
          <LinkButton
            text="modals.login.register"
            onClick={openRegisterModal}
          />
        </DescriptionWrapper>
      </Flex>
      <Form submitHandler={submit} methods={methods}>
        <Flex columnDirection gap="3xl">
          <Flex columnDirection fullWidth gap="base">
            <InputsGroup>
              <TextInput name="email" placeholder="email" fullWidth required />
              <PasswordInput name="password" placeholder="password" required />
            </InputsGroup>
            <LinkButton
              text="modals.login.forgotPassword"
              onClick={goToResetPassword}
              type="button"
            />
            <Warning
              text={errorNotification?.text}
              isError={!!errorNotification || formValidationErrors.length > 0}
              formValidationErrors={formValidationErrors}
              noTranslate
            />
          </Flex>
          <Button
            variant="primary"
            text="modals.login.button"
            type="submit"
            fullWidth
            uppercase
            disabled={isButtonDisabled}
          />
        </Flex>
      </Form>
    </MainContainer>
  );
};
