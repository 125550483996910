import React, { useEffect } from 'react';

import { MosaicImageType } from 'cogamika-back/types';
import { apiUrl } from 'config';
import { MosaicCardType } from 'types';
import { StyledImage } from './styles';

interface Props {
  card: MosaicCardType;
  cardId: string;
  level: number;
  isUserWon: boolean;
  isAllImagesLoaded: boolean;
  handleClick: (cardId: string) => void;
  handleImageLoad: () => void;
}

export const MosaicCard: React.FC<Props> = ({
  card,
  level,
  cardId,
  isUserWon,
  isAllImagesLoaded,
  handleClick,
  handleImageLoad,
}) => {
  const isWon = isUserWon && card.type === MosaicImageType.Card;

  const cardClick = () => {
    if (isUserWon) return;

    handleClick(cardId);
  };

  useEffect(() => {
    const image = new Image();
    image.src = `${apiUrl}file/${card.imageId}`;
    image.onload = () => {
      handleImageLoad();
    };

    // eslint-disable-next-line
  }, [card.imageId]);

  if (!isAllImagesLoaded) {
    return (
      <StyledImage
        src="/assets/images/card_rectangle.png"
        level={level}
        onClick={cardClick}
        data-cy="card"
        isCorrect={card.isCorrect}
        isSelected={card.isSelected}
        isWon={isWon}
      />
    );
  }

  return (
    <StyledImage
      onClick={cardClick}
      src={`${apiUrl}file/${card.imageId}`}
      level={level}
      isCorrect={card.isCorrect}
      isSelected={card.isSelected}
      isWon={isWon}
    />
  );
};
