import { Dispatch, FC, SetStateAction, useEffect } from 'react';
import { useForm } from 'react-hook-form';
import { Button } from 'components/common';
import { FlexAlignCenter, FlexCenter } from 'components/common/Flex';
import { Form, TextInput } from 'components/forms';
import { useFaq } from 'hooks';
import { FaqAddEditTopicSchema } from 'types';
import { addEditTopicSchema } from 'config';
import { yupResolver } from '@hookform/resolvers/yup';
import { Language } from 'cogamika-back/types';

interface FaqQuestionEditFormProps {
  setTopicEditing: Dispatch<SetStateAction<boolean>>;
  topicId: string;
  topic: string;
  lang: Language;
}

export const FaqTopicEditForm: FC<FaqQuestionEditFormProps> = ({
  setTopicEditing,
  topicId,
  lang,
  topic: defaultValue,
}) => {
  const { addEditFaqTopic } = useFaq();

  const editTopicMethods = useForm<FaqAddEditTopicSchema>({
    mode: 'onSubmit',
    resolver: yupResolver(addEditTopicSchema),
  });

  const handleOnEditTopic = (data: FaqAddEditTopicSchema) => {
    addEditFaqTopic({ ...data, lang }, topicId);
    setTopicEditing(false);
  };

  useEffect(() => {
    const { setValue } = editTopicMethods;
    setValue('topic', defaultValue);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <Form
      submitHandler={handleOnEditTopic}
      methods={editTopicMethods}
      fullWidth
    >
      <FlexAlignCenter gap="md" columnDirection>
        <TextInput name="topic" placeholder="topic" fullWidth required />
        <FlexCenter gap="md">
          <Button variant="secondary" text="button.editTopic" type="submit" />
          <Button
            variant="outlined"
            text="button.cancel"
            type="button"
            onClick={() => setTopicEditing(false)}
          />
        </FlexCenter>
      </FlexAlignCenter>
    </Form>
  );
};
