import styled from 'styled-components';

interface FunctionBoxProps {
  active: boolean;
}

interface PaginationButtonProps {
  direction: 'left' | 'right';
}

export const FunctionBox = styled('button')<FunctionBoxProps>(
  ({ theme: { colors }, active }) => `
  padding: 8px 16px;
  border-radius: 73px;
  background-color: ${active ? colors.info + '28' : colors.gray + '50'};
  border: 2px solid ${active ? colors.info : colors.gray};
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
`
);

export const PaginationButton = styled('button')<PaginationButtonProps>(
  ({ theme: { colors }, direction }) => `
  border: none;
  padding: 8px 16px;
  background-color: transparent;
  cursor: pointer;

  &::after {
    display: block;
    content: "";
    border: solid ${colors.secondaryGray};
    border-width: 0 2px 2px 0;
    display: inline-block;
    padding: 3px;
    transform: rotate(${direction === 'right' ? '-45' : '-135'}deg);
    -webkit-transform: rotate(${direction === 'right' ? '-45' : '135'}deg);
  }
`
);
